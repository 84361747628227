
// Include our external dependencies.
import $ from 'jquery';
import * as vec3 from "gl-matrix/src/gl-matrix/vec3.js";
import * as vec4 from 'gl-matrix/src/gl-matrix/vec4.js';
import * as mat4 from "gl-matrix/src/gl-matrix/mat4.js";
import * as mat3 from "gl-matrix/src/gl-matrix/mat3.js";
import * as quat from "gl-matrix/src/gl-matrix/quat.js";
import {
    VolumeOverlayRoot,
    VolumeOverlayDisplayGroup,
    VolumeOverlayBorders,
    VolumeOverlayPlaneCrosshair
} from "./volume-overlay";
import Animate from './animate';
import Volume from './volume';
import dconsole from "./dconsole";

// PRIVATE METHODS ============================================================

// Initializes the volume viewer.  Invoked inside the constructor after
// all variables have been initialized.
VolumeViewer.prototype.initialize = function(selector, useWebGl2=true) {
    if (!selector.length)
        return;

    // Find, or create, the canvas that we will render to
    let canvas = selector;
    if (!canvas.is('canvas')) {
        canvas = selector.find('canvas');
        if (!canvas.length) {
            canvas = $('<canvas tabindex="-1" class="volume-3d-canvas"></canvas>');
            selector.append(canvas);
        }
    }

    canvas = canvas[0];
    this.canvas = canvas;
    this.topLevel = selector[0];

    // Attempt to initialize WebGL.  If we can't, display a message
    // in the HTML so the user knows what's up.
    if (!this.initWebGl(useWebGl2)) {
        this.initError(selector, "Unable to initialize 3D viewer. Your browser or device may not support it.");
        return;
    }

    // Create a canvas for annotations, if we can
    let canvasOverlay = $('<canvas class="volume-overlay-canvas" style="pointer-events:none;position:absolute;x:0;y:0;z-index:30;"></canvas>');
    canvasOverlay.insertBefore(canvas);
    this.canvasOverlay     = canvasOverlay[0];
    this.context2d         = this.canvasOverlay.getContext('2d', {alpha: true});
    this.rootOverlay       = null;
    this.annotationOverlay = null;
    this.crosshairOverlay  = null;
    this.borderOverlay     = null;
    try {
        this.rootOverlay       = new VolumeOverlayRoot(null, null, this, this.canvasOverlay, this.context2d);
        this.annotationOverlay = new VolumeOverlayDisplayGroup(this.rootOverlay, {
            pointColor:          "#FF4040",
            pointHighlightColor: "#D0D0FF",
            pointSelectColor:    "#FFFFFF",
            pointRadius:         7,
            lineColor:           "yellow",
            lineHighlightColor:  "#D0D0FF",
            lineSelectColor:     "#FFFFFF",
            lineWidth:           1,
            lineHighlightWidth:  2,
            lineSelectWidth:     3
        });
        this.borderOverlay     = new VolumeOverlayBorders(this.rootOverlay, {
            visible: false
        });
        this.crosshairOverlay  = new VolumeOverlayPlaneCrosshair(this.rootOverlay, {
            visible: false
        });
    } catch(e) {}

    if (!this.annotationOverlay) {
        // Failed to create annotation layers -- destroy everything
        canvasOverlay.remove();

        this.crosshairOverlay  = null;
        this.borderOverlay     = null;
        this.annotationOverlay = null;
        this.rootOverlay       = null;
        this.canvasOverlay     = null;
        this.context2d         = null;
    }

    this.initBuffers();

    this.initCallbacks();

    this.setInputMode(VolumeViewer.INPUT_MODE_SPIN);

    let vertShader = VolumeViewer.DEFAULT_VS;
    let fragShader = VolumeViewer.DEFAULT_FS;

    this.vertShaderScript = VolumeViewer.getShaderScript(vertShader, VolumeViewer.VERTEX_SHADER_TYPE);
    this.fragShaderScript = VolumeViewer.getShaderScript(fragShader, VolumeViewer.FRAGMENT_SHADER_TYPE);

    this.updateShader();

    this.miniVertShaderScript = VolumeViewer.getShaderScript(VolumeViewer.DEFAULT_MINI_VS, VolumeViewer.VERTEX_SHADER_TYPE);
    this.miniFragShaderScript = VolumeViewer.getShaderScript(VolumeViewer.DEFAULT_MINI_FS, VolumeViewer.FRAGMENT_SHADER_TYPE);

    this.updateMiniShader();

    // Set up rotation animation
    let viewer = this;
    let rotationSchedule = [
        { duration: 0.65, callback: function(params)
            {
                let rotationRate = viewer.rotationRate;
                if (params.mappedOffset < 1.0 && viewer.startRotationRate !== viewer.rotationRate) {
                    let offset   = params.offset;
                    let fromRate = viewer.startRotationRate;
                    let toRate   = viewer.rotationRate;
                    rotationRate = (toRate - fromRate) * offset + fromRate;
                }
                else {
                    viewer.startRotationRate   = viewer.rotationRate;
                }
                viewer.currentRotationRate = rotationRate;
                viewer.rotateYPR(rotationRate * 360.0 * params.timeDelta);
            }
        }
    ];

    this.rotationAnimation.setAnimationSchedule(rotationSchedule, true);
    //this.rotationAnimation.setDefaultMapper(Animate.mapEaseOut);

    this.enableAutoCanvasResize(true);
};

// Permanently takes out the canvas and displays an error message, if a fatal
// error occurs during initialization.
VolumeViewer.prototype.initError = function(selector, errorText) {
    let errorElement = selector.find(".volume-3d-error");
    if (!errorElement.length) {
        let errorContainer = $('<div class="volume-3d-error-container"></div>');
        errorElement = $('<span class="volume-3d-error"></span>');
        errorContainer.append(errorElement);
        selector.append(errorContainer);
    }
    errorElement.text(errorText);
    $(this.canvas).hide();
};

// Initializes WebGL, if possible.
VolumeViewer.prototype.initWebGl = function(useWebGl2=true) {
    let canvas = this.canvas;

    let glVersion = 0;
    let gl = undefined;

    const options = {
        //alpha: true
    };

    if (!gl) {
        if (useWebGl2) {
            gl = canvas.getContext('webgl2', options);
            if (gl) {
                glVersion = 2;
                dconsole.log("Initialized WebGL 2.0 (supported)");
            }
        }
    }
    if (!gl) {
        gl = canvas.getContext('webgl', options);
        if (gl) {
            glVersion = 1;
            dconsole.log("Initialized WebGL 1.0 (supported)");
        }
    }
    if (!gl) {
        // All major web browsers now support at least WebGL 1.0,
        // but we'll leave this here, just in case.
        gl = canvas.getContext('experimental-webgl', options);
        if (gl) {
            glVersion = 1;
            dconsole.log("Initialized Experimental WebGL (supported)");
        }
    }

    // If we don't have a GL context, give up now
    if (!gl) {
        dconsole.error("Could not initialize WegGL");
        return false;
    }

    this.gl = gl;
    this.glVersion = glVersion;

    this.textureProcessed = null;

    // Various limits for WebGL
    this.glInfo.maxTextureSize               = gl.getParameter(gl.MAX_TEXTURE_SIZE);
    this.glInfo.maxRenderBufferSize          = gl.getParameter(gl.MAX_RENDERBUFFER_SIZE);
    this.glInfo.maxCubeMapTextureSize        = gl.getParameter(gl.MAX_CUBE_MAP_TEXTURE_SIZE);
    this.glInfo.maxTextureImageUnits         = gl.getParameter(gl.MAX_TEXTURE_IMAGE_UNITS);
    this.glInfo.maxVertexUniformVectors      = gl.getParameter(gl.MAX_VERTEX_UNIFORM_VECTORS);
    this.glInfo.maxVertexAttribs             = gl.getParameter(gl.MAX_VERTEX_ATTRIBS);
    this.glInfo.maxVertexTextureImageUnits   = gl.getParameter(gl.MAX_VERTEX_TEXTURE_IMAGE_UNITS);
    this.glInfo.maxFragmentUniformVectors    = gl.getParameter(gl.MAX_FRAGMENT_UNIFORM_VECTORS);
    this.glInfo.maxCombinedTextureImageUnits = gl.getParameter(gl.MAX_COMBINED_TEXTURE_IMAGE_UNITS);

    if (glVersion >= 2) {
        this.glInfo.max3DTextureSize                     = gl.getParameter(gl.MAX_3D_TEXTURE_SIZE);
        this.glInfo.maxArrayTextureLayers                = gl.getParameter(gl.MAX_ARRAY_TEXTURE_LAYERS);
        this.glInfo.maxClientWaitTimeoutWebGL            = gl.getParameter(gl.MAX_CLIENT_WAIT_TIMEOUT_WEBGL);
        this.glInfo.maxColorAttachments                  = gl.getParameter(gl.MAX_COLOR_ATTACHMENTS);
        this.glInfo.maxCombinedFragmentUniformComponents = gl.getParameter(gl.MAX_COMBINED_FRAGMENT_UNIFORM_COMPONENTS);
        this.glInfo.maxCombinedUniformBlocks             = gl.getParameter(gl.MAX_COMBINED_UNIFORM_BLOCKS);
        this.glInfo.maxCombinedVertexUniformComponents   = gl.getParameter(gl.MAX_COMBINED_VERTEX_UNIFORM_COMPONENTS);
        this.glInfo.maxFragmentInputComponents           = gl.getParameter(gl.MAX_FRAGMENT_INPUT_COMPONENTS);
        this.glInfo.maxFragmentUniformBlocks             = gl.getParameter(gl.MAX_FRAGMENT_UNIFORM_BLOCKS);
        this.glInfo.maxFragmentUniformComponents         = gl.getParameter(gl.MAX_FRAGMENT_UNIFORM_COMPONENTS);
        this.glInfo.maxSamples                           = gl.getParameter(gl.MAX_SAMPLES);
        this.glInfo.maxUniformBlockSize                  = gl.getParameter(gl.MAX_UNIFORM_BLOCK_SIZE);
        this.glInfo.maxUniformBufferBindings             = gl.getParameter(gl.MAX_UNIFORM_BUFFER_BINDINGS);
        this.glInfo.maxVaryingComponents                 = gl.getParameter(gl.MAX_VARYING_COMPONENTS);
    }

    this.glMaxTextureSize = VolumeViewer.MAX_TEX_SIDE;
    if (this.glInfo.maxTextureSize)
        if (this.glMaxTextureSize > this.glInfo.maxTextureSize)
            this.glMaxTextureSize = this.glInfo.maxTextureSize;
    if (this.glInfo.max3DTextureSize)
        if (this.glMaxTextureSize > this.glInfo.max3DTextureSize)
            this.glMaxTextureSize = this.glInfo.max3DTextureSize;

    this.invalidateShader();
    this.invalidateOverlays();

    return true;
};

// Sets up callbacks associated with the canvas and its parent.
VolumeViewer.prototype.initCallbacks = function() {
    this.uninitCallbacks();

    const viewer = this;

    function handlerAny(event, handler, handle, capture=false, level=0) {
        viewer.beginUserAction(); try {
            if (handler && (handle in handler)) {
                const callback = handler[handle];
                if (callback && typeof callback === 'function') {
                    if (callback.call(viewer, event)) {
                        if (capture && event.target.setCapture)
                            event.target.setCapture(true);
                        event.preventDefault();
                        return true;
                    }
                }
            }

            // If the callback couldn't be processed, try the base handlers
            // (if there are any).
            if (level >= 8)
                return false;
            if (handler.base && typeof handler.base === 'object')
                return handlerAny(event, handler.base, handle, capture, level+1);
        }
        finally { viewer.endUserAction(); }

        return false;
    }

    function handlerMouseDown(event) {
        viewer.endDrag();
        viewer.mouseTouch = false;
        handlerAny(event, viewer.inputHandler, 'mousedown', true);
        canvas.focus();
    }
    function handlerMouseMove(event) {
        viewer.mouseTouch = false;
        handlerAny(event, viewer.inputHandler, 'mousemove');
    }
    function handlerMouseUp(event) {
        viewer.mouseTouch = false;

        // Detect clicks through the mouse interface
        let invokeClick = false;
        if (viewer.mousePressed && !viewer.mouseDrag)
            invokeClick = true;

        viewer.beginUserAction(); try {
            handlerAny(event, viewer.inputHandler, 'mouseup');

            if (invokeClick)
                handlerAny(event, viewer.inputHandler, 'mouseclick');
        }
        finally { viewer.endUserAction(); }
    }
    function handlerMouseLeave(event) {
        handlerAny(event, viewer.inputHandler, 'mouseleave');
    }
    function handlerDblClick(event) {
        handlerAny(event, viewer.inputHandler, 'dblclick');
    }
    function handlerTouchStart(event) {
        viewer.mouseTouch = true;
        viewer.endDrag();
        handlerAny(event, viewer.inputHandler, 'touchstart');
    }
    function handlerTouchMove(event) {
        viewer.mouseTouch = true;
        handlerAny(event, viewer.inputHandler, 'touchmove');
    }
    function handlerTouchEnd(event) {
        viewer.mouseTouch = true;

        // Detect clicks through the touch interface
        let invokeClick = false;
        if (viewer.mousePressed && !viewer.mouseDrag &&
            (event.touches.length === 0) && (event.changedTouches.length === 1))
            invokeClick = true;

        viewer.beginUserAction(); try {
            handlerAny(event, viewer.inputHandler, 'touchend');

            if (invokeClick)
                handlerAny(event, viewer.inputHandler, 'touchclick');

            // We handle touchend events a little differently...
            if (event.touches.length >= 1) {
                handlerTouchStart(event);
            }
        }
        finally { viewer.endUserAction(); }
    }
    function handlerKeyDown(event) {
        handlerAny(event, viewer.inputHandler, 'keydown');
    }
    function handlerKeyUp(event) {
        handlerAny(event, viewer.inputHandler, 'keyup');
    }
    function handlerKeyPress(event) {
        handlerAny(event, viewer.inputHandler, 'keypress');
    }
    function handlerContextMenu(event) {
        handlerAny(event, viewer.inputHandler, 'contextmenu');
    }
    function handlerResize(event) {
        viewer.invalidate();
    }
    let throttleTimer = null;
    let holdEvent     = null;
    let lastDirection = 0;
    function handlerWheel(event) {
        //handlerAny(event, viewer.inputHandler, 'wheel');

        // Because wheel events are very nonstandard across browsers and
        // platforms, we throttle wheel responses here.
        // Not a great solution, but it works.

        // Maximum number of wheel events every second
        const MAX_EVENTS_PER_SECOND = 20;

        // Minimum amount of time between wheel events, in milliseconds
        const DELAY_MSEC = 1000.0 / MAX_EVENTS_PER_SECOND;

        const funcName = 'wheel';

        function throttleFunction() {
            let event = holdEvent;
            holdEvent = null;
            if (event) {
                // For finer control, do not send events that indicate a
                // sudden change of direction
                let deltaY    = event.originalEvent.deltaY;
                let direction = deltaY >= 0 ? deltaY <= 0 ? 0 : -1 : 1;
                let reversal  = (direction * lastDirection) < 0;
                lastDirection = direction;
                if (!reversal)
                    // Fire the event we've been holding onto
                    handlerAny(event, viewer.inputHandler, funcName);
            }
            else {
                // No additional events reported since the last timeout;
                // kill the interval timer
                clearInterval(throttleTimer);
                throttleTimer = null;
                lastDirection = 0;
            }
        }

        if (!throttleTimer) {
            // No recent wheel events, so we can handle this event immediately.
            // Start a timer for the next one.
            throttleTimer = setInterval(throttleFunction, DELAY_MSEC);
            lastDirection = 0;
            handlerAny(event, viewer.inputHandler, funcName);
            return;
        }

        // We only reach this point if we are still in the timeout period
        // since the last event.
        // Save this event for later, and tell the event it's been handled.
        // Implicitly throw away any previous event we were holding.
        holdEvent = event;
        if (viewer.inputHandler && viewer.inputHandler.wheel)
            event.preventDefault();
    }

    const canvas = $(this.canvas);

    function addBinding(element, event, callback) {
        viewer.boundCallbacks.push({
            element:  element,
            event:    event,
            callback: callback
        });
        element.on(event, callback);
    }

    addBinding(canvas, "mousedown",   handlerMouseDown);
    addBinding(canvas, "mousemove",   handlerMouseMove);
    addBinding(canvas, "mouseup",     handlerMouseUp);
    addBinding(canvas, "mouseleave",  handlerMouseLeave);
    addBinding(canvas, "dblclick",    handlerDblClick);
    addBinding(canvas, "contextmenu", handlerContextMenu);
    addBinding(canvas, "touchstart",  handlerTouchStart);
    addBinding(canvas, "touchmove",   handlerTouchMove);
    addBinding(canvas, "touchend",    handlerTouchEnd);
    addBinding(canvas, "keydown",     handlerKeyDown);
    addBinding(canvas, "keyup",       handlerKeyUp);
    addBinding(canvas, "keypress",    handlerKeyPress);
    addBinding(canvas, "wheel",       handlerWheel);

    addBinding($(window), "resize",   handlerResize);
};

// Removes all callbacks associated with the canvas that we previously added.
VolumeViewer.prototype.uninitCallbacks = function() {
    const canvas = $(this.canvas);
    for (let i=0; i<this.boundCallbacks.length; ++i) {
        let obj = this.boundCallbacks[i];
        obj.element.off(obj.event, obj.callback);
    }
    this.boundCallbacks.length = 0;
};

// Initializes WebGL vertex and index buffers.
VolumeViewer.prototype.initBuffers = function() {
    const gl = this.gl;
    if (!gl) return;

    // This method will build a cube (or more accurately, a cuboid) that will be
    // used to display the volume.  The back faces of the cube will be the
    // starting points for the raytraces used to generate the volume.

    // Create a vertex buffer.
    const positionBuffer = gl.createBuffer();

    // Bind the array buffer
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

    // Create vertex positions for the cube.  These will be scaled inside
    // the shader.
    const positions = [
        // Front face
        -1.0, -1.0,  1.0,
         1.0, -1.0,  1.0,
         1.0,  1.0,  1.0,
        -1.0,  1.0,  1.0,

        // Back face
        -1.0, -1.0, -1.0,
        -1.0,  1.0, -1.0,
         1.0,  1.0, -1.0,
         1.0, -1.0, -1.0,

        // Top face
        -1.0,  1.0, -1.0,
        -1.0,  1.0,  1.0,
         1.0,  1.0,  1.0,
         1.0,  1.0, -1.0,

        // Bottom face
        -1.0, -1.0, -1.0,
         1.0, -1.0, -1.0,
         1.0, -1.0,  1.0,
        -1.0, -1.0,  1.0,

        // Right face
         1.0, -1.0, -1.0,
         1.0,  1.0, -1.0,
         1.0,  1.0,  1.0,
         1.0, -1.0,  1.0,

        // Left face
        -1.0, -1.0, -1.0,
        -1.0, -1.0,  1.0,
        -1.0,  1.0,  1.0,
        -1.0,  1.0, -1.0
    ];
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);

    // Now generate the index buffer for the vertex positions
    // (used to define the triangles necessary to create the cube).
    const indexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);

    // This array defines each face as two triangles, using the
    // indices into the vertex array to specify each triangle's
    // position.
    const indices = [
        0,  1,  2,      0,  2,  3,    // front
        4,  5,  6,      4,  6,  7,    // back
        8,  9,  10,     8,  10, 11,   // top
        12, 13, 14,     12, 14, 15,   // bottom
        16, 17, 18,     16, 18, 19,   // right
        20, 21, 22,     20, 22, 23    // left
    ];
    gl.bufferData(gl.ELEMENT_ARRAY_BUFFER,
        new Uint16Array(indices), gl.STATIC_DRAW);

    // Finally, combine both buffers in a convenient structure for later use.
    this.buffers = {
        position: positionBuffer,
        indices: indexBuffer
    };
};

// Invalidates all viewers that are linked to this viewer.
VolumeViewer.prototype.invalidateSlaveViewers = function() {
    const slaveViewers = this.slaveViewers;
    if (slaveViewers) {
        for (let i=0; i<slaveViewers.length; ++i) {
            const viewer = slaveViewers[i];
            if (viewer) {
                viewer.invalidate();
            }
        }
    }
};

// Compares two shader options objects.  Returns true if they are equal,
// or false otherwise.
VolumeViewer.prototype.compareShaderOptions = function(options0, options1) {
    if (!options0 && !options1)  return true;
    if (!options0)               return false;
    if (!options1)               return false;
    for (let key in options0) {
        if (!(key in options1))               return false;
        if (options0[key] !== options1[key])  return false;
    }
    for (let key in options1)
        if (!(key in options0))  return false;
    return true;
};

// Searches for a cached shader program, based on provided shader options.
// Returns null if the shader program was not found.
VolumeViewer.prototype.findCachedShader = function(shaderOptions) {
    if (!shaderOptions)  return null;

    const cachedShaders = this.cachedShaders;
    for (let i=0; i<cachedShaders.length; ++i) {
        let item = cachedShaders[i];
        if (item && this.compareShaderOptions(item.shaderOptions, shaderOptions))
            return item.shaderProgram;
    }
    return null;
};

// Adds the specified shader program to the cache, removing stale shaders
// if necessary.
VolumeViewer.prototype.addShaderToCache = function(shaderOptions, shaderProgram) {
    if (!shaderOptions || !shaderProgram)  return;

    const MAX_CACHED_SHADERS = 16;

    const cachedShaders = this.cachedShaders;
    let newItem = null;
    for (let i=0; i<cachedShaders.length; ++i) {
        let item = cachedShaders[i];
        if (item && this.compareShaderOptions(item.shaderOptions, shaderOptions)) {
            newItem = item;
            newItem.shaderProgram = shaderProgram;
            cachedShaders.splice(i, 1);
            break;
        }
    }

    if (!newItem) {
        let optionKey = Object.assign({}, shaderOptions);
        newItem = {
            shaderOptions: optionKey,
            shaderProgram: shaderProgram
        };
    }

    if (cachedShaders.length >= MAX_CACHED_SHADERS)
        // STM_TODO - explicitly delete shader?
        cachedShaders.shift();

    cachedShaders.push(newItem);
};

// Removes all shader programs from the cache.
VolumeViewer.prototype.clearCachedShaders = function() {
    // STM_TODO - should we delete the shaders explicitly?
    this.cachedShaders = [];
};

// Compiles and links the current WebGL vertex and fragment shaders, if
// possible.  If a compilation error occurs, displays a dialog with the
// compiler errors.  (This should only happen in development.)
VolumeViewer.prototype.updateShader = function() {
    // Sanity checks
    const gl = this.gl;
    if (!gl) {
        return false;
    }

    if (this.vertShaderScript === null) {
        return false;
    }

    if (this.fragShaderScript === null) {
        return false;
    }

    // Fast abort
    if (!this.checkShader && this.shaderOptions !== null &&
         this.shaderProgram !== null) {
        return true;
    }

    this.checkShader = false;

    // The following options, if changed, will cause the shader
    // to be recompiled.  Special preprocessor directives are added to the
    // shader code to speed up rendering if certain options aren't used.

    let interpolate            = this.interpolate;
    let gradRounding           = this.gradRounding;
    let offsetGradient         = this.halfGradient;
    let showLighting           = this.showLighting && (this.showLuminosityLighting || this.showGradientLighting);
    let showPlaneLighting      = this.showLighting && (this.showPlaneLighting);
    let showFog                = this.fogLevel > 0.0 && this.fogStart > -1.0;
    let showClipFlattening     = this.showClipFlattening;
    let showPlane0             = this.volume && this.volume.count > 0 &&
                                 this.perVolume[0].planeAlpha > 0.0;
    let showPlane1             = this.volume && this.volume.count > 1 &&
                                 this.perVolume[1].planeAlpha > 0.0;
    let showPlanes             = showPlane0 || showPlane1;
    let showPlaneBorders       = showPlanes && this.showPlaneBorders;
    let showPlaneIntersections = showPlanes &&
                                 (this.showPlaneIntersections);  // || this.showPlaneCrosshairs
    let drawTrueLines          = showPlanes && this.drawTrueLines;
    let showVolume0            = this.volume && this.volume.count > 0 &&
                                 (this.perVolume[0].luminosityAlpha > 0.0 ||
                                  this.perVolume[0].gradientAlpha > 0.0);
    let showVolume1            = this.volume && this.volume.count > 1 &&
                                 (this.perVolume[1].luminosityAlpha > 0.0 ||
                                  this.perVolume[1].gradientAlpha > 0.0);
    let showVolumes            = showVolume0 || showVolume1;
    let shadowsVisible         = this.shadowMult > 0.0 && this.shadowAmbient < 1.0;
    let showShadowsLuminosity  = this.showShadowsLuminosity && showVolumes && shadowsVisible;
    let showShadowsGradient    = this.showShadowsGradient && showVolumes && shadowsVisible;
    let showShadows            = showShadowsLuminosity || showShadowsGradient;
    let sampleOnAxis           = this.sampleOnAxis;
    let randomizeSampling      = this.randomizeSampling;
    let stipple                = this.stipple;
    let frontToBack            = this.frontToBack;

    let newShaderOptions = {
        interpolate:            interpolate,
        gradRounding:           gradRounding,
        offsetGradient:         offsetGradient,
        showLighting:           showLighting,
        showPlaneLighting:      showPlaneLighting,
        showFog:                showFog,
        showClipFlattening:     showClipFlattening,
        showPlanes:             showPlanes,
        showPlane0:             showPlane0,
        showPlane1:             showPlane1,
        showPlaneBorders:       showPlaneBorders,
        showPlaneIntersections: showPlaneIntersections,
        drawTrueLines:          drawTrueLines,
        showVolumes:            showVolumes,
        showVolume0:            showVolume0,
        showVolume1:            showVolume1,
        showShadows:            showShadows,
        showShadowsLuminosity:  showShadowsLuminosity,
        showShadowsGradient:    showShadowsGradient,
        sampleOnAxis:           sampleOnAxis,
        randomizeSampling:      randomizeSampling,
        stipple:                stipple,
        frontToBack:            frontToBack
    };

    let modified = false;
    if (this.shaderOptions === null)
        modified = true;
    else if (this.shaderProgram === null)
        modified = true;
    else if (!this.compareShaderOptions(this.shaderOptions, newShaderOptions))
        modified = true;

    // No recompile necessary.  We are done!
    if (!modified) {
        return true;
    }

    let startTime = Date.now();

    if (this.shaderOptions === null)
        this.shaderOptions = {};

    Object.assign(this.shaderOptions, newShaderOptions);

    this.shaderProgram = null;

    // Try to get a cached version of the shader first
    let usedCache = true;
    let shaderProgram = this.findCachedShader(this.shaderOptions);
    if (!shaderProgram) {
        // No cached version, so compile a new shader from scratch
        usedCache = false;

        const vertexShader = this.getShader(this.vertShaderScript, VolumeViewer.VERTEX_SHADER_TYPE);
        if (vertexShader === null) {
            dconsole.error("Failed to compile vertex shader");
            this.vertShaderScript = null;
            return false;
        }

        const fragmentShader = this.getShader(this.fragShaderScript, VolumeViewer.FRAGMENT_SHADER_TYPE);
        if (fragmentShader === null) {
            dconsole.error("Failed to compile fragment shader");
            this.fragShaderScript = null;
            return false;
        }

        // Create the shader program
        shaderProgram = gl.createProgram();
        gl.attachShader(shaderProgram, vertexShader);
        gl.attachShader(shaderProgram, fragmentShader);
        gl.linkProgram(shaderProgram);

        // Abort if the compilation/linking failed
        if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
            //alert('Unable to initialize the shader program: ' + gl.getProgramInfoLog(shaderProgram));
            dconsole.error("Failed to link shaders");
            return false;
        }
    }

    this.addShaderToCache(this.shaderOptions, shaderProgram);

    this.shaderProgram = shaderProgram;

    // This object contains all data needed by the shader programs,
    // particularly buffers and uniforms that are sent to the shader.
    const programInfo = {
        program: shaderProgram,
        attribLocations: {
            vertexPosition:             gl.getAttribLocation(shaderProgram, 'aVertexPosition')
        },
        uniformLocations: {
            projectionMatrix:           gl.getUniformLocation(shaderProgram, 'uProjectionMatrix'),
            modelViewMatrix:            gl.getUniformLocation(shaderProgram, 'uModelViewMatrix'),
            //normalMatrix:               gl.getUniformLocation(shaderProgram, 'uNormalMatrix'),
            inverseMatrix:              gl.getUniformLocation(shaderProgram, 'uInverseMatrix'),
            //inverseProjMatrix:          gl.getUniformLocation(shaderProgram, 'uInverseProjMatrix'),
            //inverseModelMatrix :        gl.getUniformLocation(shaderProgram, 'uInverseModelMatrix'),
            planeMatrix:                gl.getUniformLocation(shaderProgram, 'uPlaneMatrix'),

            uSamplerLum0:               gl.getUniformLocation(shaderProgram, 'uSamplerLum0'),
            uSamplerGrad0:              gl.getUniformLocation(shaderProgram, 'uSamplerGrad0'),
            uStripSampler0:             gl.getUniformLocation(shaderProgram, 'uStripSampler0'),

            uSamplerLum1:               gl.getUniformLocation(shaderProgram, 'uSamplerLum1'),
            uSamplerGrad1:              gl.getUniformLocation(shaderProgram, 'uSamplerGrad1'),
            uStripSampler1:             gl.getUniformLocation(shaderProgram, 'uStripSampler1'),

            planeBorderColors:          gl.getUniformLocation(shaderProgram, 'uPlaneBorderColors'),
            planeIntersectionColors:    gl.getUniformLocation(shaderProgram, 'uPlaneIntersectionColors'),

            lightPos:                   gl.getUniformLocation(shaderProgram, 'uLightPos'),
            lighting:                   gl.getUniformLocation(shaderProgram, 'uLighting'),
            backgroundColor:            gl.getUniformLocation(shaderProgram, 'uBackgroundColor'),
            fogColor:                   gl.getUniformLocation(shaderProgram, 'uFogColor'),
            showFlags:                  gl.getUniformLocation(shaderProgram, 'uShowFlags'),
            showFlags2:                 gl.getUniformLocation(shaderProgram, 'uShowFlags2'),
            canvasSize:                 gl.getUniformLocation(shaderProgram, 'uCanvasSize'),
            outlineColor:               gl.getUniformLocation(shaderProgram, 'uOutlineColor'),
            clipOutlineColor:           gl.getUniformLocation(shaderProgram, 'uClipOutlineColor'),
            levels:                     gl.getUniformLocation(shaderProgram, 'uLevels'),
            alphas:                     gl.getUniformLocation(shaderProgram, 'uAlphas'),
            transferRanges0:            gl.getUniformLocation(shaderProgram, 'uTransferRanges0'),
            transferRanges1:            gl.getUniformLocation(shaderProgram, 'uTransferRanges1'),
            clipPlanePoint:             gl.getUniformLocation(shaderProgram, 'uClipPlanePoint'),
            clipPlaneNormal:            gl.getUniformLocation(shaderProgram, 'uClipPlaneNormal'),
            planePoint:                 gl.getUniformLocation(shaderProgram, 'uPlanePoint'),
            planeAlpha:                 gl.getUniformLocation(shaderProgram, 'uPlaneAlpha'),
            planeAlphaLevels:           gl.getUniformLocation(shaderProgram, 'uPlaneAlphaLevels'),
            planeCrosshairs:            gl.getUniformLocation(shaderProgram, 'uPlaneCrosshairs'),
            selectedPoint:              gl.getUniformLocation(shaderProgram, 'uSelectedPoint'),
            sampleCount:                gl.getUniformLocation(shaderProgram, 'uSampleCount'),
            scale:                      gl.getUniformLocation(shaderProgram, 'uScale'),
            size:                       gl.getUniformLocation(shaderProgram, 'uSize'),
            shadowParams:               gl.getUniformLocation(shaderProgram, 'uShadowParams'),
            depthTiles:                 gl.getUniformLocation(shaderProgram, 'uDepthTiles'),
            miscValues:                 gl.getUniformLocation(shaderProgram, 'uMiscValues')
        }
    };

    this.programInfo = programInfo;

    let endTime = Date.now();
    let deltaTime = (endTime - startTime) / 1000.0;
    if (usedCache)
        dconsole.log(`Used cached shader code (time=${deltaTime}s)`);
    else
        dconsole.log(`Recompiled shader code (time=${deltaTime}s)`);

    return true;
};

// Compiles and links the WebGL vertex and fragment shaders associated with the
// mini-display, if possible.  If a compilation error occurs, displays a dialog
// with the compiler errors.  (This should only happen in development.)
VolumeViewer.prototype.updateMiniShader = function() {
    if (!this.showMiniCube)  return false;

    // Sanity checks
    const gl = this.gl;
    if (!gl) {
        return false;
    }

    if (this.miniVertShaderScript === null) {
        return false;
    }

    if (this.miniFragShaderScript === null) {
        return false;
    }

    let modified = false;
    if (this.miniShaderProgram === null)
        modified = true;

    // STM_TODO - add shader options here as necessary

    // No recompile necessary.  We are done!
    if (!modified) {
        return true;
    }

    dconsole.log("Recompiling mini-shader code");

    // STM_TODO - add shader options here as necessary
    //if (this.shaderOptions === null)
    //    this.shaderOptions = {};

    this.miniShaderProgram = null;

    const vertexShader = this.getShader(this.miniVertShaderScript, VolumeViewer.VERTEX_SHADER_TYPE);
    if (vertexShader === null) {
        dconsole.error("Failed to compile mini vertex shader");
        this.miniVertShaderScript = null;
        return false;
    }

    const fragmentShader = this.getShader(this.miniFragShaderScript, VolumeViewer.FRAGMENT_SHADER_TYPE);
    if (fragmentShader === null) {
        dconsole.error("Failed to compile mini fragment shader");
        this.miniFragShaderScript = null;
        return false;
    }

    // Create the shader program
    const shaderProgram = gl.createProgram();
    gl.attachShader(shaderProgram, vertexShader);
    gl.attachShader(shaderProgram, fragmentShader);
    gl.linkProgram(shaderProgram);

    // Abort if the compilation/linking failed
    if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
        //alert('Unable to initialize the shader program: ' + gl.getProgramInfoLog(shaderProgram));
        return false;
    }

    this.miniShaderProgram = shaderProgram;

    // This object contains all data needed by the shader programs,
    // particularly buffers and uniforms that are sent to the shader.
    const miniProgramInfo = {
        program: shaderProgram,
        attribLocations: {
            vertexPosition:     gl.getAttribLocation(shaderProgram, 'aVertexPosition')
        },
        uniformLocations: {
            projectionMatrix:    gl.getUniformLocation(shaderProgram, 'uProjectionMatrix'),
            modelViewMatrix:     gl.getUniformLocation(shaderProgram, 'uModelViewMatrix'),
            inverseMatrix:       gl.getUniformLocation(shaderProgram, 'uInverseMatrix'),

            samplerFaces:        gl.getUniformLocation(shaderProgram, 'uSamplerFaces'),

            lightPos:            gl.getUniformLocation(shaderProgram, 'uLightPos'),
            faceHighlight:       gl.getUniformLocation(shaderProgram, 'uFaceHighlight'),
            faceColor:           gl.getUniformLocation(shaderProgram, 'uFaceColor')
        }
    };

    this.miniProgramInfo = miniProgramInfo;

    return true;
};

// Generates a shader-friendly version of the volume, with surface normals
// and smoothing, that can be bound to a WebGL texture.
VolumeViewer.prototype.processVolume = function() {
    if (this.linkedViewer !== null) {
        // Special case for linked viewers (we'll use the linked viewer's
        // processed volume instead of our own)
        let viewer = this.linkedViewer;

        if (viewer.volumeProcessed === null)
            viewer.processVolume();

        if (this.volumeProcessed === viewer.volumeProcessed)  return;

        this.volume = viewer.volume;
        this.volumeName = viewer.volumeName;
        this.volumeProcessed = viewer.volumeProcessed;
    }
    else {
        if (this.volumeProcessed)  return;

        const volume = this.volume;
        if (!volume)  return;

        let volumeProcessed = null;

        let processedSize = this.computeProcessedVolumeSize(volume);
        if (!processedSize) {
            if (this.glVersion >= 2)
                dconsole.error("Unable to build 3D volume (size too large)");
            else
                dconsole.error("Unable to map 3D volume to 2D texture (size too large)");
        }

        const upscaleX = processedSize.upscaleX;
        const upscaleY = processedSize.upscaleY;
        const upscaleZ = processedSize.upscaleZ;

        if (upscaleX !== 1.0 || upscaleY !== 1.0 || upscaleZ !== 1.0) {
            dconsole.log(`Auto-upscale: (x${upscaleX}, x${upscaleY}, x${upscaleZ})`);
        }

        let volumeArray = null;
        if (volume.count > 0) {
            volumeArray = [];
            volumeArray.length = volume.count;
        }

        for (let i=0; i<volume.count; ++i) {
            let subVolumeProcessed = null;
            const subVolume = Volume.generateSubVolume(volume, i);
            if (subVolume)
                subVolumeProcessed = this.generateTextureVolume(processedSize, subVolume,
                                                                this.perVolume[i]);

            if (subVolumeProcessed !== null) {
                volumeArray[i] = subVolumeProcessed;
            }
            else {
                volumeArray = null;
                break;
            }
        }

        if (volumeArray !== null) {
            const firstVolume = volumeArray[0];

            const width      = firstVolume.width;
            const height     = firstVolume.height;
            const depth      = firstVolume.depth;
            const depthRows  = firstVolume.depthRows;
            const depthCols  = firstVolume.depthCols;
            const scaleMultX = firstVolume.scaleMultX;
            const scaleMultY = firstVolume.scaleMultY;
            const scaleMultZ = firstVolume.scaleMultZ;

            // Sanity check
            for (let i=1; i<volumeArray.length; ++i) {
                const volume = volumeArray[i];
                if (volume.width !== width || volume.height !== height ||
                    volume.depth !== depth ||
                    volume.depthRows !== depthRows || volume.depthCols !== depthCols) {

                    volumeArray = null;
                    break;
                }
            }

            if (volumeArray !== null) {
                volumeProcessed = {
                    volumeArray: volumeArray,
                    width:       width,
                    height:      height,
                    depth:       depth,
                    depthRows:   depthRows,
                    depthCols:   depthCols,
                    scaleMultX:  scaleMultX,
                    scaleMultY:  scaleMultY,
                    scaleMultZ:  scaleMultZ
                };
            }
        }

        this.volumeProcessed = volumeProcessed;
    }

    // Destroy existing textures so we can regenerate them later
    this.releaseTextures();

    if (this.volumeProcessed !== null) {
        for (let i=0; i<this.volumeProcessed.volumeArray.length; ++i) {
            let subVolumeProcessed = this.volumeProcessed.volumeArray[i];

            // These were calculated when the texture volume was generated
            this.perVolume[i].autoMinLuminosity        = subVolumeProcessed.minLuminosity;
            this.perVolume[i].autoMaxLuminosity        = subVolumeProcessed.maxLuminosity;
            this.perVolume[i].autoMinGradientMagnitude = subVolumeProcessed.minGradientMagnitude;
            this.perVolume[i].autoMaxGradientMagnitude = subVolumeProcessed.maxGradientMagnitude;
        }

        // Volume is now ready for rendering; invoke callbacks
        this.invokeVolumeProcessedCallbacks();
    }
    else {
        dconsole.warn("Clearing volume due to error");
        this.volume = null;
        this.volumeName = null;
    }
};

// Invoked when WebGL is ready to render to the canvas.
VolumeViewer.prototype.render = function(timestamp) {
    this.animationId = null;

    let canvas = this.canvas;
    if (!canvas)  return;

    let canvasVisible = !!( canvas.offsetWidth || canvas.offsetHeight || canvas.getClientRects().length );

    // How many more frames do we have to animate before we can stop?
    let lastFrame = this.framesLeft <= 0;
    if (canvasVisible) {
        if (this.framesLeft > 0)
            --this.framesLeft;
        else
            this.framesLeft = 0;

        // How much time has passed since the last render?
        timestamp = timestamp * 0.001;  // convert to seconds
        if (this.lastTimestamp === null)
            this.lastTimestamp = timestamp;
        let deltaTime = timestamp - this.lastTimestamp;
        this.lastTimestamp = timestamp;

        // Ignore long delta times
        if (deltaTime >= 3.0)  {
            deltaTime = 0.0;
        }

        // Calculate frames per second
        if (this.frameValid) {
            if (deltaTime > 0.0) {
                const MIN_FRAMES = 3;
                const MAX_FRAMES = 5;

                this.frames.push(deltaTime);
                if (this.frames.length > MAX_FRAMES)
                    this.frames.splice(0, this.frames.length-MAX_FRAMES);

                if (this.frames.length >= MIN_FRAMES) {
                    let time = 0.0;
                    for (let i=0; i<this.frames.length; ++i)
                        time += this.frames[i];
                    if (time > 0.0)
                        this.fps = this.frames.length / time;
                }
            }
        }
        else {
            deltaTime = 0.0;
        }

        // Now actually draw stuff!
        this.renderScene(deltaTime);
    }

    // Request another animation frame, if necessary
    if (!lastFrame) {
        if (this.animationId === null)
            this.animationId = requestAnimationFrame(this.renderProxy);

        this.frameValid = true;
    }
    else {
        this.frameValid = false;
    }
};

// Processes and renders the volume (with callbacks).
VolumeViewer.prototype.renderScene = function(deltaTime) {
    // Invoke callbacks before rendering
    this.invokePreRenderCallbacks();

    // Perform deferred processing on the volume
    this.update();

    // Finally, draw the scene
    this.drawScene();

    // Invoke callbacks after rendering
    this.invokePostRenderCallbacks();

    // If forced flushing is enabled, do it now
    if (this.forcedFlush)
        this.flush();
};

// Flushes the current render.  Only returns when the canvas has been
// completely updated.
VolumeViewer.prototype.flush = function() {
    const gl = this.gl;
    if (gl) {
        gl.finish();  // flush and synchronize
    }
};

// Performs deferred processing prior to a render.
VolumeViewer.prototype.update = function() {
    this.processVolume();

    this.updateShader();
    this.updateMiniShader();

    this.bindVolumeToTextures();
    this.bindMiniTexture();
};

// Draws the entire scene (i.e. the volume) in WebGL.
VolumeViewer.prototype.drawScene = function() {
    // Sanity checks
    const gl = this.gl;
    const glVersion = this.glVersion;
    if (!gl) return;

    // Make sure we're setting up the viewport correctly
    const canvas = this.canvas;
    let canvasWidth = canvas.width;
    let canvasHeight = canvas.height;
    gl.viewport(0, 0, canvasWidth, canvasHeight);

    let backgroundColor = this.getCurrentBackgroundColor();

    // Clear to background color
    gl.clearColor(backgroundColor[0], backgroundColor[1], backgroundColor[2], 1.0);

    // Clear depth buffer
    gl.clearDepth(1.0);
    gl.depthFunc(gl.LEQUAL);

    gl.disable(gl.BLEND);
    //gl.enable(gl.BLEND);
    //gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    gl.disable(gl.DEPTH_TEST);
    gl.enable(gl.CULL_FACE);

    // NOTE: we cull the front face because our raytracing shader will render
    // starting from the BACK of the cube and and moving forward.
    gl.cullFace(gl.FRONT);

    // Clear the canvas before we start drawing on it
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    // Early abort if we can't draw...
    const programInfo = this.programInfo;
    const buffers = this.buffers;
    const volumeProcessed = this.volumeProcessed;
    if (!this.volume || !programInfo || !buffers || !volumeProcessed) return;

    const volumeArray = volumeProcessed.volumeArray;
    if (!volumeArray) return;

    const volumeCount = this.volume.count;

    // Set up the vertex buffer
    {
        const numComponents = 3;
        const type = gl.FLOAT;
        const normalize = false;
        const stride = 0;
        const offset = 0;
        gl.bindBuffer(gl.ARRAY_BUFFER, buffers.position);
        gl.vertexAttribPointer(programInfo.attribLocations.vertexPosition,
                               numComponents,
                               type,
                               normalize,
                               stride,
                               offset);
        gl.enableVertexAttribArray(programInfo.attribLocations.vertexPosition);
    }

    // Set up the index buffer
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffers.indices);

    // Specify the shader
    gl.useProgram(programInfo.program);

    const uniforms = programInfo.uniformLocations;

    let clipX = this.clipPlanePoint[0] + this.clipPlaneNormal[0] * this.clipPlaneOffset;
    let clipY = this.clipPlanePoint[1] + this.clipPlaneNormal[1] * this.clipPlaneOffset;
    let clipZ = this.clipPlanePoint[2] + this.clipPlaneNormal[2] * this.clipPlaneOffset;

    let planeData = this.getInternalPlaneData();
    const planeMatrix = planeData.matrix;
    const planePoint  = planeData.point;

    let selectedPoint;
    if (this.selectedPoint)
        selectedPoint = [this.selectedPoint[0], this.selectedPoint[1], this.selectedPoint[2], 1.0];
    else
        selectedPoint = [0.0, 0.0, 0.0, 0.0];

    // Set scaling based on resampling
    let viewer = this.linkedViewer || this;
    let origVolume = viewer.volume;
    let scale = [
        volumeProcessed.scaleMultX * origVolume.scaleX,
        volumeProcessed.scaleMultY * origVolume.scaleY,
        volumeProcessed.scaleMultZ * origVolume.scaleZ
    ];

    for (let i=0; i<volumeCount; ++i) {
        if (this.perVolume[i].stripTexture === null) {
            let arrays = [
                this.perVolume[i].luminosityArray,
                this.perVolume[i].gradientArray,
                this.perVolume[i].planeArray
            ];
            this.perVolume[i].stripTexture = this.createTextureStrips(arrays);
        }
    }

    // Compute a dynamic sampling rate, based on our framerate
    let volumeSamples = Math.max(Math.max(volumeProcessed.width, volumeProcessed.height), volumeProcessed.depth);
    let maxSampleCount = this.sampleCount;
    if (!maxSampleCount) {
        maxSampleCount = (volumeSamples * 0.75) + 2;
        const DEFAULT_SAMPLE_COUNT = 28;
        if (maxSampleCount < DEFAULT_SAMPLE_COUNT)
            maxSampleCount = DEFAULT_SAMPLE_COUNT;
    }
    let sampleCount = maxSampleCount;
    if (this.autoAdjustFramerate && this.fps !== null && this.framesLeft > 0) {
        const MIN_FRAMERATE = 15.0;
        const MAX_FRAMERATE = 28.0;

        const minSampleCount = Math.floor(maxSampleCount * 0.55);

        const fps = this.fps;
        if (this.calculatedSampleCount !== null) {
            if (fps > MAX_FRAMERATE) {
                // High FPS - increase sample count
                sampleCount = this.calculatedSampleCount + 5;
            }
            else if (fps >= MIN_FRAMERATE) {
                // Normal FPS - make no adjustments
                sampleCount = this.calculatedSampleCount;
            }
            else {
                // Low FPS - decrease sample count
                sampleCount = Math.floor(this.calculatedSampleCount * fps /
                                         MIN_FRAMERATE);
            }
        }
        if (sampleCount < minSampleCount)
            sampleCount = minSampleCount;
        if (sampleCount > maxSampleCount)
            sampleCount = maxSampleCount;
        if (sampleCount < 4)
            sampleCount = 4;

        this.calculatedSampleCount = sampleCount;
    }

    let lumAlpha0   = 0.0, lumAlpha1   = 0.0;
    let gradAlpha0  = 0.0, gradAlpha1  = 0.0;
    let planeAlpha0 = 0.0, planeAlpha1 = 0.0;
    if (volumeCount > 0) {
        const vol = this.perVolume[0];
        lumAlpha0   = vol.luminosityAlpha * vol.luminosityMaxAlpha;
        gradAlpha0  = vol.gradientAlpha   * vol.gradientMaxAlpha;
        planeAlpha0 = vol.planeAlpha      * vol.planeMaxAlpha;
    }
    if (volumeCount > 1) {
        const vol = this.perVolume[1];
        lumAlpha1   = vol.luminosityAlpha * vol.luminosityMaxAlpha;
        gradAlpha1  = vol.gradientAlpha   * vol.gradientMaxAlpha;
        planeAlpha1 = vol.planeAlpha      * vol.planeMaxAlpha;
    }

    backgroundColor = this.backgroundColor;

    // STM_TODO - do not regenerate this all the time!
    const planeBorder = this.planeBorderColors;
    const planeBorderLineWidth = this.planeBorderLineWidth;
    const planeIntersect = this.planeIntersectionColors;
    const planeIntersectionLineWidth = this.planeIntersectionLineWidth;
    const planeBorderMatrix = [
        planeBorder[0][0], planeBorder[0][1], planeBorder[0][2], planeBorder[0][3],
        planeBorder[1][0], planeBorder[1][1], planeBorder[1][2], planeBorder[1][3],
        planeBorder[2][0], planeBorder[2][1], planeBorder[2][2], planeBorder[2][3],
        0.0, 0.0, 0.0, planeBorderLineWidth
    ];
    let planeIntersectionMatrix = [
        planeIntersect[0][0], planeIntersect[0][1], planeIntersect[0][2], planeIntersect[0][3],
        planeIntersect[1][0], planeIntersect[1][1], planeIntersect[1][2], planeIntersect[1][3],
        planeIntersect[2][0], planeIntersect[2][1], planeIntersect[2][2], planeIntersect[2][3],
        0.0, 0.0, 0.0, planeIntersectionLineWidth
    ];
    if (false)  // STM_TODO - NO LONGER USED
        if (this.showPlaneCrosshairs) {
            const planeCrosshairColor = this.planeCrosshairColor;
            planeIntersectionMatrix = [
                planeCrosshairColor[0], planeCrosshairColor[1], planeCrosshairColor[2], planeCrosshairColor[3],
                planeCrosshairColor[0], planeCrosshairColor[1], planeCrosshairColor[2], planeCrosshairColor[3],
                planeCrosshairColor[0], planeCrosshairColor[1], planeCrosshairColor[2], planeCrosshairColor[3],
                0.0, 0.0, 0.0, planeIntersectionLineWidth
            ];
        }
    const planeAlphaLevels = [
        this.autoPlane ? 0.0 : this.planeAlphaLevels[0],  // suppress this plane in autoplane
        this.autoPlane ? 0.0 : this.planeAlphaLevels[1],  // suppress this plane in autoplane
        this.planeAlphaLevels[2],
        0.0
    ];
    // STM_TODO - NO LONGER USED
    const showPlaneCrosshairs = false;  // this.showPlaneCrosshairs
    const planeCrosshairs = [
        showPlaneCrosshairs ? this.planeCrosshairRadiusInner : 0.0,
        showPlaneCrosshairs ? this.planeCrosshairRadiusOuter : 4.0,
        showPlaneCrosshairs ? 1.0 : 0.0,
        0.0
    ];

    // Set the shader uniforms
    gl.uniformMatrix3fv(
        uniforms.planeMatrix,
        false,
        planeMatrix);

    gl.uniform4fv(
        uniforms.backgroundColor,
        backgroundColor);
    gl.uniform4fv(
        uniforms.levels,
        [this.ambient, this.fogLevel, this.fogStart, this.diffusePow]);
    gl.uniform4fv(
        uniforms.lighting,
        [this.specularLevel, this.planeSpecularLevel, this.clipSpecularLevel, 0]);
    gl.uniform4iv(
        uniforms.showFlags,
        [this.showOutline ? 1 : 0,
         0,//this.showSpecular ? 1 : 0,  // STM_TODO - find something else to put here
         this.showLuminosityLighting && this.showLighting ? 1 : 0,
         this.showGradientLighting && this.showLighting ? 1 : 0]);
    gl.uniform4iv(
        uniforms.showFlags2,
        [this.showClipOutline ? 1 : 0,
         0,//this.showPlaneSpecular ? 1 : 0,  // STM_TODO - find something else to put here
         this.showPlaneLighting && this.showLighting ? 1 : 0,
         0]);
    gl.uniform4fv(
        uniforms.outlineColor,
        this.outlineColor);
    gl.uniform4fv(
        uniforms.clipOutlineColor,
        this.clipOutlineColor);
    gl.uniform4fv(
        uniforms.alphas,
        [lumAlpha0, gradAlpha0, lumAlpha1, gradAlpha1]);
    gl.uniform4fv(
        uniforms.transferRanges0,
        [this.perVolume[0].minLuminosity, this.perVolume[0].maxLuminosity,
         this.perVolume[0].minGradientMagnitude, this.perVolume[0].maxGradientMagnitude]);
    if (volumeCount > 1)
        gl.uniform4fv(
            uniforms.transferRanges1,
            [this.perVolume[1].minLuminosity, this.perVolume[1].maxLuminosity,
             this.perVolume[1].minGradientMagnitude, this.perVolume[1].maxGradientMagnitude]);
    gl.uniform3fv(
        uniforms.clipPlanePoint,
        [clipX, clipY, clipZ]);
    gl.uniform3fv(
        uniforms.clipPlaneNormal,
        this.showClipping ?
        this.clipPlaneNormal : [0.0, 0.0, 0.0]);
    gl.uniform3fv(
        uniforms.planePoint,
        planePoint);
    gl.uniform4fv(
        uniforms.selectedPoint,
        selectedPoint);
    gl.uniform2fv(
        uniforms.planeAlpha,
        [planeAlpha0, planeAlpha1]);
    gl.uniform4fv(
        uniforms.planeAlphaLevels,
        planeAlphaLevels);
    gl.uniform4fv(
        uniforms.planeCrosshairs,
        planeCrosshairs);
    gl.uniformMatrix4fv(
        uniforms.planeBorderColors,
        false,
        planeBorderMatrix);
    gl.uniformMatrix4fv(
        uniforms.planeIntersectionColors,
        false,
        planeIntersectionMatrix);
    gl.uniform1f(
        uniforms.sampleCount,
        sampleCount);
    gl.uniform3fv(
        uniforms.scale,
        scale);
    gl.uniform3fv(
        uniforms.size,
        [volumeProcessed.width, volumeProcessed.height, volumeProcessed.depth]);
    gl.uniform4fv(
        uniforms.shadowParams,
        [this.shadowMult, this.minShadowAlpha*70.0, this.shadowStepMult, this.shadowAmbient]);
    gl.uniform4fv(
        uniforms.depthTiles,
        [volumeProcessed.depthCols, volumeProcessed.depthRows,
         1.0/volumeProcessed.depthCols, 1.0/volumeProcessed.depthRows]);
    gl.uniform4fv(
        uniforms.miscValues,
        [this.halfGradient ? 0.5 : 0.0,
         this.fadeLevel,
         0.0, 0.0]);

    const target = glVersion >= 2 ? gl.TEXTURE_3D : gl.TEXTURE_2D;

    // Set up our multiple volumes
    const textureArray = this.textureProcessed;
    for (let i=0; i<textureArray.length; ++i) {
        let sampler = null;
        // STM_TODO - fix these (ordering is not good)
        if      (i === 0)  sampler = uniforms.uSamplerGrad0;
        else if (i === 1)  sampler = uniforms.uSamplerGrad1;
        else if (i === 2)  sampler = uniforms.uSamplerLum0;
        else if (i === 3)  sampler = uniforms.uSamplerLum1;
        else               break;

        gl.activeTexture(gl.TEXTURE0 + i);
        gl.bindTexture(target, textureArray[i]);
        gl.uniform1i(sampler, i);
    }

    // Do the same with our other textures
    const offset = VolumeViewer.MAX_VOLUMES*2;

    gl.activeTexture(gl.TEXTURE0 + offset);
    gl.bindTexture(gl.TEXTURE_2D, this.perVolume[0].stripTexture);
    gl.uniform1i(uniforms.uStripSampler0, 0 + offset);

    if (volumeCount > 1) {
        gl.activeTexture(gl.TEXTURE1 + offset);
        gl.bindTexture(gl.TEXTURE_2D, this.perVolume[1].stripTexture);
        gl.uniform1i(uniforms.uStripSampler1, 1 + offset);
    }

    const showParallel = this.showParallel;

    let passes = this.generatePasses();

    gl.enable(gl.SCISSOR_TEST);

    // Draw border for stereo mode
    let borderColor = this.stereoBorderColor;
    for (let i=0; i<passes.length; ++i) {
        let viewport = passes[i];

        if (viewport.borderSize > 0) {
            gl.viewport(viewport.canvasX,
                        viewport.canvasY,
                        viewport.canvasWidth,
                        viewport.canvasHeight);

            // border
            gl.scissor(viewport.canvasX,
                       viewport.canvasY,
                       viewport.canvasWidth,
                       viewport.canvasHeight);
            gl.clearColor(borderColor[0], borderColor[1], borderColor[2], 1.0);
            gl.clear(gl.COLOR_BUFFER_BIT);

            // background
            gl.scissor(viewport.scissorX,
                       viewport.scissorY,
                       viewport.scissorWidth,
                       viewport.scissorHeight);
            gl.clearColor(backgroundColor[0], backgroundColor[1], backgroundColor[2], 1.0);
            gl.clear(gl.COLOR_BUFFER_BIT);

        }
    }

    // Draw the volumes
    for (let i=0; i<passes.length; ++i)
    {
        let viewport = passes[i];

        gl.viewport(viewport.canvasX,
                    viewport.canvasY,
                    viewport.canvasWidth,
                    viewport.canvasHeight);

        gl.scissor(viewport.scissorX,
                   viewport.scissorY,
                   viewport.scissorWidth,
                   viewport.scissorHeight);

        const aspect = viewport.aspect;

        let projectionMatrix, modelViewMatrix, forwardMatrix, inverseMatrix;
        let eyeDistance;

        if (viewport.displayView === VolumeViewer.VIEW_CENTER) {
            projectionMatrix = this.projectionMatrix;
            modelViewMatrix  = this.modelViewMatrix;
            forwardMatrix    = this.forwardMatrix;
            inverseMatrix    = this.inverseMatrix;
            eyeDistance      = 0.0;
        }
        else {
            projectionMatrix = this.stereoProjectionMatrix[i];
            modelViewMatrix  = this.stereoModelViewMatrix[i];
            forwardMatrix    = this.stereoForwardMatrix[i];
            inverseMatrix    = this.stereoInverseMatrix[i];
            eyeDistance      = this.eyeDistance * (i * 2.0 - 1.0);
        }
        eyeDistance *= showParallel ? -1.0 : 1.0;

        // Generate all of our projection matrices
        let distance = this.generateMatrices(projectionMatrix, modelViewMatrix, aspect,
                                             eyeDistance);

        // Other specialized matrices
        mat4.multiply(forwardMatrix, projectionMatrix, modelViewMatrix);
        mat4.invert(inverseMatrix, forwardMatrix);

        // Calculate the light position, based on user preference
        let lightPos = this.lightPos;
        if (!this.lightPosAbsolute) {
            const rotationMatrix = this.combineMatrices();
            lightPos = vec3.clone(lightPos);
            const inverseRotationMatrix = mat4.create();
            mat4.invert(inverseRotationMatrix, rotationMatrix);
            lightPos[0] *= distance;
            lightPos[1] *= distance;
            lightPos[2] *= distance;
            vec3.transformMat4(lightPos, lightPos, inverseRotationMatrix);
        }

        // Set perspective uniforms
        gl.uniformMatrix4fv(
            uniforms.projectionMatrix,
            false,
            projectionMatrix);
        gl.uniformMatrix4fv(
            uniforms.modelViewMatrix,
            false,
            modelViewMatrix);
        gl.uniformMatrix4fv(
            uniforms.inverseMatrix,
            false,
            inverseMatrix);
        gl.uniform4fv(
            uniforms.canvasSize,
            [viewport.canvasWidth, viewport.canvasHeight,
            viewport.canvasX, viewport.canvasY]);
        gl.uniform3fv(
            uniforms.lightPos,
            lightPos);

        // Finally, DRAW the friggin' thing!
        {
            const vertexCount = 36;
            const type = gl.UNSIGNED_SHORT;
            const offset = 0;
            gl.drawElements(gl.TRIANGLES, vertexCount, type, offset);
        }
    }

    // Then, if enabled, draw the mini-cube...
    this.drawMiniCube(passes);

    gl.disable(gl.SCISSOR_TEST);

    // Finally, redraw annotations
    if (this.rootOverlay)
        this.rootOverlay.render(passes);
};

// Draws the mini-cube in WebGL.
VolumeViewer.prototype.drawMiniCube = function(passes) {
    if (!passes)  return;

    if (!this.showMiniCube || !this.miniCubeUrl)  return;

    const miniProgramInfo = this.miniProgramInfo;
    if (!miniProgramInfo)  return;

    // Sanity checks
    const gl = this.gl;
    const glVersion = this.glVersion;
    if (!gl) return;

    const canvas = this.canvas;

    const showParallel = this.showParallel;

    for (let i=0; i<passes.length; ++i)
    {
        let viewport = passes[i];

        gl.viewport(viewport.canvasX,
                    viewport.canvasY,
                    viewport.canvasWidth,
                    viewport.canvasHeight);

        gl.scissor(viewport.scissorX,
                   viewport.scissorY,
                   viewport.scissorWidth,
                   viewport.scissorHeight);

        const aspect = viewport.aspect;

        const buffers = this.buffers;
        if (!buffers) return;

        let projectionMatrix, modelViewMatrix, inverseMatrix;
        let eyeDistance;

        const miniProjectionMatrix = this.tempMiniCubeMatrix;

        if (viewport.displayView === VolumeViewer.VIEW_CENTER) {
            projectionMatrix = this.miniCubeProjectionMatrix;
            modelViewMatrix  = this.miniCubeModelViewMatrix;
            inverseMatrix    = this.miniCubeInverseMatrix;
            eyeDistance      = 0.0;
        }
        else {
            projectionMatrix = this.stereoMiniCubeProjectionMatrix[i];
            modelViewMatrix  = this.stereoMiniCubeModelViewMatrix[i];
            inverseMatrix    = this.stereoMiniCubeInverseMatrix[i];
            eyeDistance      = this.eyeDistance * (i * 2.0 - 1.0);
        }
        eyeDistance *= showParallel ? -1.0 : 1.0;

        const eyeTan = 0.05 * 8.0;  // STM_TODO - kinda hacky

        eyeDistance = eyeDistance * eyeTan;

        let distance = this.generateMatrices(projectionMatrix, modelViewMatrix,
                                             aspect, eyeDistance,
                                             {
                                                 pivotPoint:     [0.0, 0.0, 0.0],
                                                 distanceOffset: 4.0,
                                                 fov:            20.0,
                                                 volumeOffset:   [0.0, 0.0],
                                                 screenPan:      [0.0, 0.0],
                                                 zoom:           1.0
                                             });

        mat4.identity(miniProjectionMatrix);
        mat4.identity(inverseMatrix);

        const miniOffset  = 1.4;
        const miniScale   = this.miniCubeScale;
        const miniXSign   = this.miniCubePosition[0];
        const miniYSign   = this.miniCubePosition[1];
        const miniXOffset = -miniXSign * miniOffset;
        const miniYOffset = -miniYSign * miniOffset;

        mat4.translate(miniProjectionMatrix, miniProjectionMatrix, [miniXSign, miniYSign, 0.0]);
        mat4.scale(miniProjectionMatrix, miniProjectionMatrix, [miniScale, miniScale, 1.0]);
        if (aspect >= 1.0)
            mat4.translate(miniProjectionMatrix, miniProjectionMatrix, [miniXOffset/aspect, miniYOffset, 0.0]);
        else
            mat4.translate(miniProjectionMatrix, miniProjectionMatrix, [miniXOffset, miniYOffset*aspect, 0.0]);
        mat4.multiply(projectionMatrix, miniProjectionMatrix, projectionMatrix);

        mat4.multiply(inverseMatrix, projectionMatrix, modelViewMatrix);
        mat4.invert(inverseMatrix, inverseMatrix);

        // STM_TODO - this is a bit ugly
        const rotationMatrix = this.combineMatrices();
        let lightPos = vec3.fromValues(0.0, 0.0, 1.0);
        const inverseRotationMatrix = mat4.create();
        mat4.invert(inverseRotationMatrix, rotationMatrix);
        lightPos[0] *= distance;
        lightPos[1] *= distance;
        lightPos[2] *= distance;
        vec3.transformMat4(lightPos, lightPos, inverseRotationMatrix);

        // Set up the vertex buffer
        {
            const numComponents = 3;
            const type = gl.FLOAT;
            const normalize = false;
            const stride = 0;
            const offset = 0;
            gl.bindBuffer(gl.ARRAY_BUFFER, buffers.position);
            gl.vertexAttribPointer(miniProgramInfo.attribLocations.vertexPosition,
                                   numComponents,
                                   type,
                                   normalize,
                                   stride,
                                   offset);
            gl.enableVertexAttribArray(miniProgramInfo.attribLocations.vertexPosition);
        }

        // Set up the index buffer
        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffers.indices);

        // Specify the shader
        gl.useProgram(miniProgramInfo.program);

        const uniforms = miniProgramInfo.uniformLocations;

        // Set the shader uniforms
        gl.uniformMatrix4fv(
            uniforms.projectionMatrix,
            false,
            projectionMatrix);
        gl.uniformMatrix4fv(
            uniforms.modelViewMatrix,
            false,
            modelViewMatrix);
        //gl.uniformMatrix4fv(
        //    uniforms.normalMatrix,
        //    false,
        //    normalMatrix);
        gl.uniformMatrix4fv(
            uniforms.inverseMatrix,
            false,
            inverseMatrix);

        gl.uniform3fv(
            uniforms.lightPos,
            lightPos);
        gl.uniform1i(
            uniforms.faceHighlight,
            this.miniCubeHighlightFace);
        gl.uniform4fv(
            uniforms.faceColor,
            this.miniCubeHighlightColor);

        // Set up textures
        if (this.miniCubeTexture) {
            gl.activeTexture(gl.TEXTURE7);
            gl.bindTexture(gl.TEXTURE_2D, this.miniCubeTexture);
            gl.uniform1i(uniforms.samplerFaces, 7);
        }

        // Draw the mini-cube!
        {
            const vertexCount = 36;
            const type = gl.UNSIGNED_SHORT;
            const offset = 0;

            gl.cullFace(gl.BACK);
            gl.drawElements(gl.TRIANGLES, vertexCount, type, offset);
        }
    }
};

// Generates a one-dimensional texture, based on user-specified lookup tables,
// that can be used for color generation in the shader.
VolumeViewer.prototype.create1DTexture = function(transferArray, size=256) {
    const gl = this.gl;
    if (!gl) return null;

    let data = VolumeViewer.generateLookupArray(transferArray, size);

    let texture = gl.createTexture();

    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, data.length/4, 1, 0, gl.RGBA, gl.UNSIGNED_BYTE, data);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

    return texture;
};

// Releases all stored texture strips.
VolumeViewer.prototype.releaseTextureStrips = function() {
    const gl = this.gl;
    if (!gl)  return;

    for (let i=0; i<this.perVolume.length; ++i) {
        if (this.perVolume[i].stripTexture) {
            gl.deleteTexture(this.perVolume[i].stripTexture);
            this.perVolume[i].stripTexture = null;
        }
    }
};

// Generates a two-dimensional texture that contains all color lookup tables
// for a single volume.  (Using a single texture for all of these tables
// reduces the number of texture units required to render the volume --
// an important consideration for Safari, which is still stuck with WebGL 1.0
// and can only handle eight texture units!)
VolumeViewer.prototype.createTextureStrips = function(transferArrays, size=256) {
    const gl = this.gl;
    if (!gl) return null;

    const LINES = 2;

    // The texture will typically contain three color lookup tables
    // (luminosity, gradient, plane) as strips across a six-pixel-high
    // texture.  Two rows are used for each lookup table so that
    // texture interpolation will work correctly.  (We could use
    // nearest neighbor for lookups instead, which would eliminate
    // this problem, but it wouldn't be much faster and would make the
    // volume image look more aliased.)
    const count = transferArrays.length;
    const width  = size;
    const height = (count * LINES) - LINES;
    const data = new Uint8Array(width*height*4);
    for (let i=0; i<count; ++i) {
        let strip = VolumeViewer.generateLookupArray(transferArrays[i], width);
        for (let j=0; j<LINES; ++j) {
            let row = i*LINES + j - Math.floor(LINES/2.0);
            if (row >= 0 && row < height) {
                data.set(strip, width*row*4);
            }
        }
    }

    let texture = gl.createTexture();

    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, width, height, 0, gl.RGBA, gl.UNSIGNED_BYTE, data);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

    return texture;
};

// Determines how to lay out a three-dimensional volume in a two-dimensional
// texture without exceeding WebGL texture sizes.  Needed to support
// WebGL 1.0, which only works with two-dimensional textures.
// (WebGL 2.0 has full volumetric support.)
// As of this writing, Safari and Microsoft Edge are the only major
// browsers that do not support WebGL 2.0.
// This method will return null if the size of the texture exceeds
// WebGL's maximum texture size, in either WebGL 1.0 or WebGL 2.0.
VolumeViewer.prototype.getDepthDimensions = function(width, height, depth) {
    const glVersion = this.glVersion;

    const maxTextureSize = this.glMaxTextureSize;

    let bestExcess = undefined;
    let bestSide = undefined;
    let bestRows = depth;
    let bestCols = 1;
    let found = false;
    if (glVersion < 2) {
        // We only need to go through this for WebGL 1.0...

        // Determine the most compact arrangement of tiles within the 2D texture
        // that does NOT cause the texture to exceed the maximum texture size
        // threshold dictated by WebGL.
        // Note that even the best arrangements may force the 2D texture to
        // contain unused space, especially if the number of tiles is a
        // large prime number.

        for (let rows=depth; rows>=1; --rows) {
            let cols = Math.ceil(1.0*depth/rows);
            let depthWidth = cols * width;
            let depthHeight = rows * height;
            let side = Math.max(depthWidth, depthHeight);
            if (side <= maxTextureSize) {
                let cells = rows*cols;
                let excess = cells - depth;

                let best = false;
                if (bestExcess === undefined || bestSide === undefined)
                    best = true;
                else if (bestExcess > excess)
                    best = true;
                else if (bestExcess < excess)
                    best = false;
                else if (bestSide > side)
                    best = true;
                else // if (bestSide <= side)
                    best = false;
                if (best) {
                    bestExcess = excess;
                    bestSide   = side;
                    bestRows   = rows;
                    bestCols   = cols;
                    found      = true;
                }
            }
        }
        if (!found) {
            return null;
        }
    }
    else {
        let size = Math.max(Math.max(width, height), depth);
        if (size > maxTextureSize) {
            return null;
        }
    }

    return {
        'width':  width,
        'height': height,
        'depth':  depth,
        'rows':   bestRows,
        'cols':   bestCols
    };
};

// Computes the volume size and aspect ratio that will be used for the
// texture version of the volume.
// Depending on what the volume viewer's resampling options are set to,
// this information may or may not be the same as what is used by the
// original volume.
VolumeViewer.prototype.computeProcessedVolumeSize = function(volume) {
    // Sanity check
    if (!volume || !volume.data) {
        return null;
    }

    let depthDim = null;

    let widthMultiplier  = this.widthMultiplier;
    let heightMultiplier = this.heightMultiplier;
    let depthMultiplier  = this.depthMultiplier;

    let upscaleX = 1.0;
    let upscaleY = 1.0;
    let upscaleZ = 1.0;

    // Resize the texture so that pixels are more isotropic (if requested)
    if (this.autoResize) {
        let scaleX = volume.scaleX / widthMultiplier;
        let scaleY = volume.scaleY / heightMultiplier;
        let scaleZ = volume.scaleZ / depthMultiplier;

        let upscale = Volume.calculateUpsampleFactors(scaleX, scaleY, scaleZ);

        upscaleX = upscale.x;
        upscaleY = upscale.y;
        upscaleZ = upscale.z;
    }

    widthMultiplier  *= upscaleX;
    heightMultiplier *= upscaleY;
    depthMultiplier  *= upscaleZ;

    // Original size parameters
    const oldWidth  = volume.width;
    const oldHeight = volume.height;
    const oldDepth  = volume.depth;

    // New size parameters (these will be updated!)
    let newWidth  = Math.ceil(oldWidth  * widthMultiplier);
    let newHeight = Math.ceil(oldHeight * heightMultiplier);
    let newDepth  = Math.ceil(oldDepth  * depthMultiplier);

    const computeDownsampleSize = function(maxSize, width, height, depth) {
        let newWidth  = width;
        let newHeight = height;
        let newDepth  = depth;

        let largestSide = newWidth;
        if (largestSide < newHeight)  largestSide = newHeight;
        if (largestSide < newDepth)   largestSide = newDepth;

        if (largestSide > maxSize) {
            newWidth  = Math.ceil(newWidth  * maxSize / largestSide);
            newHeight = Math.ceil(newHeight * maxSize / largestSide);
            newDepth  = Math.ceil(newDepth  * maxSize / largestSide);
        }
        return {
            width:  newWidth,
            height: newHeight,
            depth:  newDepth
        };
    };

    newWidth  += VolumeViewer.BORDER_ADD;
    newHeight += VolumeViewer.BORDER_ADD;
    newDepth  += VolumeViewer.BORDER_ADD;

    // Downsample if necessary
    if (this.autoResize)  {
        if (!depthDim) {
            let downsize = computeDownsampleSize(256, newWidth, newHeight, newDepth);
            depthDim = this.getDepthDimensions(downsize.width, downsize.height, downsize.depth);
        }
        if (!depthDim) {
            let downsize = computeDownsampleSize(192, newWidth, newHeight, newDepth);
            depthDim = this.getDepthDimensions(downsize.width, downsize.height, downsize.depth);
        }
        if (!depthDim) {
            let downsize = computeDownsampleSize(128, newWidth, newHeight, newDepth);
            depthDim = this.getDepthDimensions(downsize.width, downsize.height, downsize.depth);
        }
        if (!depthDim) {
            let downsize = computeDownsampleSize(96, newWidth, newHeight, newDepth);
            depthDim = this.getDepthDimensions(downsize.width, downsize.height, downsize.depth);
        }
        if (!depthDim) {
            let downsize = computeDownsampleSize(64, newWidth, newHeight, newDepth);
            depthDim = this.getDepthDimensions(downsize.width, downsize.height, downsize.depth);
        }
    }
    else {
        depthDim = this.getDepthDimensions(newWidth, newHeight, newDepth);
    }

    if (!depthDim) {
        // Cannot find a size that WebGL can accept
        return null;
    }

    // New size parameters -- these already include the border size
    newWidth      = depthDim.width;
    newHeight     = depthDim.height;
    newDepth      = depthDim.depth;
    let depthRows = depthDim.rows;
    let depthCols = depthDim.cols;

    // Updated scaling factors
    const newScaleMultX = oldWidth  / (newWidth  - VolumeViewer.BORDER_ADD);
    const newScaleMultY = oldHeight / (newHeight - VolumeViewer.BORDER_ADD);
    const newScaleMultZ = oldDepth  / (newDepth  - VolumeViewer.BORDER_ADD);

    return {
        width:      newWidth,
        height:     newHeight,
        depth:      newDepth,
        depthRows:  depthRows,
        depthCols:  depthCols,
        width2d:    newWidth*depthCols,
        height2d:   newHeight*depthRows,
        scaleMultX: newScaleMultX,
        scaleMultY: newScaleMultY,
        scaleMultZ: newScaleMultZ,
        upscaleX:   upscaleX,
        upscaleY:   upscaleY,
        upscaleZ:   upscaleZ
    };
};

// Preprocesses and generates a texture volume that the shader can render,
// based on a raw user-specified volume.
// Happens on-demand just prior to rendering a scene.
// Note that this operation can take several seconds.
// (In Microsoft Edge this can take a MINUTE or more, which is one of many
// reasons why I hate Microsoft Edge.)
VolumeViewer.prototype.generateTextureVolume = function(processedSize, volume, perVolume) {

    // STM_TODO - this should go into a worker
    const glVersion = this.glVersion;

    let originalVolume = volume;
    if (!originalVolume || !originalVolume.data) {
        dconsole.error("Attempted to generate volume texture with invalid volume");
        return null;
    }

    if (!processedSize) {
        dconsole.error("No destination size provided!");
        return null;
    }

    dconsole.log("");
    dconsole.log("GENERATING TEXTURE VOLUME...");
    let totalStartTime = Date.now();

    let smoothedVolume;
    let textureVolume;

    let halfGradient  = this.halfGradient;
    let offset        = this.resampleOffset;

    // Original size parameters
    const oldWidth  = originalVolume.width;
    const oldHeight = originalVolume.height;
    const oldDepth  = originalVolume.depth;

    let newWidth  = processedSize.width  - VolumeViewer.BORDER_ADD;
    let newHeight = processedSize.height - VolumeViewer.BORDER_ADD;
    let newDepth  = processedSize.depth  - VolumeViewer.BORDER_ADD;

    let xFilter = this.resamplingXFilter;
    let yFilter = this.resamplingYFilter;
    let zFilter = this.resamplingZFilter;

    let upsampleFilter = Volume.filterCatmullRom;
    let normalFilter   = Volume.filterTrapezoid;

    if (!xFilter)  {
        if (newWidth > oldWidth)
            xFilter = this.upsampleFilter || upsampleFilter;
        else
            xFilter = this.normalFilter || normalFilter;
    }

    if (!yFilter)  {
        if (newHeight > oldHeight)
            yFilter = this.upsampleFilter || upsampleFilter;
        else
            yFilter = this.normalFilter || normalFilter;
    }

    if (!zFilter)  {
        if (newDepth > oldDepth)
            zFilter = this.upsampleFilter || upsampleFilter;
        else
            zFilter = this.normalFilter || normalFilter;
    }

    let xRange = Volume.getFilterWindowSize(xFilter, oldWidth  / newWidth);
    let yRange = Volume.getFilterWindowSize(yFilter, oldHeight / newHeight);
    let zRange = Volume.getFilterWindowSize(zFilter, oldDepth  / newDepth);

    // Optionally smooth the image used for computing gradients
    // (this may improve image quality, but we have to be careful not to lose features!)
    if (newWidth !== oldWidth || newHeight !== oldHeight || newDepth !== oldDepth ||
        xRange > 1.0 || yRange > 1.0 || zRange > 1.0 ||
        offset !== 0.0) {

        // STM_TODO - reuse buffer?
        smoothedVolume = originalVolume.resampleVolume(newWidth, newHeight, newDepth,
                                                       offset, offset, offset,
                                                       xFilter, yFilter, zFilter);

        textureVolume = smoothedVolume;
    }
    else {
        smoothedVolume = null;
        textureVolume  = originalVolume;
    }

    // New size parameters -- these already include the border size
    newWidth        = processedSize.width;
    newHeight       = processedSize.height;
    newDepth        = processedSize.depth;
    const depthRows = processedSize.depthRows;
    const depthCols = processedSize.depthCols;

    // Updated scaling factors
    const newScaleMultX = processedSize.scaleMultX;
    const newScaleMultY = processedSize.scaleMultY;
    const newScaleMultZ = processedSize.scaleMultZ;

    if (glVersion < 2) {
        // For debugging
        dconsole.log(`3D (${newWidth}, ${newHeight}, ${newDepth}) => ` +
                     `2D (${newWidth*depthCols}, ${newHeight*depthRows})`);

        // Special case: this is WebGL 1.0, and we are using a 2D texture
        // that is pretending to be a 3D texture.
        let fromSize = newWidth * newHeight * newDepth;
        let toSize = newWidth*depthCols * newHeight*depthRows;
        let excess = toSize - fromSize;
        if (excess > 0)
            dconsole.log("Excess pixels: " + excess);
    }

    const isOverlay         = perVolume.isOverlay;
    const generateGradients = this.useGradients;  // STM_TODO - do this per volume

    // Find the luminosity range; this will be normalized in the final volume
    let values = textureVolume.data;
    let limits = textureVolume.calculateLimits();  // assumes this is a sub-volume
    let luminosityMin   = 0.0;
    let luminosityMax   = 1.0;
    let luminosityDelta = 1.0;
    if (limits) {
        luminosityMin   = limits.luminosityMin;
        luminosityMax   = limits.luminosityMax;
        luminosityDelta = limits.luminosityDelta;
    }

    let luminosityHistogram = null;
    let gradientHistogram   = null;
    let gradientMax       = 1.0;
    let norms             = null;

    // Calculate histogram for luminosity
    if (!isOverlay)
        luminosityHistogram = textureVolume.calculateHistogram();

    // Now find the gradient range (0 is the implied lower limit)
    if (generateGradients) {
        let gradients = textureVolume.generateGradients(halfGradient, true);
        gradientMax   = gradients.gradientMax;
        norms         = gradients.gradients;

        // Calculate histogram for gradient magnitude
        if (!isOverlay)
            gradientHistogram = Volume.calculateGradientHistogram(gradients);
    }

    // Regenerate the volume as two textures.
    // One texture contains one color channel (R) representing luminosity.
    // The other texture contains four color channels (RGBA) representing
    // gradients.
    // The alpha channel is used for precomputed gradient magnitude as a scalar.
    // The red, green and blue channels are used for the X, Y and Z components
    // of the surface normal vector (which is not a unit vector and incorporates
    // the gradient magnitude).
    // All values are normalized to the 0-255 range.
    // Surface normals are biased by 128 so that both positive and negative
    // coordinates may be represented.
    dconsole.log("Converting values to RGBA...");
    let startTime = Date.now();

    const normStride = 3;

    const lumStride = 1;  // bytes per pixel
    const newLumPadWidth = Math.ceil(lumStride * newWidth * depthCols / 4.0) * 4;  // calculate padded width, in bytes (NOT pixels)
    const newLumPadHeight = newHeight * depthRows;
    const lumSize = newLumPadWidth * newLumPadHeight;
    perVolume.luminosityBuffer = VolumeViewer.smartReallocate(perVolume.luminosityBuffer, 'Uint8Array', lumSize);
    if (!perVolume.luminosityBuffer) {
        return null;
    }
    const newLumData = perVolume.luminosityBuffer;

    const gradStride = 4;  // bytes per pixel
    const newGradPadWidth = Math.ceil(gradStride * newWidth * depthCols / 4.0) * 4;  // calculate padded width, in bytes (NOT pixels)
    const newGradPadHeight = newHeight * depthRows;
    const gradSize = newGradPadWidth * newGradPadHeight;
    if (generateGradients) {
        perVolume.gradientBuffer = VolumeViewer.smartReallocate(perVolume.gradientBuffer, 'Uint8Array', gradSize);
        if (!perVolume.gradientBuffer) {
            return null;
        }
    }
    const newGradData = generateGradients ? perVolume.gradientBuffer : null;

    function clamp(num, min=0, max=255) {
        return num <= min ? min : num >= max ? max : num;
    };

    const luminosityMult     = 255.0;
    const gradientBias       = 128.0;
    const gradientMult       = 127.0;
    const invLuminosityDelta = 1.0 / luminosityDelta;
    const invGradientDelta   = 1.0 / gradientMax;

    for (let kRow=0; kRow<depthRows; ++kRow) {
        for (let kCol=0; kCol<depthCols; ++kCol) {
            let k = kRow*depthCols + kCol;
            let nz = k - VolumeViewer.BORDER_SIZE;
            let zInside = nz >= 0 && nz < textureVolume.depth;
            for (let j=0; j<newHeight; ++j) {
                let yOffset = j + newHeight * kRow;
                let ny = j - VolumeViewer.BORDER_SIZE;
                let yInside = ny >= 0 && ny < textureVolume.height;
                for (let i=0; i<newWidth; ++i) {
                    let xOffset = i + newWidth * kCol;
                    let nx = i - VolumeViewer.BORDER_SIZE;
                    let xInside = nx >= 0 && nx < textureVolume.width;

                    let toLumPos = lumStride * xOffset + newLumPadWidth * yOffset;
                    let toGradPos = gradStride * xOffset + newGradPadWidth * yOffset;

                    if (zInside && yInside && xInside) {
                        let fromPos = nx + textureVolume.width * (ny + textureVolume.height * nz);
                        let value = (values[fromPos] - luminosityMin) * invLuminosityDelta;

                        value = Math.floor(value * luminosityMult);

                        newLumData[toLumPos] = clamp(value);  // red channel == luminosity

                        if (generateGradients) {
                            let fromNormPos = fromPos * normStride;
                            let xDelta = norms[fromNormPos];
                            let yDelta = norms[fromNormPos+1];
                            let zDelta = norms[fromNormPos+2];

                            let gradMag = xDelta*xDelta + yDelta*yDelta + zDelta*zDelta;
                            if (gradMag < 1e-12) {
                                gradMag = xDelta = yDelta = zDelta = 0.0;
                            }
                            else {
                                gradMag = Math.sqrt(gradMag);
                                if (this.gradRounding) {
                                    const invMag = 1.0 / gradMag;
                                    xDelta *= invMag;
                                    yDelta *= invMag;
                                    zDelta *= invMag;
                                }
                                else {
                                    xDelta *= invGradientDelta;
                                    yDelta *= invGradientDelta;
                                    zDelta *= invGradientDelta;
                                }
                                gradMag *= invGradientDelta;
                            }

                            xDelta  = Math.round(xDelta * gradientMult + gradientBias);
                            yDelta  = Math.round(yDelta * gradientMult + gradientBias);
                            zDelta  = Math.round(zDelta * gradientMult + gradientBias);
                            gradMag = Math.round(gradMag * gradientMult * 2.0);

                            newGradData[toGradPos]   = clamp(xDelta);   // red channel   == normal x
                            newGradData[toGradPos+1] = clamp(yDelta);   // green channel == normal y
                            newGradData[toGradPos+2] = clamp(zDelta);   // blue channel  == normal z
                            newGradData[toGradPos+3] = clamp(gradMag);  // alpha channel == gradient magnitude
                        }
                    }
                    else {
                        newLumData[toLumPos] = 0;  // red channel == luminosity

                        if (generateGradients) {
                            newGradData[toGradPos]   = gradientBias;    // red channel   == normal x
                            newGradData[toGradPos+1] = gradientBias;    // green channel == normal y
                            newGradData[toGradPos+2] = gradientBias;    // blue channel  == normal z
                            newGradData[toGradPos+3] = 0;               // alpha channel == gradient magnitude
                        }
                    }
                }
            }
        }
    }

    let endTime = Date.now();
    let deltaTime = (endTime - startTime) / 1000.0;
    dconsole.log("Done converting values to RGBA (time="+deltaTime+"s)");

    // Compute ideal min/max values for the luminosity range.
    // Ideally, these will show a wide range of color, and clip out noise
    // at the bottom end.
    // (These were empirically determined and will probably need to change
    // as our SNR improves.)
    let minLuminosity = 0.0;
    let maxLuminosity = 1.0;
    if (luminosityHistogram) {
        let noiseFloor, highLevel, highCeiling;

        noiseFloor = luminosityHistogram.computeMode() * 2.0;
        //highLevel  = luminosityHistogram.computeNormPercentileValue(0.9996);
        highLevel  = luminosityHistogram.computeNormPercentileValue(0.9986);
        //highLevel  = luminosityHistogram.computeHighStd(4.5);  // STM_TODO - revisit this

        highCeiling = clamp(highLevel, 0.0, 1.0);
        noiseFloor  = clamp(noiseFloor, 0.0, highCeiling);

        minLuminosity = noiseFloor;
        maxLuminosity = highCeiling;

        dconsole.log(`Luminosity range: [${minLuminosity.toFixed(4)} - ${maxLuminosity.toFixed(4)}]`);
    }
    else {
        dconsole.log(`Luminosity range: NOT CALCULATED`);
    }


    // Compute ideal min/max values for the gradient magnitude range.
    // Ideally, these will show a wide range of color, and prevent
    // smoother gradients in the volume from displaying as surfaces.
    // (These were empirically determined and will probably need to change
    // as our SNR improves.)
    let minGradientMagnitude = 0.0;
    let maxGradientMagnitude = 1.0;
    if (gradientHistogram) {
        let gradFloor = gradientHistogram.computeNormPercentileValue(0.75);
        let gradCeiling = gradientHistogram.computeNormPercentileValue(0.98);

        gradCeiling = clamp(gradCeiling, 0.0, 1.0);
        gradFloor   = clamp(gradFloor, 0.0, gradCeiling);

        minGradientMagnitude = gradFloor;
        maxGradientMagnitude = gradCeiling;

        dconsole.log(`Gradient range: [${minGradientMagnitude.toFixed(4)} - ${maxGradientMagnitude.toFixed(4)}]`);
    }
    else {
        dconsole.log(`Gradient range: NOT CALCULATED`);
    }

    // Null out our expensive memory arrays for garbage collection!
    if (smoothedVolume !== null) {
        smoothedVolume.data = null;
        smoothedVolume = null;
    }

    textureVolume = null;

    let totalEndTime = Date.now();
    deltaTime = (totalEndTime - totalStartTime) / 1000.0;
    dconsole.log("TEXTURE VOLUME GENERATED (total time="+deltaTime+"s)");
    dconsole.log("");

    const gradOffset = halfGradient ? 0.5 : 0.0;

    // Return the texture volume in all its glory!
    let obj = {
        lumData:              newLumData,
        gradData:             newGradData,
        width:                newWidth,
        height:               newHeight,
        depth:                newDepth,
        depthRows:            depthRows,
        depthCols:            depthCols,
        width2d:              newWidth*depthCols,
        height2d:             newHeight*depthRows,
        scaleMultX:           newScaleMultX,
        scaleMultY:           newScaleMultY,
        scaleMultZ:           newScaleMultZ,
        isOverlay:            isOverlay,

        gradOffset:           gradOffset,
        minLuminosity:        minLuminosity,
        maxLuminosity:        maxLuminosity,
        minGradientMagnitude: minGradientMagnitude,
        maxGradientMagnitude: maxGradientMagnitude
    };

    return obj;
};

// Destroys all textures associated with the viewer.
VolumeViewer.prototype.releaseTextures = function() {
    const gl = this.gl;
    if (!gl) return;

    // Destroy the old textures, if they exist
    if (this.textureProcessed !== null) {
        for (let i=0; i<this.textureProcessed.length; ++i)
            gl.deleteTexture(this.textureProcessed[i]);
        this.textureProcessed = null;
    }
};

// Binds the generated volume to WebGL textures.
VolumeViewer.prototype.bindVolumeToTextures = function() {
    if (this.textureProcessed !== null)  return;

    const gl = this.gl;
    const glVersion = this.glVersion;
    if (!gl) return;

    const volumeProcessed = this.volumeProcessed;
    if (!volumeProcessed) return;

    const volumeArray = volumeProcessed.volumeArray;
    if (!volumeArray) return;

    const target = glVersion >= 2 ? gl.TEXTURE_3D : gl.TEXTURE_2D;

    let textureProcessed = [];
    textureProcessed.length = volumeArray.length;

    this.textureProcessed = textureProcessed;

    // STM_TODO - texture binding offset calculations here are ugly
    const texOffset = VolumeViewer.MAX_VOLUMES;

    for (let i=0; i<volumeArray.length; ++i) {
        const volume = volumeArray[i];

        const texture = gl.createTexture();
        this.textureProcessed[i] = texture;

        gl.activeTexture(gl.TEXTURE0 + i);
        gl.bindTexture(target, texture);
        if (volume.gradData) {
            if (glVersion >= 2)
                gl.texImage3D(target, 0, gl.RGBA, volume.width, volume.height, volume.depth, 0, gl.RGBA, gl.UNSIGNED_BYTE, volume.gradData);
            else
                gl.texImage2D(target, 0, gl.RGBA, volume.width2d, volume.height2d, 0, gl.RGBA, gl.UNSIGNED_BYTE, volume.gradData);
        }
        else {
            let temp = new Uint8Array([0x80, 0x80, 0x80, 0x00]);
            if (glVersion >= 2)
                gl.texImage3D(target, 0, gl.RGBA, 1, 1, 1, 0, gl.RGBA, gl.UNSIGNED_BYTE, temp);
            else
                gl.texImage2D(target, 0, gl.RGBA, 1, 1, 0, gl.RGBA, gl.UNSIGNED_BYTE, temp);
        }

        gl.texParameteri(target, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(target, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        if (glVersion >= 2)
            gl.texParameteri(target, gl.TEXTURE_WRAP_R, gl.CLAMP_TO_EDGE);
        if (this.interpolate) {
            gl.texParameteri(target, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
            gl.texParameteri(target, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
        }
        else {
            gl.texParameteri(target, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
            gl.texParameteri(target, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
        }

        // STM_TODO - texture binding offset calculations here are ugly
        const texture2 = gl.createTexture();
        this.textureProcessed[i+texOffset] = texture2;

        gl.activeTexture(gl.TEXTURE0 + i + texOffset);
        gl.bindTexture(target, texture2);
        if (glVersion >= 2)
            gl.texImage3D(target, 0, gl.LUMINANCE, volume.width, volume.height, volume.depth, 0, gl.LUMINANCE, gl.UNSIGNED_BYTE, volume.lumData);
        else
            gl.texImage2D(target, 0, gl.LUMINANCE, volume.width2d, volume.height2d, 0, gl.LUMINANCE, gl.UNSIGNED_BYTE, volume.lumData);

        gl.texParameteri(target, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(target, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        if (glVersion >= 2)
            gl.texParameteri(target, gl.TEXTURE_WRAP_R, gl.CLAMP_TO_EDGE);
        if (this.interpolate) {
            gl.texParameteri(target, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
            gl.texParameteri(target, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
        }
        else {
            gl.texParameteri(target, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
            gl.texParameteri(target, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
        }
    }
};

// Destroys the texture associated with the mini-cube.
VolumeViewer.prototype.releaseMiniTexture = function() {
    const gl = this.gl;
    if (!gl) return;

    // Destroy the old texture, if it exists
    if (this.miniCubeTexture !== null) {
        gl.deleteTexture(this.miniCubeTexture);
        this.miniCubeTexture = null;
    }
};

// Binds the generated volume to WebGL textures.
VolumeViewer.prototype.bindMiniTexture = function() {
    if (this.miniCubeTexture)  return;

    if (!this.showMiniCube)  return;
    if (!this.miniCubeUrl)  return;

    const gl = this.gl;
    const glVersion = this.glVersion;
    if (!gl) return;

    this.miniCubeTexture = VolumeViewer.loadTexture(gl, this.miniCubeUrl);
};

// Generates and compiles a shader based on a pure GLSL script.
VolumeViewer.prototype.getShader = function(script, scriptType) {
    let gl = this.gl;
    let glVersion = this.glVersion;
    if (!gl) return null;

    var header = "";

    // Add special preprocessor directives to the shader for
    // optimization purposes, based on user-specified options.

    if (glVersion >= 2) {
        header += "#version 300 es\n";
    }

    if (this.shaderOptions.interpolate) {
        header += "#define USE_TRILINEAR_INTERPOLATION\n";
    }
    if (this.shaderOptions.gradRounding) {
        header += "#define USE_GRADIENT_ROUNDING\n";
    }
    if (this.shaderOptions.offsetGradient) {
        header += "#define OFFSET_GRADIENT\n";
    }
    if (this.shaderOptions.showLighting) {
        header += "#define SHOW_LIGHTING\n";
    }
    if (this.shaderOptions.showPlaneLighting) {
        header += "#define SHOW_PLANE_LIGHTING\n";
    }
    if (this.shaderOptions.showFog) {
        header += "#define SHOW_FOG\n";
    }
    if (this.shaderOptions.showClipFlattening) {
        header += "#define SHOW_CLIP_FLATTENING\n";
    }
    if (this.shaderOptions.showPlanes) {
        header += "#define SHOW_PLANES\n";
    }
    if (this.shaderOptions.showPlane0) {
        header += "#define SHOW_PLANE0\n";
    }
    if (this.shaderOptions.showPlane1) {
        header += "#define SHOW_PLANE1\n";
    }
    if (this.shaderOptions.showPlaneBorders) {
        header += "#define SHOW_PLANE_BORDERS\n";
    }
    if (this.shaderOptions.showPlaneIntersections) {
        header += "#define SHOW_PLANE_INTERSECTIONS\n";
    }
    if (this.shaderOptions.drawTrueLines) {
        header += "#define TRUE_LINES\n";
    }
    if (this.shaderOptions.showVolumes) {
        header += "#define SHOW_VOLUMES\n";
    }
    if (this.shaderOptions.showVolume0) {
        header += "#define SHOW_VOLUME0\n";
    }
    if (this.shaderOptions.showVolume1) {
        header += "#define SHOW_VOLUME1\n";
    }
    if (this.shaderOptions.showShadows) {
        header += "#define SHOW_SHADOWS\n";
    }
    if (this.shaderOptions.showShadowsLuminosity) {
        header += "#define SHOW_SHADOWS_LUMINOSITY\n";
    }
    if (this.shaderOptions.showShadowsGradient) {
        header += "#define SHOW_SHADOWS_GRADIENT\n";
    }
    if (this.shaderOptions.sampleOnAxis) {
        header += "#define SAMPLE_ON_AXIS\n";
    }
    if (this.shaderOptions.randomizeSampling) {
        header += "#define RANDOMIZE_SAMPLING\n";
    }
    if (this.shaderOptions.stipple) {
        header += "#define STIPPLE\n";
    }
    if (this.shaderOptions.frontToBack) {
        header += "#define FRONT_TO_BACK\n";
    }
    //header += "#define SHOW_LIGHT_SOURCE\n";

    let str = header + script;
    //dconsole.log("HEADER:\n" + header);

    var shader;
    if (scriptType === VolumeViewer.VERTEX_SHADER_TYPE) {
        shader = gl.createShader(gl.VERTEX_SHADER);
    }
    else if (scriptType === VolumeViewer.FRAGMENT_SHADER_TYPE) {
        shader = gl.createShader(gl.FRAGMENT_SHADER);
    }
    else {
        return null;
    }

    gl.shaderSource(shader, str);
    gl.compileShader(shader);

    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        alert(gl.getShaderInfoLog(shader));
        return null;
    }

    return shader;
};

// Generates various projection matrices used when rendering to WebGL.
VolumeViewer.prototype.generateMatrices = function(projectionMatrix,
                                                   modelViewMatrix,
                                                   aspect,
                                                   eyeDistance=0.0,
                                                   params=null) {

    if (params === undefined || params === null)  params = {};

    let rotationMatrix = params.rotationMatrix;
    let pivotPoint     = params.pivotPoint;
    let pivotMatrix    = params.pivotMatrix;
    let fov            = params.fov;
    let volumeOffset   = params.volumeOffset;
    let screenPan      = params.screenPan;
    let zoom           = params.zoom;
    let distanceOffset = params.distanceOffset;

    if (rotationMatrix === null || rotationMatrix === undefined)
        rotationMatrix = this.rotationMatrix;

    if (pivotPoint === null || pivotPoint === undefined)
        pivotPoint = this.pivotPoint;

    if (pivotMatrix === null || pivotMatrix === undefined)
        pivotMatrix = this.pivotMatrix;

    if (fov === null || fov === undefined)
        fov = this.fov;

    if (volumeOffset === null || volumeOffset === undefined)
        volumeOffset = this.pan;

    if (screenPan === null || screenPan === undefined)
        screenPan = this.screenPan;

    if (zoom === null || zoom === undefined)
        zoom = this.zoom;

    if (distanceOffset === null || distanceOffset === undefined)
        distanceOffset = 0.0;

    const useOrtho = fov < 1.0;

    const fovRad = useOrtho ? 0.0 : fov * Math.PI / 180.0;  // field of view, in radians
    const radius = 1.5;
    const zSpan = 4.0;
    const distance = useOrtho ? 85.0 : radius / Math.tan(fovRad*0.5);

    const eyeTan = 0.05;

    eyeDistance = eyeDistance * eyeTan;

    let zFar = distance + zSpan;
    let zNear = zFar / 15.0;
    if (zNear < 0.2)  zNear = 0.2;

    mat4.identity(projectionMatrix);

    // Generate a perspective or orthographic matrix
    if (!useOrtho) {
        let fovZoom = fov * zoom;
        if (fovZoom > 85.0) fovZoom = 85.0;
        if (fovZoom < 0.5)  fovZoom = 0.5;
        fovZoom *= Math.PI / 180.0;  // degrees to radians
        mat4.perspective(projectionMatrix,  // destination matrix
                         fovZoom,           // field of view (radians)
                         aspect,            // aspect ratio (x/y)
                         zNear,             // near plane distance
                         zFar);             // far plane distance
        if (aspect < 1.0) {
            // Special case for portrait aspect ratio
            let f = 1.0 / Math.tan(fovZoom / 2);
            projectionMatrix[0] = f;
            projectionMatrix[5] = f * aspect;
        }
    }
    else {
        let xSide = radius * zoom;
        let ySide = radius * zoom;
        if (aspect >= 1.0)  xSide *= aspect;
        else                ySide /= aspect;
        mat4.ortho(projectionMatrix,  // destination matrix
                   -xSide,            // left bound of the frustum
                   xSide,             // right bound of the frustum
                   -ySide,            // bottom bound of the frustum
                   ySide,             // top bound of the frustum
                   zNear,             // near plane distance
                   zFar);             // far plane distance
    }

    // Pan the image (without changing perspective)
    if (screenPan[0] !== 0.0 || screenPan[1] !== 0.0) {
        let trans = [screenPan[0], screenPan[1], 0.0];
        const panMatrix = this.scratchPanMatrix;
        mat4.identity(panMatrix);
        mat4.translate(panMatrix, panMatrix, trans);
        mat4.multiply(projectionMatrix, panMatrix, projectionMatrix);
    }

    // Set the model position to the origin/center of the scene
    mat4.identity(modelViewMatrix);

    // STM_TODO - this is hacky
    // Move an initial distance before rotation to simulate depth
    // in stereo mode
    const distance0 = distance * distanceOffset;
    const distance1 = distance - distance0;
    mat4.translate(modelViewMatrix,          // destination matrix
                   modelViewMatrix,          // matrix to translate
                   [0.0, 0.0, -distance0]);  // amount to translate

    // Rotate the model before we move it, based on the eye position
    // (for stereo rendering).
    // This ensures that the volume will remain centered.
    //mat4.rotateY(modelViewMatrix, modelViewMatrix, eyeDistance);
    mat4.rotateY(modelViewMatrix, modelViewMatrix, Math.atan(eyeDistance));

    // Move (translate) the view matrix based on the zoom factor
    let localPan = [volumeOffset[0] + eyeDistance*distance, -volumeOffset[1]];
    mat4.translate(modelViewMatrix,                // destination matrix
                   modelViewMatrix,                // matrix to translate
                   [localPan[0], localPan[1], -distance1]);  // amount to translate

    // Combine the rotation and translation matrices into a single model view
    // matrix
    if (!mat4.exactEquals(VolumeViewer.MAT4_IDENTITY, pivotMatrix) ||
        !vec3.exactEquals(VolumeViewer.VEC3_EMPTY, pivotPoint)) {
        rotationMatrix = this.combineMatrices(rotationMatrix, pivotPoint, pivotMatrix);
    }

    mat4.multiply(modelViewMatrix, modelViewMatrix, rotationMatrix);

    // Create a second matrix specifically for surface normals
    // STM_TODO - not needed
    //const normalMatrix = mat4.create();
    //mat4.invert(normalMatrix, modelViewMatrix);
    //mat4.transpose(normalMatrix, normalMatrix);

    return distance;
};

// Generates a rotation matrix that combines all rotations/translation matrices.
VolumeViewer.prototype.combineMatrices = function(rotationMatrix=null,
                                                  pivotPoint=null,
                                                  pivotMatrix=null) {

    if (rotationMatrix === undefined || rotationMatrix === null)
        rotationMatrix = this.rotationMatrix;
    if (pivotPoint === undefined || pivotPoint === null)
        pivotPoint = this.pivotPoint;
    if (pivotMatrix === undefined || pivotMatrix === null)
        pivotMatrix = this.pivotMatrix;

    const combinedMatrix = this.scratchCombinedMatrix;
    mat4.identity(combinedMatrix);

    const translationIn = vec3.fromValues(-pivotPoint[0], -pivotPoint[1], -pivotPoint[2]);
    const translationOut = vec3.fromValues(pivotPoint[0], pivotPoint[1], pivotPoint[2]);
    const translateInMatrix = mat4.create();
    const translateOutMatrix = mat4.create();

    mat4.fromTranslation(translateInMatrix, translationIn);
    mat4.fromTranslation(translateOutMatrix, translationOut);
    mat4.multiply(translateOutMatrix, rotationMatrix, translateOutMatrix);

    mat4.multiply(combinedMatrix, translateInMatrix, combinedMatrix);
    mat4.multiply(combinedMatrix, pivotMatrix, combinedMatrix);
    mat4.multiply(combinedMatrix, translateOutMatrix, combinedMatrix);

    return combinedMatrix;
};

// Generates viewport information for rendering passes.
VolumeViewer.prototype.generatePasses = function() {
    let passes;
    if (!this.showStereo)
        // One rendering pass if not in stereo mode
        passes = [
            this.buildViewport(VolumeViewer.VIEW_CENTER)
        ];
    else
        // Two rendering passes (left and right) for stereo mode
        passes = [
            this.buildViewport(VolumeViewer.VIEW_LEFT),
            this.buildViewport(VolumeViewer.VIEW_RIGHT)
        ];

    return passes;
};

// Updates all matrices associated with rendering in viewports.
VolumeViewer.prototype.updateMatrices = function(passes=null) {
    if (!passes)
        passes = this.generatePasses();

    for (let i=0; i<passes.length; ++i) {
        let viewport = passes[i];

        const aspect = viewport.aspect;

        let projectionMatrix, modelViewMatrix, forwardMatrix, inverseMatrix;
        let eyeDistance;

        if (viewport.displayView === VolumeViewer.VIEW_CENTER) {
            projectionMatrix = this.projectionMatrix;
            modelViewMatrix  = this.modelViewMatrix;
            forwardMatrix    = this.forwardMatrix;
            inverseMatrix    = this.inverseMatrix;
            eyeDistance      = 0.0;
        }
        else {
            projectionMatrix = this.stereoProjectionMatrix[i];
            modelViewMatrix  = this.stereoModelViewMatrix[i];
            forwardMatrix    = this.stereoForwardMatrix[i];
            inverseMatrix    = this.stereoInverseMatrix[i];
            eyeDistance      = this.eyeDistance * (i * 2.0 - 1.0);
        }
        eyeDistance *= this.showParallel ? -1.0 : 1.0;

        // Generate all of our projection matrices
        this.generateMatrices(projectionMatrix, modelViewMatrix, aspect,
                              eyeDistance);

        // Other specialized matrices
        mat4.multiply(forwardMatrix, projectionMatrix, modelViewMatrix);
        mat4.invert(inverseMatrix, forwardMatrix);
    }
};

// Generates a projection matrix based on current viewing options.  The calling
// method may override some or all of these options.
VolumeViewer.prototype.generateProjectionMatrix = function(params=null, displayView=VolumeViewer.VIEW_UNKNOWN) {
    if (displayView === VolumeViewer.VIEW_UNKNOWN)
        displayView = this.getDefaultView();

    let projectionMatrix = mat4.create();
    let modelViewMatrix = mat4.create();

    let viewport = this.getViewport(displayView);

    let aspect = viewport.aspect;

    let eyeDistance = 0.0;
    if (displayView === VolumeViewer.VIEW_LEFT)
        eyeDistance = -1.0;
    else if (displayView === VolumeViewer.VIEW_RIGHT)
        eyeDistance = 1.0;

    eyeDistance *= this.showParallel ? -1.0 : 1.0;

    // Overrides
    if (params) {
        if (params.eyeDistance !== undefined)  eyeDistance = params.eyeDistance;
        if (params.aspect !== undefined)       aspect      = params.aspect;
    }

    this.generateMatrices(projectionMatrix, modelViewMatrix, aspect, eyeDistance, params);

    mat4.multiply(projectionMatrix, projectionMatrix, modelViewMatrix);

    return projectionMatrix;
};

// Gets the aspect ratio of the volume viewer's canvas.
// Note that this is calculated differently if you are in stereo viewing mode;
// the aspect ratio is for a single "eye", not the entire canvas.
VolumeViewer.prototype.getAspectRatio = function() {
    let aspect = 1.0;

    const gl = this.gl;
    if (gl) {
        const windowAspect = this.showStereo ? 0.5 : 1.0;
        aspect = windowAspect * gl.canvas.clientWidth / gl.canvas.clientHeight;
    }

    return aspect;
};

// Gets the current background color for the canvas.
// This may change depending on whether we are displaying in normal mode
// or stereo mode.
VolumeViewer.prototype.getCurrentBackgroundColor = function() {
    let backgroundColor = this.backgroundColor;
    if (this.showStereo && this.stereoBackgroundColor !== null)
        backgroundColor = this.stereoBackgroundColor;

    return backgroundColor;
};

// Updates the CSS background color for the canvas (or its container)
// based on the current background color.
VolumeViewer.prototype.updateBackgroundCss = function() {
    let background = this.getCurrentBackgroundColor();

    let value = VolumeViewer.buildColorString(background);
    if (!value)  value = "black";

    $(this.topLevel).css("background-color", value);
};

// Sanitizes the requested animation parameter, converting it to an
// animation time.
VolumeViewer.prototype.sanitizeAnimate = function(animate, defaultEnabled, timeMult) {
    defaultEnabled = !!defaultEnabled;
    if (timeMult === undefined || timeMult === null)  timeMult = 1.0;

    let defaultTime = this.defaultStateAnimationTime * timeMult;

    if (animate === undefined || animate === null)
        animate = defaultEnabled ? defaultTime : 0.0;
    else if (animate === true)
        animate = defaultTime;
    else if (animate === false)
        animate = 0.0;

    return animate;
};

// Begins a block of code that represents a group of actions.
// Handles nested requests.
VolumeViewer.prototype.beginActionBlock = function() {
    ++this.actionDepth;
};

// Ends a block of code that represents a group of actions.
// Handles nested requests.
VolumeViewer.prototype.endActionBlock = function() {
    if (this.actionDepth > 0)
        --this.actionDepth;

    this.invokeActionCallbacks(null);
};

// Begins a block of code that represents a user action.
// Handles nested requests.
VolumeViewer.prototype.beginUserAction = function() {
    this.beginActionBlock();
    ++this.userActionDepth;
};

// Ends a block of code that represents a user action.
// Handles nested requests.
VolumeViewer.prototype.endUserAction = function() {
    if (this.userActionDepth > 0)
        --this.userActionDepth;
    this.endActionBlock();
};

// Invokes callbacks when a volume has been loaded and processed.
VolumeViewer.prototype.invokeVolumeProcessedCallbacks = function() {
    VolumeViewer.invokeCallbacks(this.volumeProcessedCallbacks, {
        viewer: this
    });
};

// Invokes callbacks when a volume has been set.
VolumeViewer.prototype.invokeVolumeSetCallbacks = function() {
    VolumeViewer.invokeCallbacks(this.volumeSetCallbacks, {
        viewer: this
    });
};

// Invokes callbacks just before rendering.
VolumeViewer.prototype.invokePreRenderCallbacks = function() {
    VolumeViewer.invokeCallbacks(this.preRenderCallbacks, {
        viewer: this
    });
};

// Invokes callbacks just after rendering.
VolumeViewer.prototype.invokePostRenderCallbacks = function() {
    VolumeViewer.invokeCallbacks(this.postRenderCallbacks, {
        viewer: this
    });
};

// Invokes callbacks when the user has performed an action.
VolumeViewer.prototype.invokeActionCallbacks = function(actionName) {
    // Skip if we're blocking action callbacks
    // (prevents infinite recursion)
    if (this.blockActions)  return;

    let deferredActions = this.deferredActions;
    if (actionName) {
        // Is this action already in our list of deferred actions?
        let isUserAction = this.userActionDepth > 0;

        let found = false;
        for (let i=0; i<deferredActions.length; ++i) {
            let deferredAction = deferredActions[i];
            if (deferredAction.actionName === actionName &&
                deferredAction.isUserAction === isUserAction) {
                found = true;
                break;
            }
        }

        // This action isn't in our list, so add it to the end
        if (!found) {
            let deferredAction = {
                viewer:       this,
                actionName:   actionName,
                isUserAction: isUserAction
            };
            deferredActions.push(deferredAction);
        }
    }

    // Only perform the following if we are NOT in an action group block
    if (this.actionDepth <= 0) {
        // We are allowed to invoke action callbacks now
        if (deferredActions.length) {
            // We have action callbacks waiting for invocation
            this.deferredActions = [];

            let blockActions = this.blockActions;
            this.blockActions = true;
            try {
                // Call our callbacks!
                for (let i=0; i<deferredActions.length; ++i) {
                    let deferredAction = deferredActions[i];
                    let isUserAction = deferredAction.isUserAction;
                    let callbacks = isUserAction ? this.userActionCallbacks : this.programActionCallbacks;
                    VolumeViewer.invokeCallbacks(callbacks, deferredAction);
                }
            }
            finally { this.blockActions = blockActions; }
        }
    }
};

// Converts mouse positional data into a consistent, easy-to-use format.
VolumeViewer.prototype.getMouseData = function(mouseX0, mouseY0, mouseX1, mouseY1) {
    if (mouseX0 === undefined || mouseY0 === undefined) {
        mouseX0 = 0.0;
        mouseY0 = 0.0;
    }
    if (mouseX1 === undefined || mouseY1 === undefined) {
        mouseX1 = mouseX0;
        mouseY1 = mouseY0;
    }
    let mouseX = (mouseX0 + mouseX1) * 0.5;
    let mouseY = (mouseY0 + mouseY1) * 0.5;

    return {
        mouseX:  mouseX,
        mouseY:  mouseY,
        mouseX0: mouseX0,
        mouseY0: mouseY0,
        mouseX1: mouseX1,
        mouseY1: mouseY1
    };
};

// Copies the specified mouse data into the viewer.
// Future mouse movement will be relative to this data.
VolumeViewer.prototype.setMouseData = function(mouseData) {
    this.mouseX  = mouseData.mouseX;
    this.mouseY  = mouseData.mouseY;
    this.mouseX0 = mouseData.mouseX0;
    this.mouseY0 = mouseData.mouseY0;
    this.mouseX1 = mouseData.mouseX1;
    this.mouseY1 = mouseData.mouseY1;
};

// Computes angular and distance information based on the previous and
// current mouse positions.  (Only used for two-fingered tablet actions.)
VolumeViewer.prototype.computeMouseMetrics = function(mouseData) {
    const oldDeltaX = this.mouseX1 - this.mouseX0;
    const oldDeltaY = this.mouseY1 - this.mouseY0;
    const newDeltaX = mouseData.mouseX1 - mouseData.mouseX0;
    const newDeltaY = mouseData.mouseY1 - mouseData.mouseY0;

    if (oldDeltaX !== 0.0 || oldDeltaY !== 0.0 || newDeltaX !== 0.0 || newDeltaY !== 0.0) {
        // Two-finger metrics
        const oldSpread = Math.sqrt(oldDeltaX*oldDeltaX + oldDeltaY*oldDeltaY);
        const oldAngle  = Math.atan2(oldDeltaY, oldDeltaX) * 180.0 / Math.PI;

        const newSpread = Math.sqrt(newDeltaX*newDeltaX + newDeltaY*newDeltaY);
        const newAngle  = Math.atan2(newDeltaY, newDeltaX) * 180.0 / Math.PI;

        let angle = newAngle - oldAngle;
        if (angle >= 180.0)  angle -= 360.0;
        if (angle < -180.0)  angle += 360.0;

        const spread = newSpread - oldSpread;

        const deltaX  = mouseData.mouseX  - this.mouseX;
        const deltaY  = mouseData.mouseY  - this.mouseY;
        const deltaX0 = mouseData.mouseX0 - this.mouseX0;
        const deltaY0 = mouseData.mouseY0 - this.mouseY0;
        const deltaX1 = mouseData.mouseX1 - this.mouseX1;
        const deltaY1 = mouseData.mouseY1 - this.mouseY1;

        const distance  = Math.sqrt(deltaX*deltaX + deltaY*deltaY);
        const distance0 = Math.sqrt(deltaX0*deltaX0 + deltaY0*deltaY0);
        const distance1 = Math.sqrt(deltaX1*deltaX1 + deltaY1*deltaY1);

        return {
            angle:    angle,
            spread:   spread,
            distance: distance,
            delta0:   distance0,
            delta1:   distance1
        };
    }

    // Return null if this is a mouse drag or one-fingered drag
    return null;
};

// Gets the face that is most exactly pointing towards the user.
VolumeViewer.prototype.getFacingFace = function() {
    let axis = this.getFacingAxis();

    if (axis[0] === -1.0)  return VolumeViewer.FACE_LEFT;
    if (axis[0] ===  1.0)  return VolumeViewer.FACE_RIGHT;
    if (axis[1] === -1.0)  return VolumeViewer.FACE_ANTERIOR;
    if (axis[1] ===  1.0)  return VolumeViewer.FACE_POSTERIOR;
    if (axis[2] === -1.0)  return VolumeViewer.FACE_SUPERIOR;
    if (axis[2] ===  1.0)  return VolumeViewer.FACE_INFERIOR;

    return VolumeViewer.FACE_NONE;
};

// Gets the natural axis that is most exactly pointing towards the user.
VolumeViewer.prototype.getFacingAxis = function() {
    const xAxis = vec3.fromValues(1.0, 0.0, 0.0);
    const yAxis = vec3.fromValues(0.0, 1.0, 0.0);
    const zAxis = vec3.fromValues(0.0, 0.0, 1.0);

    const rotationMatrix = this.combineMatrices();
    const cameraDir = vec3.fromValues(rotationMatrix[2], rotationMatrix[6], rotationMatrix[10]);

    let bestDot = 0.0;
    let curDot;
    let bestAxis;

    curDot = vec3.dot(xAxis, cameraDir);
    bestDot = curDot;
    bestAxis = xAxis;

    curDot = vec3.dot(yAxis, cameraDir);
    if (Math.abs(bestDot) < Math.abs(curDot)) {
        bestDot = curDot;
        bestAxis = yAxis;
    }

    curDot = vec3.dot(zAxis, cameraDir);
    if (Math.abs(bestDot) < Math.abs(curDot)) {
        bestDot = curDot;
        bestAxis = zAxis;
    }

    // Negative numbers mean the axis is facing away from us
    if (bestDot < 0.0)
        vec3.negate(bestAxis, bestAxis);

    return bestAxis;
};

// Gets the MPR plane number that is most exactly facing the user.
// Plane numbers are one-based and range from 1 to 3.
// If the plane is facing away from the user, the plane number will be negative.
// If an error occurs (rare), this method will return 0.
VolumeViewer.prototype.getFacingPlane = function() {
    if (!this.canShowPlanes())  return 0;

    // The plane we will choose to move is the one that is most nearly
    // facing the user.  Dragging up will move this plane towards the user,
    // and dragging down will move it away, regardless of our orientation.
    const planeMatrix = this.getInternalPlaneMatrix();
    let   xPlaneNormal = vec3.fromValues(planeMatrix[0], planeMatrix[1], planeMatrix[2]);
    let   yPlaneNormal = vec3.fromValues(planeMatrix[3], planeMatrix[4], planeMatrix[5]);
    let   zPlaneNormal = vec3.fromValues(planeMatrix[6], planeMatrix[7], planeMatrix[8]);

    // Don't include invisible planes in our search
    if (this.planeAlphaLevels[0] <= 0.0 || this.autoPlane)
        xPlaneNormal = vec3.fromValues(0.0, 0.0, 0.0);
    if (this.planeAlphaLevels[1] <= 0.0 || this.autoPlane)
        yPlaneNormal = vec3.fromValues(0.0, 0.0, 0.0);
    if (this.planeAlphaLevels[2] <= 0.0)
        zPlaneNormal = vec3.fromValues(0.0, 0.0, 0.0);

    const rotationMatrix = this.combineMatrices();
    const cameraDir = vec3.fromValues(rotationMatrix[2], rotationMatrix[6], rotationMatrix[10]);

    let bestDot = 0.0;
    let curDot;

    let mousePlaneSelected = 0;

    curDot = vec3.dot(xPlaneNormal, cameraDir);
    if (Math.abs(bestDot) < Math.abs(curDot)) {
        bestDot = curDot;
        mousePlaneSelected = 1;
    }

    curDot = vec3.dot(yPlaneNormal, cameraDir);
    if (Math.abs(bestDot) < Math.abs(curDot)) {
        bestDot = curDot;
        mousePlaneSelected = 2;
    }

    curDot = vec3.dot(zPlaneNormal, cameraDir);
    if (Math.abs(bestDot) < Math.abs(curDot)) {
        bestDot = curDot;
        mousePlaneSelected = 3;
    }

    // Negative numbers mean the plane is facing away from us
    if (bestDot < 0.0)
        mousePlaneSelected = -mousePlaneSelected;

    return mousePlaneSelected;
};

// Gets the surface normal for the specified plane face, as well as a point
// on the plane.
// Planes must be enabled for this method to work.
VolumeViewer.prototype.getPlaneNormal = function(selectedFace) {
    if (!this.canShowPlanes())  return null;

    if (typeof selectedFace !== 'number')  return null;

    let direction = selectedFace > 0 ? 1.0 : -1.0;
    selectedFace = Math.abs(selectedFace);
    if (selectedFace !== 1 && selectedFace !== 2 && selectedFace !== 3)  return null;

    let faceOffset = selectedFace - 1;

    let planeOffset = this.getPlaneOffset();
    let planePoint = this.convertToVolumeOffset(planeOffset);

    let planeMatrix = this.getInternalPlaneMatrix();
    let planeNormal = {
        x: planeMatrix[faceOffset*3+0] * direction,
        y: planeMatrix[faceOffset*3+1] * direction,
        z: planeMatrix[faceOffset*3+2] * direction
    };

    return {
        point:  planePoint,
        normal: planeNormal
    };
};

// Determines which action the user wants to perform, based on contextual
// cues from how the user is moving the mouse and/or dragging on the touchpad.
VolumeViewer.prototype.detectAction = function(mouseData) {
    // Here's where the magic happens...
    if (!this.mouseSpin && !this.mouseClip && !this.mousePlane &&
        !this.mouseRoll && !this.mouseZoom && !this.mousePanZoom &&
        !this.mousePan && !this.mouseInput && !this.mouseRocker &&
        !this.mouseCrosshair && !this.mouseAnnotations) {

        // Conveniences
        let checkSpin        = ((this.mouseOptions & VolumeViewer.DRAG_SPIN)        !== 0);
        let checkInput       = ((this.mouseOptions & VolumeViewer.DRAG_INPUT)       !== 0);
        let checkClip        = ((this.mouseOptions & VolumeViewer.DRAG_CLIP)        !== 0);
        let checkPlane       = ((this.mouseOptions & VolumeViewer.DRAG_PLANE)       !== 0);
        let checkRoll        = ((this.mouseOptions & VolumeViewer.DRAG_ROLL)        !== 0);
        let checkZoom        = ((this.mouseOptions & VolumeViewer.DRAG_ZOOM)        !== 0);
        let checkPanZoom     = ((this.mouseOptions & VolumeViewer.DRAG_PAN_ZOOM)    !== 0);
        let checkPan         = ((this.mouseOptions & VolumeViewer.DRAG_PAN)         !== 0);
        let checkRocker      = ((this.mouseOptions & VolumeViewer.DRAG_ROCKER)      !== 0);
        let checkCrosshair   = ((this.mouseOptions & VolumeViewer.DRAG_CROSSHAIR)   !== 0);
        let checkAnnotations = ((this.mouseOptions & VolumeViewer.DRAG_ANNOTATIONS) !== 0);

        // We have the option to disable certain operations...
        if (!this.isUserRotationAllowed()) {
            checkSpin   = false;
            checkRoll   = false;
            checkRocker = false;
        }
        if (!this.isUserRollAllowed()) {
            checkSpin   = false;
            checkRoll   = false;
            checkRocker = false;
        }
        if (!this.isUserZoomAllowed()) {
            checkZoom    = false;
            checkPanZoom = false;
        }
        if (!this.isUserPanAllowed()) {
            checkPanZoom = false;
            checkPan     = false;
            if (!this.isRockerSnapBackEnabled())
                checkRocker = false;
        }
        if (!this.isUserMovePlaneAllowed()) {
            checkPlane = false;
        }
        if (!this.isUserMoveClipAllowed()) {
            checkClip = false;
        }
        if (!this.rootOverlay || !this.annotationOverlay || !this.isUserMoveAnnotationsAllowed()) {
            checkAnnotations = false;
        }

        // Disable some checks if certain viewing options are disabled
        if (!this.canShowPlanes()) {
            checkPlane = false;
            checkCrosshair = false;
            //checkAnnotations = false;
        }
        if (!this.canShowVolume() || !this.isClippingEnabled()) {
            checkClip = false;
        }

        const doubleTouch = (mouseData.mouseX0 !== mouseData.mouseX1 ||
                             mouseData.mouseY0 !== mouseData.mouseY1);

        // The user is dragging, but no action has been set.
        // We'll need some context to figure out what he's trying to do.

        if (doubleTouch) {
            // This is a double-touch drag.  This makes things trickier.
            // We will have to use the context to figure out what the user
            // wants to do.

            const metrics = this.computeMouseMetrics(mouseData);

            const THRESHOLD_ANGLE         = 15.0;  // degrees
            const THRESHOLD_SPREAD        = 25.0;  // pixels
            const THRESHOLD_SPIN_DISTANCE = 15.0;  // pixels

            if (checkRoll && Math.abs(metrics.angle) > THRESHOLD_ANGLE)  {
                // The two points have rotated beyond a certain threshold.
                // We are rolling.

                this.mouseRoll = true;

                this.setMouseData(mouseData);

                return false;
            }
            if (checkPanZoom && Math.abs(metrics.spread) > THRESHOLD_SPREAD)  {
                // The two points have increased or decreased their separation
                // beyond a certain threshold.
                // We are zooming.

                this.mousePanZoom = true;
                this.mousePanZoomPosition = { x: mouseData.mouseX, y: mouseData.mouseX };

                this.setMouseData(mouseData);

                return false;
            }
            if (checkZoom && Math.abs(metrics.spread) > THRESHOLD_SPREAD)  {
                // The two points have increased or decreased their separation
                // beyond a certain threshold.
                // We are zooming.

                this.mouseZoom = true;

                this.setMouseData(mouseData);

                return false;
            }
            if ((checkPlane || checkClip) &&
                metrics.delta0   > THRESHOLD_SPIN_DISTANCE &&
                metrics.delta1   > THRESHOLD_SPIN_DISTANCE &&
                metrics.distance > THRESHOLD_SPIN_DISTANCE) {

                // The two points are moving more or less in tandem.
                // We are either dragging a clipping plane or an MPR plane.
                // Let's figure out which.

                if (checkPlane) {
                    // An MPR plane is visible.
                    // We are moving the MPR plane.

                    this.mousePlane = true;
                    this.mousePlaneSelected = this.getFacingPlane();

                    this.setMouseData(mouseData);

                    return false;
                }
                if (checkClip) {
                    // A clipping plane is visible.
                    // We are moving the clip plane.

                    this.mouseClip = true;

                    this.setMouseData(mouseData);

                    return false;
                }
            }

            return false;
        }
        else {
            // Our two mouse positions are the same, so this is a single-touch drag.

            // Determine what to do based on the options given to us when
            // we started dragging.

            if (checkCrosshair) {
                // We are moving crosshairs.

                // Clicking *near* the crosshair center gives crosshair movement
                // a higher priority than other possible actions.
                let planeData = this.getInternalPlaneData();
                let displayView = this.displayToView(this.mouseX, this.mouseY);
                let screenCross = this.pointToDisplay(
                    planeData.point[0],
                    planeData.point[1],
                    planeData.point[2],
                    displayView);
                let deltaX = Math.abs(screenCross.x - this.mouseX);
                let deltaY = Math.abs(screenCross.y - this.mouseY);
                const EPSILON = 15.0;
                if (deltaX <= EPSILON && deltaY <= EPSILON) {
                    let intersect = this.getFacingPlaneIntersection(this.mouseX, this.mouseY);

                    if (intersect) {
                        this.mouseCrosshair = true;

                        this.mouseCrosshairPlane = intersect.plane;

                        this.setPlaneOffsetFromPointXYZ(intersect.point.x, intersect.point.y,
                                                        intersect.point.z);

                        //this.setMouseData(mouseData);
                    }
                    return false;
                }
            }
            if (checkAnnotations) {
                let displayView = this.displayToView(this.mouseX, this.mouseY);

                if (this.rootOverlay.startDrag({ x: this.mouseX, y: this.mouseY }, displayView)) {
                    this.mouseAnnotations = true;

                    return false;
                }
            }
            if (checkRocker) {
                // We are rocking!

                let intersect;
                if (this.canShowPlanes())
                    intersect = this.getPlaneIntersection(this.mouseX, this.mouseY, true);
                else
                    intersect = { point: {x: 0.0, y: 0.0, z: 0.0}, plane: 0 };

                if (intersect) {
                    this.mouseRocker = true;

                    vec3.copy(this.mouseRockerOffset, this.planeOffset);

                    this.setPlanePointXYZ(intersect.point.x, intersect.point.y,
                                          intersect.point.z, 0.0, 0.0, 0.0);
                    this.setPivotMatrix(VolumeViewer.IDENTITY_MATRIX,
                                        intersect.point);

                    //this.setMouseData(mouseData);
                }
                return false;
            }
            if (checkSpin) {
                // We are spinning.

                this.mouseSpin = true;

                mat4.copy(this.mouseSpinMatrix, this.rotationMatrix);

                this.setMouseData(mouseData);

                this.mouseSpinDeltaX = 0.0;
                this.mouseSpinDeltaY = 0.0;

                return false;
            }
            if (checkInput) {
                // We are changing the input window.

                this.mouseInput = true;

                this.setMouseData(mouseData);

                this.mouseBrightness = this.getLuminosityBrightness();
                this.mouseContrast = this.getLuminosityContrast();

                return false;
            }
            if (checkCrosshair) {
                // We are moving crosshairs.

                let intersect = this.getFacingPlaneIntersection(this.mouseX, this.mouseY);
                if (intersect) {
                    this.mouseCrosshair = true;

                    this.mouseCrosshairPlane = intersect.plane;

                    this.setPlaneOffsetFromPointXYZ(intersect.point.x, intersect.point.y,
                                                    intersect.point.z);

                    //this.setMouseData(mouseData);

                    return false;
                }
            }
            if (checkRoll) {
                // We are rolling.

                this.mouseRoll = true;

                this.setMouseData(mouseData);

                return false;
            }
            if (checkPlane) {
                // An MPR plane is visible.
                // We are moving the MPR plane.

                this.mousePlane = true;
                this.mousePlaneSelected = this.getFacingPlane();

                this.setMouseData(mouseData);

                return false;
            }
            if (checkClip) {
                // A clipping plane is visible.
                // We are moving the clip plane.

                this.mouseClip = true;

                this.setMouseData(mouseData);

                return false;
            }
            if (checkPanZoom) {
                // We are panning and zooming.
                this.mousePanZoom = true;
                this.mousePanZoomPosition = { x: mouseData.mouseX, y: mouseData.mouseY };

                this.setMouseData(mouseData);

                return false;
            }
            if (checkZoom)  {
                // We are zooming.

                this.mouseZoom = true;

                this.setMouseData(mouseData);

                return false;
            }
            if (checkPan)  {
                // We are panning

                this.mousePan = true;
                this.mousePanPosition = this.getScreenPan();
                this.mousePanViewport = this.displayToViewport(mouseData.mouseX,
                                            mouseData.mouseY, this.mouseView);

                //this.setMouseData(mouseData);

                return false;
            }
        }
        return false;
    }

    return true;
};

// Determines whether volumetric rendering can be shown,
// based on which options are set.
VolumeViewer.prototype.canShowVolume = function() {
    let canShowVolume = false;

    let volCount = 1;
    if (this.volume)  volCount = this.volume.count;
    volCount = Math.min(volCount, this.perVolume.length);

    for (let i=0; i<volCount; ++i) {
        if (this.perVolume[i].luminosityAlpha > 0.0 ||
            this.perVolume[i].gradientAlpha > 0.0) {
            canShowVolume = true;
            break;
        }
    }

    return canShowVolume;
};

// Determines whether plane rendering can be shown,
// based on which options are set.
VolumeViewer.prototype.canShowPlanes = function() {
    let canShowPlanes = false;

    let volCount = 1;
    if (this.volume)  volCount = this.volume.count;
    volCount = Math.min(volCount, this.perVolume.length);

    for (let i=0; i<volCount; ++i) {
        if (this.perVolume[i].planeAlpha > 0.0) {
            canShowPlanes = true;
            break;
        }
    }

    return canShowPlanes;
};

// Determines whether clipping planes can be shown,
// based on which options are set.
VolumeViewer.prototype.canShowClipping = function() {
    let canShowClipping = false;

    if (this.isClippingEnabled()) {
        let volCount = 1;
        if (this.volume)  volCount = this.volume.count;
        volCount = Math.min(volCount, this.perVolume.length);

        for (let i=0; i<volCount; ++i) {
            if (this.perVolume[i].luminosityAlpha > 0.0 ||
                this.perVolume[i].gradientAlpha > 0.0) {
                canShowClipping = true;
                break;
            }
        }
    }

    return canShowClipping;
};

// Retrieves size and scale information from the processed volume.
VolumeViewer.prototype.getProcessedVolumeData = function() {
    let volume          = this.getVolumeRaw();  // STM_TODO - double-check linked viewers
    let volumeProcessed = this.volumeProcessed;
    if (volumeProcessed) {
        let scaleX = volumeProcessed.scaleMultX;
        let scaleY = volumeProcessed.scaleMultY;
        let scaleZ = volumeProcessed.scaleMultZ;
        if (volume) {
            scaleX *= volume.scaleX;
            scaleY *= volume.scaleY;
            scaleZ *= volume.scaleZ;
        }
        return {
            width:  volumeProcessed.width,
            height: volumeProcessed.height,
            depth:  volumeProcessed.depth,
            scaleX: scaleX,
            scaleY: scaleY,
            scaleZ: scaleZ
        };
    }
    else if (volume) {
        let processedSize = this.computeProcessedVolumeSize(volume);
        if (processedSize) {
            let scaleX = processedSize.scaleMultX;
            let scaleY = processedSize.scaleMultY;
            let scaleZ = processedSize.scaleMultZ;
            scaleX *= volume.scaleX;
            scaleY *= volume.scaleY;
            scaleZ *= volume.scaleZ;
            return {
                width:  processedSize.width,
                height: processedSize.height,
                depth:  processedSize.depth,
                scaleX: scaleX,
                scaleY: scaleY,
                scaleZ: scaleZ
            };
        }
        else {
            return null;
        }
    }
    else {
        return null;
    }
};

// Gets our MPR plane matrix, which may be set up to automatically orient
// towards the camera.
VolumeViewer.prototype.getInternalPlaneMatrix = function() {
    let planeMatrix = this.planeMatrix;
    if (this.autoPlane) {
        planeMatrix = this.scratchMatrix;
        const rotationMatrix = this.combineMatrices();
        mat3.set(planeMatrix,
                 rotationMatrix[0], rotationMatrix[4], rotationMatrix[8],
                 rotationMatrix[1], rotationMatrix[5], rotationMatrix[9],
                 rotationMatrix[2], rotationMatrix[6], rotationMatrix[10]);
    }

    return planeMatrix;
};

// Gets MPR data, including the collapsed plane point and the plane matrix
// (which contains the plane normals and may contain zero-length vectors).
VolumeViewer.prototype.getInternalPlaneData = function() {
    let planeMatrix = this.getInternalPlaneMatrix();
    let planePoint = vec3.clone(this.planePoint);
    let planeOffset = vec3.create();

    let tempOffset = this.getPlaneOffset();

    // Clamp and/or snap the planes to the appropriate constraints
    if (this.snapToVoxel) {
        // Snap-to-voxel always moves each plane to the *middle* of a voxel
        tempOffset = this.convertToStepOffset(tempOffset);
        tempOffset.sx = Math.floor(tempOffset.sx) + 0.5;
        tempOffset.sy = Math.floor(tempOffset.sy) + 0.5;
        tempOffset.sz = Math.floor(tempOffset.sz) + 0.5;
    }
    tempOffset = this.convertToPlaneOffset(tempOffset);

    planeOffset[0] = tempOffset.px;
    planeOffset[1] = tempOffset.py;
    planeOffset[2] = tempOffset.pz;

    // Transform the plane coordinates appropriately
    // (NOTE: this code implicitly assumes that the three planes are
    // orthogonal, and that they share a common intersection point as
    // defined by both the plane point and the plane offset!)
    vec3.transformMat3(planeOffset, planeOffset, this.planeMatrix);
    vec3.add(planePoint, planePoint, planeOffset);

    planeMatrix = mat3.clone(planeMatrix);

    return {
        point:  planePoint,
        matrix: planeMatrix
    };
};

// Gets information that can be used to convert plane offsets to
// plane step offsets (one plane step unit == one voxel).
VolumeViewer.prototype.getStepData = function(x, y, z) {
    let normVec = VolumeViewer.normalize(x, y, z, 1.0, 0.0, 0.0);

    let volumeExtents = this.getVolumeExtents();
    let maxOffset = VolumeViewer.getMaxPlaneOffset(normVec[0], normVec[1], normVec[2],
                                                   volumeExtents.x, volumeExtents.y, volumeExtents.z);

    let curAxis = vec3.fromValues(1.0, 0.0, 0.0);
    let curDot = vec3.dot(normVec, curAxis);
    let bestDot = curDot;
    let bestAxis = curAxis;
    let bestSide = 1;

    curAxis = vec3.fromValues(0.0, 1.0, 0.0);
    curDot = vec3.dot(normVec, curAxis);
    if (Math.abs(bestDot) < Math.abs(curDot)) {
        bestDot = curDot;
        bestAxis = curAxis;
        bestSide = 2;
    }
    curAxis = vec3.fromValues(0.0, 0.0, 1.0);
    curDot = vec3.dot(normVec, curAxis);
    if (Math.abs(bestDot) < Math.abs(curDot)) {
        bestDot = curDot;
        bestAxis = curAxis;
        bestSide = 3;
    }

    let volume = this.getProcessedVolumeData();
    if (!volume) {
        return {
            stepIncrement:  1.0,
            slices:         1,
            sliceOffset:    -0.5,
            sliceDirection: 1.0
        };
    }

    let sliceCount = 1;
    let sideExtent = 1.0;
    if (bestSide === 1) {
        sliceCount = volume.width;
        sideExtent = volumeExtents.x;
    }
    else if (bestSide === 2) {
        sliceCount = volume.height;
        sideExtent = volumeExtents.y;
    }
    else if (bestSide === 3) {
        sliceCount = volume.depth;
        sideExtent = volumeExtents.z;
    }
    sideExtent *= 2.0;

    let even = (sliceCount % 2) === 0;
    let stepIncrement = sideExtent / (Math.abs(vec3.dot(normVec, bestAxis)) * sliceCount);
    let extra = even ? 0.0 : stepIncrement * 0.5;
    let slices = 2.0 * Math.round((maxOffset - extra) / stepIncrement) + (even ? 0 : 1) - VolumeViewer.BORDER_ADD;
    let sliceOffset = slices / 2.0;
    let sliceDirection = (normVec[0] + normVec[1] + normVec[2]) < 0.0 ? -1.0 : 1.0;

    return {
        stepIncrement:  stepIncrement,
        slices:         slices,
        sliceOffset:    sliceOffset,
        sliceDirection: sliceDirection
    };
};

// Gets step data for all three offsets in the plane.
// The step data that is returned is based off of the rotation coordinate system.
VolumeViewer.prototype.getPlaneStepData = function() {
    const planeMatrix = this.getInternalPlaneMatrix();
    let xStepData = this.getStepData(planeMatrix[0], planeMatrix[1], planeMatrix[2]);
    let yStepData = this.getStepData(planeMatrix[3], planeMatrix[4], planeMatrix[5]);
    let zStepData = this.getStepData(planeMatrix[6], planeMatrix[7], planeMatrix[8]);

    return {
        xStep: xStepData,
        yStep: yStepData,
        zStep: zStepData
    };
};

// Gets the maximum offset for the specified plane normals, based on
// current volume extents.
// The returned offset will be the maximum values of the three plane offsets
// before they completely leave the volume.
VolumeViewer.prototype.getMaxPlaneOffsets = function() {
    const planeMatrix = this.getInternalPlaneMatrix();

    const volumeExtents = this.getVolumeExtents();
    if (!volumeExtents)
        return { x: 1.0, y: 1.0, z: 1.0 };

    let maxOffsetX = VolumeViewer.getMaxPlaneOffset(planeMatrix[0], planeMatrix[1], planeMatrix[2],
                                                    volumeExtents.x, volumeExtents.y, volumeExtents.z);
    let maxOffsetY = VolumeViewer.getMaxPlaneOffset(planeMatrix[3], planeMatrix[4], planeMatrix[5],
                                                    volumeExtents.x, volumeExtents.y, volumeExtents.z);
    let maxOffsetZ = VolumeViewer.getMaxPlaneOffset(planeMatrix[6], planeMatrix[7], planeMatrix[8],
                                                    volumeExtents.x, volumeExtents.y, volumeExtents.z);

    return {
        x: maxOffsetX,
        y: maxOffsetY,
        z: maxOffsetZ
    };
};

// Clamps the plane offsets to a sane range, so that users can't drag a plane
// to some distant location.
VolumeViewer.prototype.clampPlaneOffset = function(offset) {
    if (offset === undefined || offset === null)  return offset;

    let minStepOffset, maxStepOffset;

    let slices = this.getPlaneSliceCounts();

    if (this.planeInteriorClamping) {
        // Inside the volume
        minStepOffset = {
            sx: 0.5,
            sy: 0.5,
            sz: 0.5
        };
        maxStepOffset = {
            sx: slices.x - 0.5,
            sy: slices.y - 0.5,
            sz: slices.z - 0.5
        };
    }
    else {
        // Just outside the volume
        minStepOffset = {
            sx: -2.5,
            sy: -2.5,
            sz: -2.5
        };
        maxStepOffset = {
            sx: slices.x + 2.5,
            sy: slices.y + 2.5,
            sz: slices.z + 2.5
        };
    }

    let stepOffset = this.convertToStepOffset(offset);

    let sx = stepOffset.sx;
    let sy = stepOffset.sy;
    let sz = stepOffset.sz;

    if (sx < minStepOffset.sx)  sx = minStepOffset.sx;
    if (sx > maxStepOffset.sx)  sx = maxStepOffset.sx;

    if (sy < minStepOffset.sy)  sy = minStepOffset.sy;
    if (sy > maxStepOffset.sy)  sy = maxStepOffset.sy;

    if (sz < minStepOffset.sz)  sz = minStepOffset.sz;
    if (sz > maxStepOffset.sz)  sz = maxStepOffset.sz;

    if (stepOffset.sx !== sx || stepOffset.sy !== sy || stepOffset.sz !== sz) {
        offset = { sx: sx, sy: sy, sz: sz };
    }

    return offset;
};

// Clamps the provided plane offset to the inside of the specified plane,
// without adjusting the plane's depth.  (Think of it as a kind of 2D clamp
// in 3D.)
VolumeViewer.prototype.clampPlaneOffsetToPlane = function(offset, selectedFace) {
    selectedFace = Math.abs(selectedFace);
    if (selectedFace !== 1 && selectedFace !== 2 && selectedFace !== 3)
        return offset;  // unclamped and unchanged

    let planeData = this.getPlaneNormal(selectedFace);
    if (!planeData)
        return offset;  // unclamped and unchanged

    // For plane offsets, we want to leave the depth alone while still clamping
    // to the edges of the plane.  That is why offset is used twice here
    // (once for the offset itself, once for the plane point).
    return this.clampPointToPlane(offset, offset, planeData.normal);
};

// Clamps the provided point to the inside of the specified plane.
// The specified plane normal must be in volume space.
VolumeViewer.prototype.clampPointToPlane = function(offset, planePoint, planeNormal) {
    function nearestPointOnLine(outPoint, point, line0, line1) {
        let lineDist = vec3.squaredDistance(line0, line1);
        if (lineDist < 1e-6) {
            vec3.copy(outPoint, line0);
            return;
        }
        let delta0 = vec3.create();
        let delta1 = vec3.create();
        vec3.subtract(delta0, point, line0);
        vec3.subtract(delta1, line1, line0);
        let t = vec3.dot(delta0, delta1) / lineDist;
        t = Math.max(0, Math.min(1, t));
        vec3.scaleAndAdd(outPoint, line0, delta1, t);
    }

    let volumePoint = this.convertToVolumeOffset(offset);

    planePoint = this.convertToVolumeOffset(planePoint);

    let boundary = this.generatePlaneBorders(planePoint, planeNormal, true);
    if (boundary.length < 2)
        return offset;  // unclamped and unchanged

    volumePoint = vec3.fromValues(volumePoint.x, volumePoint.y, volumePoint.z);
    planePoint  = vec3.fromValues(planePoint.x, planePoint.y, planePoint.z);
    planeNormal = vec3.fromValues(planeNormal.x, planeNormal.y, planeNormal.z);

    let origVolumePoint = vec3.clone(volumePoint);

    let delta = vec3.create();

    // Clamp to the plane depth
    vec3.subtract(delta, volumePoint, planePoint);
    let distance = vec3.dot(delta, planeNormal);
    if (Math.abs(distance) > 1e-6) {
        // Force point in-plane
        vec3.scaleAndAdd(volumePoint, volumePoint, planeNormal, -distance);
    }

    let bestDistance = null;
    let bestPoint = vec3.create();

    // Clamp to the plane edges
    let tempPoint = vec3.create();
    let crossDir = vec3.create();
    for (let i=0; i<boundary.length; ++i) {
        let point0 = boundary[i];
        let point1 = boundary[(i+1)%boundary.length];
        point0 = vec3.fromValues(point0.x, point0.y, point0.z);
        point1 = vec3.fromValues(point1.x, point1.y, point1.z);
        vec3.subtract(delta, point1, point0);
        vec3.cross(crossDir, delta, planeNormal);
        vec3.subtract(delta, volumePoint, point0);
        let dot = vec3.dot(crossDir, delta);
        if (dot > 0) {
            // The point is outside the plane boundary.
            // Find the nearest point on this line segment as a candidate
            // for a clamping location.
            nearestPointOnLine(tempPoint, volumePoint, point0, point1);
            let distance2 = vec3.squaredDistance(tempPoint, volumePoint);
            if (bestDistance === null || bestDistance > distance2) {
                bestDistance = distance2;
                vec3.copy(bestPoint, tempPoint);
            }
        }
    }

    if (bestDistance !== null)
        vec3.copy(volumePoint, bestPoint);

    if (!vec3.exactEquals(volumePoint, origVolumePoint))
        offset = { x: volumePoint[0], y: volumePoint[1], z: volumePoint[2] };

    return offset;
};

// Generates a series of points that define the edge of the facing plane.
// The points are in the volume coordinate system.
// The results may be used for drawing or for bounds checking.
VolumeViewer.prototype.generateFacingPlaneBorders = function(interiorBorder=false) {
    let selectedFace = this.getFacingPlane();
    return this.generateFaceBorders(selectedFace, interiorBorder);
};

// Generates a series of points that define the edge of the specified plane.
// The points are in the volume coordinate system.
// The results may be used for drawing or for bounds checking.
VolumeViewer.prototype.generateFaceBorders = function(selectedFace, interiorBorder=false) {
    let plane = this.getPlaneNormal(selectedFace);
    if (!plane)  return [];

    return this.generatePlaneBorders(plane.point, plane.normal, interiorBorder);
};

// Generates a series of points that define the edge of the defined plane.
// The points are in the volume coordinate system.
// The results may be used for drawing or for bounds checking.
VolumeViewer.prototype.generatePlaneBorders = function(planePoint, planeNormal,
                                                       interiorBorder=false) {
    if (!planePoint || !planeNormal)  return [];
    if (typeof planePoint !== 'object')  return [];
    if (typeof planeNormal !== 'object')  return [];

    planePoint = this.convertToVolumeOffset(planePoint);

    if (!planePoint)  return [];

    planePoint  = vec3.fromValues(planePoint.x, planePoint.y, planePoint.z);
    planeNormal = vec3.fromValues(planeNormal.x, planeNormal.y, planeNormal.z);

    let volumeExtents = this.getVolumeExtents(interiorBorder);

    // Ensure that the plane does not lie entirely outside the volume!
    {
        let minDist = null;
        let maxDist = null;
        function addToBounds(x, y, z) {
            let delta = [x - planePoint[0], y - planePoint[1], z - planePoint[2]];
            let dot = planeNormal[0]*delta[0] + planeNormal[1]*delta[1] + planeNormal[2]*delta[2];
            if (minDist === null || minDist > dot)  minDist = dot;
            if (maxDist === null || maxDist < dot)  maxDist = dot;
        }
        addToBounds( volumeExtents.x,  volumeExtents.y,  volumeExtents.z);
        addToBounds(-volumeExtents.x,  volumeExtents.y,  volumeExtents.z);
        addToBounds( volumeExtents.x, -volumeExtents.y,  volumeExtents.z);
        addToBounds(-volumeExtents.x, -volumeExtents.y,  volumeExtents.z);
        addToBounds( volumeExtents.x,  volumeExtents.y, -volumeExtents.z);
        addToBounds(-volumeExtents.x,  volumeExtents.y, -volumeExtents.z);
        addToBounds( volumeExtents.x, -volumeExtents.y, -volumeExtents.z);
        addToBounds(-volumeExtents.x, -volumeExtents.y, -volumeExtents.z);

        const DIST = 1e-6;
        if (maxDist+DIST < 0.0)  return [];
        if (minDist-DIST > 0.0)  return [];
    }

    function computeBoundaryPlane(sidePoint, sideNormal) {
        let lineDirection = vec3.create();
        sidePoint = vec3.clone(sidePoint);
        sideNormal = vec3.clone(sideNormal);

        // The variables here are aliased to the names in the following
        // equation, which is used to compute a point on the line for the
        // intersection of two planes:
        //
        // intersection = ((p1•n1)(n2×n3) + (p2•n2)(n3×n1) + (p3•n3)(n1×n2)) / det(n1,n2,n3)
        //
        // Some simplications have been made to the equation.

        let n1 = planeNormal;
        let n2 = sideNormal;
        let n3 = lineDirection;
        let p1 = planePoint;
        let p2 = sidePoint;

        vec3.cross(n3, n1, n2);
        let det = vec3.dot(n3, n3);
        if (det > 1e-7) {
            let linePoint  = vec3.create();

            let term0      = vec3.create();
            let term1      = vec3.create();

            let d1 = vec3.dot(p1, n1);     // (p1•n1)
            let d2 = vec3.dot(p2, n2);     // (p2•n2)

            vec3.cross(term0, n2, n3);     // (n2×n3)
            vec3.scale(term0, term0, d1);  // (p1•n1)(n2×n3)

            vec3.cross(term1, n3, n1);     // (n3×n1)
            vec3.scale(term1, term1, d2);  // (p2•n2)(n3×n1)

            vec3.add(linePoint, term0, term1);  // (p1•n1)(n2×n3) + (p2•n2)(n3×n1)

            vec3.scale(linePoint, linePoint, 1.0/det);  // .../det(n1,n2,n3)

            return {
                point:      linePoint,
                direction:  lineDirection,
                sidePoint:  sidePoint,
                sideNormal: sideNormal
            };
        }
        else {
            return {
                point:      vec3.fromValues(0.0, 0.0, 0.0),
                direction:  vec3.fromValues(0.0, 0.0, 0.0),
                sidePoint:  sidePoint,
                sideNormal: sideNormal
            };
        }
    }

    // Set up planar data for the six sides of the volume, including intersection
    // lines between the side and the plane.
    let sides = [];
    sides.length = 6;
    sides[0] = computeBoundaryPlane([-volumeExtents.x, 0.0, 0.0], [-1.0, 0.0, 0.0]);
    sides[1] = computeBoundaryPlane([ volumeExtents.x, 0.0, 0.0], [ 1.0, 0.0, 0.0]);
    sides[2] = computeBoundaryPlane([0.0, -volumeExtents.y, 0.0], [0.0, -1.0, 0.0]);
    sides[3] = computeBoundaryPlane([0.0,  volumeExtents.y, 0.0], [0.0,  1.0, 0.0]);
    sides[4] = computeBoundaryPlane([0.0, 0.0, -volumeExtents.z], [0.0, 0.0, -1.0]);
    sides[5] = computeBoundaryPlane([0.0, 0.0,  volumeExtents.z], [0.0, 0.0,  1.0]);

    let curPoint = null;
    let curSide  = null;
    let curDir   = null;

    // Find an initial starting point on a side
    for (let i=0; i<sides.length; ++i) {
        const side = sides[i];
        if (vec3.squaredLength(side.direction) > 0) {
            curSide  = i;
            curPoint = vec3.clone(side.point);
            curDir   = side.direction;
            break;
        }
    }

    if (curPoint === null || curSide === null || curDir === null)
        return [];  // should never happen

    let delta = vec3.create();

    // Walk around the perimeter of the volume, adding points
    let done = false;
    let firstSide = null;
    let points = [];
    for (let loopCount=0; loopCount<8; loopCount++) {
        let bestDist = null;
        let bestSide = null;
        for (let i=0; i<sides.length; ++i) {
            if (i !== curSide) {
                let side = sides[i];
                // d = ((p0-l0)•n) / (l•n)
                let dot = vec3.dot(curDir, side.sideNormal);
                if (dot > 0.0) {  // plane must point in the same direction as the line
                    vec3.subtract(delta, side.sidePoint, curPoint);
                    let d = vec3.dot(delta, side.sideNormal) / dot;
                    if (bestDist === null || bestDist > d) {
                        bestDist = d;
                        bestSide = i;
                    }
                }
            }
        }
        if (bestDist !== null) {
            if (firstSide === curSide) {
                done = true;
                break;
            }
            vec3.scaleAndAdd(curPoint, curPoint, curDir, bestDist);
            if (loopCount >= 1) {
                points.push({
                    x: curPoint[0],
                    y: curPoint[1],
                    z: curPoint[2]
                });
                if (firstSide === null)
                    firstSide = curSide;
            }
            curSide = bestSide;
            curDir = sides[bestSide].direction;
            if (points.length > 6) {
                dconsole.error("Logic error occurred in border calculation method!");
                break;
            }
        }
        else
            break;
    }

    if (done)
        return points;

    return [];
};

// Gets cached viewport information associated with the specified display view.
VolumeViewer.prototype.getViewport = function(displayView=VolumeViewer.VIEW_UNKNOWN) {
    if (displayView === VolumeViewer.VIEW_UNKNOWN)
        displayView = this.getDefaultView();

    if (!(displayView in this.cachedViewports))
        this.buildViewport(displayView);

    return this.cachedViewports[displayView];
};

// Clears all cached viewports.
VolumeViewer.prototype.clearCachedViewports = function() {
    this.cachedViewports = {};
};

// Builds viewport information associated with the specified display view.
VolumeViewer.prototype.buildViewport = function(displayView=VolumeViewer.VIEW_UNKNOWN) {
    if (displayView === VolumeViewer.VIEW_UNKNOWN)
        displayView = this.getDefaultView();

    let viewport = null;

    const canvas = this.canvas;
    if (canvas) {
        const rect        = canvas.getBoundingClientRect();

        let rectX         = rect.left;
        let rectY         = rect.top;
        let rectWidth     = rect.width;
        let rectHeight    = rect.height;

        let x             = rectX;
        let y             = rectY;
        let width         = canvas.clientWidth;
        let height        = canvas.clientHeight;

        let canvasX       = 0;
        let canvasY       = 0;
        let canvasWidth   = canvas.width;
        let canvasHeight  = canvas.height;

        let scissorX      = canvasX;
        let scissorY      = canvasY;
        let scissorWidth  = canvasWidth;
        let scissorHeight = canvasHeight;

        let borderSize    = 0;

        if (width < 1)          width = 1;
        if (height < 1)         height = 1;
        if (canvasWidth < 1)    canvasWidth = 1;
        if (canvasHeight < 1)   canvasHeight = 1;
        if (scissorWidth < 0)   scissorWidth = 0;
        if (scissorHeight < 0)  scissorHeight = 0;

        if (displayView === VolumeViewer.VIEW_LEFT || displayView === VolumeViewer.VIEW_RIGHT) {
            let left = displayView !== VolumeViewer.VIEW_RIGHT;

            let stereoWidth = 1.0;
            if (this.stereoWidth !== null)
                stereoWidth = this.stereoWidth;
            if (this.stereoAspect !== null) {
                stereoWidth = stereoWidth * 2.0 * (this.stereoAspect * height) / width;
                if (stereoWidth > 1.0)  stereoWidth = 1.0;
            }

            let stereoXOffset = 0.5 * this.stereoSeparation * stereoWidth;
            let stereoEdge = this.stereoEdge * stereoWidth;
            let stereoTotal = stereoXOffset + stereoWidth + stereoEdge;
            if (stereoTotal > 1.0) {
                // Normalize the width and offset
                stereoXOffset /= stereoTotal;
                stereoWidth /= stereoTotal;
                stereoEdge /= stereoTotal;
            }
            if (left)  stereoXOffset = -stereoXOffset;

            let stereoYOffset = 0.0;
            let stereoHeight = 1.0;
            if (this.stereoAspect !== null) {
                stereoHeight = 0.5 * (stereoWidth * width) / (this.stereoAspect * height);
                stereoYOffset = 0.5 * (1.0 - stereoHeight);
            }

            canvasWidth = Math.max(Math.floor(canvasWidth * 0.5 * stereoWidth), 2);
            canvasHeight = Math.max(Math.floor(canvasHeight * stereoHeight), 2);
            if (left)
                canvasX += Math.floor(canvas.width * 0.5);
            else
                canvasX += Math.ceil(canvas.width * 0.5);
            if (left)  canvasX -= canvasWidth;
            canvasX += Math.floor(canvas.width * stereoXOffset * 0.5);
            canvasY += Math.floor(canvas.height * stereoYOffset);

            width = Math.max(Math.floor(width * 0.5 * stereoWidth), 2);
            height = Math.max(Math.floor(height * stereoHeight), 2);
            if (left)
                x += Math.floor(canvas.clientWidth * 0.5);
            else
                x += Math.ceil(canvas.clientWidth * 0.5);
            if (left)  x -= width;
            x += Math.floor(canvas.clientWidth * stereoXOffset * 0.5);
            y += Math.floor(canvas.clientHeight * stereoYOffset);

            borderSize = this.stereoBorderSize;

            let borderX = Math.ceil(borderSize * canvas.width / canvas.clientWidth);
            let borderY = Math.ceil(borderSize * canvas.height / canvas.clientHeight);

            scissorX      = canvasX + borderX;
            scissorY      = canvasY + borderY;
            scissorWidth  = canvasWidth - borderX * 2;
            scissorHeight = canvasHeight - borderY * 2;

            if (scissorWidth < 0)   scissorWidth = 0;
            if (scissorHeight < 0)  scissorHeight = 0;
        }
        else {
            displayView = VolumeViewer.VIEW_CENTER;
        }

        viewport = {
            displayView:   displayView,
            x:             x,
            y:             y,
            width:         width,
            height:        height,
            rectX:         rectX,
            rectY:         rectY,
            rectWidth:     rectWidth,
            rectHeight:    rectHeight,
            canvasX:       canvasX,
            canvasY:       canvasY,
            canvasWidth:   canvasWidth,
            canvasHeight:  canvasHeight,
            aspect:        canvasWidth/canvasHeight,
            scissorX:      scissorX,
            scissorY:      scissorY,
            scissorWidth:  scissorWidth,
            scissorHeight: scissorHeight,
            borderSize:    borderSize
        };
    }
    else {
        // Ugly default
        viewport = {
            displayView:   displayView,
            x:             0,
            y:             0,
            width:         2,
            height:        2,
            rectX:         0,
            rectY:         0,
            rectWidth:     2,
            rectHeight:    2,
            canvasX:       0,
            canvasY:       0,
            canvasWidth:   2,
            canvasHeight:  2,
            scissorX:      0,
            scissorY:      0,
            scissorWidth:  2,
            scissorHeight: 2,
            borderSize:    0
        };
    }

    this.cachedViewports[displayView] = viewport;
    return viewport;
};

// Gets the default view, based on whether we are displaying the stereo
// view or not.
VolumeViewer.prototype.getDefaultView = function() {
    const canvas = this.canvas;
    if (!canvas)  return VolumeViewer.VIEW_CENTER;

    if (this.showStereo)
        return VolumeViewer.VIEW_LEFT;  // well, we gotta use SOMETHING...
    else
        return VolumeViewer.VIEW_CENTER;
};

// Gets the default view, based on the specified display point.
VolumeViewer.prototype.displayToView = function(displayX, displayY) {
    const canvas = this.canvas;
    if (!canvas)  return VolumeViewer.VIEW_CENTER;

    if (this.showStereo) {
        const rect    = canvas.getBoundingClientRect();
        const offsetX = rect.left + canvas.clientWidth * 0.5;

        if (displayX < offsetX)  return VolumeViewer.VIEW_LEFT;
        else                     return VolumeViewer.VIEW_RIGHT;
    }
    else {
        return VolumeViewer.VIEW_CENTER;
    }
};

// Converts a 2D x/y position (in screen space) to a viewport position that
// can be used to convert to world space.
VolumeViewer.prototype.displayToViewport = function(displayX, displayY,
                                                    displayView=VolumeViewer.VIEW_UNKNOWN) {
    let x = displayX;
    let y = displayY;

    const canvas = this.canvas;
    if (canvas) {
        if (displayView === VolumeViewer.VIEW_UNKNOWN)
            displayView = this.displayToView(displayX, displayY);

        let viewport = this.getViewport(displayView);
        let offsetX = viewport.x;
        let offsetY = viewport.y;
        let width   = viewport.width;
        let height  = viewport.height;

        offsetX += width * 0.5;
        offsetY += height * 0.5;

        x = (x - offsetX) / (width * 0.5);
        y = (offsetY - y) / (height * 0.5);
    }

    return {
        x: x,
        y: y
    };
};

// Converts a viewport position to a 2D x/y position (in screen space).
VolumeViewer.prototype.viewportToDisplay = function(viewportX, viewportY,
                                                    displayView=VolumeViewer.VIEW_UNKNOWN) {
    let x = viewportX;
    let y = viewportY;

    const canvas = this.canvas;
    if (canvas) {
        if (displayView === VolumeViewer.VIEW_UNKNOWN)
            displayView = this.getDefaultView();

        let viewport = this.getViewport(displayView);
        let offsetX = viewport.x;
        let offsetY = viewport.y;
        let width   = viewport.width;
        let height  = viewport.height;

        offsetX += width * 0.5;
        offsetY += height * 0.5;

        x = (x * width * 0.5) + offsetX;
        y = -(y * height * 0.5) + offsetY;
    }

    return {
        x: x,
        y: y
    };
};

// PRIVATE STATIC METHODS =====================================================

// Intelligently reallocates large memory buffers.
VolumeViewer.smartReallocate = function(buffer, typeName, newSize) {
    if (!buffer || buffer.length < newSize) {
        let bigSize = Math.ceil(newSize * 1.5);
        if (typeof window[typeName] === 'function') {
            let newBuffer = null;
            try { newBuffer = new window[typeName](bigSize); } catch(e) { newBuffer = null; }
            if (!newBuffer || newBuffer.length < bigSize) {
                try { newBuffer = window[typeName](newSize); } catch(e) { newBuffer = null; }
                if (!newBuffer || newBuffer.length < newSize) {
                    dconsole.error("BUFFER REALLOCATION FAILED!  Requested size: " + newSize);
                    newBuffer = null;
                    buffer = null;
                }
                else {
                    dconsole.warn("Buffer reallocation failed, but recovered.  Requested size: " + newSize);
                    buffer = newBuffer;
                }
            }
            else
                buffer = newBuffer;
        }
        else {
            dconsole.error("Invalid reallocation type requested: " + typeName);
            buffer = null;
        }
    }

    return buffer;
};

// Recursively descends into an object hierarchy to copy its contents.
VolumeViewer.deepCopy = function(obj, level=undefined) {
    // Safeguard for infinite recursion
    if (level === undefined || level === null)  level = 32;

    let newObj = undefined;

    if (obj === undefined || obj === null) {
        return obj;
    }
    if (Array.isArray(obj)) {
        newObj = [];
        newObj.length = obj.length;
        for (let i=0; i<obj.length; ++i)
            newObj[i] = VolumeViewer.deepCopy(obj[i], level-1);
        return newObj;
    }
    if (typeof obj === 'object') {
        newObj = {};
        let keys = Object.keys(obj);
        for (let i=0; i<keys.length; ++i) {
            let key = VolumeViewer.deepCopy(keys[i], level-1);
            let value = VolumeViewer.deepCopy(obj[keys[i]], level-1);
            newObj[key] = value;
        }
        return newObj;
    }
    return obj;
};

// Recursively descends into an object hierarchy to determine whether
// two objects have identical content.
VolumeViewer.deepEquals = function(obj0, obj1, level=undefined) {
    // Safeguard for infinite recursion
    if (level === undefined || level === null)  level = 32;

    if (obj0 === obj1)
        return true;

    if (obj0 === null || typeof obj0 !== 'object' ||
        obj1 === null || typeof obj1 !== 'object')
        return false;

    let keys0 = Object.keys(obj0);
    let keys1 = Object.keys(obj1);

    if (keys0.length !== keys1.length)
        return false;

    if (level < 0)
        return false;

    for (let key of keys0) {
        if (!keys1.includes(key) ||
            !VolumeViewer.deepEquals(obj0[key], obj1[key], level-1)) {
            return false;
        }
    }

    return true;
};

// Converts a zoom level from a multiplicative scale to a linear scale.
VolumeViewer.zoomScaledToLinear = function(scale) {
    const mult = 1.0;
    return -mult*Math.log(scale)/Math.log(2.0);
};

// Converts a zoom level from a linear scale to a multiplicative scale.
VolumeViewer.zoomLinearToScaled = function(linear) {
    const mult = 1.0;
    return Math.pow(2.0, -linear/mult);
};

// Normalizes the specified vector, or sets it to the specified default
// if the vector's length is zero.
VolumeViewer.normalize = function(x, y, z, dx, dy, dz) {
    const EPSILON = 1e-10;

    let length = x*x + y*y + z*z;
    if (length < EPSILON) {
        x = dx; y = dy; z = dz;
    }
    else {
        length = Math.sqrt(length);
        x /= length; y /= length; z /= length;
    }
    return vec3.fromValues(x, y, z);
};

// Gets the maximum offset for the specified direction vector, based on
// provided volume extents.
// The returned offset will be the maximum value of the plane offset
// before it completely leaves the volume.
VolumeViewer.getMaxPlaneOffset = function(dirX, dirY, dirZ, extentX, extentY, extentZ) {
    let d = 1.0;
    extentX *= Math.sign(dirX);
    extentY *= Math.sign(dirY);
    extentZ *= Math.sign(dirZ);
    let denom = dirX*dirX + dirY*dirY + dirZ*dirZ;
    if (Math.abs(denom) > 1e-7) {
        let num = extentX*dirX + extentY*dirY + extentZ*dirZ;
        d = num / denom;
    }
    return d;
};

// Computes the maximum alpha value in a given transfer array.
VolumeViewer.computeMaxAlpha = function(array) {
    let maxAlpha = 1.0 / 255.0;  // non-zero default min

    if (array) {
        for (let i=0; i<array.length; ++i) {
            let alpha = array[i][1][3];
            if (maxAlpha < alpha)  maxAlpha = alpha;
        }
    }

    return maxAlpha;
};

// Builds a color string from a color object with red, green, blue
// and alpha fields.  All values range from 0.0 to 1.0 inclusive.
VolumeViewer.buildColorString = function(color) {
    if (!color)  return color;

    let red, green, blue, alpha;
    if (typeof color === 'string') {
        return color;
    }
    else if (Array.isArray(color)) {
        if (color.length > 0)  red   = color[0];
        if (color.length > 1)  green = color[1];
        if (color.length > 2)  blue  = color[2];
        if (color.length > 3)  alpha = color[3];
    }
    else if (typeof color === 'object') {
        red   = color.red;
        green = color.green;
        blue  = color.blue;
        alpha = color.alpha;
    }
    return VolumeViewer.buildColorStringRGBA(red, green, blue, alpha);
};

// Builds a color string from individual red, green, blue and alpha
// values.  All values range from 0.0 to 1.0 inclusive.
VolumeViewer.buildColorStringRGBA = function(red, green, blue, alpha) {
    if (red   === undefined || red   === null)  red   = 0.0;
    if (green === undefined || green === null)  green = 0.0;
    if (blue  === undefined || blue  === null)  blue  = 0.0;
    if (alpha === undefined || alpha === null)  alpha = 1.0;

    if (red   < 0.0)  red   = 0.0;
    if (red   > 1.0)  red   = 1.0;
    if (green < 0.0)  green = 0.0;
    if (green > 1.0)  green = 1.0;
    if (blue  < 0.0)  blue  = 0.0;
    if (blue  > 1.0)  blue  = 1.0;
    if (alpha < 0.0)  alpha = 0.0;
    if (alpha > 1.0)  alpha = 1.0;

    red   = Math.round(red   * 255.0);
    green = Math.round(green * 255.0);
    blue  = Math.round(blue  * 255.0);
    alpha = Math.round(alpha * 255.0);

    const HEX = "0123456789ABCDEF";
    function hex(value) {
        let str = "";
        str += HEX[(value & 0xF0) >> 4];
        str += HEX[(value & 0x0F) >> 0];
        return str;
    }

    let rstr = hex(red);
    let gstr = hex(green);
    let bstr = hex(blue);
    let astr = hex(alpha);

    let str = `#${rstr}${gstr}${bstr}${astr}`;
    return str;
};

// Converts a user-specified color lookup table to a more efficient form
// that we can use.
VolumeViewer.convertColorTable = function(colorTable) {
    if (colorTable === undefined || colorTable === null) {
        return null;
    }

    const convertColor = function(color, defaultColor) {
        if (typeof color !== 'number')  color = defaultColor;

        if (color < 0.0)  color = 0.0;
        if (color > 1.0)  color = 1.0;

        return color;
    };

    // Ensure that the table is sorted from lowest to highest value
    // (this code guarantees stable sort).
    let sortTable = [];
    for (let i=0; i<colorTable.length; ++i) {
        const value = colorTable[i].value;
        const color = colorTable[i].color;

        if (typeof value === 'number' && typeof color === 'object') {
            const red   = convertColor(color.red,   0.0);
            const green = convertColor(color.green, 0.0);
            const blue  = convertColor(color.blue,  0.0);
            const alpha = convertColor(color.alpha, 1.0);

            let entry = [value, [red, green, blue, alpha]];
            let sortEntry = [i, entry];
            sortTable.push(sortEntry);
        }
    }

    let newTable = [];
    if (sortTable.length) {
        // Stable sort
        sortTable.sort(function(a,b) {
            return (a[1][0] - b[1][0]) || (a[0] - b[0]);
        });
        for (let i=0; i<sortTable.length; ++i)
            newTable.push(sortTable[i][1]);
    }

    return newTable;
};

// Converts the internal format of a color table to a more user-friendly
// version.
VolumeViewer.generateColorTable = function(array) {
    if (array === undefined || array === null)  return null;

    let table = [];
    for (let i=0; i<array.length; ++i) {
        let entry = array[i];
        let value = entry[0];
        let color = entry[1];

        let newEntry = {
            value: value,
            color: {
                red:   color[0],
                green: color[1],
                blue:  color[2],
                alpha: color[3]
            }
        };
        table.push(newEntry);
    }

    return table;
};

// Gets the two nearest color entries on either side of a provided value.
// Assumes that the provided transfer array has been sorted.
VolumeViewer.getBracketColorEntries = function(transferArray, value) {
    if (!transferArray)  return null;
    if (value === undefined || value === null)  return null;

    let arrpos = 0;

    while (arrpos < transferArray.length) {
        if (value <= 0.0 && transferArray[arrpos][0] >= value)
            break;  // special case: first value is always first offset
        else if (transferArray[arrpos][0] > value)
            break;
        ++arrpos;
    }

    let color0, color1;
    let value0, value1;

    if (transferArray.length <= 0) {
        // Lookup table is empty, use a default
        value0 = 0.0;
        color0 = [0.0, 0.0, 0.0, 0.0];
        value1 = 1.0;
        color1 = color0;
    }
    else if (arrpos === 0) {
        // Values below the minimum use the first color
        value1 = transferArray[arrpos][0];
        color1 = transferArray[arrpos][1];
        value0 = 0.0;
        color0 = color1;
    }
    else if (arrpos === transferArray.length) {
        // Values above the maximum use the last color
        value0 = transferArray[arrpos-1][0];
        color0 = transferArray[arrpos-1][1];
        value1 = 1.0;
        color1 = color0;
    }
    else {
        // All other values are interpolated
        value0 = transferArray[arrpos-1][0];
        value1 = transferArray[arrpos][0];
        color0 = transferArray[arrpos-1][1];
        color1 = transferArray[arrpos][1];
    }

    let newEntry0 = {
        value: value0,
        color: {
            red:   color0[0],
            green: color0[1],
            blue:  color0[2],
            alpha: color0[3]
        }
    };
    let newEntry1 = {
        value: value1,
        color: {
            red:   color1[0],
            green: color1[1],
            blue:  color1[2],
            alpha: color1[3]
        }
    };

    return [newEntry0, newEntry1];
};

// Generates an interpolated color entry from a transfer array.
// Assumes that the provided transfer array has been sorted.
VolumeViewer.calculateTransferColor = function(transferArray, value) {
    let bracket = VolumeViewer.getBracketColorEntries(transferArray, value);
    if (bracket === undefined || bracket === null)  return null;

    const value0 = bracket[0].value;
    const value1 = bracket[1].value;
    const color0 = bracket[0].color;
    const color1 = bracket[1].color;
    const delta = value1 - value0;
    const mix1 = delta !== 0.0 ? (value - value0) / delta : 0.0;
    const mix0 = 1.0 - mix1;
    const r = mix0*color0.red   + mix1*color1.red;
    const g = mix0*color0.green + mix1*color1.green;
    const b = mix0*color0.blue  + mix1*color1.blue;
    const a = mix0*color0.alpha + mix1*color1.alpha;

    let newEntry = {
        value: value,
        color: {
            red:   r,
            green: g,
            blue:  b,
            alpha: a
        }
    };

    return newEntry;
};

// Generates an interpolated color entry from a provided color table.
VolumeViewer.calculateColorEntry = function(colorTable, value) {
    if (!colorTable)  return null;
    if (value === undefined || value === null)  return null;

    let transferArray = VolumeViewer.convertColorTable(colorTable);
    if (!transferArray)  return null;

    return VolumeViewer.calculateTransferColor(transferArray, value);
};

// Generates a color array that can be bound to a one-dimensional texture
// and used by the shader.
VolumeViewer.generateLookupArray = function(transferArray, size) {
    if (size < 1)  size = 1;

    let newArray = new Uint8Array(size*4);
    let pos = 0;

    const maxAlpha = VolumeViewer.computeMaxAlpha(transferArray);
    const alphaMult = 1.0 / maxAlpha;

    const toByte = function(value) {
        let newval = Math.round(value * 255.0);
        if (newval < 0)   newval = 0;
        if (newval > 255) newval = 255;
        return newval;
    };

    let value = null;
    let arrpos = 0;
    for (let i=0; i<size; ++i) {
        const offset = 1.0 * i / (size-1);
        while (arrpos < transferArray.length) {
            if (i === 0.0 && transferArray[arrpos][0] >= offset)
                break;  // special case: first value is always first offset
            else if (transferArray[arrpos][0] > offset)
                break;
            ++arrpos;
        }

        if (transferArray.length <= 0) {
            // Lookup table is empty, use a default
            value = [0.0, 0.0, 0.0, 0.0];
        }
        else if (arrpos === 0) {
            // Values below the minimum use the first color
            value = transferArray[arrpos][1];
        }
        else if (arrpos === transferArray.length) {
            // Values above the maximum use the last color
            value = transferArray[arrpos-1][1];
        }
        else {
            // All other values are interpolated
            const minOffset = transferArray[arrpos-1][0];
            const maxOffset = transferArray[arrpos][0];
            const value0 = transferArray[arrpos-1][1];
            const value1 = transferArray[arrpos][1];

            const delta = maxOffset - minOffset;
            const mix1 = delta !== 0.0 ? (offset - minOffset) / delta : 0.0;
            const mix0 = 1.0 - mix1;
            const r = mix0*value0[0] + mix1*value1[0];
            const g = mix0*value0[1] + mix1*value1[1];
            const b = mix0*value0[2] + mix1*value1[2];
            const a = mix0*value0[3] + mix1*value1[3];
            value = [r,g,b,a];
        }

        newArray[pos++] = toByte(value[0]);            // red channel
        newArray[pos++] = toByte(value[1]);            // green channel
        newArray[pos++] = toByte(value[2]);            // blue channel
        newArray[pos++] = toByte(value[3]*alphaMult);  // alpha channel
    }

    return newArray;
};

// Gets a shader script from the specified source.  The script may be for
// either a vertex shader or a fragment shader.
VolumeViewer.getShaderScript = function(script, scriptType) {
    // If the supplied script is a string, assume it *is* the script, and
    // return it unmolested.
    if (typeof script === 'string') {
        return script;
    }

    // Otherwise, it must be a selector object.  If it isn't, take our ball
    // and go home.
    if (typeof script !== 'object' || !script.length) {
        return null;
    }

    // Otherwise, assume it's the element ID of the script in HTML.
    var shaderElement = script[0];

    if (shaderElement.type !== scriptType) {
        // Sanity check
        return null;
    }

    var str = "";
    var k = shaderElement.firstChild;
    while (k) {
        if (k.nodeType === 3) {
            str += k.textContent;
        }
        k = k.nextSibling;
    }

    return str;
};

// Sanitizes the specified file type string by converting it into one of the
// supported file type constants.  If the specified file type is invalid,
// the method returns undefined.
VolumeViewer.sanitizeFileType = function(type) {
    if (!type || typeof type !== 'string')  return undefined;

    type = type.toLowerCase().trim();

    if      (type === 'nrrd')                     return VolumeViewer.TYPE_NRRD;
    else if (type === 'nii' || type === 'nifti')  return VolumeViewer.TYPE_NIFTI;
    else if (type === 'dcm' || type === 'dicom')  return VolumeViewer.TYPE_DICOM;
    else                                          return undefined;
};

// Determines the file type of the specified filename or URL by looking at its
// extension, and returns the file type as one of the supported file type
// constants.
// If the filename does not have a valid extension, the method returns undefined.
VolumeViewer.getFileTypeFromFilename = function(filename) {
    if (!filename || typeof filename !== 'string')  return undefined;

    filename = filename.toLowerCase().trim();

    if      (filename.endsWith(".nrrd"))  return VolumeViewer.TYPE_NRRD;
    else if (filename.endsWith(".nii"))   return VolumeViewer.TYPE_NIFTI;
    else if (filename.endsWith(".dcm"))   return VolumeViewer.TYPE_DICOM;
    else                                  return undefined;
};

// Initializes a texture and loads an image.
// When the image finishes loading, copy it into the texture.
VolumeViewer.loadTexture = function(gl, url) {
    // This method was largely yoinked from mozilla.org.
    const texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);

    // Because images have to be download over the internet
    // they might take a moment until they are ready.
    // Until then put a single pixel in the texture so we can
    // use it immediately. When the image has finished downloading
    // we'll update the texture with the contents of the image.
    const level = 0;
    const internalFormat = gl.RGBA;
    const width = 1;
    const height = 1;
    const border = 0;
    const srcFormat = gl.RGBA;
    const srcType = gl.UNSIGNED_BYTE;
    const pixel = new Uint8Array([0, 0, 255, 255]);  // opaque blue
    gl.texImage2D(gl.TEXTURE_2D, level, internalFormat,
                  width, height, border, srcFormat, srcType,
                  pixel);

    function isPowerOf2(value) {
        return (value & (value - 1)) == 0;
    }

    const image = new Image();
    image.onload = function() {
        gl.bindTexture(gl.TEXTURE_2D, texture);
        gl.texImage2D(gl.TEXTURE_2D, level, internalFormat,
                      srcFormat, srcType, image);

        // WebGL1 has different requirements for power of 2 images
        // vs non power of 2 images so check if the image is a
        // power of 2 in both dimensions.
        if (isPowerOf2(image.width) && isPowerOf2(image.height)) {
            // Yes, it's a power of 2. Generate mips.
            gl.generateMipmap(gl.TEXTURE_2D);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
        } else {
            // No, it's not a power of 2. Turn off mips and set
            // wrapping to clamp to edge
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
        }
    };
    image.src = url;

    return texture;
};

// Adds a callback to the provided list of callbacks.  Ensures that
// duplicate callbacks are not added.
VolumeViewer.addCallback = function(callbacks, callback) {
    for (let i=0; i<callbacks.length; ++i) {
        if (callbacks[i] === callback) {
            return;
        }
    }

    callbacks.push(callback);
};

// Clears all callbacks from a provided list of callbacks.
VolumeViewer.clearCallbacks = function(callbacks) {
    callbacks.length = 0;
};

// Invokes a list of callbacks with the specified event argument.
// Handles weird cases like the user deleting a callback in the middle of a
// callback.
VolumeViewer.invokeCallbacks = function(callbacks, event) {
    if (callbacks.length) {
        // Make a local copy of the callbacks, in case the user adds
        // or deletes callbacks inside the callbacks...
        const callbacksCopy = callbacks.slice(0);

        for (let i=0; i<callbacksCopy.length; ++i) {
            const callback = callbacksCopy[i];
            if (callbacks.indexOf(callback) >= 0) {  // make sure user hasn't deleted it...
                let retval = callback(event);
                if (retval === false) {
                    break;  // STM_TODO - should we allow this?
                }
            }
        }
    }
};

// Checks the provided input handler for deprecated handler functions.
VolumeViewer.checkDeprecatedInputHandlers = function(inputHandler) {
    if (inputHandler && 'mousewheel' in inputHandler) {
        if (inputHandler.mousewheel) {
            dconsole.error("Using deprecated input handler 'mousewheel' - " +
                           "please remove ASAP (use 'wheel' instead)!");
        }
    }
};

// PUBLIC API =================================================================

// Constructor for VolumeViewer.
export default function VolumeViewer(selector, vertShader, fragShader) {
    this.showOutline = false;
    this.showStereo = false;
    this.showParallel = false;
    this.eyeDistance = 1.5;
    this.stereoWidth = null;
    this.stereoSeparation = 0.0;
    this.stereoEdge = 0.0;
    this.stereoAspect = null;
    this.showMiniCube = false;
    this.miniCubeTexture = null;
    this.miniCubeUrl = "images/letter_cube.png";
    this.miniCubeHighlightColor = [1.0, 1.0, 0.8, 0.65];
    this.miniCubeHighlightFace = VolumeViewer.FACE_NONE;
    this.miniCubePosition = [1.0, -1.0];
    this.miniCubeScale = 0.14;
    this.outlineColor = [1.0, 0.0, 1.0, 0.5];
    this.showClipOutline = false;
    this.clipOutlineColor = [0.3, 0.8, 0.3, 0.5];
    this.backgroundColor = [0.2, 0.2, 0.2, 1.0];
    this.showPlaneBorders = false;
    this.planeBorderLineWidth = 2.0;
    this.planeBorderColors = [
        [0.259, 0.800, 0.259, 0.8],  // border of plane 0
        [0.800, 0.259, 0.259, 0.8],  // border of plane 1
        [0.259, 0.259, 0.800, 0.8]   // border of plane 2
    ];
    this.showPlaneIntersections = false;
    this.showPlaneCrosshairs = false;
    this.planeIntersectionLineWidth = 1.5;
    this.planeIntersectionColors = [
        [0.1, 0.1, 0.1, 0.8],  // intersection with plane 0
        [0.1, 0.1, 0.1, 0.8],  // intersection with plane 1
        [0.1, 0.1, 0.1, 0.8]   // intersection with plane 2
    ];
    this.drawTrueLines = true;
    this.stereoBackgroundColor = null;
    this.stereoBorderColor = [0.9, 0.9, 0.9, 1.0];
    this.stereoBorderSize = 0;
    this.fadeLevel = 1.0;
    this.ambient = 0.1;
    this.fogLevel = 0.15;
    this.fogStart = 1.0;
    this.diffusePow = 0.75;
    this.clipPlanePoint = [0.0, 0.0, 0.0];
    this.clipPlaneNormal = [0.0, 0.0, 1.0];
    this.clipPlaneOffset = 0.2;
    this.planePoint = [0.0, 0.0, 0.0];
    this.planeOffset = [0.0, 0.0, 0.0];
    this.planeAlphaLevels = [1.0, 1.0, 1.0];
    this.planeCrosshairRadiusInner = 0.078125;  // STM_TODO - obsolete
    this.planeCrosshairRadiusOuter = 0.171875;  // STM_TODO - obsolete
    this.planeCrosshairColor = [1.0, 0.1255, 0.1255, 1.0];
    this.autoPlane = false;
    this.selectedPoint = null;
    this.sampleCount = null;
    this.showClipping = true;
    this.showLighting = true;
    this.showLuminosityLighting = true;
    this.showGradientLighting = true;
    this.showPlaneLighting = true;
    this.showClipFlattening = true;
    this.specularLevel = 1.0;
    this.planeSpecularLevel = 0.0;
    this.clipSpecularLevel = 0.0;
    this.useGradients = true;
    this.halfGradient = false;
    this.resampleOffset = 0.0;
    this.defaultNormalFilter = null;
    this.defaultUpsampleFilter = null;
    this.resamplingXFilter = null;
    this.resamplingYFilter = null;
    this.resamplingZFilter = null;
    this.widthMultiplier = 1.0;
    this.heightMultiplier = 1.0;
    this.depthMultiplier = 1.0;
    this.autoResize = false;
    this.autoCanvasResize = false;
    this.autoCanvasResizeTimer = null;
    this.fov = 45.0;
    this.pan = [0.0, 0.0];
    this.screenPan = [0.0, 0.0];
    this.lightPos = vec3.fromValues(0.0, 0.0, 1.0);
    this.lightPosAbsolute = false;
    this.showShadowsLuminosity = false;
    this.showShadowsGradient = false;
    this.shadowMult = 1.5;
    this.minShadowAlpha = 0.00;
    this.shadowStepMult = 2.277266*1.5;
    this.shadowAmbient = 0.10;
    this.checkShader = true;
    this.sampleOnAxis = false;
    this.randomizeSampling = false;
    this.stipple = true;
    this.frontToBack = true;
    this.autoAdjustFramerate = true;
    this.autoRotation = false;
    this.rotationAnimation = null;
    this.stateAnimation = null;
    this.defaultStateAnimationTime = 0.1;
    this.startRotationRate = 0.0;
    this.currentRotationRate = 0.0;
    this.rotationRate = 1 / (Math.PI * 2.0);
    this.rotationAxis = vec3.fromValues(0.0, 0.0, 1.0);
    this.pitchAxis = null;
    this.rollAxis = null;
    this.pivotPoint = [0.0, 0.0, 0.0];

    this.pivotMatrix = mat4.create();
    this.rotationMatrix = mat4.create();
    this.planeMatrix = mat3.create();

    this.scratchCombinedMatrix = mat4.create();  // scratch matrix
    this.scratchPanMatrix = mat4.create();       // scratch matrix
    this.scratchMatrix = mat3.create();          // scratch matrix

    this.projectionMatrix = mat4.create();
    this.modelViewMatrix = mat4.create();
    this.forwardMatrix = mat4.create();
    this.inverseMatrix = mat4.create();

    this.stereoProjectionMatrix = [mat4.create(), mat4.create()];
    this.stereoModelViewMatrix = [mat4.create(), mat4.create()];
    this.stereoForwardMatrix = [mat4.create(), mat4.create()];
    this.stereoInverseMatrix = [mat4.create(), mat4.create()];

    this.tempMiniCubeMatrix = mat4.create();

    this.miniCubeProjectionMatrix = mat4.create();
    this.miniCubeModelViewMatrix = mat4.create();
    this.miniCubeInverseMatrix = mat4.create();

    this.stereoMiniCubeProjectionMatrix = [mat4.create(), mat4.create()];
    this.stereoMiniCubeModelViewMatrix = [mat4.create(), mat4.create()];
    this.stereoMiniCubeInverseMatrix = [mat4.create(), mat4.create()];

    this.distance = 4.0;
    this.zoom = 1.0;
    this.minZoom = 0.75;
    this.maxZoom = 1.5;
    this.interpolate = true;
    this.gradRounding = false;
    this.inputMode = VolumeViewer.INPUT_MODE_NONE;
    this.rockerSnapBack = true;
    this.canUserRotate = true;
    this.canUserZoom = true;
    this.canUserPan = true;
    this.canUserRoll = true;
    this.canUserMovePlane = true;
    this.canUserMoveClip = true;
    this.canUserMoveAnnotations = true;
    this.snapToVoxel = false;
    this.planeInteriorClamping = true;
    this.planeOffsetClamping = false;

    function createDefaultPerVolumeObject(isOverlay) {
        return {
            isOverlay:                isOverlay,
            luminosityAlpha:          1.0,
            gradientAlpha:            1.0,
            inputCenter:              0.1686585,
            inputRadius:              0.1402485,
            minLuminosity:            0.02841,
            maxLuminosity:            0.308907,
            minGradientMagnitude:     0.02841,
            maxGradientMagnitude:     1.0,
            planeAlpha:               0.0,

            autoMinLuminosity:        0.0,
            autoMaxLuminosity:        1.0,
            autoMinGradientMagnitude: 0.0,
            autoMaxGradientMagnitude: 1.0,

            defaultLuminosityArray:   null,
            defaultGradientArray:     null,
            defaultPlaneArray:        null,

            luminosityArray:          [],
            gradientArray:            [],
            planeArray:               [],

            luminosityMaxAlpha:       1.0,
            gradientMaxAlpha:         1.0,
            planeMaxAlpha:            1.0,

            stripTexture:             null,

            //rgbaBuffer:               null
            luminosityBuffer:         null,
            gradientBuffer:           null,
        };
    }

    this.perVolume = [];
    for (let i=0; i<VolumeViewer.MAX_VOLUMES; ++i) {
        this.perVolume.push(createDefaultPerVolumeObject(i !== 0));
    }

    this.perVolume[0].defaultLuminosityArray = VolumeViewer.DEFAULT_LUMINOSITY_ARRAY_0;
    this.perVolume[0].defaultGradientArray   = VolumeViewer.DEFAULT_GRADIENT_ARRAY_0;
    this.perVolume[0].defaultPlaneArray      = VolumeViewer.DEFAULT_PLANE_ARRAY_0;

    this.perVolume[1].defaultLuminosityArray = VolumeViewer.DEFAULT_LUMINOSITY_ARRAY_1;
    this.perVolume[1].defaultGradientArray   = VolumeViewer.DEFAULT_GRADIENT_ARRAY_1;
    this.perVolume[1].defaultPlaneArray      = VolumeViewer.DEFAULT_PLANE_ARRAY_1;

    this.perVolume[0].luminosityArray        = this.perVolume[0].defaultLuminosityArray;
    this.perVolume[0].gradientArray          = this.perVolume[0].defaultGradientArray;
    this.perVolume[0].planeArray             = this.perVolume[0].defaultPlaneArray;

    this.perVolume[1].luminosityArray        = this.perVolume[1].defaultLuminosityArray;
    this.perVolume[1].gradientArray          = this.perVolume[1].defaultGradientArray;
    this.perVolume[1].planeArray             = this.perVolume[1].defaultPlaneArray;

    this.perVolume[0].luminosityMaxAlpha     = VolumeViewer.computeMaxAlpha(this.perVolume[0].luminosityArray);
    this.perVolume[0].gradientMaxAlpha       = VolumeViewer.computeMaxAlpha(this.perVolume[0].gradientArray);
    this.perVolume[0].planeMaxAlpha          = VolumeViewer.computeMaxAlpha(this.perVolume[0].planeArray);

    this.perVolume[1].luminosityMaxAlpha     = VolumeViewer.computeMaxAlpha(this.perVolume[1].luminosityArray);
    this.perVolume[1].gradientMaxAlpha       = VolumeViewer.computeMaxAlpha(this.perVolume[1].gradientArray);
    this.perVolume[1].planeMaxAlpha          = VolumeViewer.computeMaxAlpha(this.perVolume[1].planeArray);

    this.volume = null;
    this.volumeName = null;
    this.volumeProcessed = null;
    this.textureProcessed = null;

    this.linkedViewer = null;
    this.slaveViewers = [];

    this.cachedViewports = {};

    this.topLevel = null;
    this.canvas = null;
    this.forcedFlush = false;

    this.maxCanvasWidth = 512;
    this.maxCanvasHeight = 512;
    this.canvasFactor = 1.0;

    this.canvasOverlay     = null;
    this.context2d         = null;
    this.rootOverlay       = null;
    this.annotationOverlay = null;
    this.crosshairOverlay  = null;
    this.borderOverlay     = null;

    this.gl = null;
    this.glVersion = 1;
    this.glMaxTextureSize = VolumeViewer.MAX_TEX_SIDE;

    this.glInfo = {
        maxTextureSize:                       null,
        maxRenderBufferSize:                  null,
        maxCubeMapTextureSize:                null,
        maxTextureImageUnits:                 null,
        maxVertexUniformVectors:              null,
        maxVertexAttribs:                     null,
        maxVertexTextureImageUnits:           null,
        maxFragmentUniformVectors:            null,
        maxCombinedTextureImageUnits:         null,
        max3DTextureSize:                     null,
        maxArrayTextureLayers:                null,
        maxClientWaitTimeoutWebGL:            null,
        maxColorAttachments:                  null,
        maxCombinedFragmentUniformComponents: null,
        maxCombinedUniformBlocks:             null,
        maxCombinedVertexUniformComponents:   null,
        maxFragmentInputComponents:           null,
        maxFragmentUniformBlocks:             null,
        maxFragmentUniformComponents:         null,
        maxSamples:                           null,
        maxUniformBlockSize:                  null,
        maxUniformBufferBindings:             null,
        maxVaryingComponents:                 null,
    };

    {
        let viewer = this;
        this.renderProxy = function(timestamp) {
            viewer.render(timestamp);
        };
    }

    this.vertShaderScript = null;
    this.fragShaderScript = null;
    this.shaderOptions = null;
    this.shaderProgram = null;
    this.programInfo = null;

    this.cachedShaders = [];

    this.miniVertShaderScript = null;
    this.miniFragShaderScript = null;
    this.miniShaderOptions = null;
    this.miniShaderProgram = null;
    this.miniProgramInfo = null;

    this.buffers = null;

    this.animationId = null;
    this.lastTimestamp = null;
    this.framesLeft = 1;
    this.frames = [];
    this.fps = null;
    this.frameValid = false;
    this.calculatedSampleCount = null;

    this.mouseX  = 0.0;
    this.mouseY  = 0.0;
    this.mouseX0 = 0.0;
    this.mouseY0 = 0.0;
    this.mouseX1 = 0.0;
    this.mouseY1 = 0.0;
    this.mousePressed = false;
    this.mouseTouch = false;
    this.mouseDrag = false;
    this.mouseView = VolumeViewer.VIEW_UNKNOWN;
    this.mouseOptions = VolumeViewer.DRAG_NONE;
    this.mouseSpin = false;  // action
    this.mouseInput = false;  // action
    this.mouseClip = false;  // action
    this.mousePlane = false;  // action
    this.mouseRoll = false;  // action
    this.mouseZoom = false;  // action
    this.mousePanZoom = false;  // action
    this.mousePan = false;  // action
    this.mouseRocker = false;  // action
    this.mouseCrosshair = false;  // action
    this.mouseAnnotations = false;  // action
    this.mousePlaneSelected = 1;
    this.mouseSpinDeltaX = 0.0;
    this.mouseSpinDeltaY = 0.0;
    this.mouseSpinAlignAxis = false;
    this.mouseSpinMatrix = mat4.create();
    this.mouseRockerOffset = vec3.create();
    this.mousePanPosition = {x: 0.0, y: 0.0};
    this.mousePanZoomPosition = {x: 0.0, y: 0.0};
    this.mouseCrosshairPlane = 0;
    this.mouseFlip = false;
    this.mouseBrightness = 0.5;
    this.mouseContrast = 0.5;

    this.actionDepth = 0;
    this.deferredActions = [];
    this.userActionDepth = 0;
    this.blockActions = false;

    this.inputHandler = null;
    this.inputHandlerMap = {};
    this.boundCallbacks = [];

    this.volumeSetCallbacks = [];
    this.volumeProcessedCallbacks = [];
    this.preRenderCallbacks = [];
    this.postRenderCallbacks = [];
    this.userActionCallbacks = [];
    this.programActionCallbacks = [];

    this.rotationAnimation = Animate.createAnimation();
    this.stateAnimation    = Animate.createAnimation();

    Object.defineProperty(this, "AnnotationOverlay", {
        enumerable: true,
        set: undefined,
        get: function() { return this.annotationOverlay; }
    });

    let inputMap = {};
    inputMap[VolumeViewer.INPUT_MODE_NONE]            = null;
    inputMap[VolumeViewer.INPUT_MODE_CUSTOM]          = null;
    inputMap[VolumeViewer.INPUT_MODE_SPIN]            = VolumeViewer.INPUT_HANDLERS_SPIN;
    inputMap[VolumeViewer.INPUT_MODE_ROCKER]          = VolumeViewer.INPUT_HANDLERS_ROCKER;
    inputMap[VolumeViewer.INPUT_MODE_INPUT]           = VolumeViewer.INPUT_HANDLERS_INPUT;
    inputMap[VolumeViewer.INPUT_MODE_PAN_ZOOM]        = VolumeViewer.INPUT_HANDLERS_PAN_ZOOM;
    inputMap[VolumeViewer.INPUT_MODE_CROSSHAIR]       = VolumeViewer.INPUT_HANDLERS_CROSSHAIR;
    inputMap[VolumeViewer.INPUT_MODE_CROSSHAIR_SPIN]  = VolumeViewer.INPUT_HANDLERS_CROSSHAIR_SPIN;
    inputMap[VolumeViewer.INPUT_MODE_CROSSHAIR_INPUT] = VolumeViewer.INPUT_HANDLERS_CROSSHAIR_INPUT;
    inputMap[VolumeViewer.INPUT_MODE_SPIN_2D]         = VolumeViewer.INPUT_HANDLERS_SPIN_2D;

    this.inputHandlerMap = inputMap;

    // Create properties for our setters and getters
    let propertyNames = Object.keys(VolumeViewer.PROPERTY_MAP);
    for (let i=0; i<propertyNames.length; ++i) {
        let propertyName = propertyNames[i];
        let propertySet = VolumeViewer.getPropertySetterName(propertyName);
        let propertyGet = VolumeViewer.getPropertyGetterName(propertyName);
        let setter = this.getPropertyFunction(propertySet);
        let getter = this.getPropertyFunction(propertyGet);
        if (!setter)
            dconsole.error(`ERROR: Couldn't find setter function ${propertySet} in VolumeViewer`);
        if (!getter)
            dconsole.error(`ERROR: Couldn't find getter function ${propertyGet} in VolumeViewer`);
        if (setter && getter) {
            Object.defineProperty(this, propertyName, {
                enumerable: true,
                set: setter,
                get: getter
            });
        }
    }

    this.initialize(selector, vertShader, fragShader);
}

// Class constants
VolumeViewer.VERTEX_SHADER_TYPE   = "x-shader/x-vertex";
VolumeViewer.FRAGMENT_SHADER_TYPE = "x-shader/x-fragment";
VolumeViewer.MAX_TEX_SIDE         = 4096;
VolumeViewer.MAX_VOLUMES          = 2;
VolumeViewer.MIN_INPUT_CENTER     = -1.0;
VolumeViewer.MAX_INPUT_CENTER     = 2.0;
VolumeViewer.MIN_INPUT_RADIUS     = 0.0;
VolumeViewer.MAX_INPUT_RADIUS     = 1.0;
VolumeViewer.MIN_LUMINOSITY       = 0.0;
VolumeViewer.MAX_LUMINOSITY       = 1.0;
VolumeViewer.CONTRAST_POWER       = 2.0;
VolumeViewer.BORDER_SIZE          = 2;
VolumeViewer.BORDER_ADD           = VolumeViewer.BORDER_SIZE * 2;

// Stereo view constants
VolumeViewer.VIEW_LEFT    = -1;
VolumeViewer.VIEW_CENTER  =  0;
VolumeViewer.VIEW_RIGHT   =  1;
VolumeViewer.VIEW_UNKNOWN = null;

// Input modes
VolumeViewer.INPUT_MODE_NONE            = "_";
VolumeViewer.INPUT_MODE_CUSTOM          = "_#";  // DEPRECATED
VolumeViewer.INPUT_MODE_SPIN            = "_SPIN";
VolumeViewer.INPUT_MODE_ROCKER          = "_ROCKER";
VolumeViewer.INPUT_MODE_INPUT           = "_INPUT";
VolumeViewer.INPUT_MODE_PAN_ZOOM        = "_PAN_ZOOM";
VolumeViewer.INPUT_MODE_CROSSHAIR       = "_CROSSHAIR";
VolumeViewer.INPUT_MODE_CROSSHAIR_SPIN  = "_CROSSHAIR_SPIN";
VolumeViewer.INPUT_MODE_CROSSHAIR_INPUT = "_CROSSHAIR_INPUT";
VolumeViewer.INPUT_MODE_SPIN_2D         = "_SPIN_2D";
VolumeViewer.INPUT_MODE_3D              = VolumeViewer.INPUT_MODE_SPIN;    // DEPRECATED
VolumeViewer.INPUT_MODE_2D              = VolumeViewer.INPUT_MODE_ROCKER;  // DEPRECATED

// Masks for different drag modes
VolumeViewer.DRAG_NONE        = 0x0000;
VolumeViewer.DRAG_SPIN        = 0x0001;
VolumeViewer.DRAG_INPUT       = 0x0002;
VolumeViewer.DRAG_CLIP        = 0x0004;
VolumeViewer.DRAG_PLANE       = 0x0008;
VolumeViewer.DRAG_ROLL        = 0x0010;
VolumeViewer.DRAG_ZOOM        = 0x0020;
VolumeViewer.DRAG_ROCKER      = 0x0040;
VolumeViewer.DRAG_SELECT      = 0x0080;
VolumeViewer.DRAG_PAN_ZOOM    = 0x0100;
VolumeViewer.DRAG_PAN         = 0x0200;
VolumeViewer.DRAG_CROSSHAIR   = 0x0400;
VolumeViewer.DRAG_ANNOTATIONS = 0x0800;
VolumeViewer.DRAG_ANY         = 0x0FFF;

VolumeViewer.DRAG_CLIP_PLANE      = VolumeViewer.DRAG_CLIP | VolumeViewer.DRAG_PLANE;
VolumeViewer.DRAG_CROSSHAIR_SPIN  = VolumeViewer.DRAG_CROSSHAIR | VolumeViewer.DRAG_SPIN;
VolumeViewer.DRAG_CROSSHAIR_INPUT = VolumeViewer.DRAG_CROSSHAIR | VolumeViewer.DRAG_INPUT;

// DEPRECATED DRAG MASKS! DO NOT USE THESE!
VolumeViewer.DRAG_2D         = VolumeViewer.DRAG_CLIP_PLANE | VolumeViewer.DRAG_ZOOM;  // DEPRECATED
VolumeViewer.DRAG_3D         = VolumeViewer.DRAG_SPIN | VolumeViewer.DRAG_CLIP_PLANE | // DEPRECATED
                               VolumeViewer.DRAG_ROLL | VolumeViewer.DRAG_ZOOM;        // DEPRECATED

// General action categories
VolumeViewer.ACTION_ROTATE     = "rotate";
VolumeViewer.ACTION_ZOOM       = "zoom";
VolumeViewer.ACTION_PAN        = "pan";
VolumeViewer.ACTION_COLORS     = "colors";
VolumeViewer.ACTION_THRESHOLDS = "thresholds";
VolumeViewer.ACTION_CLIP       = "clip";
VolumeViewer.ACTION_PLANE      = "plane";
VolumeViewer.ACTION_SELECT     = "select";
VolumeViewer.ACTION_PIVOT      = "pivot";
VolumeViewer.ACTION_VIEWPORT   = "viewport";

// Face enumerations
VolumeViewer.FACE_NONE      = 0;
VolumeViewer.FACE_LEFT      = 1;
VolumeViewer.FACE_RIGHT     = 2;
VolumeViewer.FACE_ANTERIOR  = 3;
VolumeViewer.FACE_POSTERIOR = 4;
VolumeViewer.FACE_SUPERIOR  = 5;
VolumeViewer.FACE_INFERIOR  = 6;

// Supported volume file types
VolumeViewer.TYPE_NRRD  = 'nrrd';
VolumeViewer.TYPE_NIFTI = 'nii';
VolumeViewer.TYPE_DICOM = 'dcm';

// Conveniences (read-only)
VolumeViewer.IDENTITY_MATRIX = Object.freeze({
    m00: 1.0, m01: 0.0, m02: 0.0,
    m10: 0.0, m11: 1.0, m12: 0.0,
    m20: 0.0, m21: 0.0, m22: 1.0
});
VolumeViewer.EMPTY_2D_VECTOR = Object.freeze({
    x: 0.0, y: 0.0
});
VolumeViewer.EMPTY_3D_VECTOR = Object.freeze({
    x: 0.0, y: 0.0, z: 0.0
});
VolumeViewer.EMPTY_3D_PVECTOR = Object.freeze({
    px: 0.0, py: 0.0, pz: 0.0
});

// Default color tables for our 2D transfer functions
VolumeViewer.DEFAULT_LUMINOSITY_ARRAY_0 = [
    [ 0.00, [1.000000, 0.000000, 0.000000, 0.000] ],
    [ 0.10, [1.000000, 0.000000, 0.000000, 0.010] ],
    [ 0.20, [1.000000, 0.352941, 0.000000, 0.025] ],
    [ 0.30, [1.000000, 0.776471, 0.203922, 0.015] ],
    [ 0.40, [1.000000, 0.909804, 0.400000, 0.025] ],
    [ 0.50, [1.000000, 0.988235, 0.596078, 0.040] ],
    [ 0.60, [1.000000, 0.980392, 0.827451, 0.070] ],
    [ 0.80, [0.807843, 1.000000, 0.972549, 0.080] ],
    //[ 0.95, [1.000000, 1.000000, 1.000000, 0.030] ]
    [ 0.95, [1.000000, 1.000000, 1.000000, 0.080] ]
];
VolumeViewer.DEFAULT_GRADIENT_ARRAY_0 = [
    [ 0.01, [0.000000, 0.000000, 0.000000, 0.000] ],
    [ 0.01, [0.772549, 0.933333, 1.000000, 0.180] ],
    [ 0.50, [0.772549, 0.933333, 1.000000, 0.180] ],
    [ 1.00, [0.772549, 0.933333, 1.000000, 0.390] ]
];
VolumeViewer.DEFAULT_LUMINOSITY_ARRAY_1 = [
    [ 0.00, [0.150000, 0.850000, 0.150000, 0.000] ],
    [ 0.20, [0.150000, 0.850000, 0.150000, 0.000] ],
    [ 0.60, [0.150000, 0.850000, 0.150000, 1.000] ],
    [ 1.00, [0.150000, 0.850000, 0.150000, 1.000] ]
];
VolumeViewer.DEFAULT_GRADIENT_ARRAY_1 = [];

// Default color tables for our planar displays
VolumeViewer.DEFAULT_PLANE_ARRAY_0 = [
    //[ 0.00, [0.2, 0.2, 0.2, 0.0]],
    //[ 0.00, [0.2, 0.2, 0.2, 1.0]],
    [ 0.00, [0.0, 0.0, 0.0, 1.0]],
    [ 1.00, [1.0, 1.0, 1.0, 1.0]]
];
VolumeViewer.DEFAULT_PLANE_ARRAY_1 = [
    [ 0.00, [0.15, 0.85, 0.15, 0.0]],
    [ 0.20, [0.15, 0.85, 0.15, 0.0]],
    [ 0.20, [0.15, 0.85, 0.15, 0.5]],
    [ 1.00, [0.15, 0.85, 0.15, 0.8]]
];

// Reusable buffers
VolumeViewer.normalBuffer = null;

// Matrix conveniences
VolumeViewer.VEC3_EMPTY = vec3.create();
VolumeViewer.MAT4_IDENTITY = mat4.create();

// Base input handlers for the volume viewer.
VolumeViewer.INPUT_HANDLERS_BASE = {
    mousedown: function(event) {
        const ctrlKey  = (event.ctrlKey || 0);
        const shiftKey = (event.shiftKey || 0);
        const altKey   = (event.altKey || 0);

        if (!ctrlKey && !shiftKey && !altKey && event.which === 1)  // left button drag
                return this.startDrag(VolumeViewer.DRAG_SPIN | VolumeViewer.DRAG_CLIP_PLANE |
                                      VolumeViewer.DRAG_ANNOTATIONS,
                                      event.pageX, event.pageY);

        if (!ctrlKey && !shiftKey && !altKey && event.which === 2)  // middle button drag
            return this.startDrag(VolumeViewer.DRAG_CLIP_PLANE,
                                  event.pageX, event.pageY);

        if (!ctrlKey && !shiftKey && !altKey && event.which === 3)  // right button drag
            return this.startDrag(VolumeViewer.DRAG_ZOOM | VolumeViewer.DRAG_PAN,
                                  // | VolumeViewer.DRAG_PAN_ZOOM,
                                  event.pageX, event.pageY);

        if (ctrlKey && !shiftKey && !altKey && event.which === 1)  // ctrl+left button drag
                return this.startDrag(VolumeViewer.DRAG_INPUT,
                                      event.pageX, event.pageY);

        if (ctrlKey && !shiftKey && !altKey && event.which === 3)  // ctrl+right button drag
            return this.startDrag(VolumeViewer.DRAG_PAN,
                                  event.pageX, event.pageY);

        return false;
    },
    mousemove: function(event) {
        if (this.drag(event.pageX, event.pageY))
            return true;

        if (this.isUserMoveAnnotationsAllowed())
            if (this.rootOverlay)  this.rootOverlay.highlightAtPoint(event.pageX, event.pageY);

        let face = this.getMiniCubeFace(event.pageX, event.pageY);
        this.setMiniCubeHighlightFace(face);
        return false;
    },
    mouseup: function(event) {
        return this.endDrag();
    },
    mouseclick: function(event) {
        if (event.which === 1 || event.which === 2) {  // left or middle button click
            if (event.ctrlKey) {
                this.toggleClippingFromRotation();
                return true;
            }
        }

        if (event.which === 1) {  // left button click
            if (this.isUserMoveAnnotationsAllowed())
                if (this.rootOverlay && this.rootOverlay.click({ x: event.pageX, y: event.pageY }))
                    return true;
            if (this.alignMiniCube(event.pageX, event.pageY))
                return true;
        }

        return false;
    },
    mouseleave: function(event) {
        return this.endDrag();
    },
    wheel: function(event) {
        const delta = event.originalEvent.deltaY;
        const stepAdd = 1.0;
        let step = delta < 0.0 ? stepAdd : -stepAdd;
        return this.userStepFacingPlaneClip(step, false);
    },
    dblclick: function(event) {
        // For now, do not handle this event
        return false;
    },
    touchstart: function(event) {
        let touches = event.touches;

        if (touches.length === 1) {
            let face = this.getMiniCubeFace(touches[0].pageX, touches[0].pageY);
            this.setMiniCubeHighlightFace(face);
        }

        if (touches.length === 2)  // double-touch drag
            return this.startDrag(VolumeViewer.DRAG_SPIN | VolumeViewer.DRAG_CLIP_PLANE |
                                  VolumeViewer.DRAG_ROLL | VolumeViewer.DRAG_ZOOM,
                                  touches[0].pageX, touches[0].pageY,
                                  touches[1].pageX, touches[1].pageY);

        if (touches.length === 1)  // single-touch drag
            return this.startDrag(VolumeViewer.DRAG_SPIN | VolumeViewer.DRAG_CLIP_PLANE |
                                  VolumeViewer.DRAG_ANNOTATIONS,
                                  touches[0].pageX, touches[0].pageY);

        return false;
    },
    touchmove: function(event) {
        let touches = event.touches;

        if (touches.length === 2)  // double-touch drag
            return this.drag(touches[0].pageX, touches[0].pageY,
                             touches[1].pageX, touches[1].pageY);

        if (touches.length === 1)  // single-touch drag
            return this.drag(touches[0].pageX, touches[0].pageY);

        return false;
    },
    touchend: function(event) {
        this.setMiniCubeHighlightFace(VolumeViewer.FACE_NONE);
        return this.endDrag();
    },
    touchclick: function(event) {
        let touches = event.changedTouches;
        if (touches.length === 1) {  // single-touch click
            if (this.isUserMoveAnnotationsAllowed())
                if (this.rootOverlay && this.rootOverlay.click({ x: touches[0].pageX, y: touches[0].pageY }))
                    return true;
            if (this.alignMiniCube(touches[0].pageX, touches[0].pageY))
                return true;
        }

        return false;
    },
    keydown: function(event) {
        const keyCode  = (event.which || event.keyCode || 0);
        const ctrlKey  = (event.ctrlKey || 0);
        const shiftKey = (event.shiftKey || 0);
        const altKey   = (event.altKey || 0);

        const ctrl      =  ctrlKey && !shiftKey && !altKey;
        const shift     = !ctrlKey &&  shiftKey && !altKey;
        const alt       = !ctrlKey && !shiftKey &&  altKey;
        const ctrlShift =  ctrlKey &&  shiftKey && !altKey;
        const ctrlAlt   =  ctrlKey && !shiftKey &&  altKey;
        const shiftAlt  = !ctrlKey &&  shiftKey &&  altKey;
        const noMods    = !ctrlKey && !shiftKey && !altKey;

        // STM_TODO - for now!
        if (this.isUserMoveAnnotationsAllowed())
            if (this.rootOverlay && this.rootOverlay.keyDown(event))
                return true;

        if (shift && keyCode === 82) {  // shift+R
            return this.reset(true);
        }
        if ((ctrl|shift) && keyCode === 48) {  // ctrl+0, shift+0
            return this.userFitToViewport(null, true);
        }
        if ((ctrl|shift) && keyCode === 45) {  // ctrl+0, shift+0 (numeric keypad)
            return this.userFitToViewport(null, true);
        }
        if ((ctrl|shift) && keyCode === 38) {  // ctrl+up arrow, shift+up arrow
            return this.userPan(0.0, 1.0, true);
        }
        if ((ctrl|shift) && keyCode === 40) {  // ctrl+down arrow, shift+down arrow
            return this.userPan(0.0, -1.0, true);
        }
        if ((ctrl|shift) && keyCode === 37) {  // ctrl+left arrow, shift+left arrow
            return this.userPan(-1.0, 0.0, true);
        }
        if ((ctrl|shift) && keyCode === 39) {  // ctrl+right arrow, shift+right arrow
            return this.userPan(1.0, 0.0, true);
        }
        if ((ctrl|shift) && keyCode === 61) {  // ctrl+plus, shift+plus (Windows)
            return this.userZoom(-1.0, true);
        }
        if ((ctrl|shift) && keyCode === 173) {  // ctrl+minus, shift+minus (Windows)
            return this.userZoom(1.0, true);
        }
        if ((ctrl|shift) && keyCode === 187) {  // ctrl+plus, shift+plus (Mac)
            return this.userZoom(-1.0, true);
        }
        if ((ctrl|shift) && keyCode === 189) {  // ctrl+minus, shift+minus (Mac)
            return this.userZoom(1.0, true);
        }
        if ((ctrl|shift) && keyCode === 107) {  // ctrl+plus, shift+plus (numeric keypad)
            return this.userZoom(-1.0, true);
        }
        if ((ctrl|shift) && keyCode === 109) {  // ctrl+minus, shift+minus (numeric keypad)
            return this.userZoom(1.0, true);
        }
        if (shift && keyCode === 219) {  // shift+[
            return this.userRoll(-90.0, true);
        }
        if (shift && keyCode === 221) {  // shift+]
            return this.userRoll(90.0, true);
        }
        if (shift && keyCode === 72) {  // shift+H
            return this.userFlipHorizontal();
        }
        if (shift && keyCode === 86) {  // shift+V
            return this.userFlipVertical();
        }
        if (ctrlAlt && keyCode === 49) {  // ctrl+alt+1
            return this.userSetFaceCoronal(true);
        }
        if (ctrlAlt && keyCode === 50) {  // ctrl+alt+2
            return this.userSetFaceSagittal(true);
        }
        if (ctrlAlt && keyCode === 51) {  // ctrl+alt+3
            return this.userSetFaceAxial(true);
        }
        if (noMods && keyCode === 49) {  // 1
            return this.userSetFaceAnterior(true);
        }
        if (noMods && keyCode === 50) {  // 2
            return this.userSetFacePosterior(true);
        }
        if (noMods && keyCode === 51) {  // 3
            return this.userSetFaceLeft(true);
        }
        if (noMods && keyCode === 52) {  // 4
            return this.userSetFaceRight(true);
        }
        if (noMods && keyCode === 53) {  // 5
            return this.userSetFaceSuperior(true);
        }
        if (noMods && keyCode === 54) {  // 6
            return this.userSetFaceInferior(true);
        }
        if (noMods && keyCode === 38) {  // up arrow
            return this.userStepFacingPlaneClip(-1.0);
        }
        if (noMods && keyCode === 40) {  // down arrow
            return this.userStepFacingPlaneClip(1.0);
        }
        if (noMods && keyCode === 36) {  // home
            return this.userSetFacingPlaneSlice(1);
        }
        if (noMods && keyCode === 35) {  // end
            let slices = this.getFacingPlaneSlice();
            if (slices)
                return this.userSetFacingPlaneSlice(slices.count);
        }
        if (noMods && keyCode === 219) {  // open bracket ([)
            return this.userSelectPrev();
        }
        if (noMods && keyCode === 221) {  // close bracket (])
            return this.userSelectNext();
        }
        if (noMods && keyCode === 220) {  // backslash (\)
            return this.userShowSelected();
        }
        if (noMods && keyCode === 48) {  // 0
            return this.userResetAutoThresholds(true);
        }
        return false;
    },
    keyup: function(event) {
        return false;
    },
    keypress: function(event) {
        return false;
    },
    contextmenu: function(event) {
        return true;
    }
};

// Input handler for volume spinning (the default for 3D views).
VolumeViewer.INPUT_HANDLERS_SPIN = Object.freeze({
    base: VolumeViewer.INPUT_HANDLERS_BASE
});

// Input handler for volume spinning (a variant of Spin for 2D views).
VolumeViewer.INPUT_HANDLERS_SPIN_2D = Object.freeze({
    // now exactly the same...
    base: VolumeViewer.INPUT_HANDLERS_SPIN
});

// Input handler for the rocker (the default for 2D views).
VolumeViewer.INPUT_HANDLERS_ROCKER = Object.freeze({
    base: VolumeViewer.INPUT_HANDLERS_BASE,
    mousedown: function(event) {
        const ctrlKey  = (event.ctrlKey || 0);
        const shiftKey = (event.shiftKey || 0);
        const altKey   = (event.altKey || 0);

        if (!ctrlKey && !shiftKey && !altKey && event.which === 1)  // left button drag
            return this.startDrag(VolumeViewer.DRAG_ROCKER | VolumeViewer.DRAG_ANNOTATIONS,
                                  event.pageX, event.pageY);

        return false;
    },
    touchstart: function(event) {
        let touches = event.touches;
        if (touches.length === 1)  // single-touch drag
            return this.startDrag(VolumeViewer.DRAG_ROCKER | VolumeViewer.DRAG_ANNOTATIONS,
                                  touches[0].pageX, touches[0].pageY);

        return false;
    }
});

// Input handler for the 2D crosshair view (usually for 2D modes).
VolumeViewer.INPUT_HANDLERS_CROSSHAIR_SPIN = Object.freeze({
    base: VolumeViewer.INPUT_HANDLERS_BASE,
    mousedown: function(event) {
        const ctrlKey  = (event.ctrlKey || 0);
        const shiftKey = (event.shiftKey || 0);
        const altKey   = (event.altKey || 0);

        if (!ctrlKey && !shiftKey && !altKey && event.which === 1)  // left button drag
            return this.startDrag(VolumeViewer.DRAG_CROSSHAIR_SPIN | VolumeViewer.DRAG_ANNOTATIONS,
                                  event.pageX, event.pageY);

        return false;
    },
    touchstart: function(event) {
        let touches = event.touches;
        if (touches.length === 1)  // single-touch drag
            return this.startDrag(VolumeViewer.DRAG_CROSSHAIR_SPIN | VolumeViewer.DRAG_ANNOTATIONS,
                                  touches[0].pageX, touches[0].pageY);

        return false;
    }
});

// Input handler for the 2D crosshair view (usually for 2D modes).
VolumeViewer.INPUT_HANDLERS_CROSSHAIR_INPUT = Object.freeze({
    base: VolumeViewer.INPUT_HANDLERS_BASE,
    mousedown: function(event) {
        const ctrlKey  = (event.ctrlKey || 0);
        const shiftKey = (event.shiftKey || 0);
        const altKey   = (event.altKey || 0);

        if (!ctrlKey && !shiftKey && !altKey && event.which === 1)  // left button drag
            return this.startDrag(VolumeViewer.DRAG_CROSSHAIR_INPUT | VolumeViewer.DRAG_ANNOTATIONS,
                                  event.pageX, event.pageY);

        return false;
    },
    touchstart: function(event) {
        let touches = event.touches;
        if (touches.length === 1)  // single-touch drag
            return this.startDrag(VolumeViewer.DRAG_CROSSHAIR_INPUT | VolumeViewer.DRAG_ANNOTATIONS,
                                  touches[0].pageX, touches[0].pageY);

        return false;
    }
});

// Input handler for the 2D crosshair view (usually for 2D modes).
VolumeViewer.INPUT_HANDLERS_CROSSHAIR = Object.freeze({
    base: VolumeViewer.INPUT_HANDLERS_BASE,
    mousedown: function(event) {
        const ctrlKey  = (event.ctrlKey || 0);
        const shiftKey = (event.shiftKey || 0);
        const altKey   = (event.altKey || 0);

        if (!ctrlKey && !shiftKey && !altKey && event.which === 1)  // left button drag
            return this.startDrag(VolumeViewer.DRAG_CROSSHAIR | VolumeViewer.DRAG_ANNOTATIONS,
                                  event.pageX, event.pageY);

        return false;
    },
    touchstart: function(event) {
        let touches = event.touches;
        if (touches.length === 1)  // single-touch drag
            return this.startDrag(VolumeViewer.DRAG_CROSSHAIR | VolumeViewer.DRAG_ANNOTATIONS,
                                  touches[0].pageX, touches[0].pageY);

        return false;
    }
});

// Input handler for changing the input window (hence the ungainly name).
VolumeViewer.INPUT_HANDLERS_INPUT = Object.freeze({
    base: VolumeViewer.INPUT_HANDLERS_BASE,
    mousedown: function(event) {
        const ctrlKey  = (event.ctrlKey || 0);
        const shiftKey = (event.shiftKey || 0);
        const altKey   = (event.altKey || 0);

        if (!ctrlKey && !shiftKey && !altKey && event.which === 1)  // left button drag
            return this.startDrag(VolumeViewer.DRAG_INPUT | VolumeViewer.DRAG_ANNOTATIONS,
                                  event.pageX, event.pageY);

        return false;
    },
    touchstart: function(event) {
        let touches = event.touches;
        if (touches.length === 1)  // single-touch drag
            return this.startDrag(VolumeViewer.DRAG_INPUT | VolumeViewer.DRAG_ANNOTATIONS,
                                  touches[0].pageX, touches[0].pageY);

        return false;
    }
});

// Input handler for pan/zoom.
VolumeViewer.INPUT_HANDLERS_PAN_ZOOM = Object.freeze({
    base: VolumeViewer.INPUT_HANDLERS_BASE,
    mousedown: function(event) {
        const ctrlKey  = (event.ctrlKey || 0);
        const shiftKey = (event.shiftKey || 0);
        const altKey   = (event.altKey || 0);

        if (!ctrlKey && !shiftKey && !altKey && event.which === 1)  // left button drag
            return this.startDrag(VolumeViewer.DRAG_PAN | VolumeViewer.DRAG_ANNOTATIONS,
                                  event.pageX, event.pageY);

        return false;
    },
    touchstart: function(event) {
        let touches = event.touches;
        if (touches.length === 1)  // single-touch drag
            return this.startDrag(VolumeViewer.DRAG_PAN | VolumeViewer.DRAG_ANNOTATIONS,
                                  touches[0].pageX, touches[0].pageY);

        return false;
    },
    wheel: function(event) {
        const delta = event.originalEvent.deltaY;
        const zoomAdd = 1.0;
        const newZoom = delta > 0.0 ? zoomAdd : -zoomAdd;
        return this.userStepZoomToDisplay(newZoom, event.pageX, event.pageY,
                                          VolumeViewer.VIEW_UNKNOWN, true);
    }
});

// Deprecated input handlers.
VolumeViewer.INPUT_HANDLERS_3D = VolumeViewer.INPUT_HANDLERS_SPIN;    // DEPRECATED
VolumeViewer.INPUT_HANDLERS_2D = VolumeViewer.INPUT_HANDLERS_ROCKER;  // DEPRECATED

// Destroys the VolumeViewer, and frees all resources that can be freed.
VolumeViewer.prototype.destroy = function() {
    // STM_TODO - needs more testing!!!

    const gl = this.gl;

    // Remove all viewer links
    const slaveViewers = this.slaveViewers;
    this.slaveViewers = [];
    for (let i=slaveViewers.length-1; i>=0; --i) {
        const viewer = slaveViewers[i];
        if (viewer)
            viewer.unlinkViewer();
    }

    this.unlinkViewer();

    this.clearCachedShaders();

    this.shaderProgram = null;
    this.miniShaderProgram = null;

    this.releaseTextures();
    this.releaseTextureStrips();
    this.releaseMiniTexture();

    this.clearAllCallbacks();

    this.uninitCallbacks();

    if (gl) {
        gl.clearColor(0.4, 0.05, 0.05, 1.0);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    }

    this.volume = null;
    this.volumeName = null;
    this.volumeProcessed = null;
    this.textureProcessed = null;

    if (this.borderOverlay) {
        this.borderOverlay.destroy();
        this.borderOverlay = null;
    }
    if (this.crosshairOverlay) {
        this.crosshairOverlay.destroy();
        this.crosshairOverlay = null;
    }
    if (this.annotationOverlay) {
        this.annotationOverlay.destroy();
        this.annotationOverlay = null;
    }
    if (this.rootOverlay) {
        this.rootOverlay.destroy();
        this.rootOverlay = null;
    }
    this.context2d = null;
    if (this.canvasOverlay) {
        $(this.canvasOverlay).remove();
        this.canvasOverlay = null;
    }

    this.topLevel = null;
    this.canvas = null;

    this.gl = null;
};

// Automatically force a flush after every render.
VolumeViewer.prototype.enableForcedFlush = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.forcedFlush !== enable) {
        this.forcedFlush = enable;

        this.invalidate();
    }
};

// Convenience method.  Gets the property states for 3D volume viewing.
VolumeViewer.prototype.get3DViewPropertyState = function() {
    return this.copyPropertyState(VolumeViewer.PROPERTY_STATE_3D_VIEW);
};

// Convenience method.  Gets the property states for 3D volume viewing in black and white.
VolumeViewer.prototype.get3D2ViewPropertyState = function() {
    return this.copyPropertyState(VolumeViewer.PROPERTY_STATE_3D2_VIEW);
};

// Convenience method.  Gets the property states for 3D MPR viewing.
VolumeViewer.prototype.getMPRViewPropertyState = function() {
    return this.copyPropertyState(VolumeViewer.PROPERTY_STATE_MPR_VIEW);
};

// Convenience method.  Gets the property states for 2D slice-based viewing.
VolumeViewer.prototype.get2DViewPropertyState = function() {
    return this.copyPropertyState(VolumeViewer.PROPERTY_STATE_2D_VIEW);
};

// Convenience method.  Sets up the volume viewer for 3D volume viewing.
VolumeViewer.prototype.show3DView = function() {
    let propertyState = this.get3DViewPropertyState();
    if (!propertyState)  return;

    delete propertyState.RotationMatrix;
    delete propertyState.Zoom;
    delete propertyState.ZoomLinear;
    delete propertyState.Clipping;
    delete propertyState.ClipNormal;
    delete propertyState.ClipOffset;

    //let newRotationMatrix = propertyState.RotationMatrix || this.getRotationMatrix();
    //let newZoom           = this.calculateZoomToFit(newRotationMatrix);
    //let newZoomLinear     = VolumeViewer.zoomScaledToLinear(newZoom);
    //propertyState.RotationMatrix = newRotationMatrix;
    //propertyState.ZoomLinear     = newZoomLinear;

    const animate = false;
    this.animateToPropertyState(propertyState, null, animate);
};

// Convenience method.  Sets up the volume viewer for 3D volume viewing
// in black and white.
VolumeViewer.prototype.show3D2View = function() {
    let propertyState = this.get3D2ViewPropertyState();
    if (!propertyState)  return;

    delete propertyState.RotationMatrix;
    delete propertyState.Zoom;
    delete propertyState.ZoomLinear;
    delete propertyState.Clipping;
    delete propertyState.ClipNormal;
    delete propertyState.ClipOffset;

    //let newRotationMatrix = propertyState.RotationMatrix || this.getRotationMatrix();
    //let newZoom           = this.calculateZoomToFit(newRotationMatrix);
    //let newZoomLinear     = VolumeViewer.zoomScaledToLinear(newZoom);
    //propertyState.RotationMatrix = newRotationMatrix;
    //propertyState.ZoomLinear     = newZoomLinear;

    const animate = false;
    this.animateToPropertyState(propertyState, null, animate);
};

// Convenience method.  Sets up the volume viewer for 3D MPR viewing.
VolumeViewer.prototype.showMPRView = function() {
    let propertyState = this.getMPRViewPropertyState();
    if (!propertyState)  return;

    delete propertyState.RotationMatrix;
    delete propertyState.Zoom;
    delete propertyState.ZoomLinear;

    //let newRotationMatrix = propertyState.RotationMatrix || this.getRotationMatrix();
    //let newZoom           = this.calculateZoomToFit(newRotationMatrix);
    //let newZoomLinear     = VolumeViewer.zoomScaledToLinear(newZoom);
    //propertyState.RotationMatrix = newRotationMatrix;
    //propertyState.ZoomLinear     = newZoomLinear;

    const animate = false;
    this.animateToPropertyState(propertyState, null, animate);
};

// Convenience method.  Sets up the volume viewer for 2D slice-based viewing.
VolumeViewer.prototype.show2DView = function(interpolate=true) {
    let propertyState = this.get2DViewPropertyState();
    if (!propertyState)  return;

    delete propertyState.RotationMatrix;
    delete propertyState.Zoom;
    delete propertyState.ZoomLinear;

    // STM_TODO - hack
    let orientation = this.anatomicalOrientation;
    if (!orientation)  orientation = VolumeViewer.getOrientationFromFace(Volume.FACE_INFERIOR);

    propertyState.RotationMatrix = this.generateRotationFromOrientation(orientation);

    let newRotationMatrix = propertyState.RotationMatrix || this.getRotationMatrix();
    let newZoom           = this.calculateZoomToFit(newRotationMatrix);
    let newZoomLinear     = VolumeViewer.zoomScaledToLinear(newZoom);
    propertyState.RotationMatrix = newRotationMatrix;
    propertyState.ZoomLinear     = newZoomLinear;

    propertyState.Interpolation    = interpolate;
    propertyState.PlaneSnapToVoxel = !interpolate;

    const animate = false;
    this.animateToPropertyState(propertyState, null, animate);
};

// Adds a callback that is invoked when a new volume is loaded and processed.
VolumeViewer.prototype.addVolumeProcessedCallback = function(callback) {
    VolumeViewer.addCallback(this.volumeProcessedCallbacks, callback);
};

// Clears all volume processed callbacks from the viewer.
VolumeViewer.prototype.clearVolumeProcessedCallbacks = function() {
    VolumeViewer.clearCallbacks(this.volumeProcessedCallbacks);
};

// Adds a callback that is invoked when a new volume is set.
VolumeViewer.prototype.addVolumeSetCallback = function(callback) {
    VolumeViewer.addCallback(this.volumeSetCallbacks, callback);
};

// Clears all volume set callbacks from the viewer.
VolumeViewer.prototype.clearVolumeSetCallbacks = function() {
    VolumeViewer.clearCallbacks(this.volumeSetCallbacks);
};

// Adds a callback that is invoked just before rendering a volume.
VolumeViewer.prototype.addPreRenderCallback = function(callback) {
    VolumeViewer.addCallback(this.preRenderCallbacks, callback);
};

// Clears all pre-render callbacks from the viewer.
VolumeViewer.prototype.clearPreRenderCallbacks = function() {
    VolumeViewer.clearCallbacks(this.preRenderCallbacks);
};

// Adds a callback that is invoked just after rendering a volume.
VolumeViewer.prototype.addPostRenderCallback = function(callback) {
    VolumeViewer.addCallback(this.postRenderCallbacks, callback);
};

// Clears all post-render callbacks from the viewer.
VolumeViewer.prototype.clearPostRenderCallbacks = function() {
    VolumeViewer.clearCallbacks(this.postRenderCallbacks);
};

// Adds a callback that is invoked when an action is performed by the user.
VolumeViewer.prototype.addUserActionCallback = function(callback) {
    VolumeViewer.addCallback(this.userActionCallbacks, callback);
};

// Clears all user action callbacks from the viewer.
VolumeViewer.prototype.clearUserActionCallbacks = function() {
    VolumeViewer.clearCallbacks(this.userActionCallbacks);
};

// Adds a callback that is invoked when an action is performed programmatically.
VolumeViewer.prototype.addProgramActionCallback = function(callback) {
    VolumeViewer.addCallback(this.programActionCallbacks, callback);
};

// Clears all program action callbacks from the viewer.
VolumeViewer.prototype.clearProgramActionCallbacks = function() {
    VolumeViewer.clearCallbacks(this.programActionCallbacks);
};

// Adds a callback that is invoked whenever any action is performed,
// whether by the user or programmatically.
VolumeViewer.prototype.addActionCallback = function(callback) {
    this.addUserActionCallback(callback);
    this.addProgramActionCallback(callback);
};

// Clears all action callbacks from the viewer.
VolumeViewer.prototype.clearActionCallbacks = function() {
    this.clearUserActionCallbacks();
    this.clearProgramActionCallbacks();
};

// Clears all callbacks of any kind from the viewer.
VolumeViewer.prototype.clearAllCallbacks = function() {
    this.clearVolumeProcessedCallbacks();
    this.clearVolumeSetCallbacks();
    this.clearPreRenderCallbacks();
    this.clearPostRenderCallbacks();
    this.clearActionCallbacks();
};

// Links this viewer to another.  Linked viewers will share processed volume
// and scaling data.  This allows multiple views for the same volume data
// and makes processing of these volumes more efficient.
VolumeViewer.prototype.linkViewer = function(viewer) {
    // Sanity check
    if (!this.gl || !this.canvas)  return;

    if (viewer !== undefined && viewer !== null) {
        if (viewer === this) {
            dconsole.warn("Cannot link a viewer to itself.");
            return;
        }
        if (viewer.linkedViewer !== null) {
            dconsole.warn("Chains of linked viewers are not allowed.");
            return;
        }
    }

    if (this.linkedViewer !== viewer) {
        this.unlinkViewer();

        if (viewer) {
            // Add this viewer to the parent directly
            const slaveViewers = viewer.slaveViewers;
            if (slaveViewers !== undefined && slaveViewers !== null &&
                !(this in slaveViewers))
                slaveViewers.push(this);
        }

        this.linkedViewer = viewer;

        this.volume = null;
        this.volumeName = null;
        this.volumeProcessed = null;

        this.invalidate();
        this.invalidateOverlays();
    }
};

// Unlinks this viewer.  After this method is called, the viewer will store
// its own independent volume information.
VolumeViewer.prototype.unlinkViewer = function() {
    if (this.linkedViewer !== null) {
        // Remove this viewer from the parent directly
        const slaveViewers = this.linkedViewer.slaveViewers;
        if (slaveViewers) {
            for (let i=0; i<slaveViewers.length; ++i) {
                if (slaveViewers[i] === this) {
                    slaveViewers.splice(i, 1);
                    break;
                }
            }
        }

        this.linkedViewer = null;

        this.volume = null;
        this.volumeName = null;
        this.volumeProcessed = null;

        this.invalidate();
        this.invalidateOverlays();

        this.invokeVolumeSetCallbacks();
    }
};

// Loads a generated test volume into the viewer.
// This test volume is algorithmically generated, not loaded, and therefore
// doesn't require a server fetch.
// A volumeProcessed callback will be invoked when the volume has been
// loaded and processed.
VolumeViewer.prototype.loadTestVolume = function(size=48, noise=0.0, vcount=1) {
    const gl = this.gl;
    if (!gl) return;

    this.unlinkViewer();

    let volume = Volume.generateTestVolume(size, noise, vcount);

    this.setVolumeRaw(volume);
};

// Loads the specified remote file as a volume into the viewer.
// Requires a server fetch to retrieve the data.  This method is asynchronous
// and the volume data will not be available immediately.
// A volumeProcessed callback will be invoked when the volume has been
// loaded and processed.
VolumeViewer.prototype.loadRemoteVolume = async function(url, type=undefined) {

    return new Promise((resolve, reject) => {
        const gl = this.gl;
        if (!gl) return reject('WebGL was not properly initialized');

        this.unlinkViewer();

        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "arraybuffer";

        dconsole.log("Requested URL " + url);

        const viewer = this;

        type = VolumeViewer.sanitizeFileType(type);
        if (!type)  type = VolumeViewer.getFileTypeFromFilename(url);

        if (type === VolumeViewer.TYPE_NRRD) {
            xhr.onload = function() {
                const arrayBuffer = xhr.response;

                viewer.setVolumeNrrd(arrayBuffer, url);

                dconsole.log("URL processed");
                resolve(viewer);
            };
        }
        else if (type === VolumeViewer.TYPE_NIFTI) {
            xhr.onload = function() {
                const arrayBuffer = xhr.response;

                viewer.setVolumeNifti(arrayBuffer, url);

                dconsole.log("URL processed");
                resolve(viewer);
            };
        }
        else if (type === VolumeViewer.TYPE_DICOM) {
            xhr.onload = function() {
                const arrayBuffer = xhr.response;

                viewer.setVolumeDicom([arrayBuffer], url);

                dconsole.log("URL processed");
                resolve(viewer);
            };
        }
        else {
            let errmsg = "Attempted to load unknown file type: " + url;
            dconsole.warn(errmsg);
            return reject(errmsg);
        }

        return xhr.send(null);
    });
};

// Loads the specified local file as a volume into the viewer.
// This method is asynchronous and the volume data will not be available immediately.
// A volumeProcessed callback will be invoked when the volume has been
// loaded and processed.
VolumeViewer.prototype.loadLocalVolume = async function(filename, type) {

    return new Promise((resolve, reject) => {
        const gl = this.gl;
        if (!gl) return reject('WebGL was not properly initialized');

        this.unlinkViewer();

        const viewer = this;

        let name = filename;
        if (typeof name === 'object')
            name = name.name;

        let fileReader = null;

        type = VolumeViewer.sanitizeFileType(type);
        if (!type)  type = VolumeViewer.getFileTypeFromFilename(name);

        if (type === VolumeViewer.TYPE_NRRD) {
            fileReader = new FileReader();
            fileReader.onloadend = function() {
                const arrayBuffer = fileReader.result;

                viewer.setVolumeNrrd(arrayBuffer, name);

                resolve(viewer);
            };
        }
        else if (type === VolumeViewer.TYPE_NIFTI) {
            fileReader = new FileReader();
            fileReader.onloadend = function() {
                const arrayBuffer = fileReader.result;

                viewer.setVolumeNifti(arrayBuffer, name);

                resolve(viewer);
            };
        }
        else if (type === VolumeViewer.TYPE_DICOM) {
            fileReader = new FileReader();
            fileReader.onloadend = function() {
                const arrayBuffer = fileReader.result;

                viewer.setVolumeDicom([arrayBuffer], name);

                resolve(viewer);
            };
        }
        else {
            let errmsg = "Attempted to load unknown file type: " + name;
            dconsole.warn(errmsg);
            return reject(errmsg);
        }

        dconsole.log('Loading volume: "' + name + '"');

        fileReader.readAsArrayBuffer(filename);
    });
};

// Loads the specified buffer (formatted as a NRRD) as a volume into the viewer.
// A volumeProcessed callback will be invoked when the volume has been
// loaded and processed.
// Returns true on success, or false on failure.
// NOTE: The "bivolume" variable is temporary.  Do not count on it being
// available in the future.  STM_TODO STM_DEMO
VolumeViewer.prototype.setVolumeNrrd = function(arrayBuffer, name=undefined) {
    const gl = this.gl;
    if (!gl) return false;

    if (arrayBuffer === undefined || arrayBuffer === null)  return false;

    this.unlinkViewer();

    // STM_TODO STM_DEMO temp!
    let bivolume = false;
    if (typeof name === 'string')
        bivolume = name.endsWith("_DL.nrrd");

    let volume = Volume.loadVolumeNrrd(arrayBuffer, bivolume);

    if (volume !== null)
        return this.setVolumeRaw(volume, name);

    return false;
};

// Loads the specified buffer (formatted as a NIFTI) as a volume into the viewer.
// A volumeProcessed callback will be invoked when the volume has been
// loaded and processed.
// Returns true on success, or false on failure.
VolumeViewer.prototype.setVolumeNifti = function(arrayBuffer, name=undefined) {
    const gl = this.gl;
    if (!gl) return false;

    if (arrayBuffer === undefined || arrayBuffer === null)  return false;

    this.unlinkViewer();

    let volume = Volume.loadVolumeNifti(arrayBuffer);

    if (volume !== null)
        return this.setVolumeRaw(volume, name);

    return false;
};

// Loads the specified array buffers (formatted as DICOMs) as a volume
// into the viewer.
// A volumeProcessed callback will be invoked when the volume has been
// loaded and processed.
// Returns true on success, or false on failure.
VolumeViewer.prototype.setVolumeDicom = function(arrayBuffers, name=undefined) {
    const gl = this.gl;
    if (!gl) return false;

    if (!arrayBuffers)  return false;

    this.unlinkViewer();

    let volume = Volume.loadVolumeDicom(arrayBuffers);

    if (volume !== null)
        return this.setVolumeRaw(volume, name);

    return false;
};

// Clears the current volume from the viewer.
VolumeViewer.prototype.clearVolume = function() {
    this.unlinkViewer();

    if (this.volume !== null) {
        this.volume          = null;
        this.volumeName      = null;
        this.volumeProcessed = null;

        this.invalidate();

        if (this.annotationOverlay)  this.annotationOverlay.clear();

        this.invokeVolumeSetCallbacks();
    }
};

// Sets the specified volume (formatted as raw data) as a volume into the viewer.
// The data in the volume is copied.
// A volumeProcessed callback will be invoked when the volume has been
// loaded and processed.
// Returns true on success, or false on failure.
VolumeViewer.prototype.setVolume = function(volume, name=undefined) {
    const gl = this.gl;
    if (!gl) return false;

    if (volume === undefined || volume === null)  return false;

    let newVolume = Volume.copyVolumeDeep(volume);

    if (newVolume !== null)
        return this.setVolumeRaw(newVolume, name);

    return false;
};

// Sets the specified volume (formatted as raw data) as a volume in the viewer.
// The data in the volume is not copied.
// A volumeProcessed callback will be invoked when the volume has been
// loaded and processed.
// Returns true on success, or false on failure.
VolumeViewer.prototype.setVolumeRaw = function(volume, name=undefined) {
    const maxTextureSize = this.glMaxTextureSize;

    // Sanity checks
    if (!this.gl || !this.canvas)  return false;
    if (volume === undefined || volume === null)  return false;  // STM_TODO - we should allow null volumes
    if (volume.width < 1 || volume.height < 1 || volume.depth < 1)  return false;
    if (volume.count < 1)  return false;
    if (volume.width > maxTextureSize || volume.height > maxTextureSize ||
        volume.depth > maxTextureSize)  return false;
    if (volume.count > VolumeViewer.MAX_VOLUMES)  return false;
    if (volume.width !== Math.floor(volume.width))  return false;
    if (volume.height !== Math.floor(volume.height))  return false;
    if (volume.depth !== Math.floor(volume.depth))  return false;
    if (volume.count !== Math.floor(volume.count))  return false;
    if (volume.data === undefined || volume.data === null)  return false;

    if (typeof name !== 'string')  name = null;

    this.unlinkViewer();

    if (name !== null)
        dconsole.log("Set volume: \"" + name + "\" (" + volume.width + ", " + volume.height +
                     ", " + volume.depth + ") x " + volume.count);
    else
        dconsole.log("Set volume: (" + volume.width + ", " + volume.height +
                     ", " + volume.depth + ") x " + volume.count);

    if (volume.orientation !== "RPI") {
        dconsole.warn("WARNING: VolumeViewer given an incorrectly oriented volume (is '"+volume.orientation+"', must be 'RPI')!");
    }

    this.volume = Volume.copyVolumeShallow(volume);
    this.volumeName = name;
    this.volumeProcessed = null;

    this.invalidateShader();

    if (this.annotationOverlay)  this.annotationOverlay.clear();

    this.invalidateSlaveViewers();

    this.invokeVolumeSetCallbacks();

    // Invoke volume set callbacks for all slave viewers as well
    const slaveViewers = this.slaveViewers;
    for (let i=0; i<slaveViewers.length; ++i) {
        const viewer = slaveViewers[i];
        if (viewer)
            viewer.invokeVolumeSetCallbacks();
    }

    return !!this.volume;
};

// Returns the current volume to the user.
// Note that the volume is a deep copy of the volume data owned by the viewer.
// The user must call setVolume() to make changes to the volume.
// If no volume is set, null is returned.
VolumeViewer.prototype.getVolume = function() {
    if (this.linkedViewer !== null)  return this.linkedViewer.getVolume();

    return Volume.copyVolumeDeep(this.volume);
};

// Returns the current volume to the user.
// Note that the volume contains the raw data owned by the viewer.
// The data for this volume should not be modified.
// If no volume is set, null is returned.
VolumeViewer.prototype.getVolumeRaw = function() {
    if (this.linkedViewer !== null)  return this.linkedViewer.getVolumeRaw();

    return Volume.copyVolumeShallow(this.volume);
};

// Returns the width, height, depth and count of the volume owned by the
// volume viewer.  Does not copy the volume data or provide it to the user.
VolumeViewer.prototype.getVolumeShape = function() {
    if (this.linkedViewer !== null)  return this.linkedViewer.getVolumeShape();

    if (this.volume === undefined || this.volume === null) {
        return null;
    }

    const volume      = this.volume;
    const width       = volume.width;
    const height      = volume.height;
    const depth       = volume.depth;
    const count       = volume.count;
    const scaleX      = volume.scaleX;
    const scaleY      = volume.scaleY;
    const scaleZ      = volume.scaleZ;
    const orientation = volume.orientation;

    return {
        width:       width,
        height:      height,
        depth:       depth,
        count:       count,
        scaleX:      scaleX,
        scaleY:      scaleY,
        scaleZ:      scaleZ,
        orientation: orientation,
    };
};

// Returns the current name of the volume, or null if the volume has no name.
// This field exists purely for informational purposes.
VolumeViewer.prototype.getVolumeName = function() {
    if (this.linkedViewer !== null)  return this.linkedViewer.getVolumeName();

    return this.volumeName;
};

// Sets the voxel scale for the volume, as individual x, y and z scales.
VolumeViewer.prototype.setScaleXYZ = function(xscale=1.0, yscale=1.0, zscale=1.0) {
    if (this.linkedViewer !== null)  return;

    if (!this.volume)  return;

    if (xscale === undefined || xscale === null)  xscale = this.volume.scaleX;
    if (yscale === undefined || yscale === null)  yscale = this.volume.scaleY;
    if (zscale === undefined || zscale === null)  zscale = this.volume.scaleZ;

    if (xscale === 0.0)  xscale = 1.0;
    if (yscale === 0.0)  yscale = 1.0;
    if (zscale === 0.0)  zscale = 1.0;

    if (this.volume.scaleX !== xscale || this.volume.scaleY !== yscale || this.volume.scaleZ !== zscale) {
        this.volume.scaleX = xscale;
        this.volume.scaleY = yscale;
        this.volume.scaleZ = zscale;

        this.invalidate();
        this.invalidateOverlays();

        this.invalidateSlaveViewers();
    }
};

// Sets the voxel scale for the volume, using a scale object containing
// x, y and z fields.
VolumeViewer.prototype.setScale = function(scale) {
    const xscale = scale.x;
    const yscale = scale.y;
    const zscale = scale.z;

    this.setScaleXYZ(xscale, yscale, zscale);
};

// Gets the voxel scale for the volume.
VolumeViewer.prototype.getScale = function() {
    if (this.linkedViewer !== null)  return this.linkedViewer.getScale();

    if (this.volume) {
        return {
            x: this.volume.scaleX,
            y: this.volume.scaleY,
            z: this.volume.scaleZ
        };
    }
    else {
        return { x: 1.0, y: 1.0, z: 1.0 };
    }
};

// Enables or disables gradient calculations.
VolumeViewer.prototype.enableGradients = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.useGradients !== enable) {
        this.useGradients = enable;

        this.volumeProcessed = null;

        this.invalidateShader();

        this.invalidateSlaveViewers();
    }
};

// Returns true if gradient calculations are enabled.
VolumeViewer.prototype.areGradientsEnabled = function() {
    return this.useGradients;
};

// Enables or disables auto-resizing when a volume is processed.
VolumeViewer.prototype.enableAutoResize = function(enable=true) {
    if (this.linkedViewer !== null)  return;

    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.autoResize !== enable) {
        this.autoResize = enable;

        this.volumeProcessed = null;

        this.invalidate();

        this.invalidateSlaveViewers();
    }
};

// Returns true if auto-upscaling is enabled.
VolumeViewer.prototype.isAutoResizeEnabled = function() {
    if (this.linkedViewer !== null)  return this.linkedViewer.isAutoResizeEnabled();

    return this.autoResize;
};

// Sets the offsets used for resampling a volume.
// A volumeProcessed callback will be invoked when the volume has been
// smoothed.
VolumeViewer.prototype.setResampleOffsets = function(halfGradient=false, resampleOffset=undefined) {
    if (this.linkedViewer !== null)  return;

    if (halfGradient === undefined   || halfGradient === null)    halfGradient   = this.halfGradient;
    if (resampleOffset === undefined || resampleOffset === null)  resampleOffset = this.resampleOffset;

    halfGradient = Boolean(halfGradient);

    if (resampleOffset < -0.5)  resampleOffset = -0.5;
    if (resampleOffset >  0.5)  resampleOffset =  0.5;

    if (this.halfGradient !== halfGradient || this.resampleOffset !== resampleOffset) {

        this.halfGradient   = halfGradient;
        this.resampleOffset = resampleOffset;

        this.volumeProcessed = null;

        this.invalidateShader();

        this.invalidateSlaveViewers();
    }
};

// Gets the offsets used for resampling a volume.
VolumeViewer.prototype.getResampleOffsets = function() {
    if (this.linkedViewer !== null)  return this.linkedViewer.getResampleOffsets();

    return {
        halfGradient:   this.halfGradient,
        resampleOffset: this.resampleOffset
    };
};

// Sets the resampling multipliers used for the volume in the viewer.
// A volumeProcessed callback will be invoked when the volume has been
// resampled.
VolumeViewer.prototype.setResamplingMultipliers = function(widthMultiplier, heightMultiplier, depthMultiplier) {
    if (this.linkedViewer !== null)  return;

    if (widthMultiplier  === undefined || widthMultiplier  === null)  widthMultiplier  = this.widthMultiplier;
    if (heightMultiplier === undefined || heightMultiplier === null)  heightMultiplier = this.heightMultiplier;
    if (depthMultiplier  === undefined || depthMultiplier  === null)  depthMultiplier  = this.depthMultiplier;

    const MAX_MULTIPLIER = 5.00;
    const MIN_MULTIPLIER = 0.01;

    if (widthMultiplier > MAX_MULTIPLIER)   widthMultiplier = MAX_MULTIPLIER;
    if (widthMultiplier < MIN_MULTIPLIER)   widthMultiplier = MIN_MULTIPLIER;

    if (heightMultiplier > MAX_MULTIPLIER)  heightMultiplier = MAX_MULTIPLIER;
    if (heightMultiplier < MIN_MULTIPLIER)  heightMultiplier = MIN_MULTIPLIER;

    if (depthMultiplier > MAX_MULTIPLIER)   depthMultiplier = MAX_MULTIPLIER;
    if (depthMultiplier < MIN_MULTIPLIER)   depthMultiplier = MIN_MULTIPLIER;

    if (this.widthMultiplier  !== widthMultiplier ||
        this.heightMultiplier !== heightMultiplier ||
        this.depthMultiplier  !== depthMultiplier) {

        this.widthMultiplier  = widthMultiplier;
        this.heightMultiplier = heightMultiplier;
        this.depthMultiplier  = depthMultiplier;

        this.volumeProcessed = null;

        this.invalidate();

        this.invalidateSlaveViewers();
    }
};

// Gets the resampling multipliers used for the volume in the viewer.
VolumeViewer.prototype.getResamplingMultipliers = function() {
    if (this.linkedViewer !== null)  return this.linkedViewer.getResamplingMultipliers();

    return {
        widthMultiplier:  this.widthMultiplier,
        heightMultiplier: this.heightMultiplier,
        depthMultiplier:  this.depthMultiplier
    };
};

// Sets the resampling filter used when processing a volume.
// The same filter is used for all axes.
// A volumeProcessed callback will be invoked when the volume has been
// resampled.
VolumeViewer.prototype.setResamplingFilter = function(filter) {
    this.setResamplingFilters(filter, filter, filter);
};

// Sets the resampling filters used when processing a volume.
// Individual filters may be set for each axis.
// A volumeProcessed callback will be invoked when the volume has been
// resampled.
VolumeViewer.prototype.setResamplingFilters = function(xFilter, yFilter, zFilter) {
    if (this.linkedViewer !== null)  return;

    if (xFilter === undefined || xFilter === null)  xFilter = null;
    if (yFilter === undefined || yFilter === null)  yFilter = null;
    if (zFilter === undefined || zFilter === null)  zFilter = null;

    if (this.resamplingXFilter !== xFilter || this.resamplingYFilter !== yFilter ||
        this.resamplingZFilter !== zFilter) {

        this.resamplingXFilter = xFilter;
        this.resamplingYFilter = yFilter;
        this.resamplingZFilter = zFilter;

        this.volumeProcessed = null;

        this.invalidate();

        this.invalidateSlaveViewers();
    }
};

// Sets the default normal and upsampling filters used for resampling
// if no explicit filters are specified.
VolumeViewer.prototype.setDefaultFilters = function(normalFilter, upsampleFilter) {
    if (this.linkedViewer !== null)  return;

    if (this.normalFilter !== normalFilter || this.upsampleFilter !== upsampleFilter) {
        this.normalFilter   = normalFilter;
        this.upsampleFilter = upsampleFilter;

        this.volumeProcessed = null;

        this.invalidate();

        this.invalidateSlaveViewers();
    }
};

// Sets the canvas size to the specified width and height.
VolumeViewer.prototype.setCanvasSize = function(width=undefined, height=undefined, factor=undefined) {
    let canvas = this.canvas;
    if (!canvas)  return;

    const elementWidth = $(canvas).innerWidth();
    const elementHeight = $(canvas).innerHeight();

    if (width === undefined || width === null)
        width = elementWidth;
    if (height === undefined || height === null)
        height = elementHeight;
    if (factor === undefined || factor === null)
        factor = this.canvasFactor;

    const MIN_FACTOR = 0.5;
    const MAX_FACTOR = 16.0;
    if (factor < MIN_FACTOR)  factor = MIN_FACTOR;
    if (factor > MAX_FACTOR)  factor = MAX_FACTOR;

    width  = Math.round(width / factor);
    height = Math.round(height / factor);

    if (width  <= 0.0)  width  = canvas.width;
    if (height <= 0.0)  height = canvas.height;

    if (width  < 1.0)  width  = 1.0;
    if (height < 1.0)  height = 1.0;

    let aspect = width / height;
    let clipWidth  = width;
    let clipHeight = height;
    if (aspect >= 1.0)  clipWidth  = height;  // width / aspect
    else                clipHeight = width;   // height * aspect

    if (clipWidth > this.maxCanvasWidth) {
        clipWidth = this.maxCanvasWidth;
        clipHeight = clipWidth;
    }
    if (clipHeight > this.maxCanvasHeight) {
        clipHeight = this.maxCanvasHeight;
        clipWidth = clipHeight;
    }

    if (aspect >= 1.0)  clipWidth  = clipWidth * aspect;
    else                clipHeight = clipHeight / aspect;

    width  = Math.round(clipWidth);
    height = Math.round(clipHeight);

    if (canvas.width !== width || canvas.height !== height) {
        canvas.width  = width;
        canvas.height = height;

        let xMult = elementWidth / width;
        let yMult = elementHeight / height;

        dconsole.log("Canvas size: (" + width + ", " + height + ") (" +
                     xMult.toFixed(2) + "x, " + yMult.toFixed(2) + "x)");

        // STM_TODO - not sure if we should do this here...
        const canvasOverlay = this.canvasOverlay;
        if (canvasOverlay) {
            canvasOverlay.width  = width;
            canvasOverlay.height = height;
        }

        this.clearCachedViewports();
        this.invalidate();
        this.invalidateOverlays();

        this.invokeActionCallbacks(VolumeViewer.ACTION_VIEWPORT);
    }
};

// Sets the maximum canvas width and height.
VolumeViewer.prototype.setMaxCanvasSize = function(maxCanvasWidth=undefined, maxCanvasHeight=undefined) {
    if (maxCanvasWidth === undefined || maxCanvasWidth === null)
        maxCanvasWidth = this.maxCanvasWidth;
    if (maxCanvasHeight === undefined || maxCanvasHeight === null)
        maxCanvasHeight = this.maxCanvasHeight;

    maxCanvasWidth  = Math.floor(maxCanvasWidth);
    maxCanvasHeight = Math.floor(maxCanvasHeight);

    if (maxCanvasWidth < 16)
        maxCanvasWidth = 16;
    if (maxCanvasHeight < 16)
        maxCanvasHeight = 16;

    if (this.maxCanvasWidth !== maxCanvasWidth || this.maxCanvasHeight !== maxCanvasHeight) {
        this.maxCanvasWidth  = maxCanvasWidth;
        this.maxCanvasHeight = maxCanvasHeight;

        // Do nothing else for now
    }
};

// Sets the default canvas pixel multiplication factor.
// A factor of 1.0 means that the canvas will map 1:1 to the actual screen
// resolution of the canvas DOM element.
// A factor of 2.0 means that pixels will be blown up 2x in each direction,
// and so on.
VolumeViewer.prototype.setCanvasFactor = function(factor=undefined) {
    if (factor === undefined || factor === null)  factor = 1.0;

    const MIN_FACTOR = 0.5;
    const MAX_FACTOR = 16.0;
    if (factor < MIN_FACTOR)  factor = MIN_FACTOR;
    if (factor > MAX_FACTOR)  factor = MAX_FACTOR;

    if (this.canvasFactor !== factor)  {
        this.canvasFactor = factor;

        // Do nothing else for now
    }
};

// Tells the volume viewer to automatically resize the canvas viewport
// when the canvas is resized.
VolumeViewer.prototype.enableAutoCanvasResize = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.autoCanvasResize !== enable) {
        this.autoCanvasResize = enable;

        if (this.autoCanvasResize) {
            if (this.autoCanvasResizeTimer === null) {
                let viewer = this;
                this.autoCanvasResizeTimer = setInterval(function() {
                    viewer.setCanvasSize();
                }, 250);
            }
            this.setCanvasSize();
        }
        else {
            if (this.autoCanvasResizeTimer !== null) {
                clearInterval(this.autoCanvasResizeTimer);
                this.autoCanvasResizeTimer = null;
            }
        }

        this.invalidate();
        this.invalidateOverlays();
    }
};

// Returns true if automatic canvas resizing is enabled.
VolumeViewer.prototype.isAutoCanvasResizeEnabled = function() {
    return this.autoCanvasResize;
};

// Sets the field of view for the viewer, in degrees.
VolumeViewer.prototype.setFov = function(fieldOfView=45.0) {
    if (fieldOfView === undefined || fieldOfView === null)  return;

    if (fieldOfView >= 65.0)  fieldOfView = 65.0;
    if (fieldOfView <= 0.0)   fieldOfView = 0.0;

    if (this.fov !== fieldOfView) {
        this.fov = fieldOfView;

        this.invalidate();
        this.invalidateOverlays();
    }
};

// Gets the field of view for the viewer, in degrees.
VolumeViewer.prototype.getFov = function() {
    return this.fov;
};

// Sets the relative position for the volume, in world space, as individual
// x and y values.
// Similar to setScreenPanXY(), but can change perspective.
// The values can range from -1 to 1 inclusive.
// (0.0, 0.0) represents a centered volume.
VolumeViewer.prototype.setVolumeOffsetXY = function(x, y) {

    if (x === undefined || x === null)  x = this.pan[0];
    if (y === undefined || y === null)  y = this.pan[1];

    // Clamp
    if      (x < -1.0)  x = -1.0;
    else if (x >  1.0)  x =  1.0;
    if      (y < -1.0)  y = -1.0;
    else if (y >  1.0)  y =  1.0;

    if (this.pan[0] !== x || this.pan[1] !== y) {
        this.pan[0] = x;
        this.pan[1] = y;

        this.invalidate();
        this.invalidateOverlays();
    }
};

// Sets the relative position for the volume, in world space, using a position
// object containing x and y fields.
// Similar to setScreenPan(), but can change perspective.
// The values can range from -1 to 1 inclusive.
// (0.0, 0.0) represents a centered volume.
VolumeViewer.prototype.setVolumeOffset = function(position) {
   const x = position.x;
   const y = position.y;

   this.setVolumeOffsetXY(x, y);
};

// Gets the relative position for the volume, in world space, as an object
// containing x and y fields.
// These range from -1.0 to 1.0 inclusive.
// (0.0, 0.0) represents a centered volume.
VolumeViewer.prototype.getVolumeOffset = function() {
    return {
        x: this.pan[0],
        y: this.pan[1]
    };
};

// Clamps the specified screen pan position based on current zoom level.
VolumeViewer.prototype.clampScreenPan = function(x, y) {
    if (x === undefined || x === null)  x = this.screenPan[0];
    if (y === undefined || y === null)  y = this.screenPan[1];

    let aspect = this.getAspectRatio();

    // This number represents the minimum fraction of the image that should
    // be visible in each dimension.
    // 0.0 (0%) means that the image can be scrolled completely off the screen.
    // 1.0 (100%) means that the entire image must remain on the screen.
    const IMAGE_FRAC = 0.5;

    // This number represents the minimum fraction of the screen that
    // should be filled with the image in each dimension.
    // 0.0 (0%) means that the screen may contain none of the image.
    // 1.0 (100%) means that the screen must be filled with the image
    // (if possible at the current zoom level).
    const SCREEN_FRAC = 0.5;

    // Clamp the pan x/y to a reasonable range
    let zoom = this.zoom * 1.53846;  // empirical value - hacky, but pragmatic!
    let panX = x * zoom;
    let panY = y * zoom;
    let maxX = aspect <= 1.0 ? 1.0 : 1.0 / aspect;
    let maxY = aspect >= 1.0 ? 1.0 : 1.0 * aspect;

    // Use the image fraction and screen fraction value
    // (whichever is more permissive) to clamp the position of the image.

    // NOTE: This is approximate.  There is no guarantee that the
    // image being displayed is axis-aligned to the display, or uses an
    // orthogonal projection, or is the same size in all dimensions.
    let clampImageX = (zoom - maxX*(2.0*IMAGE_FRAC-1.0));
    let clampImageY = (zoom - maxY*(2.0*IMAGE_FRAC-1.0));
    if (clampImageX < 0.0)  clampImageX = -clampImageX;
    if (clampImageY < 0.0)  clampImageY = -clampImageY;

    let clampScreenX = ((1.0-2.0*SCREEN_FRAC)*zoom + maxX);
    let clampScreenY = ((1.0-2.0*SCREEN_FRAC)*zoom + maxY);
    if (clampScreenX < 0.0)  clampScreenX = -clampScreenX;
    if (clampScreenY < 0.0)  clampScreenY = -clampScreenY;

    let clampX = Math.max(clampImageX, clampScreenX);
    let clampY = Math.max(clampImageY, clampScreenY);

    // Clamp the image based on our meticulously calculated edge values
    if      (panX >  clampX)  x =  clampX / zoom;
    else if (panX < -clampX)  x = -clampX / zoom;
    if      (panY >  clampY)  y =  clampY / zoom;
    else if (panY < -clampY)  y = -clampY / zoom;

    return { x: x, y: y };
};

// Sets the relative position for the volume, in screen space, as individual
// x and y values.
// Viewport units are used, ranging from -1 to 1 for the visible viewing area.
// (0.0, 0.0) represents a centered image.
VolumeViewer.prototype.setScreenPanXY = function(x, y) {

    if (x === undefined || x === null)  x = this.screenPan[0];
    if (y === undefined || y === null)  y = this.screenPan[1];

    let newScreenPan = this.clampScreenPan(x, y);

    if (this.screenPan[0] !== newScreenPan.x ||
        this.screenPan[1] !== newScreenPan.y) {
        this.screenPan[0] = newScreenPan.x;
        this.screenPan[1] = newScreenPan.y;

        this.invalidate();
        this.invalidateOverlays();

        this.invokeActionCallbacks(VolumeViewer.ACTION_PAN);
    }
};

// Sets the relative position for the volume, in screen space, using a position
// object containing x and y fields.
// Viewport units are used, ranging from -1 to 1 for the visible viewing area.
// (0.0, 0.0) represents a centered image.
VolumeViewer.prototype.setScreenPan = function(position) {
   const x = position.x;
   const y = position.y;

   this.setScreenPanXY(x, y);
};

// Gets the relative position for the volume, in screen space, as an object
// containing x and y fields.
// Viewport units are used, ranging from -1 to 1 for the visible viewing area.
// (0.0, 0.0) represents a centered image.
VolumeViewer.prototype.getScreenPan = function() {
    return {
        x: this.screenPan[0],
        y: this.screenPan[1]
    };
};

// Sets the zoom level for the viewer.
// Levels below 1.0 expand the image, and levels above 1.0 shrink the image.
VolumeViewer.prototype.setZoom = function(zoom) {
    if (zoom === undefined || zoom === null)  return;

    if (zoom < this.minZoom)  zoom = this.minZoom;
    if (zoom > this.maxZoom)  zoom = this.maxZoom;

    if (this.zoom !== zoom) {
        this.zoom = zoom;

        // Re-clamp screen position
        let newScreenPan = this.clampScreenPan();

        let invokePan = false;
        if (this.screenPan[0] !== newScreenPan.x ||
            this.screenPan[1] !== newScreenPan.y) {
            this.screenPan[0] = newScreenPan.x;
            this.screenPan[1] = newScreenPan.y;

            invokePan = true;
        }

        this.invalidate();
        this.invalidateOverlays();

        this.invokeActionCallbacks(VolumeViewer.ACTION_ZOOM);
        if (invokePan)
            this.invokeActionCallbacks(VolumeViewer.ACTION_PAN);
    }
};

// Gets the zoom level for the viewer.
// Levels below 1.0 expand the image, and levels above 1.0 shrink the image.
VolumeViewer.prototype.getZoom = function() {
    return this.zoom;
};

// Sets the minimum and maximum values allowed for zooming.
VolumeViewer.prototype.setZoomRangeMinMax = function(minZoom, maxZoom) {
    if (minZoom === undefined || minZoom === null)  minZoom = this.minZoom;
    if (maxZoom === undefined || maxZoom === null)  maxZoom = this.maxZoom;

    minZoom = Math.abs(minZoom);
    maxZoom = Math.abs(maxZoom);

    const MAX_VALUE = 16.0;
    const MIN_VALUE = 1.0 / MAX_VALUE;

    if (minZoom < MIN_VALUE)  minZoom = MIN_VALUE;
    if (minZoom > MAX_VALUE)  minZoom = MAX_VALUE;
    if (maxZoom < MIN_VALUE)  maxZoom = MIN_VALUE;
    if (maxZoom > MAX_VALUE)  maxZoom = MAX_VALUE;

    if (minZoom > maxZoom) {
        let tempZoom = minZoom;
        minZoom = maxZoom;
        maxZoom = tempZoom;
    }

    if (this.minZoom !== minZoom || this.maxZoom !== maxZoom) {
        this.minZoom = minZoom;
        this.maxZoom = maxZoom;

        this.setZoom(this.zoom);
    }
};

// Sets the minimum and maximum values for zooming, as a range object with
// minValue and maxValue fields.
VolumeViewer.prototype.setZoomRange = function(range) {
    const minValue = range.minValue;
    const maxValue = range.maxValue;

    this.setZoomRangeMinMax(minValue, maxValue);
};

// Returns the minimum and maximum values allowed for zooming,
// as a range object with minValue and maxValue fields.
VolumeViewer.prototype.getZoomRange = function() {
    return {
        minValue: this.minZoom,
        maxValue: this.maxZoom
    };
};

// Sets the zoom level for the viewer using a linear scale.
// 0 represents the default zoom level.
// Negative numbers show the volume zoomed out, and
// positive numbers show the volume zoomed in.
VolumeViewer.prototype.setZoomLinear = function(zoom) {
    if (zoom === undefined || zoom === null)  return;

    let zoomScaled = VolumeViewer.zoomLinearToScaled(zoom);

    this.setZoom(zoomScaled);
};

// Gets the zoom level for the viewer using a linear scale.
// 0 represents the default zoom level.
// Negative numbers show the volume zoomed out, and
// positive numbers show the volume zoomed in.
VolumeViewer.prototype.getZoomLinear = function() {
    let zoomLinear = VolumeViewer.zoomScaledToLinear(this.zoom);
    return zoomLinear;
};

// Sets the minimum and maximum values allowed for zooming, using a linear scale.
VolumeViewer.prototype.setZoomLinearRangeMinMax = function(minZoom, maxZoom) {
    if (minZoom === undefined || minZoom === null)  minZoom = this.minZoom;
    else minZoom = VolumeViewer.zoomLinearToScaled(minZoom);

    if (maxZoom === undefined || maxZoom === null)  maxZoom = this.maxZoom;
    else maxZoom = VolumeViewer.zoomLinearToScaled(maxZoom);

    this.setZoomRangeMinMax(minZoom, maxZoom);
};

// Sets the minimum and maximum values for zooming, as a range object with
// minValue and maxValue fields, using a linear scale.
VolumeViewer.prototype.setZoomLinearRange = function(range) {
    const minValue = range.minValue;
    const maxValue = range.maxValue;

    this.setZoomLinearRangeMinMax(minValue, maxValue);
};

// Returns the minimum and maximum values allowed for zooming,
// as a range object with minValue and maxValue fields,
// using a linear scale.
VolumeViewer.prototype.getZoomLinearRange = function() {
    const minZoom = VolumeViewer.zoomScaledToLinear(this.minZoom);
    const maxZoom = VolumeViewer.zoomScaledToLinear(this.maxZoom);

    return {
        minValue: minZoom,
        maxValue: maxZoom
    };
};

// Moves the viewed volume to the center of the viewport, and sets the zoom
// level such that the volume fills up as much of the viewport as possible.
VolumeViewer.prototype.fitToViewport = function(fraction=undefined, animate=undefined) {
    if (fraction === undefined || fraction === null)  fraction = 1.0;

    animate = this.sanitizeAnimate(animate);

    let newZoom       = this.calculateZoomToFit(null, fraction);
    let newZoomLinear = VolumeViewer.zoomScaledToLinear(newZoom);

    let newPropertyState = {
        ZoomLinear:    newZoomLinear,
        ScreenPan:     VolumeViewer.EMPTY_2D_VECTOR,
        VolumeOffset:  VolumeViewer.EMPTY_2D_VECTOR
    };

    this.animateToPropertyState(newPropertyState, null, animate);

    return true;
};

// Calculates the zoom level that would be needed to show the volume
// filling as much of the viewport window as possible.
// NOTE: this method is HIGHLY DEPENDENT on the following:
//    - The orientation of the volume in the viewer
//    - The aspect ratio of the volume in the viewport
//    - The aspect ratio of the viewport itself
// Rotating the volume may cause portions of the volume to be clipped by
// the viewport.
// If you want to ensure that the volume will not be clipped by the viewport
// even when rotated, use zoom level 1 instead.
VolumeViewer.prototype.calculateZoomToFit = function(rotationMatrix=null, fraction=1.0) {
    if (fraction < 0.05)  fraction = 0.05;
    if (fraction > 5.00)  fraction = 5.00;

    if (rotationMatrix === undefined || rotationMatrix === null)
        rotationMatrix = this.getRotationMatrix();

    let rot = [
        rotationMatrix.m00, rotationMatrix.m01, rotationMatrix.m02, 0.0,
        rotationMatrix.m10, rotationMatrix.m11, rotationMatrix.m12, 0.0,
        rotationMatrix.m20, rotationMatrix.m21, rotationMatrix.m22, 0.0,
        0.0,                0.0,                0.0,                1.0
    ];

    let params = {
        rotationMatrix: rot,
        pivotPoint:     [0.0, 0.0, 0.0],
        pivotMatrix:    [1.0, 0.0, 0.0, 0.0,
                         0.0, 1.0, 0.0, 0.0,
                         0.0, 0.0, 1.0, 0.0,
                         0.0, 0.0, 0.0, 1.0],
        volumeOffset:   [0.0, 0.0],
        screenPan:      [0.0, 0.0],
        zoom:           1.0
    };

    let forwardMatrix = this.generateProjectionMatrix(params);

    let screenPos = vec3.create();

    let extents = this.getVolumeExtents();

    // Get the total extent of the displayed volume in 2D viewport space
    let minX = 0.0, minY = 0.0;
    let maxX = 0.0, maxY = 0.0;
    function expandExtents(x, y, z) {
        vec3.transformMat4(screenPos, [x, y, z], forwardMatrix);
        if (minX === null || minX > screenPos[0])  minX = screenPos[0];
        if (maxX === null || maxX < screenPos[0])  maxX = screenPos[0];
        if (minY === null || minY > screenPos[1])  minY = screenPos[1];
        if (maxY === null || maxY < screenPos[1])  maxY = screenPos[1];
    }
    expandExtents( extents.x,  extents.y,  extents.z);
    expandExtents(-extents.x,  extents.y,  extents.z);
    expandExtents( extents.x, -extents.y,  extents.z);
    expandExtents(-extents.x, -extents.y,  extents.z);
    expandExtents( extents.x,  extents.y, -extents.z);
    expandExtents(-extents.x,  extents.y, -extents.z);
    expandExtents( extents.x, -extents.y, -extents.z);
    expandExtents(-extents.x, -extents.y, -extents.z);

    let zoom   = 1.0;
    let deltaX = maxX - minX;
    let deltaY = maxY - minY;

    // Generate a zoom level that will show the complete extent in the viewport
    const maxsize = 2.0 * fraction;
    if (deltaX > deltaY)  zoom = deltaX / maxsize;
    else                  zoom = deltaY / maxsize;

    return zoom;
};

// Enables or disables linear interpolation in the volume viewer.
VolumeViewer.prototype.enableInterpolation = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.interpolate !== enable) {
        this.interpolate = enable;

        this.releaseTextures();

        this.invalidateShader();
    }
};

// Returns true if linear interpolation is enabled in the viewer.
VolumeViewer.prototype.isInterpolationEnabled = function() {
    return this.interpolate;
};

// Enables or disables gradient rounding in the volume viewer.
VolumeViewer.prototype.enableGradientRounding = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.gradRounding !== enable) {
        this.gradRounding = enable;

        this.volumeProcessed = null;

        this.invalidateShader();

        this.invalidateSlaveViewers();
    }
};

// Returns true if gradient rounding is enabled in the viewer.
VolumeViewer.prototype.isGradientRoundingEnabled = function() {
    return this.gradRounding;
};

// Sets the rotation axis for the volume, using individual x, y and z coordinates.
VolumeViewer.prototype.setRotationAxisXYZ = function(x, y, z) {
    if (x === undefined || x === null)  x = this.rotationAxis ? this.rotationAxis[0] : 0.0;
    if (y === undefined || y === null)  y = this.rotationAxis ? this.rotationAxis[1] : 0.0;
    if (z === undefined || z === null)  z = this.rotationAxis ? this.rotationAxis[2] : 0.0;

    let rotationAxis = VolumeViewer.normalize(x, y, z, 0.0, 0.0, 1.0);

    if (!this.rotationAxis ||
        this.rotationAxis[0] !== rotationAxis[0] || this.rotationAxis[1] !== rotationAxis[1] ||
        this.rotationAxis[2] !== rotationAxis[2]) {

        this.rotationAxis = rotationAxis;

        this.invalidate();
    }
};

// Sets the rotation axis for the volume, as a coordinate object containing
// x, y and z fields.
VolumeViewer.prototype.setRotationAxis = function(rotation) {
    if (rotation !== undefined && rotation !== null) {
        const x = rotation.x;
        const y = rotation.y;
        const z = rotation.z;

        this.setRotationAxisXYZ(x, y, z);
    }
    else {
        this.clearRotationAxis();
    }
};

// Clears the rotation axis for the volume.  After this call, the rotation axis
// will be based on the rotation matrix.
VolumeViewer.prototype.clearRotationAxis = function() {
    if (this.rotationAxis) {
        this.rotationAxis = null;

        this.invalidate();
    }
};

// Gets the rotation axis for the volume, as a coordinate object containing
// x, y and z fields.
VolumeViewer.prototype.getRotationAxis = function() {
    if (this.rotationAxis) {
        return {
            x: this.rotationAxis[0],
            y: this.rotationAxis[1],
            z: this.rotationAxis[2]
        };
    }
    else {
        return {
            x: this.rotationMatrix[1],
            y: this.rotationMatrix[5],
            z: this.rotationMatrix[9]
        };
    }
};

// Sets the pitch axis for the volume, using individual x, y and z coordinates.
VolumeViewer.prototype.setPitchAxisXYZ = function(x, y, z) {
    if (x === undefined || x === null)  x = this.pitchAxis ? this.pitchAxis[0] : 0.0;
    if (y === undefined || y === null)  y = this.pitchAxis ? this.pitchAxis[1] : 0.0;
    if (z === undefined || z === null)  z = this.pitchAxis ? this.pitchAxis[2] : 0.0;

    let pitchAxis = VolumeViewer.normalize(x, y, z, 1.0, 0.0, 0.0);

    if (!this.pitchAxis ||
        this.pitchAxis[0] !== pitchAxis[0] || this.pitchAxis[1] !== pitchAxis[1] ||
        this.pitchAxis[2] !== pitchAxis[2]) {

        this.pitchAxis = pitchAxis;

        this.invalidate();
    }
};

// Sets the pitch axis for the volume, as a coordinate object containing
// x, y and z fields.
VolumeViewer.prototype.setPitchAxis = function(pitch) {
    if (pitch !== undefined && pitch !== null) {
        const x = pitch.x;
        const y = pitch.y;
        const z = pitch.z;

        this.setPitchAxisXYZ(x, y, z);
    }
    else {
        this.clearPitchAxis();
    }
};

// Clears the pitch axis for the volume.  After this call, the pitch axis
// will be based on the rotation matrix.
VolumeViewer.prototype.clearPitchAxis = function() {
    if (this.pitchAxis) {
        this.pitchAxis = null;

        this.invalidate();
    }
};

// Gets the pitch axis for the volume, as a coordinate object containing
// x, y and z fields.
VolumeViewer.prototype.getPitchAxis = function() {
    if (this.pitchAxis) {
        return {
            x: this.pitchAxis[0],
            y: this.pitchAxis[1],
            z: this.pitchAxis[2]
        };
    }
    else {
        return {
            x: this.rotationMatrix[0],
            y: this.rotationMatrix[4],
            z: this.rotationMatrix[8]
        };
    }
};

// Sets the roll axis for the volume, using individual x, y and z coordinates.
VolumeViewer.prototype.setRollAxisXYZ = function(x, y, z) {
    if (x === undefined || x === null)  x = this.rollAxis ? this.rollAxis[0] : 0.0;
    if (y === undefined || y === null)  y = this.rollAxis ? this.rollAxis[1] : 0.0;
    if (z === undefined || z === null)  z = this.rollAxis ? this.rollAxis[2] : 0.0;

    let rollAxis = VolumeViewer.normalize(x, y, z, 0.0, 1.0, 0.0);

    if (!this.rollAxis ||
        this.rollAxis[0] !== rollAxis[0] || this.rollAxis[1] !== rollAxis[1] ||
        this.rollAxis[2] !== rollAxis[2]) {

        this.rollAxis = rollAxis;

        this.invalidate();
    }
};

// Sets the roll axis for the volume, as a coordinate object containing
// x, y and z fields.
VolumeViewer.prototype.setRollAxis = function(roll) {
    if (roll !== undefined && roll !== null) {
        const x = roll.x;
        const y = roll.y;
        const z = roll.z;

        this.setRollAxisXYZ(x, y, z);
    }
    else {
        this.clearRollAxis();
    }
};

// Clears the roll axis for the volume.  After this call, the roll axis
// will be based on the rotation matrix.
VolumeViewer.prototype.clearRollAxis = function() {
    if (this.rollAxis) {
        this.rollAxis = null;

        this.invalidate();
    }
};

// Gets the roll axis for the volume, as a coordinate object containing
// x, y and z fields.
VolumeViewer.prototype.getRollAxis = function() {
    if (this.rollAxis) {
        return {
            x: this.rollAxis[0],
            y: this.rollAxis[1],
            z: this.rollAxis[2]
        };
    }
    else {
        return {
            x: this.rotationMatrix[2],
            y: this.rotationMatrix[6],
            z: this.rotationMatrix[10]
        };
    }
};

// Sets the rotation matrix using individual values representing a 3x3 matrix.
VolumeViewer.prototype.setRotationMatrixValues = function(m00, m01, m02, m10, m11, m12,
                                                          m20, m21, m22) {

    if (m00 === undefined || m00 === null)  m00 = this.rotationMatrix[0];
    if (m01 === undefined || m01 === null)  m01 = this.rotationMatrix[1];
    if (m02 === undefined || m02 === null)  m02 = this.rotationMatrix[2];

    if (m10 === undefined || m10 === null)  m10 = this.rotationMatrix[4];
    if (m11 === undefined || m11 === null)  m11 = this.rotationMatrix[5];
    if (m12 === undefined || m12 === null)  m12 = this.rotationMatrix[6];

    if (m20 === undefined || m20 === null)  m20 = this.rotationMatrix[8];
    if (m21 === undefined || m21 === null)  m21 = this.rotationMatrix[9];
    if (m22 === undefined || m22 === null)  m22 = this.rotationMatrix[10];

    let xAxis = VolumeViewer.normalize(m00, m01, m02, 1.0, 0.0, 0.0);
    let yAxis = VolumeViewer.normalize(m10, m11, m12, 0.0, 1.0, 0.0);
    let zAxis = VolumeViewer.normalize(m20, m21, m22, 0.0, 0.0, 1.0);

    // Ensure that our coordinate system is right-handed
    let tempAxis = vec3.create();
    if (vec3.dot(vec3.cross(tempAxis, xAxis, yAxis), zAxis) < 0)
        vec3.negate(zAxis, zAxis);

    const rotationMatrix = this.rotationMatrix;

    if (rotationMatrix[0]  !== xAxis[0] ||
        rotationMatrix[1]  !== xAxis[1] ||
        rotationMatrix[2]  !== xAxis[2] ||
        rotationMatrix[4]  !== yAxis[0] ||
        rotationMatrix[5]  !== yAxis[1] ||
        rotationMatrix[6]  !== yAxis[2] ||
        rotationMatrix[8]  !== zAxis[0] ||
        rotationMatrix[9]  !== zAxis[1] ||
        rotationMatrix[10] !== zAxis[2]) {

        rotationMatrix[0]  = xAxis[0];
        rotationMatrix[1]  = xAxis[1];
        rotationMatrix[2]  = xAxis[2];
        rotationMatrix[4]  = yAxis[0];
        rotationMatrix[5]  = yAxis[1];
        rotationMatrix[6]  = yAxis[2];
        rotationMatrix[8]  = zAxis[0];
        rotationMatrix[9]  = zAxis[1];
        rotationMatrix[10] = zAxis[2];

        this.invalidate();
        this.invalidateOverlays();

        this.invokeActionCallbacks(VolumeViewer.ACTION_ROTATE);
        if (this.autoPlane && this.canShowPlanes())
            this.invokeActionCallbacks(VolumeViewer.ACTION_PLANE);
    }
};

// Sets the current rotation matrix.
VolumeViewer.prototype.setRotationMatrix = function(matrix) {
    if (!matrix)  matrix = {};

    const m00 = matrix.m00;
    const m01 = matrix.m01;
    const m02 = matrix.m02;

    const m10 = matrix.m10;
    const m11 = matrix.m11;
    const m12 = matrix.m12;

    const m20 = matrix.m20;
    const m21 = matrix.m21;
    const m22 = matrix.m22;

    this.setRotationMatrixValues(m00, m01, m02, m10, m11, m12, m20, m21, m22);
};

// Resets the current rotation matrix.
VolumeViewer.prototype.resetRotationMatrix = function() {
    this.setRotationMatrix(VolumeViewer.IDENTITY_MATRIX);
};

// Gets the current rotation matrix.
VolumeViewer.prototype.getRotationMatrix = function() {
    const rotationMatrix = this.rotationMatrix;

    return {
        m00: rotationMatrix[0],
        m01: rotationMatrix[1],
        m02: rotationMatrix[2],

        m10: rotationMatrix[4],
        m11: rotationMatrix[5],
        m12: rotationMatrix[6],

        m20: rotationMatrix[8],
        m21: rotationMatrix[9],
        m22: rotationMatrix[10]
    };
};

// Rotates the current rotation matrix, using the specified yaw, pitch and roll
// values.
VolumeViewer.prototype.rotateYPR = function(yaw, pitch=undefined, roll=undefined, useAxes=true) {
    if (yaw   === undefined || yaw   === null)  yaw   = 0.0;
    if (pitch === undefined || pitch === null)  pitch = 0.0;
    if (roll  === undefined || roll  === null)  roll  = 0.0;

    const RANGE = 360.0;

    // Force to [0-360) degree range
    yaw   = ((yaw % RANGE) + RANGE) % RANGE;
    pitch = ((pitch % RANGE) + RANGE) % RANGE;
    roll  = ((roll % RANGE) + RANGE) % RANGE;

    if (yaw !== 0.0 || pitch !== 0.0 || roll !== 0.0) {
        // Convert from degrees to radians
        const CONVERT = Math.PI / 180.0;

        const rotationMatrix = mat4.clone(this.rotationMatrix);

        if (roll !== 0.0) {
            roll *= CONVERT;
            const rollAxis = (useAxes && this.rollAxis) ||
                             vec3.fromValues(rotationMatrix[2], rotationMatrix[6], rotationMatrix[10]);
            mat4.rotate(rotationMatrix,  // destination matrix
                        rotationMatrix,  // source matrix to rotate
                        roll,            // amount to rotate (radians)
                        rollAxis);       // axis of rotation (roll)
        }

        if (pitch !== 0.0) {
            pitch *= CONVERT;
            const pitchAxis = (useAxes && this.pitchAxis) ||
                              vec3.fromValues(rotationMatrix[0], rotationMatrix[4], rotationMatrix[8]);
            mat4.rotate(rotationMatrix,  // destination matrix
                        rotationMatrix,  // source matrix to rotate
                        pitch,           // amount to rotate (radians)
                        pitchAxis);      // axis of rotation (pitch)
        }

        if (yaw !== 0.0) {
            yaw *= CONVERT;
            const yawAxis = (useAxes && this.rotationAxis) ||
                            vec3.fromValues(rotationMatrix[1], rotationMatrix[5], rotationMatrix[9]);
            mat4.rotate(rotationMatrix,  // destination matrix
                        rotationMatrix,  // source matrix to rotate
                        yaw,             // amount to rotate (radians)
                        yawAxis);        // axis of rotation (yaw)
        }

        this.setRotationMatrixValues(rotationMatrix[0], rotationMatrix[1], rotationMatrix[2],
                                     rotationMatrix[4], rotationMatrix[5], rotationMatrix[6],
                                     rotationMatrix[8], rotationMatrix[9], rotationMatrix[10]);
    }
};

// Gets the axis that is currently pointing closest to "up" on the display
// (with a bit of "forward" thrown in for good measure).
VolumeViewer.prototype.getUpAxis = function() {
    const rotationMatrix = this.combineMatrices();
    const AXIS_PERCENT = 0.75;
    let upAxis = vec3.fromValues(rotationMatrix[1], rotationMatrix[5], rotationMatrix[9]);
    let frontAxis = vec3.fromValues(rotationMatrix[2], rotationMatrix[6], rotationMatrix[10]);
    upAxis[0] = upAxis[0]*AXIS_PERCENT + frontAxis[0]*(1.0-AXIS_PERCENT);
    upAxis[1] = upAxis[1]*AXIS_PERCENT + frontAxis[1]*(1.0-AXIS_PERCENT);
    upAxis[2] = upAxis[2]*AXIS_PERCENT + frontAxis[2]*(1.0-AXIS_PERCENT);

    let bestVector = vec3.fromValues(1.0, 0.0, 0.0);
    let bestDot    = vec3.dot(upAxis, bestVector);

    let vector, dot;

    vector = vec3.fromValues(0.0, 1.0, 0.0);
    dot    = vec3.dot(upAxis, vector);
    if (Math.abs(bestDot) < Math.abs(dot)) {
        bestVector = vector;
        bestDot    = dot;
    }

    vector = vec3.fromValues(0.0, 0.0, 1.0);
    dot    = vec3.dot(upAxis, vector);
    if (Math.abs(bestDot) < Math.abs(dot)) {
        bestVector = vector;
        bestDot    = dot;
    }

    if (bestDot < 0.0)
        vec3.negate(bestVector, bestVector);

    return {
        x: bestVector[0],
        y: bestVector[1],
        z: bestVector[2]
    };
};

// Sets appropriate rotation, pitch and roll axes for the viewer, based on
// the volume's current orientation.
VolumeViewer.prototype.setRotationAxesFromOrientation = function() {
    const rotationMatrix = this.combineMatrices();
    const AXIS_PERCENT = 0.75;
    let upAxis    = vec3.fromValues(rotationMatrix[1], rotationMatrix[5], rotationMatrix[9]);
    let frontAxis = vec3.fromValues(rotationMatrix[2], rotationMatrix[6], rotationMatrix[10]);
    let sideAxis  = vec3.fromValues(rotationMatrix[0], rotationMatrix[4], rotationMatrix[8]);
    upAxis[0] = upAxis[0]*AXIS_PERCENT + frontAxis[0]*(1.0-AXIS_PERCENT);
    upAxis[1] = upAxis[1]*AXIS_PERCENT + frontAxis[1]*(1.0-AXIS_PERCENT);
    upAxis[2] = upAxis[2]*AXIS_PERCENT + frontAxis[2]*(1.0-AXIS_PERCENT);

    frontAxis[0] = frontAxis[0]*AXIS_PERCENT + sideAxis[0]*(1.0-AXIS_PERCENT);
    frontAxis[1] = frontAxis[1]*AXIS_PERCENT + sideAxis[1]*(1.0-AXIS_PERCENT);
    frontAxis[2] = frontAxis[2]*AXIS_PERCENT + sideAxis[2]*(1.0-AXIS_PERCENT);

    vec3.cross(sideAxis, upAxis, frontAxis);

    let basisArray = [
        vec3.fromValues(1.0, 0.0, 0.0),
        vec3.fromValues(0.0, 1.0, 0.0),
        vec3.fromValues(0.0, 0.0, 1.0)
    ];

    function sortByAxis(startIndex, compareAxis) {
        let bestIndex = null;
        let bestDot   = -2.0;
        for (let i=startIndex; i<3; ++i) {
            let dot = vec3.dot(compareAxis, basisArray[i]);
            if (bestIndex === null || Math.abs(bestDot) < Math.abs(dot)) {
                bestIndex = i;
                bestDot   = dot;
            }
        }
        if (bestDot < 0.0)
            vec3.negate(basisArray[bestIndex], basisArray[bestIndex]);
        if (bestIndex !== startIndex) {
            let temp = basisArray[startIndex];
            basisArray[startIndex] = basisArray[bestIndex];
            basisArray[bestIndex] = temp;
        }
    }

    sortByAxis(0, upAxis);
    sortByAxis(1, frontAxis);
    sortByAxis(2, sideAxis);

    this.setRotationAxisXYZ(basisArray[0][0], basisArray[0][1], basisArray[0][2]);
    this.setRollAxisXYZ(basisArray[1][0], basisArray[1][1], basisArray[1][2]);
    this.setPitchAxisXYZ(basisArray[2][0], basisArray[2][1], basisArray[2][2]);
};

// Sets the background color for the viewer, using individual red, green, blue
// and alpha values.  All values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setBackgroundColorRGBA = function(red, green, blue, alpha=1.0) {
    if (red === undefined   || red === null)    red   = this.backgroundColor[0];
    if (green === undefined || green === null)  green = this.backgroundColor[1];
    if (blue === undefined  || blue === null)   blue  = this.backgroundColor[2];
    if (alpha === undefined || alpha === null)  alpha = this.backgroundColor[3];

    if (red >= 1.0)    red = 1.0;
    if (red <= 0.0)    red = 0.0;

    if (green >= 1.0)  green = 1.0;
    if (green <= 0.0)  green = 0.0;

    if (blue >= 1.0)   blue = 1.0;
    if (blue <= 0.0)   blue = 0.0;

    if (alpha >= 1.0)  alpha = 1.0;
    if (alpha <= 0.0)  alpha = 0.0;

    if (this.backgroundColor[0] !== red || this.backgroundColor[1] !== green ||
        this.backgroundColor[2] !== blue || this.backgroundColor[3] !== alpha) {

        this.backgroundColor = [red, green, blue, alpha];

        let background = this.getCurrentBackgroundColor();

        let str = VolumeViewer.buildColorString(background);
        if (!str)  str = "black";

        $(this.topLevel).css("background-color", str);

        this.invalidate();
    }
};

// Sets the background color for the viewer, as a color object with red, green, blue
// and alpha fields.  All values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setBackgroundColor = function(color) {
    const red   = color.red;
    const green = color.green;
    const blue  = color.blue;
    const alpha = color.alpha;

    this.setBackgroundColorRGBA(red, green, blue, alpha);
};

// Gets the background color for the viewer, as a color object with red, green, blue
// and alpha fields.  All values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.getBackgroundColor = function() {
    return {
        red:   this.backgroundColor[0],
        green: this.backgroundColor[1],
        blue:  this.backgroundColor[2],
        alpha: this.backgroundColor[3]
    };
};

// Enables or disables border drawing for MPR planes.
VolumeViewer.prototype.enablePlaneBorders = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.showPlaneBorders !== enable) {
        this.showPlaneBorders = enable;

        this.invalidateShader();
    }
};

// Returns true if border drawing for MPR planes is enabled.
VolumeViewer.prototype.arePlaneBordersEnabled = function() {
    return this.showPlaneBorders;
};

// Sets the plane border color for a plane in the viewer, using individual red, green, blue
// and alpha values.  All color values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setPlaneBorderColorRGBA = function(planeNum, red, green, blue, alpha=1.0) {
    if (planeNum === undefined || planeNum === null)  return;
    if (planeNum < 1 || planeNum > 3)  return;

    // Plane numbers are one-based
    --planeNum;

    if (red === undefined   || red === null)    red   = this.planeBorderColors[planeNum][0];
    if (green === undefined || green === null)  green = this.planeBorderColors[planeNum][1];
    if (blue === undefined  || blue === null)   blue  = this.planeBorderColors[planeNum][2];
    if (alpha === undefined || alpha === null)  alpha = this.planeBorderColors[planeNum][3];

    if (red >= 1.0)    red = 1.0;
    if (red <= 0.0)    red = 0.0;

    if (green >= 1.0)  green = 1.0;
    if (green <= 0.0)  green = 0.0;

    if (blue >= 1.0)   blue = 1.0;
    if (blue <= 0.0)   blue = 0.0;

    if (alpha >= 1.0)  alpha = 1.0;
    if (alpha <= 0.0)  alpha = 0.0;

    if (this.planeBorderColors[planeNum][0] !== red ||
        this.planeBorderColors[planeNum][1] !== green ||
        this.planeBorderColors[planeNum][2] !== blue ||
        this.planeBorderColors[planeNum][3] !== alpha) {

        this.planeBorderColors[planeNum] = [red, green, blue, alpha];

        this.invalidate();
    }
};

// Sets the plane border color for a plane in the viewer, as a color object with red, green, blue
// and alpha fields.  All color values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setPlaneBorderColor = function(planeNum, color) {
    const red   = color.red;
    const green = color.green;
    const blue  = color.blue;
    const alpha = color.alpha;

    this.setPlaneBorderColorRGBA(planeNum, red, green, blue, alpha);
};

// Gets the plane border color for a plane in the viewer, as a color object with red, green, blue
// and alpha fields.  All color values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.getPlaneBorderColor = function(planeNum) {
    if (planeNum === undefined || planeNum === null)  return null;
    if (planeNum < 1 || planeNum > 3)  return null;

    // Plane numbers are one-based
    --planeNum;

    return {
        red:   this.planeBorderColors[planeNum][0],
        green: this.planeBorderColors[planeNum][1],
        blue:  this.planeBorderColors[planeNum][2],
        alpha: this.planeBorderColors[planeNum][3]
    };
};

// Sets the plane border colors for all planes in the viewer,
// as an array of color objects with red, green, blue and alpha fields.
// All color values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setPlaneBorderColors = function(colorArray) {
    if (colorArray === undefined || colorArray === null)  return;

    if (colorArray.length > 0)  this.setPlaneBorderColor(1, colorArray[0]);
    if (colorArray.length > 1)  this.setPlaneBorderColor(2, colorArray[1]);
    if (colorArray.length > 2)  this.setPlaneBorderColor(3, colorArray[2]);
};

// Gets the plane border colors for all planes in the viewer,
// as an array of color objects with red, green, blue and alpha fields.
// All color values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.getPlaneBorderColors = function() {
    return [
        this.getPlaneBorderColor(1),
        this.getPlaneBorderColor(2),
        this.getPlaneBorderColor(3)
    ];
};

// Sets the line width of plane borders when drawing in MPR mode.
VolumeViewer.prototype.setPlaneBorderLineWidth = function(lineWidth) {
    if (lineWidth === null || lineWidth === undefined)  lineWidth = 0.0;

    if (lineWidth > 20.0)  lineWidth = 20.0;
    if (lineWidth <  0.5)  lineWidth =  0.5;

    if (this.planeBorderLineWidth !== lineWidth) {
        this.planeBorderLineWidth = lineWidth;

        this.invalidate();
    }
};

// Gets the line width of plane borders when drawing in MPR mode.
VolumeViewer.prototype.getPlaneBorderLineWidth = function() {
    return this.planeBorderLineWidth;
};

// Enables or disables plane intersection drawing for MPR planes.
VolumeViewer.prototype.enablePlaneIntersections = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.showPlaneIntersections !== enable) {
        this.showPlaneIntersections = enable;

        this.invalidateShader();
    }
};

// Returns true if plane intersection drawing for MPR planes is enabled.
VolumeViewer.prototype.arePlaneIntersectionsEnabled = function() {
    return this.showPlaneIntersections;
};

// Sets the plane intersection color for a plane in the viewer, using individual red, green, blue
// and alpha values.  All color values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setPlaneIntersectionColorRGBA = function(planeNum, red, green, blue, alpha=1.0) {
    if (planeNum === undefined || planeNum === null)  return;
    if (planeNum < 1 || planeNum > 3)  return;

    // Plane numbers are one-based
    --planeNum;

    if (red === undefined   || red === null)    red   = this.planeIntersectionColors[planeNum][0];
    if (green === undefined || green === null)  green = this.planeIntersectionColors[planeNum][1];
    if (blue === undefined  || blue === null)   blue  = this.planeIntersectionColors[planeNum][2];
    if (alpha === undefined || alpha === null)  alpha = this.planeIntersectionColors[planeNum][3];

    if (red >= 1.0)    red = 1.0;
    if (red <= 0.0)    red = 0.0;

    if (green >= 1.0)  green = 1.0;
    if (green <= 0.0)  green = 0.0;

    if (blue >= 1.0)   blue = 1.0;
    if (blue <= 0.0)   blue = 0.0;

    if (alpha >= 1.0)  alpha = 1.0;
    if (alpha <= 0.0)  alpha = 0.0;

    if (this.planeIntersectionColors[planeNum][0] !== red ||
        this.planeIntersectionColors[planeNum][1] !== green ||
        this.planeIntersectionColors[planeNum][2] !== blue ||
        this.planeIntersectionColors[planeNum][3] !== alpha) {

        this.planeIntersectionColors[planeNum] = [red, green, blue, alpha];

        this.invalidate();
    }
};

// Sets the plane intersection color for a plane in the viewer, as a color object with red, green, blue
// and alpha fields.  All color values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setPlaneIntersectionColor = function(planeNum, color) {
    const red   = color.red;
    const green = color.green;
    const blue  = color.blue;
    const alpha = color.alpha;

    this.setPlaneIntersectionColorRGBA(planeNum, red, green, blue, alpha);
};

// Gets the plane intersection color for a plane in the viewer, as a color object with red, green, blue
// and alpha fields.  All color values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.getPlaneIntersectionColor = function(planeNum) {
    if (planeNum === undefined || planeNum === null)  return null;
    if (planeNum < 1 || planeNum > 3)  return null;

    // Plane numbers are one-based
    --planeNum;

    return {
        red:   this.planeIntersectionColors[planeNum][0],
        green: this.planeIntersectionColors[planeNum][1],
        blue:  this.planeIntersectionColors[planeNum][2],
        alpha: this.planeIntersectionColors[planeNum][3]
    };
};

// Sets the plane intersection colors for all planes in the viewer,
// as an array of color objects with red, green, blue and alpha fields.
// All color values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setPlaneIntersectionColors = function(colorArray) {
    if (colorArray === undefined || colorArray === null)  return;

    if (colorArray.length > 0)  this.setPlaneIntersectionColor(1, colorArray[0]);
    if (colorArray.length > 1)  this.setPlaneIntersectionColor(2, colorArray[1]);
    if (colorArray.length > 2)  this.setPlaneIntersectionColor(3, colorArray[2]);
};

// Gets the plane intersection colors for all planes in the viewer,
// as an array of color objects with red, green, blue and alpha fields.
// All color values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.getPlaneIntersectionColors = function() {
    return [
        this.getPlaneIntersectionColor(1),
        this.getPlaneIntersectionColor(2),
        this.getPlaneIntersectionColor(3)
    ];
};

// Sets the line width of plane intersections when drawing in MPR mode.
VolumeViewer.prototype.setPlaneIntersectionLineWidth = function(lineWidth) {
    if (lineWidth === null || lineWidth === undefined)  lineWidth = 0.0;

    if (lineWidth > 20.0)  lineWidth = 20.0;
    if (lineWidth <  0.5)  lineWidth =  0.5;

    if (this.planeIntersectionLineWidth !== lineWidth) {
        this.planeIntersectionLineWidth = lineWidth;

        this.invalidate();
    }
};

// Gets the line width of plane intersections when drawing in MPR mode.
VolumeViewer.prototype.getPlaneIntersectionLineWidth = function() {
    return this.planeIntersectionLineWidth;
};

// Enables or disables true line drawing for MPR planes.
// (This looks better, but is also slower to render and compile.)
VolumeViewer.prototype.enableTrueLines = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.drawTrueLines !== enable) {
        this.drawTrueLines = enable;

        this.invalidateShader();
    }
};

// Returns true if true line drawing for MPR planes is enabled.
VolumeViewer.prototype.areTrueLinesEnabled = function() {
    return this.drawTrueLines;
};

// Sets the background color for the viewer in stereo mode, using individual red, green, blue
// and alpha values.  All values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setStereoBackgroundColorRGBA = function(red, green, blue, alpha=1.0) {
    let oldBackgroundColor = this.stereoBackgroundColor;
    if (oldBackgroundColor === null)
        oldBackgroundColor = this.backgroundColor;

    if (red === undefined   || red === null)    red   = oldBackgroundColor[0];
    if (green === undefined || green === null)  green = oldBackgroundColor[1];
    if (blue === undefined  || blue === null)   blue  = oldBackgroundColor[2];
    if (alpha === undefined || alpha === null)  alpha = oldBackgroundColor[3];

    if (red >= 1.0)    red = 1.0;
    if (red <= 0.0)    red = 0.0;

    if (green >= 1.0)  green = 1.0;
    if (green <= 0.0)  green = 0.0;

    if (blue >= 1.0)   blue = 1.0;
    if (blue <= 0.0)   blue = 0.0;

    if (alpha >= 1.0)  alpha = 1.0;
    if (alpha <= 0.0)  alpha = 0.0;

    if (this.stereoBackgroundColor === null ||
        this.stereoBackgroundColor[0] !== red || this.stereoBackgroundColor[1] !== green ||
        this.stereoBackgroundColor[2] !== blue || this.stereoBackgroundColor[3] !== alpha) {

        this.stereoBackgroundColor = [red, green, blue, alpha];

        this.updateBackgroundCss();

        this.invalidate();
    }
};

// Sets the background color for the viewer in stereo mode, as a color object with red, green, blue
// and alpha fields.  All values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setStereoBackgroundColor = function(color) {
    if (color === null || color === undefined) {
        this.resetStereoBackgroundColor();
        return;
    }

    const red   = color.red;
    const green = color.green;
    const blue  = color.blue;
    const alpha = color.alpha;

    this.setStereoBackgroundColorRGBA(red, green, blue, alpha);
};

// Resets the background color for the viewer in stereo mode.
// This makes the viewer use the normal background color instead of the
// stereo background color.
VolumeViewer.prototype.resetStereoBackgroundColor = function() {
    if (this.stereoBackgroundColor !== null) {
        this.stereoBackgroundColor = null;

        this.invalidate();
    }
};

// Gets the background color for the viewer in stereo mode,
// as a color object with red, green, blue and alpha fields.
// All values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.getStereoBackgroundColor = function() {
    if (this.stereoBackgroundColor !== null) {
        return {
            red:   this.stereoBackgroundColor[0],
            green: this.stereoBackgroundColor[1],
            blue:  this.stereoBackgroundColor[2],
            alpha: this.stereoBackgroundColor[3]
        };
    }
    else {
        return null;
    }
};

// Sets the border color for the viewer in stereo mode, using individual red, green, blue
// and alpha values.  All values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setStereoBorderColorRGBA = function(red, green, blue, alpha=1.0) {
    if (red === undefined   || red === null)    red   = this.stereoBorderColor[0];
    if (green === undefined || green === null)  green = this.stereoBorderColor[1];
    if (blue === undefined  || blue === null)   blue  = this.stereoBorderColor[2];
    if (alpha === undefined || alpha === null)  alpha = this.stereoBorderColor[3];

    if (red >= 1.0)    red = 1.0;
    if (red <= 0.0)    red = 0.0;

    if (green >= 1.0)  green = 1.0;
    if (green <= 0.0)  green = 0.0;

    if (blue >= 1.0)   blue = 1.0;
    if (blue <= 0.0)   blue = 0.0;

    if (alpha >= 1.0)  alpha = 1.0;
    if (alpha <= 0.0)  alpha = 0.0;

    if (this.stereoBorderColor[0] !== red || this.stereoBorderColor[1] !== green ||
        this.stereoBorderColor[2] !== blue || this.stereoBorderColor[3] !== alpha) {

        this.stereoBorderColor = [red, green, blue, alpha];

        this.invalidate();
    }
};

// Sets the border color for the viewer in stereo mode, as a color object with red, green, blue
// and alpha fields.  All values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setStereoBorderColor = function(color) {
    const red   = color.red;
    const green = color.green;
    const blue  = color.blue;
    const alpha = color.alpha;

    this.setStereoBorderColorRGBA(red, green, blue, alpha);
};

// Gets the border color for the viewer in stereo mode,
// as a color object with red, green, blue and alpha fields.
// All values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.getStereoBorderColor = function() {
    return {
        red:   this.stereoBorderColor[0],
        green: this.stereoBorderColor[1],
        blue:  this.stereoBorderColor[2],
        alpha: this.stereoBorderColor[3]
    };
};

// Sets the border size drawn around the volume in stereo mode.
VolumeViewer.prototype.setStereoBorderSize = function(borderSize) {
    if (borderSize === null || borderSize === undefined)  borderSize = 0.0;

    borderSize = Math.round(borderSize);

    if (borderSize > 16.0)  borderSize = 16.0;
    if (borderSize <  0.0)  borderSize =  0.0;

    if (this.stereoBorderSize !== borderSize) {
        this.stereoBorderSize = borderSize;

        this.invalidate();
    }
};

// Gets the border size drawn around the volume in stereo mode.
VolumeViewer.prototype.getStereoBorderSize = function() {
    return this.stereoBorderSize;
};

// Sets the fade level for the entire 3D image.
// This is always a value between 0.0 and 1.0.
// 0.0 represents a full fadeout to black, and 1.0 represents no fadeout.
VolumeViewer.prototype.setFadeLevel = function(fadeLevel) {
    if (fadeLevel > 1.0)  fadeLevel = 1.0;
    if (fadeLevel < 0.0)  fadeLevel = 0.0;

    if (this.fadeLevel !== fadeLevel) {
        this.fadeLevel = fadeLevel;

        this.invalidate();
    }
};

// Gets the fade level for the entire 3D image.
// This is always a value between 0.0 and 1.0.
// 0.0 represents a full fadeout to black, and 1.0 represents no fadeout.
VolumeViewer.prototype.getFadeLevel = function() {
    return this.fadeLevel;
};

// Gets the fade level for the entire 3D image.
// This is always a value between 0.0 and 1.0.
// 0.0 represents a full fadeout to black, and 1.0 represents no fadeout.
VolumeViewer.prototype.getAmbientLevel = function() {
    return this.fadeLevel;
};

// Sets the ambient lighting level used to light the volume.
// This is always a value between 0.0 and 1.0.
VolumeViewer.prototype.setAmbientLevel = function(ambientLevel) {
    if (ambientLevel > 1.0)  ambientLevel = 1.0;
    if (ambientLevel < 0.0)  ambientLevel = 0.0;

    if (this.ambient !== ambientLevel) {
        this.ambient = ambientLevel;

        this.invalidate();
    }
};

// Gets the ambient lighting level used to light the volume.
// This is always a value between 0.0 and 1.0.
VolumeViewer.prototype.getAmbientLevel = function() {
    return this.ambient;
};

// Sets the fog level for the viewer. Valid values range from 0.0 to 1.0.
// 0.0 means that fog is disabled.
VolumeViewer.prototype.setFogLevel = function(fogLevel) {
    if (fogLevel < 0.0)  fogLevel = 0.0;
    if (fogLevel > 4.0)  fogLevel = 4.0;

    if (this.fogLevel !== fogLevel)  {
        this.fogLevel = fogLevel;

        this.invalidateShader();
    }
};

// Gets the fog level for the viewer. Valid values range from 0.0 to 1.0.
// 0.0 means that fog is disabled.
VolumeViewer.prototype.getFogLevel = function() {
    return this.fogLevel;
};

// Sets the fog starting point.  Valid values range from -1.0 to 1.0.
// 1.0 represents the nearest point in the volume, 0.0 represents
// the center of the volume, and -1.0 represents the farthest point in the
// volume.
VolumeViewer.prototype.setFogStart = function(fogStart) {
    if (fogStart < -1.0)  fogStart = -1.0;
    if (fogStart >  2.0)  fogStart = 2.0;

    if (this.fogStart !== fogStart) {
        this.fogStart = fogStart;

        this.invalidateShader();
    }
};

// Gets the fog starting point.  Valid values range from -1.0 to 1.0.
// 1.0 represents the nearest point in the volume, 0.0 represents
// the center of the volume, and -1.0 represents the farthest point in the
// volume.
VolumeViewer.prototype.getFogStart = function() {
    return this.fogStart;
};

// Sets the diffuse power exponent used to light the volume.
// This is always a value between 0.0 and 1.0, exclusive.
VolumeViewer.prototype.setDiffusePower = function(diffusePow) {
    if (diffusePow > 0.99)  diffusePow = 0.99;
    if (diffusePow < 0.01)  diffusePow = 0.01;

    if (this.diffusePow !== diffusePow) {
        this.diffusePow = diffusePow;

        this.invalidate();
    }
};

// Gets the diffuse power exponent used to light the volume.
// This is always a value between 0.0 and 1.0, exclusive.
VolumeViewer.prototype.getDiffusePower = function() {
    return this.diffusePow;
};

// Enables or disables an outline around the volume in the viewer.
VolumeViewer.prototype.enableOutline = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.showOutline !== enable) {
        this.showOutline = enable;

        this.invalidate();
    }
};

// Returns true if the volume outline is enabled.
VolumeViewer.prototype.isOutlineEnabled = function() {
    return this.showOutline;
};

// Sets the outline color for the viewer, using individual red, green, blue
// and alpha values.  All values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setOutlineColorRGBA = function(red, green, blue, alpha=1.0) {
    if (red === undefined   || red === null)    red   = this.outlineColor[0];
    if (green === undefined || green === null)  green = this.outlineColor[1];
    if (blue === undefined  || blue === null)   blue  = this.outlineColor[2];
    if (alpha === undefined || alpha === null)  alpha = this.outlineColor[3];

    if (red >= 1.0)    red = 1.0;
    if (red <= 0.0)    red = 0.0;

    if (green >= 1.0)  green = 1.0;
    if (green <= 0.0)  green = 0.0;

    if (blue >= 1.0)   blue = 1.0;
    if (blue <= 0.0)   blue = 0.0;

    if (alpha >= 1.0)  alpha = 1.0;
    if (alpha <= 0.0)  alpha = 0.0;

    if (this.outlineColor[0] !== red || this.outlineColor[1] !== green ||
        this.outlineColor[2] !== blue || this.outlineColor[3] !== alpha) {

        this.outlineColor = [red, green, blue, alpha];

        this.invalidate();
    }
};

// Sets the outline color for the viewer, as a color object with red, green, blue
// and alpha fields.  All values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setOutlineColor = function(color) {
    const red   = color.red;
    const green = color.green;
    const blue  = color.blue;
    const alpha = color.alpha;

    this.setOutlineColorRGBA(red, green, blue, alpha);
};

// Gets the outline color for the viewer, as a color object with red, green, blue
// and alpha fields.  All values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.getOutlineColor = function() {
    return {
        red:   this.outlineColor[0],
        green: this.outlineColor[1],
        blue:  this.outlineColor[2],
        alpha: this.outlineColor[3]
    };
};

// Enables or disables an outline around the clipping plane in the viewer.
VolumeViewer.prototype.enableClipOutline = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.showClipOutline !== enable) {
       this.showClipOutline = enable;

        this.invalidate();
    }
};

// Returns true if the volume clip outline is enabled.
VolumeViewer.prototype.isClipOutlineEnabled = function() {
    return this.showClipOutline;
};

// Sets the clip outline color for the viewer, using individual red, green, blue
// and alpha values.  All values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setClipOutlineColorRGBA = function(red, green, blue, alpha=1.0) {
    if (red === undefined   || red === null)    red   = this.clipOutlineColor[0];
    if (green === undefined || green === null)  green = this.clipOutlineColor[1];
    if (blue === undefined  || blue === null)   blue  = this.clipOutlineColor[2];
    if (alpha === undefined || alpha === null)  alpha = this.clipOutlineColor[3];

    if (red >= 1.0)    red = 1.0;
    if (red <= 0.0)    red = 0.0;

    if (green >= 1.0)  green = 1.0;
    if (green <= 0.0)  green = 0.0;

    if (blue >= 1.0)   blue = 1.0;
    if (blue <= 0.0)   blue = 0.0;

    if (alpha >= 1.0)  alpha = 1.0;
    if (alpha <= 0.0)  alpha = 0.0;

    if (this.clipOutlineColor[0] !== red || this.clipOutlineColor[1] !== green ||
        this.clipOutlineColor[2] !== blue || this.clipOutlineColor[3] !== alpha) {

        this.clipOutlineColor = [red, green, blue, alpha];

        this.invalidate();
    }
};

// Sets the clip outline color for the viewer, as a color object with red, green, blue
// and alpha fields.  All values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setClipOutlineColor = function(color) {
    const red   = color.red;
    const green = color.green;
    const blue  = color.blue;
    const alpha = color.alpha;

    this.setClipOutlineColorRGBA(red, green, blue, alpha);
};

// Gets the clip outline color for the viewer, as a color object with red, green, blue
// and alpha fields.  All values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.getClipOutlineColor = function() {
    return {
        red:   this.clipOutlineColor[0],
        green: this.clipOutlineColor[1],
        blue:  this.clipOutlineColor[2],
        alpha: this.clipOutlineColor[3]
    };
};

// Sets the overall luminosity alpha value for the volume.
// Note that this value may be greater than 1.0; it is multiplied directly
// with the alpha values in the luminosity lookup table.
VolumeViewer.prototype.setLuminosityAlpha = function(alpha, index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return;

    if (alpha === undefined || alpha === null)  return;

    if (alpha <= 0.0)  alpha = 0.0;

    if (this.perVolume[index].luminosityAlpha !== alpha) {
        this.perVolume[index].luminosityAlpha = alpha;

        this.invalidateShader();

        // STM_TODO - invoke callback?
    }
};

// Property convenience method.
VolumeViewer.prototype.setLuminosityAlpha0 = function(alpha) {
    return this.setLuminosityAlpha(alpha, 0);
};

// Property convenience method.
VolumeViewer.prototype.setLuminosityAlpha1 = function(alpha) {
    return this.setLuminosityAlpha(alpha, 1);
};

// Gets the overall luminosity alpha value for the volume.
VolumeViewer.prototype.getLuminosityAlpha = function(index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return 0.0;

    return this.perVolume[index].luminosityAlpha;
};

// Property convenience method.
VolumeViewer.prototype.getLuminosityAlpha0 = function() {
    return this.getLuminosityAlpha(0);
};

// Property convenience method.
VolumeViewer.prototype.getLuminosityAlpha1 = function() {
    return this.getLuminosityAlpha(1);
};

// Sets the overall gradient alpha value for the volume.
// Note that this value may be greater than 1.0; it is multiplied directly
// with the alpha values in the gradient lookup table.
VolumeViewer.prototype.setGradientAlpha = function(alpha, index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return;

    if (alpha === undefined || alpha === null)  return;

    //if (alpha >= 1.0)  alpha = 1.0;
    if (alpha <= 0.0)  alpha = 0.0;

    if (this.perVolume[index].gradientAlpha !== alpha) {
        this.perVolume[index].gradientAlpha = alpha;

        this.invalidateShader();

        // STM_TODO - invoke callback?
    }
};

// Property convenience method.
VolumeViewer.prototype.setGradientAlpha0 = function(alpha) {
    return this.setGradientAlpha(alpha, 0);
};

// Property convenience method.
VolumeViewer.prototype.setGradientAlpha1 = function(alpha) {
    return this.setGradientAlpha(alpha, 1);
};

// Gets the overall gradient alpha value for the volume.
VolumeViewer.prototype.getGradientAlpha = function(index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return 0.0;

    return this.perVolume[index].gradientAlpha;
};

// Property convenience method.
VolumeViewer.prototype.getGradientAlpha0 = function() {
    return this.getGradientAlpha(0);
};

// Property convenience method.
VolumeViewer.prototype.getGradientAlpha1 = function() {
    return this.getGradientAlpha(1);
};

// Sets the brightness and luminosity of the volume in a single call.
// Does not affect gradient brightness or contrast.
// Brightness and contrast are normalized to the range 0.0 to 1.0 inclusive.
// This method is similar to, but not quite the same as, the
// setInputWindow() method.
VolumeViewer.prototype.setLuminosityBrightnessAndContrast = function(brightness, contrast, index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return;

    if (brightness === undefined || brightness === null)  brightness = this.getLuminosityBrightness(index);
    if (contrast   === undefined || contrast   === null)  contrast = this.getLuminosityContrast(index);

    if (brightness < 0.0)  brightness = 0.0;
    if (brightness > 1.0)  brightness = 1.0;

    if (contrast < 0.0)  contrast = 0.0;
    if (contrast > 1.0)  contrast = 1.0;

    contrast = Math.pow(contrast, 1.0/VolumeViewer.CONTRAST_POWER);

    const minRadius = VolumeViewer.MIN_INPUT_RADIUS;
    const maxRadius = VolumeViewer.MAX_INPUT_RADIUS;
    const rangeRadius = maxRadius - minRadius;
    let radius = (1.0 - contrast) * rangeRadius + minRadius;

    const minCenter = 0.0 - radius;
    const maxCenter = 1.0 + radius;
    const rangeCenter = maxCenter - minCenter;
    let center = (1.0 - brightness) * rangeCenter + minCenter;

    this.setInputWindow(center, radius, true, index);
};

// Gets the brightness and luminosity for the volume.
// This is similar to getInputWindow(), but returns a brightness/contrast
// instead of a center/radius format.
// Brightness and contrast are correlated with center/radius, but not quite
// the same.
// These will always range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.getLuminosityBrightnessAndContrast = function(index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return null;

    return {
        brightness: this.getLuminosityBrightness(index),
        contrast:   this.getLuminosityContrast(index)
    };
};

// Sets the brightness for the volume.
// This will always range from 0.0 to 1.0 inclusive.
// Does not affect gradients.
VolumeViewer.prototype.setLuminosityBrightness = function(brightness, index=0) {
    this.setLuminosityBrightnessAndContrast(brightness, null, index);
};

// Gets the brightness for the volume.
// This will always range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.getLuminosityBrightness = function(index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return 0.0;

    const center = this.perVolume[index].inputCenter;
    const radius = this.perVolume[index].inputRadius;
    const minValue = 0.0 - radius;
    const maxValue = 1.0 + radius;
    const range = maxValue - minValue;
    let brightness = 1.0 - (center - minValue) / range;

    return brightness;
};

// Sets the contrast for the volume.
// This will always range from 0.0 to 1.0 inclusive.
// Does not affect gradients.
VolumeViewer.prototype.setLuminosityContrast = function(contrast, index=0) {
    this.setLuminosityBrightnessAndContrast(null, contrast, index);
};

// Gets the contrast for the volume.
// This will always range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.getLuminosityContrast = function(index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return 1.0;

    const radius = this.perVolume[index].inputRadius;
    const minValue = VolumeViewer.MIN_INPUT_RADIUS;
    const maxValue = VolumeViewer.MAX_INPUT_RADIUS;
    const range = maxValue - minValue;
    let contrast = 1.0 - (radius - VolumeViewer.MIN_INPUT_RADIUS) / range;

    if (contrast < 0.0)  contrast = 0.0;
    if (contrast > 1.0)  contrast = 1.0;
    contrast = Math.pow(contrast, VolumeViewer.CONTRAST_POWER);

    return contrast;
};

// Sets the input window for the volume.
// This is similar to setLuminosityRange(), but uses a center/radius format
// instead of min/max values.
VolumeViewer.prototype.setInputWindow = function(center, radius, clamp=true, index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return;

    if (center === undefined || center === null)  center = this.perVolume[index].inputCenter;
    if (radius === undefined || radius === null)  radius = this.perVolume[index].inputRadius;

    radius = Math.max(radius, 0.0);

    if (clamp) {
        if (center < VolumeViewer.MIN_INPUT_CENTER)  center = VolumeViewer.MIN_INPUT_CENTER;
        if (center > VolumeViewer.MAX_INPUT_CENTER)  center = VolumeViewer.MAX_INPUT_CENTER;
        if (radius < VolumeViewer.MIN_INPUT_RADIUS)  radius = VolumeViewer.MIN_INPUT_RADIUS;
        if (radius > VolumeViewer.MAX_INPUT_RADIUS)  radius = VolumeViewer.MAX_INPUT_RADIUS;

        if (center+radius < VolumeViewer.MIN_LUMINOSITY)  center = VolumeViewer.MIN_LUMINOSITY - radius;
        if (center-radius > VolumeViewer.MAX_LUMINOSITY)  center = VolumeViewer.MAX_LUMINOSITY + radius;
    }

    if (this.perVolume[index].inputCenter !== center ||
        this.perVolume[index].inputRadius !== radius)  {

        this.perVolume[index].inputCenter = center;
        this.perVolume[index].inputRadius = radius;

        // Directly set the luminosity range
        let clampedRadius = Math.max(radius, 0.0);
        let minValue = center - clampedRadius;
        let maxValue = center + clampedRadius;

        this.perVolume[index].minLuminosity = minValue;
        this.perVolume[index].maxLuminosity = maxValue;

        this.invalidate();

        this.invokeActionCallbacks(VolumeViewer.ACTION_THRESHOLDS);
    }
};

// Gets the input window for the volume.
// This is similar to getLuminosityRange(), but returns a center/radius format
// instead of min/max values.
VolumeViewer.prototype.getInputWindow = function(index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return null;

    return {
        center: this.perVolume[index].inputCenter,
        radius: this.perVolume[index].inputRadius
    };
};

// Sets the minimum and maximum values used for the endpoints of the luminosity
// color lookup table.  These will always range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setLuminosityRangeMinMax = function(minValue, maxValue, index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return;

    if (minValue === undefined || minValue === null)  minValue = this.perVolume[index].minLuminosity;
    if (maxValue === undefined || maxValue === null)  maxValue = this.perVolume[index].maxLuminosity;

    if (minValue > maxValue) {
        const temp = minValue;
        minValue = maxValue;
        maxValue = temp;
    }

    if (maxValue < VolumeViewer.MIN_LUMINOSITY)  maxValue = minValue = VolumeViewer.MIN_LUMINOSITY;
    if (minValue > VolumeViewer.MAX_LUMINOSITY)  minValue = maxValue = VolumeViewer.MAX_LUMINOSITY;

    if (this.perVolume[index].minLuminosity !== minValue ||
        this.perVolume[index].maxLuminosity !== maxValue) {

        this.perVolume[index].minLuminosity = minValue;
        this.perVolume[index].maxLuminosity = maxValue;

        let inputCenter = (maxValue + minValue) * 0.5;
        let inputRadius = (maxValue - minValue) * 0.5;

        this.perVolume[index].inputCenter = inputCenter;
        this.perVolume[index].inputRadius = inputRadius;

        this.invalidate();

        this.invokeActionCallbacks(VolumeViewer.ACTION_THRESHOLDS);
    }
};

// Sets the minimum and maximum values used for the endpoints of the luminosity
// color lookup table, as a range object with minValue and maxValue fields.
// These will always range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setLuminosityRange = function(range, index=0) {
    const minValue = range.minValue;
    const maxValue = range.maxValue;

    this.setLuminosityRangeMinMax(minValue, maxValue, index);
};

// Property convenience method.
VolumeViewer.prototype.setLuminosityRange0 = function(range) {
    return this.setLuminosityRange(range, 0);
};

// Property convenience method.
VolumeViewer.prototype.setLuminosityRange1 = function(range) {
    return this.setLuminosityRange(range, 1);
};

// Sets the value used for the minimum endpoint of the luminosity color
// lookup table.  This will always range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setLuminosityMin = function(minValue, index=0) {
    this.setLuminosityRangeMinMax(minValue, undefined, index);
};

// Sets the value used for the maximum endpoint of the luminosity color
// lookup table.  This will always range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setLuminosityMax = function(maxValue, index=0) {
    this.setLuminosityRangeMinMax(undefined, maxValue, index);
};

// Gets the minimum and maximum values used for the endpoints of the luminosity
// color lookup table, as a range object with minValue and maxValue fields.
// These will always range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.getLuminosityRange = function(index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return null;

    return {
        minValue: this.perVolume[index].minLuminosity,
        maxValue: this.perVolume[index].maxLuminosity
    };
};

// Property convenience method.
VolumeViewer.prototype.getLuminosityRange0 = function() {
    return this.getLuminosityRange(0);
};

// Property convenience method.
VolumeViewer.prototype.getLuminosityRange1 = function() {
    return this.getLuminosityRange(1);
};


// Gets the value used for the minimum endpoint of the luminosity color
// lookup table.  This will always range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.getLuminosityMin = function(index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return 0.0;

    return this.perVolume[index].minLuminosity;
};

// Gets the value used for the maximum endpoint of the luminosity color
// lookup table.  This will always range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.getLuminosityMax = function(index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return 1.0;

    return this.perVolume[index].maxLuminosity;
};

// Sets the minimum and maximum values used for the endpoints of the gradient
// color lookup table.  These will always range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setGradientRangeMinMax = function(minValue, maxValue, index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return;

    if (minValue === undefined || minValue === null)  minValue = this.perVolume[index].minGradientMagnitude;
    if (maxValue === undefined || maxValue === null)  maxValue = this.perVolume[index].maxGradientMagnitude;

    if (minValue >= 1.0)  minValue = 1.0;
    if (minValue <= 0.0)  minValue = 0.0;

    if (maxValue >= 1.0)  maxValue = 1.0;
    if (maxValue <= 0.0)  maxValue = 0.0;

    if (minValue > maxValue) {
        const temp = minValue;
        minValue = maxValue;
        maxValue = temp;
    }

    if (this.perVolume[index].minGradientMagnitude !== minValue || this.perVolume[index].maxGradientMagnitude !== maxValue) {
        this.perVolume[index].minGradientMagnitude = minValue;
        this.perVolume[index].maxGradientMagnitude = maxValue;

        this.invalidate();

        this.invokeActionCallbacks(VolumeViewer.ACTION_THRESHOLDS);
    }
};

// Sets the minimum and maximum values used for the endpoints of the gradient
// color lookup table, as a range object with minValue and maxValue fields.
// These will always range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setGradientRange = function(range, index=0) {
    const minValue = range.minValue;
    const maxValue = range.maxValue;

    this.setGradientRangeMinMax(minValue, maxValue, index);
};

// Property convenience method.
VolumeViewer.prototype.setGradientRange0 = function(range) {
    return this.setGradientRange(range, 0);
};

// Property convenience method.
VolumeViewer.prototype.setGradientRange1 = function(range) {
    return this.setGradientRange(range, 1);
};

// Sets the value used for the minimum endpoint of the gradient color
// lookup table.  This will always range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setGradientMin = function(minValue, index=0) {
    this.setGradientRangeMinMax(minValue, undefined, index);
};

// Sets the value used for the maximum endpoint of the gradient color
// lookup table.  This will always range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setGradientMax = function(maxValue, index=0) {
    this.setGradientRangeMinMax(undefined, maxValue, index);
};

// Gets the minimum and maximum values used for the endpoints of the gradient
// color lookup table, as a range object with minValue and maxValue fields.
// These will always range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.getGradientRange = function(index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return null;

    return {
        minValue: this.perVolume[index].minGradientMagnitude,
        maxValue: this.perVolume[index].maxGradientMagnitude
    };
};

// Property convenience method.
VolumeViewer.prototype.getGradientRange0 = function() {
    return this.getGradientRange(0);
};

// Property convenience method.
VolumeViewer.prototype.getGradientRange1 = function() {
    return this.getGradientRange(1);
};

// Gets the value used for the minimum endpoint of the gradient color
// lookup table.  This will always range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.getGradientMin = function(index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return 0.0;

    return this.perVolume[index].minGradientMagnitude;
};

// Gets the value used for the maximum endpoint of the gradient color
// lookup table.  This will always range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.getGradientMax = function(index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return 1.0;

    return this.perVolume[index].maxGradientMagnitude;
};

// Gets the computed "best" values for the minimum and maximum endpoints
// of the luminosity color lookup table.  These are calculated to clip
// out noise and provide a good dynamic color range.
// These values will always range from 0.0 to 1.0 inclusive.
// This will not reflect the best values for the current volume
// until the volume has been processed (see the volumeProcessed callback
// methods).
VolumeViewer.prototype.getAutoLuminosityRange = function(index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return null;

    return {
        minValue: this.perVolume[index].autoMinLuminosity,
        maxValue: this.perVolume[index].autoMaxLuminosity
    };
};

// Gets the computed "best" value for the minimum endpoint
// of the luminosity color lookup table.  This is calculated to clip
// out noise and provide a good dynamic color range.
// This value will always range from 0.0 to 1.0 inclusive.
// This will not reflect the best value for the current volume
// until the volume has been processed (see the volumeProcessed callback
// methods).
VolumeViewer.prototype.getAutoLuminosityMin = function(index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return 0.0;

    return this.perVolume[index].autoMinLuminosity;
};

// Gets the computed "best" value for the maximum endpoint
// of the luminosity color lookup table.  This is calculated to clip
// out noise and provide a good dynamic color range.
// This value will always range from 0.0 to 1.0 inclusive.
// This will not reflect the best value for the current volume
// until the volume has been processed (see the volumeProcessed callback
// methods).
VolumeViewer.prototype.getAutoLuminosityMax = function(index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return 1.0;

    return this.perVolume[index].autoMaxLuminosity;
};

// Gets the computed "best" values for the minimum and maximum endpoints
// of the gradient color lookup table.  These are calculated to show surfaces in
// high-gradient areas and provide a good dynamic color range.
// These values will always range from 0.0 to 1.0 inclusive.
// This will not reflect the best value for the current volume
// until the volume has been processed (see the volumeProcessed callback
// methods).
VolumeViewer.prototype.getAutoGradientRange = function(index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return null;

    return {
        minValue: this.perVolume[index].autoMinGradientMagnitude,
        maxValue: this.perVolume[index].autoMaxGradientMagnitude
    };
};

// Gets the computed "best" value for the minimum endpoint
// of the gradient color lookup table.  This is calculated to show surfaces in
// high-gradient areas and provide a good dynamic color range.
// This value will always range from 0.0 to 1.0 inclusive.
// This will not reflect the best value for the current volume
// until the volume has been processed (see the volumeProcessed callback
// methods).
VolumeViewer.prototype.getAutoGradientMin = function(index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return 0.0;

    return this.perVolume[index].autoMinGradientMagnitude;
};

// Gets the computed "best" value for the maximum endpoint
// of the gradient color lookup table.  This is calculated to show surfaces in
// high-gradient areas and provide a good dynamic color range.
// This value will always range from 0.0 to 1.0 inclusive.
// This will not reflect the best value for the current volume
// until the volume has been processed (see the volumeProcessed callback
// methods).
VolumeViewer.prototype.getAutoGradientMax = function(index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return 1.0;

    return this.perVolume[index].autoMaxGradientMagnitude;
};

// Sets the noise threshold for the volume.
// The value will always range from 0.0 to 1.0 inclusive.
// Note that this is essentially a convenience function
// for setting the luminosity range using a single value.
VolumeViewer.prototype.setNoiseThreshold = function(threshold, index=0) {
    let maxValue = this.getAutoLuminosityMax(index);

    if (threshold > 1.0)  threshold = 1.0;
    if (threshold < 0.0)  threshold = 0.0;

    let minValue = maxValue * threshold;

    this.setLuminosityRangeMinMax(minValue, maxValue, index);
};

// Gets the noise threshold for the volume.
// The value will always range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.getNoiseThreshold = function(index=0) {
    let maxValue = this.getAutoLuminosityMax(index);
    let minValue = this.getLuminosityMin(index);

    let threshold = 0.0;
    if (maxValue > 0.0) {
        threshold = minValue / maxValue;
        if (threshold > 1.0)  threshold = 1.0;
        if (threshold < 0.0)  threshold = 0.0;
    }

    return threshold;
};

// Sets the surface threshold for the volume.
// The value will always range from 0.0 to 1.0 inclusive.
// Note that this is essentially a convenience function
// for setting the gradient range using a single value.
VolumeViewer.prototype.setSurfaceThreshold = function(threshold, index=0) {
    let maxValue = this.getAutoGradientMax(index);

    if (threshold > 1.0)  threshold = 1.0;
    if (threshold < 0.0)  threshold = 0.0;

    let minValue = maxValue * threshold;

    this.setGradientRangeMinMax(minValue, maxValue, index);
};

// Gets the surface threshold for the volume.
// The value will always range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.getSurfaceThreshold = function(index=0) {
    let maxValue = this.getAutoGradientMax(index);
    let minValue = this.getGradientMin(index);

    let threshold = 0.0;
    if (maxValue > 0.0) {
        threshold = minValue / maxValue;
        if (threshold > 1.0)  threshold = 1.0;
        if (threshold < 0.0)  threshold = 0.0;
    }

    return threshold;
};

// Convenience method to set the auto luminosity and gradient values
// associated with the current volume.
VolumeViewer.prototype.setAutoThresholds = function(index=0) {
    let luminosityRange = this.getAutoLuminosityRange(index);
    let gradientRange   = this.getAutoGradientRange(index);

    this.setLuminosityRange(luminosityRange, index);
    this.setGradientRange(gradientRange, index);
};

// Enables or disables a clipping plane for the volume in the viewer.
VolumeViewer.prototype.enableClipping = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.showClipping !== enable) {
        this.showClipping = enable;

        this.invalidate();

        this.invokeActionCallbacks(VolumeViewer.ACTION_CLIP);
    }
};

// Returns true if a clipping plane is enabled in the viewer.
VolumeViewer.prototype.isClippingEnabled = function() {
    return this.showClipping;
};

// Sets the clip plane normal, as individual x, y and z coordinates.
// These generally range from -1.0 to 1.0 inclusive.
VolumeViewer.prototype.setClipNormalXYZ = function(xnorm, ynorm, znorm) {
    if (xnorm === undefined || xnorm === null)  xnorm = this.clipPlaneNormal[0];
    if (ynorm === undefined || ynorm === null)  ynorm = this.clipPlaneNormal[1];
    if (znorm === undefined || znorm === null)  znorm = this.clipPlaneNormal[2];

    if (this.clipPlaneNormal[0] !== xnorm || this.clipPlaneNormal[1] !== ynorm ||
        this.clipPlaneNormal[2] !== znorm) {

        this.clipPlaneNormal = [xnorm, ynorm, znorm];

        this.invalidate();

        this.invokeActionCallbacks(VolumeViewer.ACTION_CLIP);
    }
};

// Sets the clip plane normal, as a coordinate object containing x, y and z fields.
// These generally range from -1.0 to 1.0 inclusive.
VolumeViewer.prototype.setClipNormal = function(normal) {
    const x = normal.x;
    const y = normal.y;
    const z = normal.z;

    this.setClipNormalXYZ(x, y, z);
};

// Sets the clip plane normal, based on the current rotation matrix.
// The clip plane will directly face the camera.
VolumeViewer.prototype.setClipNormalFromRotation = function() {
    const rotationMatrix = this.combineMatrices();
    const boreAxis = vec3.fromValues(rotationMatrix[2], rotationMatrix[6], rotationMatrix[10]);
    this.setClipNormalXYZ(boreAxis[0], boreAxis[1], boreAxis[2]);
};

// Gets the clip plane normal, as a coordinate object containing x, y and z fields.
// These generally range from -1.0 to 1.0 inclusive.
VolumeViewer.prototype.getClipNormal = function() {
    return {
        x: this.clipPlaneNormal[0],
        y: this.clipPlaneNormal[1],
        z: this.clipPlaneNormal[2]
    };
};

// Clamps the specified clipping offset so it doesn't go too far
// outside the volume's bounding box.
VolumeViewer.prototype.clampClipOffset = function(offset) {
    let volumeExtents = this.getVolumeExtents();

    let normal = this.clipPlaneNormal;
    let point = [offset*normal[0], offset*normal[1], offset*normal[2]];

    let minDist = null;
    let maxDist = null;
    function addToBounds(x, y, z) {
        let delta = [x - point[0], y - point[1], z - point[2]];
        let dot = normal[0]*delta[0] + normal[1]*delta[1] + normal[2]*delta[2];
        if (minDist === null || minDist > dot)  minDist = dot;
        if (maxDist === null || maxDist < dot)  maxDist = dot;
    }
    addToBounds( volumeExtents.x,  volumeExtents.y,  volumeExtents.z);
    addToBounds(-volumeExtents.x,  volumeExtents.y,  volumeExtents.z);
    addToBounds( volumeExtents.x, -volumeExtents.y,  volumeExtents.z);
    addToBounds(-volumeExtents.x, -volumeExtents.y,  volumeExtents.z);
    addToBounds( volumeExtents.x,  volumeExtents.y, -volumeExtents.z);
    addToBounds(-volumeExtents.x,  volumeExtents.y, -volumeExtents.z);
    addToBounds( volumeExtents.x, -volumeExtents.y, -volumeExtents.z);
    addToBounds(-volumeExtents.x, -volumeExtents.y, -volumeExtents.z);

    const DIST = 0.15;
    if (maxDist+DIST < 0.0)  offset += (maxDist+DIST);
    if (minDist-DIST > 0.0)  offset -= (DIST-minDist);

    return offset;
};

// Sets the offset of the clipping plane from the center in the direction
// of the plane normal.
VolumeViewer.prototype.setClipOffset = function(offset) {
    if (offset === undefined || offset === null)  return;

    offset = this.clampClipOffset(offset);

    if (this.clipPlaneOffset !== offset) {
        this.clipPlaneOffset = offset;

        this.invalidate();

        this.invokeActionCallbacks(VolumeViewer.ACTION_CLIP);
    }
};

// Gets the offset of the clipping plane from the center in the direction
// of the plane normal.
VolumeViewer.prototype.getClipOffset = function() {
    return this.clipPlaneOffset;
};

// Enables or disables flattening on the clipping plane surface.
VolumeViewer.prototype.enableClipFlattening = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.showClipFlattening !== enable) {
        this.showClipFlattening = enable;

        this.invalidateShader();
    }
};

// Returns true if flattening on the clipping plane surface
// is enabled in the viewer.
VolumeViewer.prototype.isClipFlatteningEnabled = function() {
    return this.showClipFlattening;
};

// Enables or disables specular highlighting on the clipping plane surface.
// WARNING: This method is obsolescent.  Use setClipSpecularLevel() instead.
VolumeViewer.prototype.enableClipSpecular = function(enable=true) {
    this.setClipSpecularLevel(enable);
};

// Returns true if specular highlighting on the clipping plane surface
// is enabled in the viewer.
// WARNING: This method is obsolescent.  Use getClipSpecularLevel() instead.
VolumeViewer.prototype.isClipSpecularEnabled = function() {
    return this.getClipSpecularLevel() > 0.0;
};

// Sets the specularity on the clip surface of a 3D volume.
// 0 is no specularity, and 1 is maximum specularity.
VolumeViewer.prototype.setClipSpecularLevel = function(specularLevel) {
    if (specularLevel === undefined || specularLevel === null)  return;

    if (typeof specularLevel === 'boolean') {
        if (specularLevel)  specularLevel = 1.0;
        else                specularLevel = 0.0;
    }
    if (specularLevel < 0.0)  specularLevel = 0.0;
    if (specularLevel > 1.0)  specularLevel = 1.0;

    if (this.clipSpecularLevel !== specularLevel)  {
        this.clipSpecularLevel = specularLevel;

        this.invalidate();
    }
};

// Gets the specularity on the clip surface of a 3D volume.
// 0 is no specularity, and 1 is maximum specularity.
VolumeViewer.prototype.getClipSpecularLevel = function() {
    return this.clipSpecularLevel;
};

// Toggles clipping, and sets the clip plane normal, based on the current
// rotation of the volume.  The clip plane will directly face the camera.
VolumeViewer.prototype.toggleClippingFromRotation = function(clipOffset=0.2) {
    let oldEnable = this.isClippingEnabled();
    let oldClip   = this.getClipNormal();
    let oldOffset = this.getClipOffset();

    this.enableClipping();
    this.setClipOffset(clipOffset);
    this.setClipNormalFromRotation();

    let newEnable = this.isClippingEnabled();
    let newClip   = this.getClipNormal();
    let newOffset = this.getClipOffset();

    if (oldEnable === newEnable && oldOffset === newOffset &&
        oldClip.x === newClip.x && oldClip.y === newClip.y &&
        oldClip.z === newClip.z)  {
        this.enableClipping(false);
    }
};

// Enables or disables automatic snapping of MPR planes to
// the center of the nearest voxel.
// This flag only affects the display; it does not change the offsets
// returned by getPlaneOffset() or getPlaneStepOffset().
VolumeViewer.prototype.enablePlaneSnapToVoxel = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.snapToVoxel !== enable) {
        this.snapToVoxel = enable;

        this.invalidate();
    }
};

// Returns true if automatic snapping of MPR planes to
// the center of the nearest voxel is supported.
VolumeViewer.prototype.isPlaneSnapToVoxelEnabled = function() {
    return this.snapToVoxel;
};

// Enables or disables clamping of MPR planes to the interior of the volume.
VolumeViewer.prototype.enablePlaneInteriorClamping = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.planeInteriorClamping !== enable) {
        this.planeInteriorClamping = enable;

        let offset = this.getPlaneOffset();
        this.setPlaneOffset(offset, true);
    }
};

// Returns true if MPR planes should be clamped to the interior of the volume.
VolumeViewer.prototype.isPlaneInteriorClampingEnabled = function() {
    return this.planeInteriorClamping;
};

// Enables or disables clamping of the plane offset to the interior of the volume.
// Slightly different than enablePlaneInteriorClamping(); the latter allows
// the plane offset to be outside the volume, but the planes themselves are
// clamped so they are always visible.
VolumeViewer.prototype.enablePlaneOffsetClamping = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.planeOffsetClamping !== enable) {
        this.planeOffsetClamping = enable;

        this.invalidate();
    }
};

// Returns true if the plane offset should be clamped to the interior of the volume.
VolumeViewer.prototype.isPlaneOffsetClampingEnabled = function() {
    return this.planeOffsetClamping;
};

// Sets the alpha value of the multiplanar display.
// An alpha value of 0.0 will disable the multiplanar display.
VolumeViewer.prototype.setPlaneAlpha = function(alpha, index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return;

    if (alpha === undefined || alpha === null)  return;

    if (alpha >= 1.0)  alpha = 1.0;
    if (alpha <= 0.0)  alpha = 0.0;

    let stateChanged = (this.perVolume[index].planeAlpha > 0.0) ^ (alpha > 0.0);

    if (this.perVolume[index].planeAlpha !== alpha) {
        this.perVolume[index].planeAlpha = alpha;

        this.invalidateShader();
        if (stateChanged)
            this.invalidateOverlays();
    }
};

// Property convenience method.
VolumeViewer.prototype.setPlaneAlpha0 = function(alpha) {
    return this.setPlaneAlpha(alpha, 0);
};

// Property convenience method.
VolumeViewer.prototype.setPlaneAlpha1 = function(alpha) {
    return this.setPlaneAlpha(alpha, 1);
};

// Gets the alpha value of the multiplanar display.
VolumeViewer.prototype.getPlaneAlpha = function(index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return 0.0;

    return this.perVolume[index].planeAlpha;
};

// Property convenience method.
VolumeViewer.prototype.getPlaneAlpha0 = function() {
    return this.getPlaneAlpha(0);
};

// Property convenience method.
VolumeViewer.prototype.getPlaneAlpha1 = function() {
    return this.getPlaneAlpha(1);
};

// Sets the alpha transparency of individual planes.
// This is combined with the overall plane transparency during rendering.
VolumeViewer.prototype.setPlaneAlphaLevels = function(alphaLevels) {
    if (alphaLevels === undefined || alphaLevels === null)  return;
    if (alphaLevels.length < 1)  return;

    let newAlphaLevels = [];
    newAlphaLevels.length = 3;

    for (let i=0; i<3; ++i) {
        newAlphaLevels[i] = (alphaLevels.length > i) ? alphaLevels[i] : null;
        if (newAlphaLevels[i] === undefined || newAlphaLevels[i] === null)
            newAlphaLevels[i] = this.planeAlphaLevels[i];
        if (newAlphaLevels[i] < 0.0)  newAlphaLevels[i] = 0.0;
        if (newAlphaLevels[i] > 1.0)  newAlphaLevels[i] = 1.0;
    }

    if (this.planeAlphaLevels[0] !== newAlphaLevels[0] ||
        this.planeAlphaLevels[1] !== newAlphaLevels[1] ||
        this.planeAlphaLevels[2] !== newAlphaLevels[2]) {

        this.planeAlphaLevels[0] = newAlphaLevels[0];
        this.planeAlphaLevels[1] = newAlphaLevels[1];
        this.planeAlphaLevels[2] = newAlphaLevels[2];

        this.invalidate();
    }
};

// Gets the alpha transparency levels of individual planes.
VolumeViewer.prototype.getPlaneAlphaLevels = function() {
    return [
        this.planeAlphaLevels[0],
        this.planeAlphaLevels[1],
        this.planeAlphaLevels[2]
    ];
};

// Enables or disables plane crosshair drawing for MPR planes.
VolumeViewer.prototype.enablePlaneCrosshairs = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.showPlaneCrosshairs !== enable) {
        this.showPlaneCrosshairs = enable;

        //this.invalidateShader();
        if (this.crosshairOverlay) {
            this.crosshairOverlay.visible = enable;
        }
    }
};

// Returns true if plane crosshair drawing for MPR planes is enabled.
VolumeViewer.prototype.arePlaneCrosshairsEnabled = function() {
    return this.showPlaneCrosshairs;
};

// Sets the inner and outer radii for the plane crosshair.
VolumeViewer.prototype.setPlaneCrosshairRadii = function(radii) {
    if (radii === undefined || radii === null)  return;

    this.setPlaneCrosshairRadiusValues(radii.inner, radii.outer);
};

// Sets the inner and outer radii for the plane crosshair.
VolumeViewer.prototype.setPlaneCrosshairRadiusValues = function(innerRadius, outerRadius) {
    if (innerRadius === undefined || innerRadius === null)
        innerRadius = this.planeCrosshairRadiusInner;
    if (outerRadius === undefined || outerRadius === null)
        outerRadius = this.planeCrosshairRadiusOuter;

    const MAX_RADIUS = 3.5;

    if (innerRadius < 0.0)         innerRadius = 0.0;
    if (innerRadius > MAX_RADIUS)  innerRadius = MAX_RADIUS;

    if (outerRadius < 0.0)         outerRadius = 0.0;
    if (outerRadius > MAX_RADIUS)  outerRadius = MAX_RADIUS;

    if (innerRadius > outerRadius) {
        let temp    = innerRadius;
        innerRadius = outerRadius;
        outerRadius = temp;
    }

    if (this.planeCrosshairRadiusInner !== innerRadius ||
        this.planeCrosshairRadiusOuter !== outerRadius) {
        this.planeCrosshairRadiusInner = innerRadius;
        this.planeCrosshairRadiusOuter = outerRadius;

        this.invalidate();
    }
};

// Gets the inner and outer radii for the plane crosshair.
VolumeViewer.prototype.getPlaneCrosshairRadii = function() {
    return {
        inner: this.planeCrosshairRadiusInner,
        outer: this.planeCrosshairRadiusOuter
    }
};

// Sets the plane crosshair color for the viewer, as a color object with red, green, blue
// and alpha fields.  All values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setPlaneCrosshairColor = function(color) {
    if (color === undefined || color === null)  return;

    const red   = color.red;
    const green = color.green;
    const blue  = color.blue;
    const alpha = color.alpha;

    this.setPlaneCrosshairColorRGBA(red, green, blue, alpha);
};

// Sets the plane crosshair color for the viewer, using individual red, green, blue
// and alpha values.  All values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setPlaneCrosshairColorRGBA = function(red, green, blue, alpha=1.0) {
    if (red === undefined   || red === null)    red   = this.planeCrosshairColor[0];
    if (green === undefined || green === null)  green = this.planeCrosshairColor[1];
    if (blue === undefined  || blue === null)   blue  = this.planeCrosshairColor[2];
    if (alpha === undefined || alpha === null)  alpha = this.planeCrosshairColor[3];

    if (red >= 1.0)    red = 1.0;
    if (red <= 0.0)    red = 0.0;

    if (green >= 1.0)  green = 1.0;
    if (green <= 0.0)  green = 0.0;

    if (blue >= 1.0)   blue = 1.0;
    if (blue <= 0.0)   blue = 0.0;

    if (alpha >= 1.0)  alpha = 1.0;
    if (alpha <= 0.0)  alpha = 0.0;

    if (this.planeCrosshairColor[0] !== red || this.planeCrosshairColor[1] !== green ||
        this.planeCrosshairColor[2] !== blue || this.planeCrosshairColor[3] !== alpha) {

        this.planeCrosshairColor = [red, green, blue, alpha];

        //this.invalidate();
        if (this.crosshairOverlay)
            this.crosshairOverlay.color = this.planeCrosshairColor;
    }
};

// Gets the plane crosshair color for the viewer, as a color object with red, green, blue
// and alpha fields.  All values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.getPlaneCrosshairColor = function() {
    return {
        red:   this.planeCrosshairColor[0],
        green: this.planeCrosshairColor[1],
        blue:  this.planeCrosshairColor[2],
        alpha: this.planeCrosshairColor[3]
    };
};

// Enables or disables auto-plane, which causes the three planes in MPR to
// automatically align with the camera view.
VolumeViewer.prototype.enableAutoPlane = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.autoPlane !== enable) {
        this.autoPlane = enable;

        this.invalidate();
        this.invalidateOverlays();

        this.invokeActionCallbacks(VolumeViewer.ACTION_PLANE);
    }
};

// Returns true if auto-plane is enabled.
VolumeViewer.prototype.isAutoPlaneEnabled = function() {
    return this.autoPlane;
};

// Sets the center of the three intersecting planes in the multiplanar
// display, using individual x, y and z values.
VolumeViewer.prototype.setPlanePointXYZ = function(x, y, z,
                                                   xoff=undefined,
                                                   yoff=undefined,
                                                   zoff=undefined) {
    if (x === undefined || x === null)  x = this.planePoint[0];
    if (y === undefined || y === null)  y = this.planePoint[1];
    if (z === undefined || z === null)  z = this.planePoint[2];

    if (xoff === undefined || xoff === null)  xoff = this.planeOffset[0];
    if (yoff === undefined || yoff === null)  yoff = this.planeOffset[1];
    if (zoff === undefined || zoff === null)  zoff = this.planeOffset[2];

    if (this.planePoint[0] !== x || this.planePoint[1] !== y ||
        this.planePoint[2] !== z ||
        this.planeOffset[0] !== xoff || this.planeOffset[1] !== yoff ||
        this.planeOffset[2] !== zoff) {

        this.planePoint[0] = x;
        this.planePoint[1] = y;
        this.planePoint[2] = z;

        this.planeOffset[0] = xoff;
        this.planeOffset[1] = yoff;
        this.planeOffset[2] = zoff;

        this.invalidate();
        this.invalidateOverlays();

        // STM_TODO - this...  is a bit of a hack.
        // Taking this out for now.
        //this.invokeActionCallbacks(VolumeViewer.ACTION_PLANE);
    }
};

// Sets the center of the three intersecting planes in the multiplanar
// display, as a coordinate object containing x, y and z fields.
VolumeViewer.prototype.setPlanePoint = function(point) {
    const x = point.x;
    const y = point.y;
    const z = point.z;

    this.setPlanePointXYZ(x, y, z);
};

// Gets the center of the three intersecting planes in the multiplanar
// display, as a coordinate object containing x, y and z fields.
VolumeViewer.prototype.getPlanePoint = function() {
    return {
        x: this.planePoint[0],
        y: this.planePoint[1],
        z: this.planePoint[2]
    };
};

// Sets the plane matrix, which is used to orient the three planes in MPR.
VolumeViewer.prototype.setPlaneMatrixValues = function(m00, m01, m02, m10, m11, m12, m20, m21, m22) {
    const planeMatrix = this.planeMatrix;

    if (planeMatrix[0] !== m00 || planeMatrix[1] !== m01 || planeMatrix[2] !== m02 ||
        planeMatrix[3] !== m10 || planeMatrix[4] !== m11 || planeMatrix[5] !== m12 ||
        planeMatrix[6] !== m20 || planeMatrix[7] !== m21 || planeMatrix[8] !== m22) {

        if (typeof m00 === 'number')  planeMatrix[0] = m00;
        if (typeof m01 === 'number')  planeMatrix[1] = m01;
        if (typeof m02 === 'number')  planeMatrix[2] = m02;
        if (typeof m10 === 'number')  planeMatrix[3] = m10;
        if (typeof m11 === 'number')  planeMatrix[4] = m11;
        if (typeof m12 === 'number')  planeMatrix[5] = m12;
        if (typeof m20 === 'number')  planeMatrix[6] = m20;
        if (typeof m21 === 'number')  planeMatrix[7] = m21;
        if (typeof m22 === 'number')  planeMatrix[8] = m22;

        this.invalidate();
        this.invalidateOverlays();

        this.invokeActionCallbacks(VolumeViewer.ACTION_PLANE);
    }
};

// Sets the plane matrix, which is used to orient the three planes in MPR.
VolumeViewer.prototype.setPlaneMatrix = function(planeMatrix,
                                                 d1, d2, d3, d4, d5, d6, d7, d8) {  // dummy params

    // STM_TODO - remove this check at earliest opportunity
    // Check for obsolescent usage of setPlaneMatrix (with nine parameters)
    if (d1 !== undefined || d2 !== undefined ||
        d3 !== undefined || d4 !== undefined || d5 !== undefined ||
        d6 !== undefined || d7 !== undefined || d8 !== undefined) {
        dconsole.error("ERROR: setPlaneMatrix() method is now setPlaneMatrixValues(). " +
                       "Please update your code.");
        this.setPlaneMatrixValues(planeMatrix, d1, d2, d3, d4, d5, d6, d7, d8);
        return;
    }

    if (!planeMatrix)  planeMatrix = {};

    this.setPlaneMatrixValues(planeMatrix.m00, planeMatrix.m01, planeMatrix.m02,
                              planeMatrix.m10, planeMatrix.m11, planeMatrix.m12,
                              planeMatrix.m20, planeMatrix.m21, planeMatrix.m22);
};

// Sets the plane matrix from the current rotation.  This will align the three
// planes in MPR with the current camera view.
VolumeViewer.prototype.setPlaneMatrixFromRotation = function() {
    const rotationMatrix = this.combineMatrices();

    this.setPlaneMatrixValues(rotationMatrix[0], rotationMatrix[4], rotationMatrix[8],
                              rotationMatrix[1], rotationMatrix[5], rotationMatrix[9],
                              rotationMatrix[2], rotationMatrix[6], rotationMatrix[10]);

};

// Resets the current plane matrix to its default.
VolumeViewer.prototype.resetPlaneMatrix = function() {
    let identity = mat3.create();
    if (!mat3.exactEquals(this.planeMatrix, identity)) {
        mat3.copy(this.planeMatrix, identity);

        this.invalidate();
        this.invalidateOverlays();

        this.invokeActionCallbacks(VolumeViewer.ACTION_PLANE);
    }
};

// Gets the plane matrix, which is used to orient the three planes in MPR.
VolumeViewer.prototype.getPlaneMatrix = function()  {
    const planeMatrix = this.planeMatrix;

    return {
        m00: planeMatrix[0],
        m01: planeMatrix[1],
        m02: planeMatrix[2],

        m10: planeMatrix[3],
        m11: planeMatrix[4],
        m12: planeMatrix[5],

        m20: planeMatrix[6],
        m21: planeMatrix[7],
        m22: planeMatrix[8]
    };
};

// Sets the plane offsets for all three planes in MPR, using an offset object
// in the plane coordinate system containing px, py and pz fields.
// If the offset is in a different coordinate system, it will automatically
// be converted to the plane coordinate system.
VolumeViewer.prototype.setPlaneOffset = function(offset, clamp=true) {
    offset = this.convertToPlaneOffset(offset);

    if (offset === undefined || offset === null)  return;

    this.setPlaneOffsetXYZ(offset.px, offset.py, offset.pz, clamp);
};

// Sets the plane offsets for all three planes in MPR, using individual
// x, y and z offset values in the plane coordinate system.
VolumeViewer.prototype.setPlaneOffsetXYZ = function(x, y, z, clamp=true) {
    if (x === undefined || x === null)  x = this.planeOffset[0];
    if (y === undefined || y === null)  y = this.planeOffset[1];
    if (z === undefined || z === null)  z = this.planeOffset[2];

    let newOffset = { px: x, py: y, pz: z };

    if (clamp)
        newOffset = this.clampPlaneOffset(newOffset);

    newOffset = this.convertToPlaneOffset(newOffset);

    if (this.planeOffset[0] !== newOffset.px || this.planeOffset[1] !== newOffset.py ||
        this.planeOffset[2] !== newOffset.pz) {

        this.planeOffset[0] = newOffset.px;
        this.planeOffset[1] = newOffset.py;
        this.planeOffset[2] = newOffset.pz;

        this.invalidate();
        this.invalidateOverlays();

        this.invokeActionCallbacks(VolumeViewer.ACTION_PLANE);
    }
};

// Gets the plane offsets for all three planes in MPR, as an offset object
// in the plane coordinate system containing px, py and pz fields.
VolumeViewer.prototype.getPlaneOffset = function() {
    return {
        px: this.planeOffset[0],
        py: this.planeOffset[1],
        pz: this.planeOffset[2]
    };
};

// Sets the plane offsets for all three planes in MPR, using an offset object
// in the volume coordinate system containing x, y and z fields.
// If the offset is in a different coordinate system, it will automatically
// be converted to the volume coordinate system.
VolumeViewer.prototype.setPlaneOffsetFromPoint = function(point, clamp=true) {
    point = this.convertToVolumeOffset(point);

    if (point === undefined || point === null)  return;

    this.setPlaneOffsetFromPointXYZ(point.x, point.y, point.z, clamp);
};

// Sets the plane offsets for all three planes in MPR, using individual
// x, y and z offset values in the volume coordinate system.
VolumeViewer.prototype.setPlaneOffsetFromPointXYZ = function(x, y, z, clamp=true) {
    if (x === undefined || x === null)  x = 0.0;
    if (y === undefined || y === null)  y = 0.0;
    if (z === undefined || z === null)  z = 0.0;

    let offset = { x: x, y: y, z: z };

    this.setPlaneOffset(offset, clamp);  // conversion will happen automatically here
};

// Gets the plane step offsets for all three planes in MPR, as an offset object
// containing x, y and z fields in the volume coordinate system.
VolumeViewer.prototype.getPlaneOffsetAsPoint = function() {
    let planeOffset = this.getPlaneOffset();
    let volumeOffset = this.convertToStepOffset(planeOffset);
    return volumeOffset;
};

// Sets the plane step offsets for all three planes in MPR, using an offset object
// containing sx, sy and sz fields in the step coordinate system.
// One plane step unit == one voxel.
VolumeViewer.prototype.setPlaneStepOffset = function(stepOffset, clamp=true) {
    stepOffset = this.convertToStepOffset(stepOffset);

    if (stepOffset === undefined || stepOffset === null)  return;

    this.setPlaneStepOffsetXYZ(stepOffset.sx, stepOffset.sy, stepOffset.sz, clamp);
};

// Sets the plane step offsets for all three planes in MPR, using individual
// x, y and z offset values in the step coordinate system.
// One plane step unit == one voxel.
VolumeViewer.prototype.setPlaneStepOffsetXYZ = function(x, y, z, clamp=true) {
    let oldStepOffset = this.getPlaneStepOffset();

    if (x === undefined || x === null)  x = oldStepOffset.sx;
    if (y === undefined || y === null)  y = oldStepOffset.sy;
    if (z === undefined || z === null)  z = oldStepOffset.sz;

    let stepOffset = { sx: x, sy: y, sz: z };

    this.setPlaneOffset(stepOffset, clamp);  // conversion will happen automatically here
};

// Gets the plane step offsets for all three planes in MPR, as an offset object
// containing sx, sy and sz fields in the step coordinate system.
// One plane step unit == one voxel.
VolumeViewer.prototype.getPlaneStepOffset = function(floor=false) {
    let planeOffset = this.getPlaneOffset();
    let stepOffset = this.convertToStepOffset(planeOffset);
    if (stepOffset) {
        if (floor) {
            stepOffset.sx = Math.floor(stepOffset.sx);
            stepOffset.sy = Math.floor(stepOffset.sy);
            stepOffset.sz = Math.floor(stepOffset.sz);
        }
    }

    return stepOffset;
};

// Convenience method.  Sets the number of the slice being
// displayed in a 2D view.
// Note that the slice numbers used by this method are one-based, not
// zero-based -- that is, the first slice is 1, not 0.
// WARNING: this method is obsolescent.  Use setFacingPlaneSlice() instead.
VolumeViewer.prototype.setPlaneSlice = function(sliceNumber) {
    this.setFacingPlaneSlice(sliceNumber);
};

// Convenience method.  Returns the number of the current slice being
// displayed in a 2D view.
// Note that the slice numbers used by this method are one-based, not
// zero-based -- that is, the first slice is 1, not 0.
// WARNING: this method is obsolescent.  Use getFacingPlaneSlice() instead.
VolumeViewer.prototype.getPlaneSlice = function() {
    return this.getFacingPlaneSlice();
};

// SOME INFORMATION ABOUT PLANE COORDINATE SYSTEMS:
// There are five coordinate systems used by the plane viewer.
//   * Volume:
//       Offsets are in the volume (or world) coordinate system.
//       Offsets are differentiated with x, y and z parameters.
//       The center of the volume is at [0, 0, 0] and all coordinates
//       range from -1 to 1.
//       This is the default coordinate system, and the one that is most
//       directly useful to the volume viewer.
//   * Metric:
//       Offsets are in the volume (or world) coordinate system, converted
//       to millimeter units.
//       Offsets are differentiated with mx, my and mz parameters.
//       The center of the volume is at [0, 0, 0].
//       The range depends on the size of the volume in millimeters.
//   * Plane:
//       Offsets are in the planar coordinate system, as defined by the viewer's
//       PlaneMatrix.
//       Offsets are differentiated with px, py and pz parameters.
//       The center of the volume is at -PlanePoint.  Coordinates are at the same
//       scale as the Volume coordinate system, but may range beyond -1 and 1
//       if the plane matrix is not axis-aligned.
//   * Rotation:
//       Offsets are in the rotation coordinate system.
//       Offsets are differentiated with rx, ry and rz parameters.
//       When viewing in autoplane mode, this coordinate system will be defined
//       by the viewer's RotationMatrix.  Otherwise, it will be defined by the
//       viewer's PlaneMatrix.
//       The center of the volume is at -PlanePoint.  Coordinates are at the same
//       scale as the Volume coordinate system, but may range beyond -1 and 1
//       if the rotation matrix is not axis-aligned.
//   * Step:
//       Offsets are in the step coordinate system, which is based on voxels.
//       Offsets are differentiated with sx, sy and sz parameters.
//       The orientation is the same as the rotation coordinate system,
//       but distances are scaled based on voxel sizes along each axis.
//       This can be thought of as a slice-based coordinate system.
//       All coordinates range from 0 to nslices, where nslices is axis-dependent.
//       Because each axis is scaled differently, this coordinate system
//       should not be used for distance calculations.
//       Also note that the "slices" are somewhat arbitrary if the rotation matrix
//       is not axis-aligned, but they have been set up so that no important
//       features in the volume will be missed if the user is stepping between
//       slices in the viewer.

// Determines whether the specified offset is based on the metric coordinate system.
// This is determined by duck-type examination of the fields in the offset.
VolumeViewer.isMetricOffset = function(offset) {
    return offset && 'mx' in offset && 'my' in offset && 'mz' in offset;
};

// Determines whether the specified offset is based on the volume coordinate system.
// This is determined by duck-type examination of the fields in the offset.
VolumeViewer.isVolumeOffset = function(offset) {
    return offset && 'x' in offset && 'y' in offset && 'z' in offset;
};

// Determines whether the specified offset is based on the plane coordinate system.
// This is determined by duck-type examination of the fields in the offset.
VolumeViewer.isPlaneOffset = function(offset) {
    return offset && 'px' in offset && 'py' in offset && 'pz' in offset;
};

// Determines whether the specified offset is based on the rotation coordinate system.
// This is determined by duck-type examination of the fields in the offset.
VolumeViewer.isRotationOffset = function(offset) {
    return offset && 'rx' in offset && 'ry' in offset && 'rz' in offset;
};

// Determines whether the specified offset is based on the step coordinate system.
// This is determined by duck-type examination of the fields in the offset.
VolumeViewer.isStepOffset = function(offset) {
    return offset && 'sx' in offset && 'sy' in offset && 'sz' in offset;
};

// Converts the specified offset from its original coordinate system to the
// step coordinate system.
// If the offset could not be converted, this method returns null.
VolumeViewer.prototype.convertToStepOffset = function(offset) {
    if (VolumeViewer.isStepOffset(offset)) {
        // Already in correct form, just pass it back
        return {
            sx: offset.sx,
            sy: offset.sy,
            sz: offset.sz
        };
    }

    if (VolumeViewer.isMetricOffset(offset)) {
        // Convert metric offset to volume offset (intermediate step)
        offset = this.convertToVolumeOffset(offset);
    }

    if (VolumeViewer.isVolumeOffset(offset)) {
        // Convert volume offset to plane offset (intermediate step)
        offset = this.convertToPlaneOffset(offset);
    }

    if (VolumeViewer.isPlaneOffset(offset)) {
        // Convert plane offset to rotation offset (intermediate step)
        offset = this.convertToRotationOffset(offset);
    }

    if (VolumeViewer.isRotationOffset(offset)) {
        // Convert from rotation offset to step offset
        let stepData = this.getPlaneStepData();

        function convert(value, stepData) {
            if (stepData) {
                let newValue;
                if (stepData.sliceDirection >= 0.0)
                    newValue = stepData.sliceOffset - value / stepData.stepIncrement;
                else
                    newValue = stepData.sliceOffset + value / stepData.stepIncrement;
                return newValue;
            }
            else
                return value;
        }

        return {
            sx: convert(offset.rx, stepData.xStep),
            sy: convert(offset.ry, stepData.yStep),
            sz: convert(offset.rz, stepData.zStep)
        };
    }

    return null;
};

// Converts the specified offset from its original coordinate system to the
// rotation coordinate system.
// If the offset could not be converted, this method returns null.
VolumeViewer.prototype.convertToRotationOffset = function(offset) {
    if (VolumeViewer.isRotationOffset(offset)) {
        // Already in correct form, just pass it back
        return {
            rx: offset.rx,
            ry: offset.ry,
            rz: offset.rz
        };
    }

    if (VolumeViewer.isMetricOffset(offset)) {
        // Convert metric offset to volume offset (intermediate step)
        offset = this.convertToVolumeOffset(offset);
    }

    if (VolumeViewer.isVolumeOffset(offset)) {
        // Convert volume offset to plane offset (intermediate step)
        offset = this.convertToPlaneOffset(offset);
    }

    if (VolumeViewer.isStepOffset(offset)) {
        // Convert step offset to rotation offset
        let stepData = this.getPlaneStepData();

        function convert(value, stepData) {
            if (stepData) {
                let newValue;
                if (stepData.sliceDirection >= 0.0)
                    newValue = (-value + stepData.sliceOffset) * stepData.stepIncrement;
                else
                    newValue = (value - stepData.sliceOffset) * stepData.stepIncrement;
                return newValue;
            }
            else
                return value;
        }
        return {
            rx: convert(offset.sx, stepData.xStep),
            ry: convert(offset.sy, stepData.yStep),
            rz: convert(offset.sz, stepData.zStep)
        };
    }

    if (VolumeViewer.isPlaneOffset(offset)) {
        if (!this.autoPlane) {
            // If not in autoplane, rotation offset and plane offset are the same
            return {
                rx: offset.px,
                ry: offset.py,
                rz: offset.pz
            };
        }

        // Convert plane offset to rotation offset
        const planeOffset        = vec3.fromValues(offset.px, offset.py, offset.pz);
        const planePoint         = this.planePoint;
        const planeMatrix        = this.planeMatrix;
        const invAutoPlaneMatrix = mat3.create();
        mat3.invert(invAutoPlaneMatrix, this.getInternalPlaneMatrix());

        vec3.transformMat3(planeOffset, planeOffset, planeMatrix);
        vec3.add(planeOffset, planeOffset, planePoint);
        vec3.transformMat3(planeOffset, planeOffset, invAutoPlaneMatrix);

        return {
            rx: planeOffset[0],
            ry: planeOffset[1],
            rz: planeOffset[2]
        };
    }

    return null;
};

// Converts the specified offset from its original coordinate system to the
// plane coordinate system.
// If the offset could not be converted, this method returns null.
VolumeViewer.prototype.convertToPlaneOffset = function(offset) {
    if (VolumeViewer.isPlaneOffset(offset)) {
        // Already in correct form, just pass it back
        return {
            px: offset.px,
            py: offset.py,
            pz: offset.pz
        };
    }

    if (VolumeViewer.isMetricOffset(offset)) {
        // Convert metric offset to volume offset (intermediate step)
        offset = this.convertToVolumeOffset(offset);
    }

    if (VolumeViewer.isVolumeOffset(offset)) {
        const planePoint     = vec3.fromValues(offset.x, offset.y, offset.z);
        const planeOrigin    = this.planePoint;
        const invPlaneMatrix = mat3.create();
        mat3.invert(invPlaneMatrix, this.planeMatrix);

        vec3.subtract(planePoint, planePoint, planeOrigin);
        vec3.transformMat3(planePoint, planePoint, invPlaneMatrix);

        return {
            px: planePoint[0],
            py: planePoint[1],
            pz: planePoint[2]
        };
    }

    if (VolumeViewer.isStepOffset(offset)) {
        // Convert step offset to rotation offset (intermediate step)
        offset = this.convertToRotationOffset(offset);
    }

    if (VolumeViewer.isRotationOffset(offset)) {
        if (!this.autoPlane) {
            // If not in autoplane, rotation offset and plane offset are the same
            return {
                px: offset.rx,
                py: offset.ry,
                pz: offset.rz
            };
        }

        // Convert rotation offset to plane offset
        const planeOffset     = vec3.fromValues(offset.rx, offset.ry, offset.rz);
        const autoPlaneMatrix = this.getInternalPlaneMatrix();
        const planePoint      = this.planePoint;
        const invPlaneMatrix  = mat3.create();
        mat3.invert(invPlaneMatrix, this.planeMatrix);

        vec3.transformMat3(planeOffset, planeOffset, autoPlaneMatrix);
        vec3.subtract(planeOffset, planeOffset, planePoint);
        vec3.transformMat3(planeOffset, planeOffset, invPlaneMatrix);

        return {
            px: planeOffset[0],
            py: planeOffset[1],
            pz: planeOffset[2]
        };
    }

    return null;
};

// Converts the specified offset from its original coordinate system to the
// volume coordinate system.
// If the offset could not be converted, this method returns null.
VolumeViewer.prototype.convertToVolumeOffset = function(offset) {
    if (VolumeViewer.isVolumeOffset(offset)) {
        // Already in correct form, just pass it back
        return {
            x: offset.x,
            y: offset.y,
            z: offset.z
        };
    }

    if (VolumeViewer.isMetricOffset(offset)) {
        // Convert metric offset to volume offset
        //offset = this.convertToVolumeOffset(offset);
        let mult = this.getVoxelMultiplier();
        return {
            x: offset.mx / mult,
            y: offset.my / mult,
            z: offset.mz / mult
        }
    }

    if (VolumeViewer.isStepOffset(offset)) {
        // Convert step offset to plane offset (intermediate step)
        offset = this.convertToPlaneOffset(offset);
    }

    if (VolumeViewer.isRotationOffset(offset)) {
        // Convert rotation offset to plane offset (intermediate step)
        offset = this.convertToPlaneOffset(offset);
    }

    if (VolumeViewer.isPlaneOffset(offset)) {
        // Convert volume offset to plane offset
        const planePoint     = vec3.fromValues(offset.px, offset.py, offset.pz);
        const planeMatrix    = this.planeMatrix;
        const planeOrigin    = this.planePoint;
        const invPlaneMatrix = mat3.create();

        mat3.invert(invPlaneMatrix, planeMatrix);

        vec3.transformMat3(planePoint, planePoint, planeMatrix);
        vec3.add(planePoint, planePoint, planeOrigin);

        return {
            x: planePoint[0],
            y: planePoint[1],
            z: planePoint[2]
        };
    }

    return null;
};

// Converts the specified offset from its original coordinate system to the
// metric coordinate system.
// If the offset could not be converted, this method returns null.
VolumeViewer.prototype.convertToMetricOffset = function(offset) {
    if (VolumeViewer.isMetricOffset(offset)) {
        // Already in correct form, just pass it back
        return {
            mx: offset.mx,
            my: offset.my,
            mz: offset.mz
        };
    }

    if (!VolumeViewer.isVolumeOffset(offset)) {
        offset = this.convertToVolumeOffset(offset);
    }

    if (VolumeViewer.isVolumeOffset(offset)) {
        // Convert volume offset to metric offset
        let mult = this.getVoxelMultiplier();
        return {
            mx: offset.x * mult,
            my: offset.y * mult,
            mz: offset.z * mult
        }
    }

    return null;
};

// Sets the number of the slice being displayed in a 2D view.
// Note that the slice numbers used by this method are one-based, not
// zero-based -- that is, the first slice is 1, not 0.
VolumeViewer.prototype.setFacingPlaneSlice = function(sliceNumber, animate=undefined) {
    let selectedFace = this.getFacingPlane();
    if (selectedFace < 0) {
        selectedFace = -selectedFace;
    }
    if (selectedFace !== 1 && selectedFace !== 2 && selectedFace !== 3)  return false;

    animate = this.sanitizeAnimate(animate);

    // Hack!
    if (typeof sliceNumber === 'object' && typeof sliceNumber.slice === 'number')
        sliceNumber = sliceNumber.slice;

    let stepOffset = sliceNumber - 1;  // slices are 1-based

    let offset = this.getPlaneStepOffset();

    if      (selectedFace === 3)
        offset.sz = Math.floor(stepOffset) + 0.5;
    else if (selectedFace === 2)
        offset.sy = Math.floor(stepOffset) + 0.5;
    else
        offset.sx = Math.floor(stepOffset) + 0.5;

    offset = this.clampPlaneOffset(offset);
    if (this.planeOffsetClamping)
        offset = this.clampPlaneOffsetToPlane(offset, selectedFace);
    offset = this.convertToPlaneOffset(offset);

    let propertyState = {
        PlaneOffset: offset
    };

    this.animateToPropertyState(propertyState, null, animate);
    return true;
};

// Convenience method.  Returns the current slice number and total number of
// slices in the facing plane being displayed in a 2D view.
// Note that the slice numbers used by this method are one-based, not
// zero-based -- that is, the first slice is 1, not 0.
VolumeViewer.prototype.getFacingPlaneSlice = function() {
    let selectedFace = this.getFacingPlane();
    if (selectedFace < 0)
        selectedFace = -selectedFace;

    let steps  = this.getPlaneStepOffset(true);
    let counts = this.getPlaneSliceCounts();

    let slice = 0;
    let count = 0;
    if (selectedFace === 1)  {
        slice = steps.sx + 1;
        count = counts.x;
    }
    else if (selectedFace === 2)  {
        slice = steps.sy + 1;
        count = counts.y;
    }
    else if (selectedFace === 3)  {
        slice = steps.sz + 1;
        count = counts.z;
    }

    return {
        slice: slice,
        count: count
    };
};

// Returns the maximum extents of the volume along the x, y and z axes,
// in plane offset coordinates.
VolumeViewer.prototype.getVolumeExtents = function(interiorClamp=false) {
    let volume = this.getProcessedVolumeData();

    if (volume === undefined || volume === null) {
        return {x: 1.0, y: 1.0, z: 1.0};
    }

    const width  = volume.width;
    const height = volume.height;
    const depth  = volume.depth;

    let x = Math.abs(volume.scaleX) * width;
    let y = Math.abs(volume.scaleY) * height;
    let z = Math.abs(volume.scaleZ) * depth;
    let max = Math.max(x, Math.max(y, z));

    if (interiorClamp) {
        const SUBTRACT = VolumeViewer.BORDER_SIZE * 2 + 1;
        x = Math.abs(volume.scaleX) * (width  - SUBTRACT);
        y = Math.abs(volume.scaleY) * (height - SUBTRACT);
        z = Math.abs(volume.scaleZ) * (depth  - SUBTRACT);
    }

    x /= max;
    y /= max;
    z /= max;

    return {x: x, y: y, z: z};
};

// Gets a multiplier that can be used to convert volume space (constrained to
// the range [-1, 1] for each axis) to metric space (in millimeters).
VolumeViewer.prototype.getVoxelMultiplier = function() {
    let volume = this.getProcessedVolumeData();

    if (volume === undefined || volume === null) {
        return {x: 1.0, y: 1.0, z: 1.0};
    }

    let x        = volume.scaleX;
    let y        = volume.scaleY;
    let z        = volume.scaleZ;
    const width  = volume.width;
    const height = volume.height;
    const depth  = volume.depth;

    x = Math.abs(x) * width;
    y = Math.abs(y) * height;
    z = Math.abs(z) * depth;
    let max = Math.max(x, Math.max(y, z));

    return max * 0.5;
};

// Returns the number of slices along each MPR planar axis in
// the x, y and z directions.
VolumeViewer.prototype.getPlaneSliceCounts = function() {
    let stepData = this.getPlaneStepData();

    return {
        x: stepData.xStep.slices,
        y: stepData.yStep.slices,
        z: stepData.zStep.slices
    };
};

// Returns the voxel size along each MPR planar axis in
// the x, y and z directions, in millimeters.
// Note that "voxel" sizes are somewhat arbitrary if the planar axes
// are not aligned with the original volume.
VolumeViewer.prototype.getPlaneVoxelSizes = function() {
    let stepData = this.getPlaneStepData();
    let voxelMultiplier = this.getVoxelMultiplier();

    return {
        x: stepData.xStep.stepIncrement * voxelMultiplier,
        y: stepData.yStep.stepIncrement * voxelMultiplier,
        z: stepData.zStep.stepIncrement * voxelMultiplier
    };
};

// Sets the selected point in the multiplanar display, using
// individual x, y and z values.
VolumeViewer.prototype.setSelectedPointXYZ = function(x, y, z) {
    if (x === undefined || x === null)  x = this.selectedPoint ? this.selectedPoint[0] : 0.0;
    if (y === undefined || y === null)  y = this.selectedPoint ? this.selectedPoint[0] : 0.0;
    if (z === undefined || z === null)  z = this.selectedPoint ? this.selectedPoint[0] : 0.0;

    if (!this.selectedPoint ||
        this.selectedPoint[0] !== x || this.selectedPoint[1] !== y ||
        this.selectedPoint[2] !== z) {

        this.selectedPoint = [x, y, z];

        this.invalidate();

        this.invokeActionCallbacks(VolumeViewer.ACTION_SELECT);
    }
};

// Sets the selected point in the multiplanar display, as a
// coordinate object containing x, y and z fields.
VolumeViewer.prototype.setSelectedPoint = function(point) {
    if (!point) {
        this.clearSelectedPoint();
    }
    else {
        const x = point.x;
        const y = point.y;
        const z = point.z;

        this.setSelectedPointXYZ(x, y, z);
    }
};

// Clears the selected point in the multiplanar display.
VolumeViewer.prototype.clearSelectedPoint = function() {
    if (this.selectedPoint) {
        this.selectedPoint = null;

        this.invalidate();

        this.invokeActionCallbacks(VolumeViewer.ACTION_SELECT);
    }
};

// Gets the selected point in the multiplanar display, as a coordinate object
// containing x, y and z fields.  If no point is selected, null is returned.
VolumeViewer.prototype.getSelectedPoint = function() {
    if (!this.selectedPoint)  return null;

    return {
        x: this.selectedPoint[0],
        y: this.selectedPoint[1],
        z: this.selectedPoint[2]
    };
};

// Sets the pivot point in the display, using individual x, y and z values.
VolumeViewer.prototype.setPivotPointXYZ = function(x, y, z) {
    if (x === undefined || x === null)  x = this.pivotPoint[0];
    if (y === undefined || y === null)  y = this.pivotPoint[0];
    if (z === undefined || z === null)  z = this.pivotPoint[0];

    if (this.pivotPoint[0] !== x || this.pivotPoint[1] !== y ||
        this.pivotPoint[2] !== z) {

        this.pivotPoint[0] = x;
        this.pivotPoint[1] = y;
        this.pivotPoint[2] = z;

        this.invalidate();
        this.invalidateOverlays();

        this.invokeActionCallbacks(VolumeViewer.ACTION_PIVOT);
    }
};

// Sets the pivot point in the display, as a coordinate object
// containing x, y and z fields.
VolumeViewer.prototype.setPivotPoint = function(point) {
    if (!point) {
        this.clearPivotPoint();
    }
    else {
        const x = point.x;
        const y = point.y;
        const z = point.z;

        this.setPivotPointXYZ(x, y, z);
    }
};

// Clears the pivot point in the display.
VolumeViewer.prototype.clearPivotPoint = function() {
    this.setPivotPoint(VolumeViewer.EMPTY_3D_VECTOR);
};

// Gets the pivot point in the display, as a coordinate object
// containing x, y and z fields.
VolumeViewer.prototype.getPivotPoint = function() {
    return {
        x: this.pivotPoint[0],
        y: this.pivotPoint[1],
        z: this.pivotPoint[2]
    };
};

// Sets the pivot matrix using individual values representing a 3x3 matrix.
VolumeViewer.prototype.setPivotMatrixValues = function(m00, m01, m02,
                                                       m10, m11, m12,
                                                       m20, m21, m22,
                                                       pivot0=undefined,
                                                       pivot1=undefined,
                                                       pivot2=undefined) {

    if (m00 === undefined || m00 === null)  m00 = this.pivotMatrix[0];
    if (m01 === undefined || m01 === null)  m01 = this.pivotMatrix[1];
    if (m02 === undefined || m02 === null)  m02 = this.pivotMatrix[2];

    if (m10 === undefined || m10 === null)  m10 = this.pivotMatrix[4];
    if (m11 === undefined || m11 === null)  m11 = this.pivotMatrix[5];
    if (m12 === undefined || m12 === null)  m12 = this.pivotMatrix[6];

    if (m20 === undefined || m20 === null)  m20 = this.pivotMatrix[8];
    if (m21 === undefined || m21 === null)  m21 = this.pivotMatrix[9];
    if (m22 === undefined || m22 === null)  m22 = this.pivotMatrix[10];

    if (pivot0 === undefined || pivot0 === null)  pivot0 = this.pivotPoint[0];
    if (pivot1 === undefined || pivot1 === null)  pivot1 = this.pivotPoint[1];
    if (pivot2 === undefined || pivot2 === null)  pivot2 = this.pivotPoint[2];

    let xAxis = VolumeViewer.normalize(m00, m01, m02, 1.0, 0.0, 0.0);
    let yAxis = VolumeViewer.normalize(m10, m11, m12, 0.0, 1.0, 0.0);
    let zAxis = VolumeViewer.normalize(m20, m21, m22, 0.0, 0.0, 1.0);

    // Ensure that our coordinate system is right-handed
    let tempAxis = vec3.create();
    if (vec3.dot(vec3.cross(tempAxis, xAxis, yAxis), zAxis) < 0)
        vec3.negate(zAxis, zAxis);

    const pivotMatrix = this.pivotMatrix;
    const pivotPoint  = this.pivotPoint;

    if (pivotMatrix[0]  !== xAxis[0] ||
        pivotMatrix[1]  !== xAxis[1] ||
        pivotMatrix[2]  !== xAxis[2] ||
        pivotMatrix[4]  !== yAxis[0] ||
        pivotMatrix[5]  !== yAxis[1] ||
        pivotMatrix[6]  !== yAxis[2] ||
        pivotMatrix[8]  !== zAxis[0] ||
        pivotMatrix[9]  !== zAxis[1] ||
        pivotMatrix[10] !== zAxis[2] ||
        pivotPoint[0]   !== pivot0 ||
        pivotPoint[1]   !== pivot1 ||
        pivotPoint[2]   !== pivot2) {

        pivotMatrix[0]  = xAxis[0];
        pivotMatrix[1]  = xAxis[1];
        pivotMatrix[2]  = xAxis[2];
        pivotMatrix[4]  = yAxis[0];
        pivotMatrix[5]  = yAxis[1];
        pivotMatrix[6]  = yAxis[2];
        pivotMatrix[8]  = zAxis[0];
        pivotMatrix[9]  = zAxis[1];
        pivotMatrix[10] = zAxis[2];

        pivotPoint[0]   = pivot0;
        pivotPoint[1]   = pivot1;
        pivotPoint[2]   = pivot2;

        this.invalidate();
        this.invalidateOverlays();

        this.invokeActionCallbacks(VolumeViewer.ACTION_PIVOT);
    }
};

// Sets the current pivot matrix.
VolumeViewer.prototype.setPivotMatrix = function(matrix, point=undefined) {
    if (!matrix)  matrix = {};
    if (!point)   point = {};

    const m00 = matrix.m00;
    const m01 = matrix.m01;
    const m02 = matrix.m02;

    const m10 = matrix.m10;
    const m11 = matrix.m11;
    const m12 = matrix.m12;

    const m20 = matrix.m20;
    const m21 = matrix.m21;
    const m22 = matrix.m22;

    const point0 = point.x;
    const point1 = point.y;
    const point2 = point.z;

    this.setPivotMatrixValues(m00, m01, m02, m10, m11, m12, m20, m21, m22,
                              point0, point1, point2);
};

// Resets the current pivot matrix.
VolumeViewer.prototype.resetPivotMatrix = function() {
    this.setPivotMatrix(VolumeViewer.IDENTITY_MATRIX,
                        VolumeViewer.EMPTY_3D_VECTOR);
};

// Gets the current pivot matrix.
VolumeViewer.prototype.getPivotMatrix = function() {
    const pivotMatrix = this.pivotMatrix;

    return {
        m00: pivotMatrix[0],
        m01: pivotMatrix[1],
        m02: pivotMatrix[2],

        m10: pivotMatrix[4],
        m11: pivotMatrix[5],
        m12: pivotMatrix[6],

        m20: pivotMatrix[8],
        m21: pivotMatrix[9],
        m22: pivotMatrix[10]
    };
};

// Enables or disables lighting in the viewer.
VolumeViewer.prototype.enableLighting = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.showLighting !== enable) {
        this.showLighting = enable;

        this.invalidateShader();
    }
};

// Returns true if lighting is enabled in the viewer.
VolumeViewer.prototype.isLightingEnabled = function() {
    return this.showLighting;
};

// Sets the position of the light relative to the model,
// using individual x, y and z positions.
// (z is toward the camera.)
VolumeViewer.prototype.setLightPositionXYZ = function(x, y, z, absolute=false) {
    if (x === undefined || x === null)  x = this.lightPos[0];
    if (y === undefined || y === null)  y = this.lightPos[1];
    if (z === undefined || z === null)  z = this.lightPos[2];

    if (absolute === undefined || absolute === null)  absolute = false;

    absolute = Boolean(absolute);

    if (this.lightPos[0] !== x || this.lightPos[1] !== y ||
        this.lightPos[2] !== z || this.lightPosAbsolute !== absolute) {

        this.lightPos[0] = x;
        this.lightPos[1] = y;
        this.lightPos[2] = z;

        this.lightPosAbsolute = absolute;

        this.invalidate();
    }
};

// Sets the position of the light relative to the model,
// using a position object containing x, y and z fields.
// (z is toward the camera.)
VolumeViewer.prototype.setLightPosition = function(position) {
    const x = position.x;
    const y = position.y;
    const z = position.z;

    const absolute = position.absolute;

    this.setLightPositionXYZ(x, y, z, absolute);
};

// Gets the position of the light relative to the model,
// as a position object containing x, y and z fields.
// (z is toward the camera.)
VolumeViewer.prototype.getLightPosition = function() {
    return {
        x:        this.lightPos[0],
        y:        this.lightPos[1],
        z:        this.lightPos[2],
        absolute: this.lightPosAbsolute
    };
};

// Enables or disables luminosity lighting in the viewer.
VolumeViewer.prototype.enableLuminosityLighting = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.showLuminosityLighting !== enable) {
        this.showLuminosityLighting = enable;

        this.invalidateShader();
    }
};

// Returns true if luminosity lighting is enabled in the viewer.
VolumeViewer.prototype.isLuminosityLightingEnabled = function() {
    return this.showLuminosityLighting;
};

// Enables or disables gradient lighting in the viewer.
VolumeViewer.prototype.enableGradientLighting = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.showGradientLighting !== enable) {
        this.showGradientLighting = enable;

        this.invalidateShader();
    }
};

// Returns true if gradient lighting is enabled in the viewer.
VolumeViewer.prototype.isGradientLightingEnabled = function() {
    return this.showGradientLighting;
};

// Enables or disables plane lighting in the viewer.
VolumeViewer.prototype.enablePlaneLighting = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.showPlaneLighting !== enable) {
        this.showPlaneLighting = enable;

        this.invalidateShader();
    }
};

// Returns true if plane lighting is enabled in the viewer.
VolumeViewer.prototype.isPlaneLightingEnabled = function() {
    return this.showPlaneLighting;
};

// Enables or disables specular highlighting in the viewer.
// WARNING: This method is obsolescent.  Use setSpecularLevel() instead.
VolumeViewer.prototype.enableSpecular = function(enable=true) {
    this.setSpecularLevel(enable);
};

// Returns true if specular highlighting is enabled in the viewer.
// WARNING: This method is obsolescent.  Use getSpecularLevel() instead.
VolumeViewer.prototype.isSpecularEnabled = function() {
    return this.getSpecularLevel() > 0.0;
};

// Sets the specularity on the surface of a 3D volume.
// 0 is no specularity, and 1 is maximum specularity.
VolumeViewer.prototype.setSpecularLevel = function(specularLevel) {
    if (specularLevel === undefined || specularLevel === null)  return;

    if (typeof specularLevel === 'boolean') {
        if (specularLevel)  specularLevel = 1.0;
        else                specularLevel = 0.0;
    }
    if (specularLevel < 0.0)  specularLevel = 0.0;
    if (specularLevel > 1.0)  specularLevel = 1.0;

    if (this.specularLevel !== specularLevel)  {
        this.specularLevel = specularLevel;

        this.invalidate();
    }
};

// Gets the specularity on the surface of a 3D volume.
// 0 is no specularity, and 1 is maximum specularity.
VolumeViewer.prototype.getSpecularLevel = function() {
    return this.specularLevel;
};

// Enables or disables specular highlighting for planes in the viewer.
// WARNING: This method is obsolescent.  Use setPlaneSpecularLevel() instead.
VolumeViewer.prototype.enablePlaneSpecular = function(enable=true) {
    this.setPlaneSpecularLevel(enable);
};

// Returns true if specular highlighting for planes is enabled in the viewer.
// WARNING: This method is obsolescent.  Use getPlaneSpecularLevel() instead.
VolumeViewer.prototype.isPlaneSpecularEnabled = function() {
    return this.getPlaneSpecularLevel() > 0.0;
};

// Sets the specularity on the surface of a plane.
// 0 is no specularity, and 1 is maximum specularity.
VolumeViewer.prototype.setPlaneSpecularLevel = function(specularLevel) {
    if (specularLevel === undefined || specularLevel === null)  return;

    if (typeof specularLevel === 'boolean') {
        if (specularLevel)  specularLevel = 1.0;
        else                specularLevel = 0.0;
    }
    if (specularLevel < 0.0)  specularLevel = 0.0;
    if (specularLevel > 1.0)  specularLevel = 1.0;

    if (this.planeSpecularLevel !== specularLevel)  {
        this.planeSpecularLevel = specularLevel;

        this.invalidate();
    }
};

// Gets the specularity on the surface of a plane.
// 0 is no specularity, and 1 is maximum specularity.
VolumeViewer.prototype.getPlaneSpecularLevel = function() {
    return this.planeSpecularLevel;
};

// Enables or disables sampling in an axis-aligned direction.
// This option tends to make rotation more solid-looking, but may also make
// the volume look "sliced".
VolumeViewer.prototype.enableSampleOnAxis = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.sampleOnAxis !== enable) {
        this.sampleOnAxis = enable;

        this.invalidateShader();
    }
};

// Returns true if sampling on-axis is enabled in the viewer.
VolumeViewer.prototype.isSampleOnAxisEnabled = function() {
    return this.sampleOnAxis;
};

// Enables or disables randomized sampling offsets during rendering.
// This option removes banding artifacts at low sample densities, but
// also introduces aliased "sparkles" due to the randomization.
VolumeViewer.prototype.enableRandomizedSampling = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.randomizeSampling !== enable) {
        this.randomizeSampling = enable;

        this.invalidateShader();
    }
};

// Returns true if randomized sampling offsets are enabled in the viewer.
VolumeViewer.prototype.isRandomizedSamplingEnabled = function() {
    return this.randomizeSampling;
};

// Enables or disables stippled sampling offsets during rendering.
// This option removes banding artifacts at low sample densities, but
// also introduces a visible stippling pattern that may be seen on displays
// with large pixel sizes.
VolumeViewer.prototype.enableStipple = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.stipple !== enable) {
        this.stipple = enable;

        this.invalidateShader();
    }
};

// Returns true if stippled sampling offsets are enabled in the viewer.
VolumeViewer.prototype.isStippleEnabled = function() {
    return this.stipple;
};

// Enables or disables front-to-back rendering.
// Front-to-back rendering improves speed, but also makes framerates inconsistent.
VolumeViewer.prototype.enableFrontToBackRendering = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.frontToBack !== enable) {
        this.frontToBack = enable;

        this.invalidateShader();
    }
};

// Returns true if front-to-back rendering is enabled in the viewer.
VolumeViewer.prototype.isFrontToBackRenderingEnabled = function() {
    return this.frontToBack;
};

// Enables or disables luminosity self-shadowing in the viewer.
VolumeViewer.prototype.enableLuminosityShadows = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.showShadowsLuminosity !== enable) {
        this.showShadowsLuminosity = enable;

        this.invalidateShader();
    }
};

// Enables or disables gradient self-shadowing in the viewer.
VolumeViewer.prototype.enableGradientShadows = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.showShadowsGradient !== enable) {
        this.showShadowsGradient = enable;

        this.invalidateShader();
    }
};

// Enables or disables self-shadowing in the viewer.
VolumeViewer.prototype.enableShadows = function(enableLum=true, enableGrad=undefined) {
    if (enableLum === undefined || enableLum === null)    enableLum = true;
    if (enableGrad === undefined || enableGrad === null)  enableGrad = enableLum;

    enableLum  = Boolean(enableLum);
    enableGrad = Boolean(enableGrad);

    if (this.showShadowsLuminosity !== enableLum ||
        this.showShadowsGradient !== enableGrad) {
        this.showShadowsLuminosity = enableLum;
        this.showShadowsGradient   = enableGrad;

        this.invalidateShader();
    }
};

// Returns true if luminosity self-shadowing is enabled in the viewer.
VolumeViewer.prototype.areLuminosityShadowsEnabled = function() {
    return this.showShadowsLuminosity;
};

// Returns true if gradient self-shadowing is enabled in the viewer.
VolumeViewer.prototype.areGradientShadowsEnabled = function() {
    return this.showShadowsGradient;
};

// Returns true if self-shadowing is enabled in the viewer.
VolumeViewer.prototype.areShadowsEnabled = function() {
    return this.showShadowsLuminosity || this.showShadowsGradient;
};

// Sets the multiplier used to lighten or darken shadows.
VolumeViewer.prototype.setShadowMultiplier = function(mult) {
    if      (mult === undefined || mult === null)  mult = 1.5;
    else if (mult <  0.0)  mult =  0.0;
    else if (mult > 10.0)  mult = 10.0;

    if (this.shadowMult !== mult) {
        this.shadowMult = mult;

        this.invalidateShader();
    }
};

// Gets the multiplier used to lighten or darken shadows.
VolumeViewer.prototype.getShadowMultiplier = function() {
    return this.shadowMult;
};

// Sets the minimum alpha value onto which a shadow may be projected.
VolumeViewer.prototype.setShadowSurfaceAlpha = function(alpha) {
    if      (alpha === undefined || alpha === null)  alpha = 0.0;
    else if (alpha < 0.0)  alpha = 0.0;
    else if (alpha > 1.0)  alpha = 1.0;

    if (this.minShadowAlpha !== alpha) {
        this.minShadowAlpha = alpha;

        this.invalidate();
    }
};

// Gets the minimum alpha value onto which a shadow may be projected.
VolumeViewer.prototype.getShadowSurfaceAlpha = function() {
    return this.minShadowAlpha;
};

// Sets the multiplier used to sample volume data for projecting shadows.
VolumeViewer.prototype.setShadowStepMultiplier = function(mult) {
    if (mult === undefined || mult === null)  mult = 1.0;
    else if (mult < 0.1)   mult = 0.1;
    else if (mult > 10.0)  mult = 10.0;

    if (this.shadowStepMult !== mult) {
        this.shadowStepMult = mult;

        this.invalidate();
    }
};

// Gets the multiplier used to sample volume data for projecting shadows.
VolumeViewer.prototype.getShadowStepMultiplier = function() {
    return this.shadowStepMult;
};

// Sets the ambient light level for fully darkened shadows.
VolumeViewer.prototype.setShadowAmbientLevel = function(ambient) {
    if (ambient === undefined || ambient === null)  ambient = 0.1;
    else if (ambient < 0.0)  ambient = 0.0;
    else if (ambient > 1.0)  ambient = 1.0;

    if (this.shadowAmbient !== ambient) {
        this.shadowAmbient = ambient;

        this.invalidateShader();
    }
};

// Gets the ambient light level for fully darkened shadows.
VolumeViewer.prototype.getShadowAmbientLevel = function() {
    return this.shadowAmbient;
};

// Sets the maximum sample density for the viewer, in samples per unit.
// Ideally, this should be greater than or equal to the number of samples
// on the longest side of the volume.
// If set to null, the resolution will be calculated automatically based on the
// size of the volume.
VolumeViewer.prototype.setSampleDensity = function(sampleDensity) {
    if      (!sampleDensity)      sampleDensity = null;
    else if (sampleDensity < 1)   sampleDensity = 1;
    else if (sampleDensity > 512) sampleDensity = 512;

    if (this.sampleCount !== sampleDensity)  {
        this.sampleCount = sampleDensity;

        this.invalidate();
    }
};

// Gets the maximum raytrace resolution for the viewer.
// If this is null, the resolution is calculated automatically based on the
// size of the volume.
VolumeViewer.prototype.getSampleDensity = function() {
    return this.sampleCount;
};

// Enables or disables automatic framerate adjustment in the viewer.
VolumeViewer.prototype.enableAutoAdjustFramerate = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.autoAdjustFramerate !== enable) {
        this.autoAdjustFramerate = enable;

        this.invalidate();
    }
};

// Returns true if automatic framerate adjustment is enabled in the viewer.
VolumeViewer.prototype.isAutoAdjustFramerateEnabled = function() {
    return this.autoAdjustFramerate;
};

// Returns the approximate number of frames per second at which the viewer
// is running, or null if not enough frames have been rendered to make this
// calculation meaningful.
VolumeViewer.prototype.getFps = function() {
    return this.fps;
};

// Enables or disables auto-rotation in the viewer.
VolumeViewer.prototype.enableAutoRotation = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.autoRotation !== enable) {
        this.autoRotation = enable;

        if (this.autoRotation) {
            this.startRotationRate = 0.0;
            this.currentRotationRate = 0.0;
            this.rotationAnimation.start();
        }
        else {
            this.rotationAnimation.stop();
            this.startRotationRate = 0.0;
            this.currentRotationRate = 0.0;
        }

        this.invalidate();
    }
};

// Returns true if auto-rotation is enabled in the viewer.
VolumeViewer.prototype.isAutoRotationEnabled = function() {
    return this.autoRotation;
};

// Sets the rotation rate for auto-rotation in the viewer, in revolutions per second.
VolumeViewer.prototype.setRotationRate = function(rps=0.0) {
    if (rps === undefined || rps === null)  return;

    if (this.rotationRate !== rps) {
        this.rotationRate = rps;

        this.startRotationRate = this.currentRotationRate;
        this.rotationAnimation.reset();

        this.invalidate();
    }
};

// Gets the rotation rate for auto-rotation in the viewer, in revolutions per second.
VolumeViewer.prototype.getRotationRate = function() {
    return this.rotationRate;
};

// Enables or disables the stereo view in the volume viewer.
VolumeViewer.prototype.enableStereoView = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.showStereo !== enable) {
        this.showStereo = enable;

        this.updateBackgroundCss();

        this.invalidate();
        this.invalidateOverlays();
    }
};

// Returns true if the stereo view is enabled.
VolumeViewer.prototype.isStereoViewEnabled = function() {
    return this.showStereo;
};

// Enables or disables parallel viewing when the stereo view is enabled
// in the volume viewer.
VolumeViewer.prototype.enableParallelStereo = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.showParallel !== enable) {
        this.showParallel = enable;

        this.invalidate();
        this.invalidateOverlays();
    }
};

// Returns true if parallel viewing is enabled.
VolumeViewer.prototype.isParallelStereoEnabled = function() {
    return this.showParallel;
};

// Sets the eye distance used to produce the stereo view.
VolumeViewer.prototype.setEyeDistance = function(eyeDistance) {
    if (eyeDistance === undefined || eyeDistance === null)  eyeDistance = 1.5;

    if      (eyeDistance < 0.0)  eyeDistance = 0.0;
    else if (eyeDistance > 4.0)  eyeDistance = 4.0;

    if (this.eyeDistance !== eyeDistance)  {
        this.eyeDistance = eyeDistance;

        this.invalidate();
        this.invalidateOverlays();
    }
};

// Gets the eye distance used to produce the stereo view.
VolumeViewer.prototype.getEyeDistance = function() {
    return this.eyeDistance;
};

// Sets the stereo separation used when displaying a volume in stereo mode.
// The separation represents the fraction of the image width by which
// the stereo images should be separated.
// Generally, the wider the separation, the further back you have to stand
// to see the 3D effect.
VolumeViewer.prototype.setStereoSeparation = function(separation) {
    if (separation === null || separation === undefined)  separation = 0.0;

    if (separation < 0.0)  separation = 0.0;
    if (separation > 4.0)  separation = 4.0;

    if (this.stereoSeparation !== separation) {
        this.stereoSeparation = separation;

        this.clearCachedViewports();
        this.invalidate();
        this.invalidateOverlays();
    }
};

// Gets the stereo separation used when displaying a volume in stereo mode.
// The separation represents the fraction of the image width by which
// the stereo images should be separated.
VolumeViewer.prototype.getStereoSeparation = function() {
    return this.stereoSeparation;
};

// Sets the minimum distance between a stereo image and the outer edge of the
// canvas.  The edge value represents the fraction of the image width
// used to pad the horizontal border of the image.
VolumeViewer.prototype.setStereoEdge = function(edge) {
    if (edge === null || edge === undefined)  edge = 0.0;

    if (edge < 0.0)  edge = 0.0;
    if (edge > 4.0)  edge = 4.0;

    if (this.stereoEdge !== edge) {
        this.stereoEdge = edge;

        this.clearCachedViewports();
        this.invalidate();
        this.invalidateOverlays();
    }
};

// Gets the minimum distance between a stereo image and the outer edge of the
// canvas.  The edge value represents the fraction of the image width
// used to pad the horizontal border of the image.
VolumeViewer.prototype.getStereoEdge = function() {
    return this.stereoEdge;
};

// Sets the width of a stereo image, as a fraction of half of the canvas width.
VolumeViewer.prototype.setStereoWidth = function(widthPercent) {
    if (widthPercent === null || widthPercent === undefined)  widthPercent = null;
    else if (widthPercent <= 0.0)  widthPercent = null;

    if (widthPercent !== null) {
        if (widthPercent > 1.0)  widthPercent = 1.0;
        if (widthPercent < 0.0)  widthPercent = 0.0;
    }

    if (this.stereoWidth !== widthPercent) {
        this.stereoWidth = widthPercent;

        this.clearCachedViewports();
        this.invalidate();
        this.invalidateOverlays();
    }
};

// Gets the width of a stereo image, as a fraction of half of the canvas width.
VolumeViewer.prototype.getStereoWidth = function() {
    return this.stereoWidth;
};

// Sets the preferred aspect ratio of a stereo image.  The viewer will try
// to honor this regardless of the aspect ratio of the canvas.
// Setting this to null will tell the viewer to take its best guess.
VolumeViewer.prototype.setStereoAspect = function(aspectRatio) {
    if (aspectRatio === null || aspectRatio === undefined)  aspectRatio = null;
    else if (aspectRatio <= 0.0)  aspectRatio = null;

    if (aspectRatio !== null) {
        if (aspectRatio > 4.00)  aspectRatio = 4.00;
        if (aspectRatio < 0.25)  aspectRatio = 0.25;
    }

    if (this.stereoAspect !== aspectRatio) {
        this.stereoAspect = aspectRatio;

        this.clearCachedViewports();
        this.invalidate();
        this.invalidateOverlays();
    }
};

// Gets the preferred aspect ratio of a stereo image.
VolumeViewer.prototype.getStereoAspect = function() {
    return this.stereoAspect;
};

// Enables or disables the mini-cube in the volume viewer.
// The mini-cube shows the anatomical orientation of the volume.
VolumeViewer.prototype.enableMiniCube = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.showMiniCube !== enable) {
        this.showMiniCube = enable;

        this.invalidate();
    }
};

// Returns true if the mini-cube is enabled.
VolumeViewer.prototype.isMiniCubeEnabled = function() {
    return this.showMiniCube;
};

// Sets the URL for the texture of the mini-cube.
VolumeViewer.prototype.setMiniCubeTextureURL = function(url) {
    if (!url)  url = null;

    if (this.miniCubeUrl !== url) {
        this.miniCubeUrl = url;

        this.releaseMiniTexture();

        this.invalidate();
    }
};

// Sets the highlight color for the selected face of the mini-cube, using
// individual red, green, blue and alpha values.
// All values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setMiniCubeHighlightColorRGBA = function(red, green, blue, alpha=1.0) {
    if (red === undefined   || red === null)    red   = this.outlineColor[0];
    if (green === undefined || green === null)  green = this.outlineColor[1];
    if (blue === undefined  || blue === null)   blue  = this.outlineColor[2];
    if (alpha === undefined || alpha === null)  alpha = this.outlineColor[3];

    if (red >= 1.0)    red = 1.0;
    if (red <= 0.0)    red = 0.0;

    if (green >= 1.0)  green = 1.0;
    if (green <= 0.0)  green = 0.0;

    if (blue >= 1.0)   blue = 1.0;
    if (blue <= 0.0)   blue = 0.0;

    if (alpha >= 1.0)  alpha = 1.0;
    if (alpha <= 0.0)  alpha = 0.0;

    if (this.miniCubeHighlightColor[0] !== red || this.miniCubeHighlightColor[1] !== green ||
        this.miniCubeHighlightColor[2] !== blue || this.miniCubeHighlightColor[3] !== alpha) {

        this.miniCubeHighlightColor = [red, green, blue, alpha];

        this.invalidate();
    }
};

// Sets the highlight color for the selected face of the mini-cube,
// as a color object with red, green, blue and alpha fields.
// All values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.setMiniCubeHighlightColor = function(color) {
    const red   = color.red;
    const green = color.green;
    const blue  = color.blue;
    const alpha = color.alpha;

    this.setMiniCubeHighlightColorRGBA(red, green, blue, alpha);
};

// Gets the highlight color for the selected face of the mini-cube,
// as a color object with red, green, blue and alpha fields.
// All values range from 0.0 to 1.0 inclusive.
VolumeViewer.prototype.getMiniCubeHighlightColor = function() {
    return {
        red:   this.miniCubeHighlightColor[0],
        green: this.miniCubeHighlightColor[1],
        blue:  this.miniCubeHighlightColor[2],
        alpha: this.miniCubeHighlightColor[3]
    };
};

// Sets the face number to highlight on the mini-cube.
VolumeViewer.prototype.setMiniCubeHighlightFace = function(face=undefined) {
    if (face !== VolumeViewer.FACE_LEFT &&
        face !== VolumeViewer.FACE_RIGHT &&
        face !== VolumeViewer.FACE_ANTERIOR &&
        face !== VolumeViewer.FACE_POSTERIOR &&
        face !== VolumeViewer.FACE_SUPERIOR &&
        face !== VolumeViewer.FACE_INFERIOR) {
        face = VolumeViewer.FACE_NONE;
    }

    if (this.miniCubeHighlightFace !== face)  {
        this.miniCubeHighlightFace = face;

        this.invalidate();
    }
};

// Gets the currently highlighted face number on the mini-cube.
VolumeViewer.prototype.getMiniCubeHighlightFace = function() {
    return this.miniCubeHighlightFace;
};

// Sets the relative screen position for the mini-cube, as individual x and y
// values.
// The values can range from -1 to 1 inclusive.
// The upper right corner is [1, 1].
VolumeViewer.prototype.setMiniCubePositionXY = function(x, y) {

    if (x === undefined || x === null)  x = this.miniCubePosition[0];
    if (y === undefined || y === null)  y = this.miniCubePosition[1];

    if      (x < -1.0)  x = -1.0;
    else if (x >  1.0)  x =  1.0;
    if      (y < -1.0)  y = -1.0;
    else if (y >  1.0)  y =  1.0;

    if (this.miniCubePosition[0] !== x || this.miniCubePosition[1] !== y) {
        this.miniCubePosition[0] = x;
        this.miniCubePosition[1] = y;

        this.invalidate();
    }
};

// Sets the relative screen position for the mini-cube, using a position object
// containing x and y fields.
// The values can range from -1 to 1 inclusive.
// The upper right corner is [1, 1].
VolumeViewer.prototype.setMiniCubePosition = function(position) {
   const x = position.x;
   const y = position.y;

   this.setMiniCubePositionXY(x, y);
};

// Gets the relative screen position for the mini-cube, as an object
// containing x and y fields.
// These range from -1.0 to 1.0 inclusive.
VolumeViewer.prototype.getMiniCubePosition = function() {
    return {
        x: this.miniCubePosition[0],
        y: this.miniCubePosition[1]
    };
};

// Sets the scale factor of the mini-cube, relative to the size of the
// actual volume.
// Valid range is [0.05 - 0.5] inclusive.
VolumeViewer.prototype.setMiniCubeScale = function(scale) {
    if (scale === undefined || scale === null)  scale = 0.12;

    if      (scale < 0.05)  scale = 0.05;
    else if (scale > 0.50)  scale = 0.50;

    if (this.miniCubeScale !== scale)  {
        this.miniCubeScale = scale;

        this.invalidate();
    }
};

// Gets the scale factor of the mini-cube, relative to the size of the
// actual volume.
VolumeViewer.prototype.getMiniCubeScale = function() {
    return this.miniCubeScale;
};

// Enables or disables overlays/annotations in the volume viewer.
VolumeViewer.prototype.enableAnnotations = function(enable=true) {
    if (!this.rootOverlay || !this.annotationOverlay)  return;

    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.rootOverlay.visible !== enable) {
        this.rootOverlay.visible = enable;

        // STM_TODO - do anything here?
    }
};

// Returns true if overlays/annotations are enabled.
VolumeViewer.prototype.areAnnotationsEnabled = function() {
    if (!this.rootOverlay || !this.annotationOverlay)  return false;
    return this.rootOverlay.visible;
};

// Converts a face identifier to an orientation string.
VolumeViewer.getOrientationFromFace = function(face) {
    // In reality, each face can have one of four possible orientations.
    // The following choices are based on orientation conventions.
    if      (face === VolumeViewer.FACE_LEFT)       return "PIR";
    else if (face === VolumeViewer.FACE_RIGHT)      return "AIL";
    else if (face === VolumeViewer.FACE_ANTERIOR)   return "LIP";
    else if (face === VolumeViewer.FACE_POSTERIOR)  return "RIA";
    else if (face === VolumeViewer.FACE_SUPERIOR)   return "RPI";
    else if (face === VolumeViewer.FACE_INFERIOR)   return "LPS";

    return null;  // default
};

// Sets the orientation of the volume to superior orientation.
VolumeViewer.prototype.setFaceSuperior = function(animate=undefined) {
    return this.setFace(VolumeViewer.FACE_SUPERIOR, animate);
};

// Sets the orientation of the volume to inferior orientation.
VolumeViewer.prototype.setFaceInferior = function(animate=undefined) {
    return this.setFace(VolumeViewer.FACE_INFERIOR, animate);
};

// Sets the orientation of the volume to anterior orientation.
VolumeViewer.prototype.setFaceAnterior = function(animate=undefined) {
    return this.setFace(VolumeViewer.FACE_ANTERIOR, animate);
};

// Sets the orientation of the volume to posterior orientation.
VolumeViewer.prototype.setFacePosterior = function(animate=undefined) {
    return this.setFace(VolumeViewer.FACE_POSTERIOR, animate);
};

// Sets the orientation of the volume to right orientation.
VolumeViewer.prototype.setFaceRight = function(animate=undefined) {
    return this.setFace(VolumeViewer.FACE_RIGHT, animate);
};

// Sets the orientation of the volume to left orientation.
VolumeViewer.prototype.setFaceLeft = function(animate=undefined) {
    return this.setFace(VolumeViewer.FACE_LEFT, animate);
};

// Sets the orientation of the volume to axial (inferior) orientation.
VolumeViewer.prototype.setFaceAxial = function(animate=undefined) {
    return this.setFaceInferior(animate);
};

// Sets the orientation of the volume to sagittal (left) orientation.
VolumeViewer.prototype.setFaceSagittal = function(animate=undefined) {
    return this.setFaceLeft(animate);
};

// Sets the orientation of the volume to coronal (anterior) orientation.
VolumeViewer.prototype.setFaceCoronal = function(animate=undefined) {
    return this.setFaceAnterior(animate);
};

// Sets the orientation of the volume to the face that is most exactly
// pointing towards the user.
VolumeViewer.prototype.setFaceNearest = function(animate=undefined) {
    let face = this.getFacingFace();
    return this.setFace(face, animate);
};

// Sets the orientation of the volume so that the specified face is facing
// the user.
// Setting this value to FACE_NONE will not change the orientation.
VolumeViewer.prototype.setFace = function(face, animate=undefined) {
    let orientation = VolumeViewer.getOrientationFromFace(face);
    if (orientation === null)  return false;

    return this.setFaceOrientation(orientation, animate);
};

// Sets the orientation of the volume in the viewer, using a standard
// volume orientation string.
// The first letter represents the side on the RIGHT.
// The second letter represents the side on the BOTTOM.
// The third letter represents the side facing AWAY from the user.
// This method enforces a right-handed coordinate system.
// If the specified orientation represents a left-handed coordinate system,
// the DEPTH direction will be flipped.
// Allowed characters in the string:
// "S" = superior
// "I" = inferior
// "A" = anterior
// "P" = posterior
// "R" = right
// "L" = left
// See the Volume class for more information.
VolumeViewer.prototype.setFaceOrientation = function(orientation, animate=undefined) {
    animate = this.sanitizeAnimate(animate);

    let newRotationMatrix = this.generateRotationFromOrientation(orientation);
    if (!newRotationMatrix)  return;

    let newZoom       = this.calculateZoomToFit(newRotationMatrix);
    let newZoomLinear = VolumeViewer.zoomScaledToLinear(newZoom);

    let newPropertyState = {
        RotationMatrix: newRotationMatrix,
        ZoomLinear:     newZoomLinear,
        ScreenPan:      VolumeViewer.EMPTY_2D_VECTOR,
        VolumeOffset:   VolumeViewer.EMPTY_2D_VECTOR
    };

    let animationTime = animate ? this.defaultStateAnimationTime * 2.5 : 0.0;

    this.animateToPropertyState(newPropertyState, null, animationTime);

    return true;
};

// Generates a rotation matrix from an standard volume orientation string.
// The first letter represents the side on the RIGHT.
// The second letter represents the side on the BOTTOM.
// The third letter represents the side facing AWAY from the user.
// This method enforces a right-handed coordinate system.
// If the specified orientation represents a left-handed coordinate system,
// the DEPTH direction will be flipped.
// Allowed characters in the string:
// "S" = superior
// "I" = inferior
// "A" = anterior
// "P" = posterior
// "R" = right
// "L" = left
// See the Volume class for more information.
VolumeViewer.prototype.generateRotationFromOrientation = function(orientation) {
    orientation = Volume.sanitizeOrientation(orientation);
    if (!orientation)  return null;

    // The rotation matrix uses something close to this orientation by
    // default; that is, using the identity matrix as a rotation matrix will
    // produce something like this orientation.
    // (There are slight differences in orientation convention, which
    // will be dealt with below.)
    const oldOrientation = "RAS";

    // Calculate the affine transformation matrix needed to convert from
    // our default orientation to the requested orientation
    let affine = Volume.generateAffine(oldOrientation, orientation);
    if (!affine)  return null;

    // Conveniences
    let a = affine[0][0];
    let b = affine[1][0];
    let c = affine[2][0];
    let d = affine[0][1];
    let e = affine[1][1];
    let f = affine[2][1];
    let g = affine[0][2];
    let h = affine[1][2];
    let i = affine[2][2];

    // Flip the first row and first column of the transformation matrix
    // (necessary due to differences in orientation convention)
    b = -b;
    c = -c;
    d = -d;
    g = -g;

    // Ensure this is a right-handed coordinate system
    let det = a*(e*i-f*h) + b*(f*g-d*i) + c*(d*h-e*g);
    if (det < 0) {
        // Flip the last column; this inverts the image in the depth direction
        c = -c;
        f = -f;
        i = -i;
    }

    let newRotationMatrix = {
        m00: a, m01: b, m02: c,
        m10: d, m11: e, m12: f,
        m20: g, m21: h, m22: i
    };

    return newRotationMatrix;
};

// Converts the specified 3D x/y/z coordinate (in world space) to a 2D x/y
// coordinate (in screen space).
VolumeViewer.prototype.pointToDisplay = function(x, y, z,
                                                 displayView=VolumeViewer.VIEW_UNKNOWN) {

    if (displayView === VolumeViewer.VIEW_UNKNOWN)
        displayView = this.getDefaultView();

    let viewport = this.pointToViewport(x, y, z, displayView);

    let display = this.viewportToDisplay(viewport.x, viewport.y, displayView);

    return {
        x: display.x,
        y: display.y,
        z: viewport.z
    };
};

// Converts the specified 3D x/y/z coordinate (in world space) to a 3D x/y/z
// coordinate (in viewport space).
VolumeViewer.prototype.pointToViewport = function(x, y, z,
                                                  displayView=VolumeViewer.VIEW_UNKNOWN) {
    let worldPos  = vec3.fromValues(x, y, z);
    let screenPos = vec3.create();

    if (displayView === VolumeViewer.VIEW_UNKNOWN)
        displayView = this.getDefaultView();

    let forwardMatrix = this.forwardMatrix;
    if (displayView === VolumeViewer.VIEW_LEFT)
        forwardMatrix = this.stereoForwardMatrix[0];
    else if (displayView === VolumeViewer.VIEW_RIGHT)
        forwardMatrix = this.stereoForwardMatrix[1];

    vec3.transformMat4(screenPos, worldPos, forwardMatrix);

    let viewportX = screenPos[0];
    let viewportY = screenPos[1];
    let viewportZ = screenPos[2];

    return {
        x: viewportX,
        y: viewportY,
        z: viewportZ
    };
};

// Converts the specified 2D x/y coordinate (in screen space) to a 3D line
// (in world space) with a normalized direction vector.
VolumeViewer.prototype.displayToLine = function(displayX, displayY,
                                                displayView=VolumeViewer.VIEW_UNKNOWN) {

    if (displayView === VolumeViewer.VIEW_UNKNOWN)
        displayView = this.displayToView(displayX, displayY);

    let viewport = this.displayToViewport(displayX, displayY, displayView);

    let worldPos0 = vec3.create();
    let worldPos1 = vec3.create();
    let screenPos0 = vec3.fromValues(viewport.x, viewport.y, 0.0);
    let screenPos1 = vec3.fromValues(viewport.x, viewport.y, 1.0);

    let inverseMatrix = this.inverseMatrix;
    if (displayView === VolumeViewer.VIEW_LEFT)
        inverseMatrix = this.stereoInverseMatrix[0];
    else if (displayView === VolumeViewer.VIEW_RIGHT)
        inverseMatrix = this.stereoInverseMatrix[1];

    vec3.transformMat4(worldPos0, screenPos0, inverseMatrix);
    vec3.transformMat4(worldPos1, screenPos1, inverseMatrix);

    let dx = worldPos1[0] - worldPos0[0];
    let dy = worldPos1[1] - worldPos0[1];
    let dz = worldPos1[2] - worldPos0[2];
    let dirVec = VolumeViewer.normalize(dx, dy, dz, 0.0, 0.0, 1.0);

    let point = {
        x: worldPos0[0],
        y: worldPos0[1],
        z: worldPos0[2]
    };
    let dir = {
        x: dirVec[0],
        y: dirVec[1],
        z: dirVec[2]
    };
    return {
        point: point,
        dir:   dir
    };
};

// Returns the intersection point and plane number underneath the specified
// mouse coordinates, using only the facing plane and ignoring the other
// two planes.
// If no plane is at the specified coordinates, or planar view is not enabled,
// this method returns null.
VolumeViewer.prototype.getFacingPlaneIntersection = function(mouseX, mouseY, insideOnly=false) {
    let facingPlane = this.getFacingPlane();
    return this.getPlaneIntersection(mouseX, mouseY, insideOnly, [Math.abs(facingPlane)]);
};

// Returns the intersection point and plane number underneath the specified
// mouse coordinates.
// If no plane is at the specified coordinates, or planar view is not enabled,
// this method returns null.
VolumeViewer.prototype.getPlaneIntersection = function(mouseX, mouseY,
                                                       insideOnly=false, planeNumbers=null) {
    if (!this.canShowPlanes())
        return null;

    if (planeNumbers === undefined || planeNumbers === null)
        planeNumbers = [1, 2, 3];

    let absPlaneNumbers = [];
    for (let i=0; i<planeNumbers.length; ++i)
        absPlaneNumbers.push(Math.abs(planeNumbers[i]));
    planeNumbers = absPlaneNumbers;

    let lineData = this.displayToLine(mouseX, mouseY);

    let extents = this.getVolumeExtents();
    const planeData   = this.getInternalPlaneData();
    const planePoint  = planeData.point;
    const planeMatrix = planeData.matrix;
    const px = planePoint[0];
    const py = planePoint[1];
    const pz = planePoint[2];
    const autoPlane = this.autoPlane;

    const line = vec3.fromValues(lineData.dir.x, lineData.dir.y, lineData.dir.z);
    const line0 = vec3.fromValues(lineData.point.x, lineData.point.y, lineData.point.z);

    let bestPlane     = 0;
    let bestDistSq    = null;
    let bestIntersect = null;

    function calculatePlaneIntersection(plane, x, y, z) {
        let axis = vec3.fromValues(x, y, z);
        let denom = vec3.dot(line, axis);
        if (Math.abs(denom) > 1e-7) {
            let delta = vec3.fromValues(px-line0[0], py-line0[1], pz-line0[2]);
            let num = vec3.dot(delta, axis);
            let d = num / denom;
            let intersect = vec3.fromValues(d*line[0]+line0[0], d*line[1]+line0[1], d*line[2]+line0[2]);
            let inside = Math.abs(intersect[0]) < extents.x &&
                         Math.abs(intersect[1]) < extents.y &&
                         Math.abs(intersect[2]) < extents.z;
            if (inside || !insideOnly) {
                let distSq = vec3.squaredDistance(intersect, line0);
                if (bestDistSq === null || (bestDistSq > distSq)) {
                    bestDistSq    = distSq;
                    bestIntersect = intersect;
                    bestPlane     = plane;
                }
            }
        }
    }

    if (planeNumbers.includes(1) && this.planeAlphaLevels[0] > 0.0 && !this.autoPlane)
        calculatePlaneIntersection(1, planeMatrix[0], planeMatrix[1], planeMatrix[2]);
    if (planeNumbers.includes(2) && this.planeAlphaLevels[1] > 0.0 && !this.autoPlane)
        calculatePlaneIntersection(2, planeMatrix[3], planeMatrix[4], planeMatrix[5]);
    if (planeNumbers.includes(3) && this.planeAlphaLevels[2] > 0.0)
        calculatePlaneIntersection(3, planeMatrix[6], planeMatrix[7], planeMatrix[8]);

    if (bestIntersect === null)  return null;

    return {
        point: {
            x: bestIntersect[0],
            y: bestIntersect[1],
            z: bestIntersect[2]
        },
        plane: bestPlane
    };
};

// Returns the intersection point underneath the specified mouse coordinates.
// Uses a plane normal and point provided by the caller.
// If no plane is at the specified coordinates, or planar view is not enabled,
// this method returns null.
VolumeViewer.prototype.getLinePlaneIntersection = function(mouseX, mouseY,
                                                           planePoint, planeNormal,
                                                           insideOnly=false,) {
    // STM_TODO - THIS IS NEW AND SHOULD BE REFACTORED
    let lineData = this.displayToLine(mouseX, mouseY);

    planePoint = this.convertToVolumeOffset(planePoint);
    let extents = this.getVolumeExtents();
    const px = planePoint.x;
    const py = planePoint.y;
    const pz = planePoint.z;

    const line = vec3.fromValues(lineData.dir.x, lineData.dir.y, lineData.dir.z);
    const line0 = vec3.fromValues(lineData.point.x, lineData.point.y, lineData.point.z);

    let bestDistSq    = null;
    let bestIntersect = null;

    // STM_TODO - use Vmath here
    function calculatePlaneIntersection(x, y, z) {
        let axis = vec3.fromValues(x, y, z);
        let denom = vec3.dot(line, axis);
        if (Math.abs(denom) > 1e-7) {
            let delta = vec3.fromValues(px-line0[0], py-line0[1], pz-line0[2]);
            let num = vec3.dot(delta, axis);
            let d = num / denom;
            let intersect = vec3.fromValues(d*line[0]+line0[0], d*line[1]+line0[1], d*line[2]+line0[2]);
            let inside = Math.abs(intersect[0]) < extents.x &&
                         Math.abs(intersect[1]) < extents.y &&
                         Math.abs(intersect[2]) < extents.z;
            if (inside || !insideOnly) {
                let distSq = vec3.squaredDistance(intersect, line0);
                if (bestDistSq === null || (bestDistSq > distSq)) {
                    bestDistSq    = distSq;
                    bestIntersect = intersect;
                }
            }
        }
    }

    calculatePlaneIntersection(planeNormal.x, planeNormal.y, planeNormal.z);
    if (bestIntersect === null)  return null;

    return {
        point: {
            x: bestIntersect[0],
            y: bestIntersect[1],
            z: bestIntersect[2]
        },
        distSquared: bestDistSq
    };
};

// Returns the face on the mini-cube underneath the specified mouse
// coordinates.
// If no face is at the specified coordinates, or the mini-cube is not enabled,
// this method returns FACE_NONE.
VolumeViewer.prototype.getMiniCubeFace = function(mouseX, mouseY,
                                                  displayView=VolumeViewer.VIEW_UNKNOWN) {

    if (!this.showMiniCube)  return VolumeViewer.FACE_NONE;
    if (!this.miniCubeUrl)  return VolumeViewer.FACE_NONE;

    if (displayView === VolumeViewer.VIEW_UNKNOWN)
        displayView = this.displayToView(mouseX, mouseY);

    let viewport = this.displayToViewport(mouseX, mouseY, displayView);

    let worldPos0 = vec3.create();
    let worldPos1 = vec3.create();
    let screenPos0 = vec3.fromValues(viewport.x, viewport.y, 0.5);
    let screenPos1 = vec3.fromValues(viewport.x, viewport.y, 1.0);

    let miniCubeInverseMatrix = this.miniCubeInverseMatrix;
    if (displayView === VolumeViewer.VIEW_LEFT)
        miniCubeInverseMatrix = this.stereoMiniCubeInverseMatrix[0];
    else if (displayView === VolumeViewer.VIEW_RIGHT)
        miniCubeInverseMatrix = this.stereoMiniCubeInverseMatrix[1];

    vec3.transformMat4(worldPos0, screenPos0, miniCubeInverseMatrix);
    vec3.transformMat4(worldPos1, screenPos1, miniCubeInverseMatrix);

    let line = vec3.create();
    vec3.subtract(line, worldPos1, worldPos0);
    let line0 = worldPos0;

    let bestFace = VolumeViewer.FACE_NONE;
    let bestDistSq = null;
    function calculateFaceIntersection(face, x, y, z) {
        let axis = vec3.fromValues(x, y, z);
        let denom = vec3.dot(line, axis);
        if (Math.abs(denom) > 1e-7) {
            let delta = vec3.fromValues(axis[0]-line0[0], axis[1]-line0[1], axis[2]-line0[2]);
            let num = vec3.dot(delta, axis);
            let d = num / denom;
            let intersect = vec3.fromValues(d*line[0]+line0[0], d*line[1]+line0[1], d*line[2]+line0[2]);
            const insideVal = 1.00001;
            if (Math.abs(intersect[0]) < insideVal && Math.abs(intersect[1]) < insideVal && Math.abs(intersect[2]) < insideVal) {
                let distSq = vec3.squaredDistance(intersect, line0);
                if (bestDistSq === null || (bestDistSq > distSq)) {
                    bestDistSq = distSq;
                    bestFace = face;
                }
            }
        }
    }

    calculateFaceIntersection(VolumeViewer.FACE_LEFT,      -1.0,  0.0,  0.0);
    calculateFaceIntersection(VolumeViewer.FACE_RIGHT,      1.0,  0.0,  0.0);
    calculateFaceIntersection(VolumeViewer.FACE_ANTERIOR,   0.0, -1.0,  0.0);
    calculateFaceIntersection(VolumeViewer.FACE_POSTERIOR,  0.0,  1.0,  0.0);
    calculateFaceIntersection(VolumeViewer.FACE_SUPERIOR,   0.0,  0.0, -1.0);
    calculateFaceIntersection(VolumeViewer.FACE_INFERIOR,   0.0,  0.0,  1.0);

    return bestFace;
} ;

// Sets vertex and fragment shader scripts for the viewer.
VolumeViewer.prototype.setShaders = function(vertShader, fragShader) {
    vertShader = VolumeViewer.getShaderScript(vertShader, VolumeViewer.VERTEX_SHADER_TYPE);

    if (vertShader === null) {
        return;
    }

    fragShader = VolumeViewer.getShaderScript(fragShader, VolumeViewer.FRAGMENT_SHADER_TYPE);

    if (fragShader === null) {
        return;
    }

    if (this.vertShaderScript !== vertShader || this.fragShaderScript !== fragShader) {
        this.vertShaderScript = vertShader;
        this.fragShaderScript = fragShader;

        this.shaderOptions = null;

        this.invalidateShader();
    }
};

// Sets the luminosity color lookup table for the viewer.
VolumeViewer.prototype.setLuminosityTable = function(table, index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return;

    let array = VolumeViewer.convertColorTable(table);
    if (array === null)  array = this.perVolume[index].defaultLuminosityArray;
    if (array !== null) {
        if (!VolumeViewer.deepEquals(this.perVolume[index].luminosityArray, array)) {
            this.perVolume[index].luminosityArray    = array;
            this.perVolume[index].luminosityMaxAlpha = VolumeViewer.computeMaxAlpha(array);
            this.perVolume[index].stripTexture       = null;

            this.invalidate();

            this.invokeActionCallbacks(VolumeViewer.ACTION_COLORS);
        }
    }
};

// Property convenience method.
VolumeViewer.prototype.setLuminosityTable0 = function(table) {
    return this.setLuminosityTable(table, 0);
};

// Property convenience method.
VolumeViewer.prototype.setLuminosityTable1 = function(table) {
    return this.setLuminosityTable(table, 1);
};

// Sets the luminosity color lookup table for the viewer.
VolumeViewer.prototype.getLuminosityTable = function(index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return null;

    let array = this.perVolume[index].luminosityArray;
    if (array === undefined || array === null)  return null;

    let table = VolumeViewer.generateColorTable(array);
    return table;
};

// Property convenience method.
VolumeViewer.prototype.getLuminosityTable0 = function() {
    return this.getLuminosityTable(0);
};

// Property convenience method.
VolumeViewer.prototype.getLuminosityTable1 = function() {
    return this.getLuminosityTable(1);
};

// Sets the gradient color lookup table for the viewer.
VolumeViewer.prototype.setGradientTable = function(table, index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return;

    let array = VolumeViewer.convertColorTable(table);
    if (array === null)  array = this.perVolume[index].defaultGradientArray;
    if (array !== null) {
        if (!VolumeViewer.deepEquals(this.perVolume[index].gradientArray, array)) {
            this.perVolume[index].gradientArray    = array;
            this.perVolume[index].gradientMaxAlpha = VolumeViewer.computeMaxAlpha(array);
            this.perVolume[index].stripTexture     = null;

            this.invalidate();

            this.invokeActionCallbacks(VolumeViewer.ACTION_COLORS);
        }
    }
};

// Property convenience method.
VolumeViewer.prototype.setGradientTable0 = function(table) {
    return this.setGradientTable(table, 0);
};

// Property convenience method.
VolumeViewer.prototype.setGradientTable1 = function(table) {
    return this.setGradientTable(table, 1);
};

// Gets the gradient color lookup table for the viewer.
VolumeViewer.prototype.getGradientTable = function(index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return null;

    let array = this.perVolume[index].gradientArray;
    if (array === undefined || array === null)  return null;

    let table = VolumeViewer.generateColorTable(array);
    return table;
};

// Property convenience method.
VolumeViewer.prototype.getGradientTable0 = function() {
    return this.getGradientTable(0);
};

// Property convenience method.
VolumeViewer.prototype.getGradientTable1 = function() {
    return this.getGradientTable(1);
};

// Sets the planar color lookup table for the viewer.
VolumeViewer.prototype.setPlaneTable = function(table, index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return;

    let array = VolumeViewer.convertColorTable(table);
    if (array === null)  array = this.perVolume[index].defaultPlaneArray;
    if (array !== null) {
        if (!VolumeViewer.deepEquals(this.perVolume[index].planeArray, array)) {
            this.perVolume[index].planeArray    = array;
            this.perVolume[index].planeMaxAlpha = VolumeViewer.computeMaxAlpha(array);
            this.perVolume[index].stripTexture  = null;

            this.invalidate();

            this.invokeActionCallbacks(VolumeViewer.ACTION_COLORS);
        }
    }
};

// Property convenience method.
VolumeViewer.prototype.setPlaneTable0 = function(table) {
    return this.setPlaneTable(table, 0);
};

// Property convenience method.
VolumeViewer.prototype.setPlaneTable1 = function(table) {
    return this.setPlaneTable(table, 1);
};

// Gets the planar color lookup table for the viewer.
VolumeViewer.prototype.getPlaneTable = function(index=0) {
    if (index < 0 || index >= VolumeViewer.MAX_VOLUMES)  return null;

    let array = this.perVolume[index].planeArray;
    if (array === undefined || array === null)  return null;

    let table = VolumeViewer.generateColorTable(array);
    return table;
};

// Property convenience method.
VolumeViewer.prototype.getPlaneTable0 = function() {
    return this.getPlaneTable(0);
};

// Property convenience method.
VolumeViewer.prototype.getPlaneTable1 = function() {
    return this.getPlaneTable(1);
};

// Sets the behavior for mouse and touchpad manipulation.
// If this is set to INPUT_MODE_NONE, mouse and touchpad events will not be
// processed, and the user is free to create an entirely new handler for
// these events.
VolumeViewer.prototype.setInputMode = function(inputMode)  {
    if (!inputMode || (typeof inputMode !== 'string'))
        inputMode = VolumeViewer.INPUT_MODE_NONE;

    let inputHandler = null;
    if (inputMode in this.inputHandlerMap) {
        inputHandler = this.inputHandlerMap[inputMode];
    }
    else {
        dconsole.warn(`WARNING: Input mode ${inputMode} not recognized by viewer`);
    }

    if (this.inputMode !== inputMode || this.inputHandler !== inputHandler) {
        this.endDrag();

        this.inputMode    = inputMode;
        this.inputHandler = this.inputHandlerMap[inputMode];
    }
};

// Gets the behavior for mouse and touchpad manipulation.
VolumeViewer.prototype.getInputMode = function() {
    return this.inputMode;
};

// Sets the behavior for mouse and touchpad manipulation,
// by allowing the user to specify a named "bank" of keyboard, mouse and
// touchpad handlers.
// This makes it easier to switch modes and have keyboard/mouse/touchpad
// events be context-dependent.
// The handler is assigned to a named input mode, which the caller
// can select at a later time.
VolumeViewer.prototype.addInputMode = function(inputMode, inputHandler) {
    if (!inputMode || (typeof inputMode !== 'string'))  return;

    if (typeof inputHandler !== 'object')
        inputHandler = null;

    if (inputMode in this.inputHandlerMap) {
        if (this.inputHandlerMap[inputMode] === inputHandler) {
            return;  // done!
        }
    }

    this.inputHandlerMap[inputMode] = inputHandler;

    if (inputMode === this.inputMode) {
        this.endDrag();

        this.inputHandler = inputHandler;
    }
};

// Removes the handlers associated with a named input mode.
// Predefined input modes cannot be removed.
VolumeViewer.prototype.removeInputMode = function(inputMode) {
    if (!inputMode || (typeof inputMode !== 'string'))  return;

    if (inputMode.startsWith('_')) {
        dconsole.warn(`WARNING: Cannot remove input mode ${inputMode}; name is reserved`);
        return;
    }

    if (inputMode in this.inputHandlerMap) {
        delete this.inputHandlerMap[inputMode];

        if (inputMode === this.inputMode) {
            this.endDrag();

            this.inputHandler = null;
        }
    }
};

// Sets the behavior for mouse and touchpad manipulation,
// by allowing the user to specify a "bank" of keyboard, mouse and touchpad
// handlers.
// This makes it easier to switch modes and have keyboard/mouse/touchpad
// events be context-dependent.
// NOTE: THIS METHOD IS OBSOLESCENT.  USE addInputMode() AND setInputMode
// INSTEAD!
VolumeViewer.prototype.setInputHandlers = function(newInputHandler)  {
    if (newInputHandler === this.inputHandler)  return;  // no change

    let newInputMode = undefined;
    let inputModes = Object.keys(this.inputHandlerMap);
    for (let inputMode in inputModes) {
        let handlers = this.inputHandlerMap[inputMode];
        if (newInputHandler === handlers) {
            newInputMode = inputMode;
            break;
        }
    }
    if (newInputMode === undefined) {
        dconsole.error("WARNING: Use of setInputHandlers() to define custom " +
                       "input handlers is deprecated.  Use addInputMode() " +
                       "and setInputMode() instead.");
        newInputMode = VolumeViewer.INPUT_MODE_CUSTOM;
        this.inputHandlerMap[newInputMode] = newInputHandler;
    }

    this.endDrag();

    this.inputMode    = newInputMode;
    this.inputHandler = newInputHandler;

    VolumeViewer.checkDeprecatedInputHandlers(this.inputHandler);
};

// Enables or disables snap-back when using the rocker.
VolumeViewer.prototype.enableRockerSnapBack = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    this.rockerSnapBack = enable;
};

// Returns true if rocker snap-back is enabled.
VolumeViewer.prototype.isRockerSnapBackEnabled = function() {
    return this.rockerSnapBack;
};

// Allows or disallows user rotation.
VolumeViewer.prototype.allowUserRotation = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    this.canUserRotate = enable;
};

// Returns true if user rotation is allowed.
VolumeViewer.prototype.isUserRotationAllowed = function() {
    return this.canUserRotate;
};

// Allows or disallows user zoom.
VolumeViewer.prototype.allowUserZoom = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    this.canUserZoom = enable;
};

// Returns true if user zoom is allowed.
VolumeViewer.prototype.isUserZoomAllowed = function() {
    return this.canUserZoom;
};

// Allows or disallows user pan.
VolumeViewer.prototype.allowUserPan = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    this.canUserPan = enable;
};

// Returns true if user pan is allowed.
VolumeViewer.prototype.isUserPanAllowed = function() {
    return this.canUserPan;
};

// Allows or disallows user roll.
VolumeViewer.prototype.allowUserRoll = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    this.canUserRoll = enable;
};

// Returns true if user roll is allowed.
VolumeViewer.prototype.isUserRollAllowed = function() {
    return this.canUserRoll;
};

// Allows or disallows user plane movement.
VolumeViewer.prototype.allowUserMovePlane = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    this.canUserMovePlane = enable;
};

// Returns true if user plane movement is allowed.
VolumeViewer.prototype.isUserMovePlaneAllowed = function() {
    return this.canUserMovePlane;
};

// Allows or disallows user clip movement.
VolumeViewer.prototype.allowUserMoveClip = function(enable=true) {
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    this.canUserMoveClip = enable;
};

// Returns true if user clip movement is allowed.
VolumeViewer.prototype.isUserMoveClipAllowed = function() {
    return this.canUserMoveClip;
};

// Allows or disallows user editing of annotations.
VolumeViewer.prototype.allowUserMoveAnnotations = function(enable=true) {
    if (!this.rootOverlay || !this.annotationOverlay)  return;
    if (enable === undefined || enable === null)  return;

    enable = Boolean(enable);

    if (this.canUserMoveAnnotations !== enable) {
        this.canUserMoveAnnotations = enable;

        if (!enable) {
            this.rootOverlay.unhighlightAll();
            this.rootOverlay.unselectAll();
        }
    }
};

// Returns true if user editing of annotations is allowed.
VolumeViewer.prototype.isUserMoveAnnotationsAllowed = function() {
    if (!this.rootOverlay || !this.annotationOverlay)  return false;
    return this.canUserMoveAnnotations;
};

// Based on a user action, resets thresholds to the maximum range for the
// input window.
VolumeViewer.prototype.userResetThresholds = function(animate=undefined) {
    animate = this.sanitizeAnimate(animate);

    let range = { minValue: 0.0, maxValue: 1.0 };

    let propertyState = {
        LuminosityRange0: range,
        LuminosityRange1: range,
        GradientRange0:   range,
        GradientRange1:   range,
    };

    this.animateToPropertyState(propertyState, null, animate);

    return true;
};

// Based on a user action, resets auto thresholds for the input window.
VolumeViewer.prototype.userResetAutoThresholds = function(animate=undefined) {
    animate = this.sanitizeAnimate(animate);

    let luminosityRange0 = this.getAutoLuminosityRange(0);
    let luminosityRange1 = this.getAutoLuminosityRange(1);
    let gradientRange0   = this.getAutoGradientRange(0);
    let gradientRange1   = this.getAutoGradientRange(1);

    let propertyState = {
        LuminosityRange0: luminosityRange0,
        LuminosityRange1: luminosityRange1,
        GradientRange0:   gradientRange0,
        GradientRange1:   gradientRange1,
    };

    this.animateToPropertyState(propertyState, null, animate);

    return true;
};

// Based on a user action, pans the volume across the screen.
VolumeViewer.prototype.userPan = function(x, y, animate=undefined) {
    if (!this.isUserPanAllowed())  return false;

    animate = this.sanitizeAnimate(animate);

    if (x === undefined || x === null)  x = 0.0;
    if (y === undefined || y === null)  y = 0.0;

    const USER_PAN_STEP = 0.1;

    this.stopInternalAnimations();

    let pan = this.getScreenPan();
    if (x)  pan.x += x * USER_PAN_STEP;
    if (y)  pan.y += y * USER_PAN_STEP;

    let propertyState = {
        ScreenPan: pan
    };

    this.animateToPropertyState(propertyState, null, animate);

    return true;
};

// Based on a user action, rolls the volume around an axis perpendicular to
// the screen.
VolumeViewer.prototype.userRoll = function(angle, animate=undefined) {
    if (!this.isUserRollAllowed() || !this.isUserRotationAllowed())  return false;

    animate = this.sanitizeAnimate(animate);

    return this.stepRoll(angle, animate);
};

// Based on a user action, flips the volume horizontally.
VolumeViewer.prototype.userFlipHorizontal = function(animate=undefined) {
    if (!this.isUserRotationAllowed())  return false;

    animate = this.sanitizeAnimate(animate);

    return this.stepFlipHorizontal(animate);
};

// Based on a user action, flips the volume vertically.
VolumeViewer.prototype.userFlipVertical = function(animate=undefined) {
    if (!this.isUserRotationAllowed())  return false;

    animate = this.sanitizeAnimate(animate);

    return this.stepFlipVertical(animate);
};

// Based on a user action, zooms into or out of the volume.
VolumeViewer.prototype.userZoom = function(step, animate=undefined) {
    if (!this.isUserZoomAllowed())  return false;

    animate = this.sanitizeAnimate(animate);

    if (step === undefined || step === null)  return true;

    this.stopInternalAnimations();

    let zoom = this.getZoom();
    step = Math.pow(1.5, 0.5*step);
    zoom *= step;
    if (zoom < this.minZoom)  zoom = this.minZoom;
    if (zoom > this.maxZoom)  zoom = this.maxZoom;
    let zoomLinear = VolumeViewer.zoomScaledToLinear(zoom);

    let propertyState = {
        ZoomLinear: zoomLinear
    };

    this.animateToPropertyState(propertyState, null, animate);

    return true;
};

// Based on a user action, zooms into or out of the volume based on the specified step.
// Unlike userZoom(), this also zooms towards/away from the specified
// display location, Google Maps-style.
// Returns true if this event was handled.
VolumeViewer.prototype.userStepZoomToDisplay = function(zoomStep, displayX, displayY,
                                                        displayView=VolumeViewer.VIEW_UNKNOWN,
                                                        animate=undefined) {

    if (!this.isUserZoomAllowed())  return false;

    if (!this.isUserPanAllowed())
        return this.userZoom(zoomStep, animate);

    animate = this.sanitizeAnimate(animate);

    this.stopInternalAnimations();

    return this.stepZoomToDisplay(zoomStep, displayX, displayY, displayView, animate);
};

// Based on a user action, sets the number of the slice being displayed in a
// 2D view.
// Note that the slice numbers used by this method are one-based, not
// zero-based -- that is, the first slice is 1, not 0.
VolumeViewer.prototype.userSetFacingPlaneSlice = function(sliceNumber, animate=undefined) {
    if (!this.isUserMovePlaneAllowed())  return false;

    animate = this.sanitizeAnimate(animate);

    return this.setFacingPlaneSlice(sliceNumber, animate);
};

// Based on a user action, moves the facing plane inward or outward based
// on the specified step.
VolumeViewer.prototype.userStepFacingPlane = function(step, animate=undefined) {
    if (!this.isUserMovePlaneAllowed())  return false;

    animate = this.sanitizeAnimate(animate);

    return this.stepFacingPlane(step, animate);
};

// Based on a user action, moves the clipping plane up or down based
// on the specified step.
VolumeViewer.prototype.userStepClippingPlane = function(step, animate=undefined) {
    if (!this.isUserMoveClipAllowed())  return false;

    animate = this.sanitizeAnimate(animate);

    return this.stepFacingPlane(step, animate);
};

// Based on a user action, moves the facing plane inward or outward,
// or the clipping plane up or down, based on the specified step.
VolumeViewer.prototype.userStepFacingPlaneClip = function(step, animate=undefined) {
    let canMovePlanes   = this.canShowPlanes() && this.isUserMovePlaneAllowed();
    let canMoveClipping = this.canShowClipping() && this.isUserMoveClipAllowed();

    if (canMovePlanes) {
        return this.stepFacingPlane(step, animate);
    }
    else if (canMoveClipping) {
        if (animate === undefined || animate === null)  animate = true;
        return this.stepClippingPlane(step, animate);
    }

    return false;
};

// Based on a user action, moves the viewed volume to the center of the
// viewport, and sets the zoom level such that the volume fills up as much of
// the viewport as possible.
VolumeViewer.prototype.userFitToViewport = function(fraction=undefined, animate=undefined) {
    if (!this.isUserZoomAllowed())  return false;

    return this.fitToViewport(fraction, animate);
};

// Based on a user action, sets the orientation of the volume to
// superior orientation.
VolumeViewer.prototype.userSetFaceSuperior = function(animate=undefined) {
    if (!this.isUserRotationAllowed())  return false;

    return this.setFaceSuperior(animate);
};

// Based on a user action, sets the orientation of the volume to
// inferior orientation.
VolumeViewer.prototype.userSetFaceInferior = function(animate=undefined) {
    if (!this.isUserRotationAllowed())  return false;

    return this.setFaceInferior(animate);
};

// Based on a user action, sets the orientation of the volume to
// anterior orientation.
VolumeViewer.prototype.userSetFaceAnterior = function(animate=undefined) {
    if (!this.isUserRotationAllowed())  return false;

    return this.setFaceAnterior(animate);
};

// Based on a user action, sets the orientation of the volume to
// posterior orientation.
VolumeViewer.prototype.userSetFacePosterior = function(animate=undefined) {
    if (!this.isUserRotationAllowed())  return false;

    return this.setFacePosterior(animate);
};

// Based on a user action, sets the orientation of the volume to
// right orientation.
VolumeViewer.prototype.userSetFaceRight = function(animate=undefined) {
    if (!this.isUserRotationAllowed())  return false;

    return this.setFaceRight(animate);
};

// Based on a user action, sets the orientation of the volume to
// left orientation.
VolumeViewer.prototype.userSetFaceLeft = function(animate=undefined) {
    if (!this.isUserRotationAllowed())  return false;

    return this.setFaceLeft(animate);
};

// Based on a user action, sets the orientation of the volume to
// axial (inferior) orientation.
VolumeViewer.prototype.userSetFaceAxial = function(animate=undefined) {
    if (!this.isUserRotationAllowed())  return false;

    return this.setFaceAxial(animate);
};

// Based on a user action, sets the orientation of the volume to
// sagittal (left) orientation.
VolumeViewer.prototype.userSetFaceSagittal = function(animate=undefined) {
    if (!this.isUserRotationAllowed())  return false;

    return this.setFaceSagittal(animate);
};

// Based on a user action, sets the orientation of the volume to
// coronal (anterior) orientation.
VolumeViewer.prototype.userSetFaceCoronal = function(animate=undefined) {
    if (!this.isUserRotationAllowed())  return false;

    return this.setFaceCoronal(animate);
};

// Based on a user action, sets the orientation of the volume to the face that
// is most exactly pointing towards the user.
VolumeViewer.prototype.userSetFaceNearest = function(animate=undefined) {
    if (!this.isUserRotationAllowed())  return false;

    return this.setFaceNearest(animate);
};

// Selects and shows the next selectable overlay.
VolumeViewer.prototype.userSelectNext = function(topSelectedOnly=true) {
    if (!this.annotationOverlay)  return false;
    if (!this.isUserMoveAnnotationsAllowed())  return false;

    let selected = this.annotationOverlay.selectNext(topSelectedOnly);
    this.annotationOverlay.showSelected();
    return true;
};

// Selects and shows the previous selectable overlay.
VolumeViewer.prototype.userSelectPrev = function(topSelectedOnly=true) {
    if (!this.annotationOverlay)  return false;
    if (!this.isUserMoveAnnotationsAllowed())  return false;

    let selected = this.annotationOverlay.selectPrev(topSelectedOnly);
    this.annotationOverlay.showSelected();
    return true;
};

// Shows the currently selected overlay.
VolumeViewer.prototype.userShowSelected = function() {
    if (!this.annotationOverlay)  return false;
    if (!this.isUserMoveAnnotationsAllowed())  return false;

    this.annotationOverlay.showSelected();
    return true;
};

// Based on a user action, sets the orientation of the volume so that the
// specified face is facing the user.
// Setting this value to FACE_NONE will not change the orientation.
VolumeViewer.prototype.userSetFace = function(face, animate=undefined) {
    if (!this.isUserRotationAllowed())  return false;

    return this.setFace(face, animate);
};

// Returns true if the volume viewer has high sensitivity to drag/click events
// (e.g. when using a mouse), or false if it has low sensitivity (e.g. when
// using a touch-based tablet).
VolumeViewer.prototype.hasPrecision = function() {
    return !this.mouseTouch;
};

// Starts a dragging operation.
// Returns true if this event was handled.
VolumeViewer.prototype.startDrag = function(options, mouseX0, mouseY0, mouseX1, mouseY1) {
    this.stopInternalAnimations();

    if (mouseX1 === undefined || mouseY1 === undefined) {
        mouseX1 = mouseX0;
        mouseY1 = mouseY0;
    }
    let mouseX = (mouseX0 + mouseX1) * 0.5;
    let mouseY = (mouseY0 + mouseY1) * 0.5;

    let displayView = this.displayToView(mouseX, mouseY);

    this.mousePressed     = true;
    this.mouseDrag        = false;
    this.mouseView        = displayView;
    this.mouseOptions     = options;
    this.mouseSpin        = false;
    this.mouseInput       = false;
    this.mouseClip        = false;
    this.mousePlane       = false;
    this.mouseRoll        = false;
    this.mouseZoom        = false;
    this.mousePanZoom     = false;
    this.mousePan         = false;
    this.mouseRocker      = false;
    this.mouseCrosshair   = false;
    this.mouseAnnotations = false;
    this.mouseX           = mouseX;
    this.mouseY           = mouseY;
    this.mouseX0          = mouseX0;
    this.mouseY0          = mouseY0;
    this.mouseX1          = mouseX1;
    this.mouseY1          = mouseX1;

    return true;
};

// Starts a clipping or plane dragging operation.
// Returns true if this event was handled.
VolumeViewer.prototype.startClipPlaneDrag = function(mouseX0, mouseY0, mouseX1, mouseY1) {
    return this.startDrag(VolumeViewer.DRAG_CLIP_PLANE | VolumeViewer.DRAG_ANNOTATIONS, mouseX0, mouseY0, mouseX1, mouseY1);
};

// Starts an input window dragging operation.
// This manipulates the center and radius of the input window (also known
// as the luminosity range in a 3D context).
// Returns true if this event was handled.
VolumeViewer.prototype.startInputDrag = function(mouseX0, mouseY0, mouseX1, mouseY1) {
    return this.startDrag(VolumeViewer.DRAG_INPUT | VolumeViewer.DRAG_ANNOTATIONS, mouseX0, mouseY0, mouseX1, mouseY1);
};

// Starts a rocker dragging operation.
// Returns true if this event was handled.
VolumeViewer.prototype.startRockerDrag = function(mouseX0, mouseY0, mouseX1, mouseY1) {
    return this.startDrag(VolumeViewer.DRAG_ROCKER | VolumeViewer.DRAG_ANNOTATIONS, mouseX0, mouseY0, mouseX1, mouseY1);
};

// Starts a pan dragging operation.
// Returns true if this event was handled.
VolumeViewer.prototype.startPanDrag = function(mouseX0, mouseY0, mouseX1, mouseY1) {
    return this.startDrag(VolumeViewer.DRAG_PAN | VolumeViewer.DRAG_ANNOTATIONS, mouseX0, mouseY0, mouseX1, mouseY1);
};

// Starts a crosshair dragging operation.
// Returns true if this event was handled.
VolumeViewer.prototype.startCrosshairDrag = function(mouseX0, mouseY0, mouseX1, mouseY1) {
    return this.startDrag(VolumeViewer.DRAG_CROSSHAIR | VolumeViewer.DRAG_ANNOTATIONS, mouseX0, mouseY0, mouseX1, mouseY1);
};

// Starts a 3D dragging operation.
// Returns true if this event was handled.
// DEPRECATED!
VolumeViewer.prototype.start3DDrag = function(mouseX0, mouseY0, mouseX1, mouseY1) {  // DEPRECATED
    return this.startDrag(VolumeViewer.DRAG_3D | VolumeViewer.DRAG_ANNOTATIONS, mouseX0, mouseY0, mouseX1, mouseY1);
};

// Starts a 2D dragging operation.
// Returns true if this event was handled.
// DEPRECATED!
VolumeViewer.prototype.start2DDrag = function(mouseX0, mouseY0, mouseX1, mouseY1) {  // DEPRECATED
    return this.startDrag(VolumeViewer.DRAG_2D | VolumeViewer.DRAG_ANNOTATIONS, mouseX0, mouseY0, mouseX1, mouseY1);
};

// Continues a dragging operation.
// Returns true if this event was handled.
VolumeViewer.prototype.drag = function(mouseX0, mouseY0, mouseX1, mouseY1) {
    if (!this.mousePressed)
        return false;

    const mouseData = this.getMouseData(mouseX0, mouseY0, mouseX1, mouseY1);

    if (!this.mouseDrag) {
        const EPSILON = 5;
        if (Math.abs(mouseData.mouseX - this.mouseX) >= EPSILON ||
            Math.abs(mouseData.mouseY - this.mouseY) >= EPSILON) {

            // We are dragging.  Still don't necessarily know what we're
            // dragging, though.

            this.mouseDrag = true;

            if (mouseData.mouseX0 !== mouseData.mouseX1 ||
                mouseData.mouseY1 !== mouseData.mouseY1) {
                // STM_TODO - fix this someday
                // Double-touch is an ugly special case
                this.setMouseData(mouseData);
            }

            if (this.rotationAxis && this.mouseSpinAlignAxis) {
                let curUp = vec3.fromValues(this.rotationMatrix[1], this.rotationMatrix[5], this.rotationMatrix[9]);
                this.mouseFlip = vec3.dot(curUp, this.rotationAxis) < 0.0;
            }
            else {
                this.mouseFlip = false;
            }
        }
    }

    if (!this.mouseDrag)  return true;

    // Figure out what we're dragging here...
    if (!this.detectAction(mouseData))
        return true;

    const viewer = this;
    function getMouseDelta(mouseData, proportional=false) {
        // Get the mouse movement delta and normalize it to a -1 to 1 range
        let deltaX = mouseData.mouseX - viewer.mouseX;
        let deltaY = mouseData.mouseY - viewer.mouseY;

        const canvas  = viewer.canvas;
        let canvasX = Math.max(canvas.clientWidth, 2.0);
        let canvasY = Math.max(canvas.clientHeight, 2.0);
        if (proportional) {
            if      (canvasX > canvasY)  canvasX = canvasY;
            else if (canvasX < canvasY)  canvasY = canvasX;
        }

        deltaX /= canvasX;
        deltaY /= canvasY;

        return { x: deltaX, y: deltaY };
    }

    if (this.mouseClip) {
        // We are dragging the clipping plane

        if (this.showClipping && this.canShowVolume()) {
            const delta = getMouseDelta(mouseData);

            let offset = this.getClipOffset();
            offset -= 3.75 * delta.y;
            this.setClipOffset(offset);
        }

        this.setMouseData(mouseData);
    }

    if (this.mousePlane) {
        // We are dragging an MPR plane

        if (this.canShowPlanes()) {
            const delta = getMouseDelta(mouseData);

            let offset = this.getPlaneOffset();
            offset = this.convertToRotationOffset(offset);
            const DRAG_MULT = 3.75 * (this.mousePlaneSelected < 0 ? -1 : 1);
            const selected = Math.abs(this.mousePlaneSelected);
            if (selected === 3)
                offset.rz -= delta.y * DRAG_MULT;
            else if (selected === 2)
                offset.ry -= delta.y * DRAG_MULT;
            else
                offset.rx -= delta.y * DRAG_MULT;
            if (this.planeOffsetClamping)
                offset = this.clampPlaneOffsetToPlane(offset, selected);
            this.setPlaneOffset(offset, true);
        }

        this.setMouseData(mouseData);
    }

    if (this.mouseSpin) {
        // We are spinning

        if (this.mouseSpinAlignAxis) {
            // OLD WAY: keep one axis always aligned
            let deltaX = mouseData.mouseX - this.mouseX;
            let deltaY = mouseData.mouseY - this.mouseY;

            if (deltaX !== 0.0 || deltaY !== 0.0) {
                const deltaDist = Math.sqrt(deltaX*deltaX + deltaY*deltaY);

                // If we're moving in the same general direction as last time,
                // smooth our motion
                if (deltaX*this.mouseSpinDeltaX + deltaY*this.mouseSpinDeltaY >= 0.0) {
                    deltaX += this.mouseSpinDeltaX;
                    deltaY += this.mouseSpinDeltaY;
                }

                const dist = Math.sqrt(deltaX*deltaX + deltaY*deltaY);
                if (dist > 0.0) {
                    // Semi-normalize our motion vector
                    const MAX_DIST = 15.0;
                    if (dist > MAX_DIST) {
                        this.mouseSpinDeltaX = deltaX * MAX_DIST / dist;
                        this.mouseSpinDeltaY = deltaY * MAX_DIST / dist;
                    }
                    else {
                        this.mouseSpinDeltaX = deltaX;
                        this.mouseSpinDeltaY = deltaY;
                    }
                    deltaX = deltaX * deltaDist / dist;
                    deltaY = deltaY * deltaDist / dist;
                }
                else {
                    this.mouseSpinDeltaX = 0.0;
                    this.mouseSpinDeltaY = 0.0;
                    deltaX = 0.0;
                    deltaY = 0.0;
                }

                const mult = 1.146;
                this.rotateYPR(deltaX * mult * (this.mouseFlip ? -1.0 : 1.0), deltaY * mult, null, true);

                this.invalidate();

                this.setMouseData(mouseData);
            }
        }
        else {
            // NEW WAY: don't use fixed rotation axes
            let rotationMatrix = mat4.clone(this.mouseSpinMatrix);

            // We treat the 2D canvas area as a map for a polar coordinate
            // system, representing a hemisphere of radius 1 around the
            // three dimensional space of the model.
            // Both the starting and ending 2D coordinates are converted to
            // three-dimensional vectors using this coordinate system.
            // The cross product of the two converted vectors represents
            // the axis of rotation, and the angle between the two vectors
            // (times two) represents the angle of rotation.
            // Any coordinates outside the radius of the polar coordinate
            // system are clamped to 90 degrees (the edge of the hemisphere),
            // and movement in this area will roll the model
            // (in other words, the rotational axis will be perpendicular
            // to the screen).

            // One consequence of this scheme is that, when dragging,
            // the point where the dragging starts (relative to the
            // center of the volume) is meaningful.
            const canvas      = this.canvas;
            const minSide     = Math.min(canvas.clientWidth, canvas.clientHeight);
            const radius      = minSide * 0.45;  // radius of the polar coordinate system, in pixels
            const invRadius   = radius > 0.0 ? 1.0 / radius : 1.0;
            const centerPoint = this.pointToDisplay(0.0, 0.0, 0.0, this.mouseView);  // center of the polar coordinate system
            const offsetX     = centerPoint.x;
            const offsetY     = centerPoint.y;

            // Polar coordinate conversion method
            const toHemisphereVector = function(x, y) {
                let newX = (x - offsetX) * invRadius;
                let newY = -(y - offsetY) * invRadius;
                let distance = Math.sqrt(newX*newX + newY*newY);
                if (distance > 1.0) {
                    // Clamp to the edge of the hemisphere
                    newX /= distance;
                    newY /= distance;
                    distance = 1.0;
                }

                let angle = distance * Math.PI * 0.5;
                let newZ = Math.cos(angle);

                // Adjust x and y to form a unit vector
                let newXY = 1.0 - newZ*newZ;
                if (newXY < 0.0)  newXY = 0.0;
                newXY = Math.sqrt(newXY);
                let factor = 1.0;
                if (distance > 1e-7)  factor = newXY / distance;
                newX = newX * factor;
                newY = newY * factor;

                return vec4.fromValues(newX, newY, newZ, 0.0);
            };

            // Convert both mouse positions to 3D vectors
            let mouseVector0 = toHemisphereVector(this.mouseX, this.mouseY);
            let mouseVector1 = toHemisphereVector(mouseData.mouseX, mouseData.mouseY);

            // Transform them into the model's coordinate system
            let invMatrix = mat4.create();
            mat4.invert(invMatrix, rotationMatrix);
            vec4.transformMat4(mouseVector0, mouseVector0, invMatrix);
            vec4.transformMat4(mouseVector1, mouseVector1, invMatrix);

            // Compute the cross product and angle
            let cross = vec3.create();
            vec3.cross(cross, mouseVector0, mouseVector1);
            let angle = vec3.angle(mouseVector0, mouseVector1) * 2.0;

            // Rotate the model
            mat4.rotate(rotationMatrix, rotationMatrix, angle, cross);

            this.setRotationMatrixValues(rotationMatrix[0], rotationMatrix[1], rotationMatrix[2],
                                         rotationMatrix[4], rotationMatrix[5], rotationMatrix[6],
                                         rotationMatrix[8], rotationMatrix[9], rotationMatrix[10]);
        }
    }

    if (this.mouseRoll) {
        // We are rolling

        const metrics = this.computeMouseMetrics(mouseData);
        if (metrics !== null) {
            // Double-touch version
            let angle = -2.0 * metrics.angle;

            this.rotateYPR(undefined, undefined, angle, false);
        }
        else {
            // Single-touch version
            const centerPoint = this.pointToDisplay(0.0, 0.0, 0.0, this.mouseView);  // center of the polar coordinate system
            let offsetX0 = this.mouseX - centerPoint.x;
            let offsetY0 = this.mouseY - centerPoint.y;
            let angle0 = Math.atan2(offsetY0, offsetX0);

            let offsetX1 = mouseData.mouseX - centerPoint.x;
            let offsetY1 = mouseData.mouseY - centerPoint.y;
            let angle1 = Math.atan2(offsetY1, offsetX1);

            let angle = (angle0 - angle1) * 180.0 / Math.PI;

            this.rotateYPR(undefined, undefined, angle, false);
        }

        this.setMouseData(mouseData);
    }

    if (this.mousePan) {
        // We are panning

        let viewport0 = this.displayToViewport(this.mouseX, this.mouseY, this.mouseView);
        let viewport1 = this.displayToViewport(mouseData.mouseX, mouseData.mouseY, this.mouseView);

        let deltaX = viewport1.x - viewport0.x;
        let deltaY = viewport1.y - viewport0.y;

        //let screenPan = this.getScreenPan();
        const screenPan = this.mousePanPosition;
        this.setScreenPanXY(screenPan.x + deltaX, screenPan.y + deltaY);;

        //this.setMouseData(mouseData);
    }

    if (this.mousePanZoom) {
        // We are panning and zooming

        const metrics = this.computeMouseMetrics(mouseData);
        if (metrics !== null) {
            // Double-touch version
            let mouseX = Math.floor((mouseData.mouseX + this.mouseX) * 0.5 + 0.5);
            let mouseY = Math.floor((mouseData.mouseY + this.mouseY) * 0.5 + 0.5);

            const mult = -metrics.spread / 15.0;
            this.stepZoomToDisplay(mult, mouseX, mouseY, this.mouseView);
        }
        else {
            // Single-touch version
            const delta = getMouseDelta(mouseData);

            let pos = this.mousePanZoomPosition;

            const mult = delta.y * 10.0;
            this.stepZoomToDisplay(mult, pos.x, pos.y, this.mouseView);
        }

        this.setMouseData(mouseData);
    }

    if (this.mouseZoom) {
        // We are zooming

        const metrics = this.computeMouseMetrics(mouseData);
        if (metrics !== null) {
            // Double-touch version
            const mult = -metrics.spread / 30.0;
            this.stepZoom(mult);
        }
        else {
            // Single-touch version
            const delta = getMouseDelta(mouseData);

            const mult = delta.y * 10.0;
            this.stepZoom(mult);
        }

        this.setMouseData(mouseData);
    }

    if (this.mouseInput) {
        // We are dragging the input window

        const delta = getMouseDelta(mouseData);

        const STEP = -1.875;
        delta.x *= STEP;
        delta.y *= STEP;
        let brightness = this.mouseBrightness + delta.y;
        let contrast = this.mouseContrast + delta.x;
        this.setLuminosityBrightnessAndContrast(brightness, contrast);

        //this.setMouseData(mouseData);
    }

    if (this.mouseCrosshair) {
        // We are moving crosshairs on the current plane

        let mouseX = mouseData.mouseX;
        let mouseY = mouseData.mouseY;

        let intersect = this.getPlaneIntersection(mouseX, mouseY, false,
                                                  [this.mouseCrosshairPlane]);
        if (intersect) {
            let offset = intersect.point;
            if (this.planeOffsetClamping)
                offset = this.clampPlaneOffsetToPlane(intersect.point,
                                                      this.mouseCrosshairPlane);
            this.setPlaneOffset(offset);
        }
    }

    if (this.mouseAnnotations) {
        // We are dragging annotations on the current plane

        let mouseX = mouseData.mouseX;
        let mouseY = mouseData.mouseY;

        this.rootOverlay.drag({ x: mouseX, y: mouseY });

        this.setMouseData(mouseData);
    }

    if (this.mouseRocker) {
        // We are rocking the rotation of the volume

        const delta = getMouseDelta(mouseData, true);

        const rotationMatrix = this.rotationMatrix;
        const pivotMatrix = mat4.create();

        const CONVERT = 70.0 * Math.PI / 180.0;

        let pitch = -delta.y * CONVERT;
        const pitchAxis = vec3.fromValues(rotationMatrix[0], rotationMatrix[4], rotationMatrix[8]);
        mat4.rotate(pivotMatrix,  // destination matrix
                    pivotMatrix,  // source matrix to rotate
                    pitch,        // amount to rotate (radians)
                    pitchAxis);   // axis of rotation (pitch)

        let roll = -delta.x * CONVERT;
        const rollAxis = vec3.fromValues(rotationMatrix[1], rotationMatrix[5], rotationMatrix[9]);
        mat4.rotate(pivotMatrix,  // destination matrix
                    pivotMatrix,  // source matrix to rotate
                    roll,         // amount to rotate (radians)
                    rollAxis);    // axis of rotation (pitch)

        this.setPivotMatrixValues(pivotMatrix[0], pivotMatrix[1], pivotMatrix[2],
                                  pivotMatrix[4], pivotMatrix[5], pivotMatrix[6],
                                  pivotMatrix[8], pivotMatrix[9], pivotMatrix[10]);
    }

    return true;
};

// Ends a dragging operation.
// Returns true if this event was handled.
VolumeViewer.prototype.endDrag = function() {
    if (this.mousePressed) {
        if (this.mouseRocker) {
            if (this.isRockerSnapBackEnabled())
                this.startSnapBack();
            else
                this.applyPivot();
        }

        if (this.mouseAnnotations) {
            if (this.rootOverlay) {
                let mouseX = this.mouseX;
                let mouseY = this.mouseY;

                this.rootOverlay.endDrag({ x: mouseX, y: mouseY });
            }
        }

        this.mousePressed     = false;
        this.mouseDrag        = false;
        this.mouseView        = VolumeViewer.VIEW_UNKNOWN;
        this.mouseOptions     = VolumeViewer.DRAG_NONE;
        this.mouseSpin        = false;
        this.mouseInput       = false;
        this.mouseClip        = false;
        this.mousePlane       = false;
        this.mouseRoll        = false;
        this.mouseZoom        = false;
        this.mousePanZoom     = false;
        this.mousePan         = false;
        this.mouseRocker      = false;
        this.mouseCrosshair   = false;
        this.mouseAnnotations = false;
        //this.invalidate();

        return true;
    }

    return false;
};

// Aligns the volume based on the face of the mini-cube underneath
// the specified mouse position.
// Returns true if this event was handled.
VolumeViewer.prototype.alignMiniCube = function(mouseX, mouseY, animate=undefined) {
    if (animate === undefined || animate === null)  animate = true;

    let face = this.getMiniCubeFace(mouseX, mouseY);
    if (face !== VolumeViewer.FACE_NONE) {
        this.setMiniCubeHighlightFace();
        return this.userSetFace(face, animate);
    }

    return false;
};

// Moves the facing plane inward or outward based on the specified step.
// Returns true if this event was handled.
VolumeViewer.prototype.stepFacingPlane = function(step, animate=undefined) {
    if (!this.canShowPlanes())  return false;

    this.stopInternalAnimations();

    let selectedFace = this.getFacingPlane();
    return this.stepPlane(selectedFace, step, animate);
};

// Moves the specified plane inward or outward based on the specified step.
// Valid plane numbers range from 1-3 inclusive and are integers.
// Returns true if this event was handled.
VolumeViewer.prototype.stepPlane = function(selectedFace, step, animate=undefined) {
    if (!this.canShowPlanes())  return false;

    if (selectedFace < 0) {
        selectedFace = -selectedFace;
        step *= -1.0;
    }
    if (selectedFace !== 1 && selectedFace !== 2 && selectedFace !== 3)  return false;

    animate = this.sanitizeAnimate(animate);

    this.stopInternalAnimations();

    let offset = this.getPlaneStepOffset();

    if      (selectedFace === 3)
        offset.sz = Math.floor(offset.sz + step) + 0.5;
    else if (selectedFace === 2)
        offset.sy = Math.floor(offset.sy + step) + 0.5;
    else
        offset.sx = Math.floor(offset.sx + step) + 0.5;

    offset = this.clampPlaneOffset(offset);
    if (this.planeOffsetClamping)
        offset = this.clampPlaneOffsetToPlane(offset, selectedFace);
    offset = this.convertToPlaneOffset(offset);

    let propertyState = {
        PlaneOffset: offset
    };

    this.animateToPropertyState(propertyState, null, animate);
    return true;
};

// Moves the clipping plane up or down based on the specified step.
// Returns true if this event was handled.
VolumeViewer.prototype.stepClippingPlane = function(step, animate=undefined) {
    if (!this.isClippingEnabled())  return false;

    animate = this.sanitizeAnimate(animate);

    this.stopInternalAnimations();

    const STEP = 0.05;
    let offset = this.getClipOffset();
    offset += step * STEP;

    offset = this.clampClipOffset(offset);

    let propertyState = {
        ClipOffset: offset
    };

    this.animateToPropertyState(propertyState, null, animate);
    return true;
};

// Moves the facing plane inward or outward, or the clipping plane up or down,
// based on the specified step.
// Returns true if this event was handled.
VolumeViewer.prototype.stepFacingPlaneClip = function(step, animate=undefined) {
    let canShowPlanes = this.canShowPlanes();
    let canShowClipping = this.canShowClipping();

    if (canShowPlanes) {
        return this.stepFacingPlane(step, animate);
    }
    else if (canShowClipping) {
        return this.stepClippingPlane(step, animate);
    }

    return false;
};

// Zooms into or out of the volume based on the specified step.
// Returns true if this event was handled.
VolumeViewer.prototype.stepZoom = function(zoomStep, animate=undefined) {
    animate = this.sanitizeAnimate(animate);

    this.stopInternalAnimations();

    let zoom = this.getZoom();
    zoomStep = Math.pow(1.5, 0.5*zoomStep);
    zoom *= zoomStep;
    if (zoom < this.minZoom)  zoom = this.minZoom;
    if (zoom > this.maxZoom)  zoom = this.maxZoom;

    let zoomLinear = VolumeViewer.zoomScaledToLinear(zoom);

    let propertyState = {
        ZoomLinear: zoomLinear
    };

    this.animateToPropertyState(propertyState, null, animate);
    return true;
};

// Zooms into or out of the volume based on the specified angle (in degrees).
// Returns true if this event was handled.
VolumeViewer.prototype.stepRoll = function(angle, animate=undefined) {
    animate = this.sanitizeAnimate(animate);

    if (!angle)  return true;

    const RANGE = 360.0;

    // Force to [0-360) degree range
    angle = ((angle % RANGE) + RANGE) % RANGE;

    if (angle !== 0.0) {
        this.stopInternalAnimations();

        const rotationMatrix = mat4.clone(this.rotationMatrix);

        angle *= -Math.PI / 180.0;
        const rollAxis = vec3.fromValues(rotationMatrix[2], rotationMatrix[6], rotationMatrix[10]);
        mat4.rotate(rotationMatrix,  // destination matrix
                    rotationMatrix,  // source matrix to rotate
                    angle,           // amount to rotate (radians)
                    rollAxis);       // axis of rotation (roll)

        let propertyState = {
            RotationMatrix: {
                m00: rotationMatrix[0], m01: rotationMatrix[1], m02: rotationMatrix[2],
                m10: rotationMatrix[4], m11: rotationMatrix[5], m12: rotationMatrix[6],
                m20: rotationMatrix[8], m21: rotationMatrix[9], m22: rotationMatrix[10]
            }
        };

        this.animateToPropertyState(propertyState, null, animate);
        return true;
    }

    return true;
};

// Flips the volume horizontally.
VolumeViewer.prototype.stepFlipHorizontal = function(animate=undefined) {
    animate = this.sanitizeAnimate(animate);

    let angle = Math.PI;

    this.stopInternalAnimations();

    const rotationMatrix = mat4.clone(this.rotationMatrix);

    const axis = vec3.fromValues(rotationMatrix[1], rotationMatrix[5], rotationMatrix[9]);
    mat4.rotate(rotationMatrix,  // destination matrix
                rotationMatrix,  // source matrix to rotate
                angle,           // amount to rotate (radians)
                axis);           // axis of rotation

    let planeOffset = this.getPlaneOffset();

    let propertyState = {
        RotationMatrix: {
            m00: rotationMatrix[0], m01: rotationMatrix[1], m02: rotationMatrix[2],
            m10: rotationMatrix[4], m11: rotationMatrix[5], m12: rotationMatrix[6],
            m20: rotationMatrix[8], m21: rotationMatrix[9], m22: rotationMatrix[10]
        },
        PlaneOffset: planeOffset
    };

    this.animateToPropertyState(propertyState, null, animate);
    return true;
};

// Flips the volume vertically.
VolumeViewer.prototype.stepFlipVertical = function(animate=undefined) {
    animate = this.sanitizeAnimate(animate);

    let angle = Math.PI;

    this.stopInternalAnimations();

    const rotationMatrix = mat4.clone(this.rotationMatrix);

    const axis = vec3.fromValues(rotationMatrix[0], rotationMatrix[4], rotationMatrix[8]);
    mat4.rotate(rotationMatrix,  // destination matrix
                rotationMatrix,  // source matrix to rotate
                angle,           // amount to rotate (radians)
                axis);           // axis of rotation

    let planeOffset = this.getPlaneOffset();

    let propertyState = {
        RotationMatrix: {
            m00: rotationMatrix[0], m01: rotationMatrix[1], m02: rotationMatrix[2],
            m10: rotationMatrix[4], m11: rotationMatrix[5], m12: rotationMatrix[6],
            m20: rotationMatrix[8], m21: rotationMatrix[9], m22: rotationMatrix[10]
        },
        PlaneOffset: planeOffset
    };

    this.animateToPropertyState(propertyState, null, animate);
    return true;
};

// Zooms into or out of the volume based on the specified step.
// Unlike stepZoom(), this also zooms towards/away from the specified
// display location, Google Maps-style.
// Returns true if this event was handled.
VolumeViewer.prototype.stepZoomToDisplay = function(zoomStep, displayX, displayY,
                                                    displayView=VolumeViewer.VIEW_UNKNOWN,
                                                    animate=undefined) {
    animate = this.sanitizeAnimate(animate);

    let oldZoom = this.getZoom();
    zoomStep = Math.pow(1.5, 0.5*zoomStep);
    let newZoom = oldZoom * zoomStep;
    return this.zoomToDisplay(newZoom, displayX, displayY, displayView, animate);
};

// Sets the zoom level, and pans the image on the screen so that the image at
// the specified screen position remains at the same position after the zoom.
VolumeViewer.prototype.zoomToDisplay = function(newZoom, displayX, displayY,
                                                displayView=VolumeViewer.VIEW_UNKNOWN,
                                                animate=undefined) {

    animate = this.sanitizeAnimate(animate);

    let oldZoom = this.getZoom();
    let oldPan  = this.getScreenPan();
    let oldPanX = oldPan.x;
    let oldPanY = oldPan.y;

    if (newZoom < this.minZoom)  newZoom = this.minZoom;
    if (newZoom > this.maxZoom)  newZoom = this.maxZoom;

    if (displayView === VolumeViewer.VIEW_UNKNOWN)
        displayView = this.displayToView(displayX, displayY);

    let viewport = this.displayToViewport(displayX, displayY, displayView);
    let newPanX = (oldPanX*oldZoom - viewport.x*oldZoom + viewport.x*newZoom)/newZoom;
    let newPanY = (oldPanY*oldZoom - viewport.y*oldZoom + viewport.y*newZoom)/newZoom;

    let zoomLinear = VolumeViewer.zoomScaledToLinear(newZoom);

    let propertyState = {
        ZoomLinear: zoomLinear,
        ScreenPan:  { x: newPanX, y: newPanY }
    };

    this.animateToPropertyState(propertyState, null, animate);
    return true;
};

// Permanently applies a pivot rotation to the volume being viewed.
// The pivot itself is reset.
// This really only works properly in orthographic projection.
VolumeViewer.prototype.applyPivot = function() {
    this.stopInternalAnimations();

    // Also implicitly reset the MPR origin point
    let planePoint = this.planePoint;
    if (planePoint[0] !== 0.0 || planePoint[0] !== 0.0 || planePoint[2] !== 0.0) {
        let planeOffset = vec3.clone(planePoint);
        let planeMatrix = mat3.clone(this.getInternalPlaneMatrix());
        mat3.transpose(planeMatrix, planeMatrix);
        vec3.transformMat3(planeOffset, planeOffset, planeMatrix);
        this.setPlanePointXYZ(0.0, 0.0, 0.0,
                              planeOffset[0], planeOffset[1], planeOffset[2]);
    }

    let projectionMatrix = this.generateProjectionMatrix({eyeDistance: 0.0});
    let center = vec3.fromValues(0.0, 0.0, 0.0);
    vec3.transformMat4(center, center, projectionMatrix);

    let rotation = this.combineMatrices();
    this.resetPivotMatrix();
    this.setRotationMatrixValues(rotation[0], rotation[1], rotation[2],
                                 rotation[4], rotation[5], rotation[6],
                                 rotation[8], rotation[9], rotation[10]);
    this.setScreenPanXY(center[0], center[1]);
};

// Starts a snap-back animation.
VolumeViewer.prototype.startSnapBack = function(animate=undefined) {
    // Not pivoting?  Don't bother
    if (mat4.exactEquals(VolumeViewer.MAT4_IDENTITY, this.pivotMatrix) &&
        vec3.exactEquals(VolumeViewer.VEC3_EMPTY, this.pivotPoint))
        return;

    if (animate === undefined || animate === null || animate === true)
        animate = 0.5 * this.getDefaultAnimationTime();
    else if (animate === false)
        animate = 0.0;

    const planeOffset = {
        px: this.mouseRockerOffset[0],
        py: this.mouseRockerOffset[1],
        pz: this.mouseRockerOffset[2]
    };
    let propertyState = {
        PivotMatrix: VolumeViewer.IDENTITY_MATRIX
    };
    let finalizerState = {
        PivotPoint:  VolumeViewer.EMPTY_3D_VECTOR,
        PlanePoint:  VolumeViewer.EMPTY_3D_VECTOR,
        PlaneOffset: planeOffset
    };

    this.animateToPropertyState(propertyState, finalizerState, animate,
                                Animate.mapSmoother);
};

// Ends the snap-back animation.
VolumeViewer.prototype.endSnapBack = function() {
    this.stopInternalAnimations();
};

// Smoothly transitions to a new property state through animation.
// If the time is 0.0, this transition will happen immediately, without
// animation.
// A "finalizer" state or function, which is not applied until the end of the
// animation, may also be specified.
VolumeViewer.prototype.animateToPropertyState = function(propertyState, finalizer=null,
                                                         time=undefined, mapper=undefined) {
    if (time === undefined || time === null || time === true)
        time = this.defaultStateAnimationTime;
    else if (time === false)
        time = 0.0;

    if (!mapper)  mapper = Animate.mapEaseOut;

    // Get the old and new property states for the transition
    let propertyStates = this.generatePropertyStateDelta(propertyState);

    // Finish our existing animations.
    // This will also ensure that existing finalizers are called.
    this.stopInternalAnimations();

    // This will be called at the end of the animation, or
    // immediately if the animation time is zero.
    const viewer = this;
    function finalize() {
        if (finalizer) {
            if (typeof finalizer === 'object') {
                viewer.applyPropertyState(finalizer);
            }
            else if (typeof finalizer === 'function') {
                finalizer();
            }
        }
    }

    if (time > 0.0) {
        this.applyPropertyState(propertyStates[0]);

        // Set up an animation schedule for the property state animation
        let stateSchedule = [
            { duration: time, mapper: mapper, callback: function(params)
                {
                    viewer.beginUserAction(); try {
                        const offset = params.mappedOffset;

                        viewer.applyInterpolatedPropertyState(offset, propertyStates[0], propertyStates[1]);
                    }
                    finally { viewer.endUserAction(); }
                }
            },
            { callback: function()
                {
                    viewer.beginUserAction(); try {
                        // This acts like a destructor
                        finalize();
                    }
                    finally { viewer.endUserAction(); }
                }
            }
        ];

        this.stateAnimation.setAnimationSchedule(stateSchedule, false);
        this.stateAnimation.start();
    }
    else {
        // Apply everything immediately
        this.beginActionBlock(); try {
            this.applyPropertyState(propertyStates[1]);
            finalize();
        }
        finally { this.endActionBlock(); }
    }
};

// Stops all animations.
VolumeViewer.prototype.stopInternalAnimations = function() {
    this.stateAnimation.finish();
    this.enableAutoRotation(false);
};

// Sets the default time for animations.
VolumeViewer.prototype.setDefaultAnimationTime = function(time) {
    if (time === undefined || time === null)  time = 0.1;

    if (time < 0.0)   time = 0.0;
    if (time > 10.0)  time = 10.0;

    if (this.defaultStateAnimationTime !== time)  {
        this.defaultStateAnimationTime = time;

        //this.invalidate();
    }
};

// Gets the default time for animations.
VolumeViewer.prototype.getDefaultAnimationTime = function() {
    return this.defaultStateAnimationTime;
};

// Resets orientation, plane and color threshold information for the viewer.
VolumeViewer.prototype.reset = function(animate=undefined) {
    animate = this.sanitizeAnimate(animate);

    // Reset everything
    this.beginActionBlock();
    try {
        this.endDrag();

        this.clearSelectedPoint();

        let luminosityRange0 = this.getAutoLuminosityRange(0);
        let luminosityRange1 = this.getAutoLuminosityRange(1);
        let gradientRange0   = this.getAutoGradientRange(0);
        let gradientRange1   = this.getAutoGradientRange(1);

        let face             = this.getFacingFace();
        let orientation      = VolumeViewer.getOrientationFromFace(face);
        let rotationMatrix   = this.generateRotationFromOrientation(orientation);

        let zoom             = this.calculateZoomToFit(rotationMatrix);
        let zoomLinear       = VolumeViewer.zoomScaledToLinear(zoom);

        let propertyState = {
            RotationMatrix:   rotationMatrix,
            PivotMatrix:      VolumeViewer.IDENTITY_MATRIX,
            PivotPoint:       VolumeViewer.EMPTY_3D_VECTOR,
            PlaneMatrix:      VolumeViewer.IDENTITY_MATRIX,
            PlanePoint:       VolumeViewer.EMPTY_3D_VECTOR,
            PlaneOffset:      VolumeViewer.EMPTY_3D_PVECTOR,
            ZoomLinear:       zoomLinear,
            VolumeOffset:     VolumeViewer.EMPTY_2D_VECTOR,
            ScreenPan:        VolumeViewer.EMPTY_2D_VECTOR,
            Clipping:         false,
            LuminosityRange0: luminosityRange0,
            LuminosityRange1: luminosityRange1,
            GradientRange0:   gradientRange0,
            GradientRange1:   gradientRange1,
        };

        this.animateToPropertyState(propertyState, null, animate);
    }
    finally { this.endActionBlock(); }

    return true;
};

// Invalidates the current view in the viewer, forcing a deferred redraw.
VolumeViewer.prototype.invalidate = function() {
    this.framesLeft = 3;
    if (this.animationId === null && this.gl)
        this.animationId = requestAnimationFrame(this.renderProxy);
};

// Invalidates the shader in the viewer, forcing a check for a deferred
// shader recompile.
VolumeViewer.prototype.invalidateShader = function() {
    this.checkShader = true;
    this.invalidate();
};

// Invalidates the annotations in the viewer.
VolumeViewer.prototype.invalidateOverlays = function() {
    if (this.rootOverlay) {
        this.rootOverlay.invalidate();
    }
};

// Forces the viewer to redraw immediately.
VolumeViewer.prototype.forceRedraw = function() {
    if (this.gl) {
        this.renderScene(0.0);
    }
};

// Generates a string representing the current state of the volume viewer.
// The string is actually a JSON stringified property state, and can be
// copied/pasted directly into JavaScript.
VolumeViewer.prototype.generateStateString = function(whitelist, blacklist) {
    let propertyState = this.generatePropertyState(whitelist, blacklist);

    let str = VolumeViewer.jsonSmartStringify(propertyState, null, 4);
    return str;
};

// Generates a string representing the current state of the volume viewer.
// Similar to generateStateString(), but is explicitly intended for use in
// generating default views.
VolumeViewer.prototype.generateDefaultStateString = function() {
    let blacklist = [
        //"RotationMatrix",
        //"Zoom",
        //"ZoomLinear",
        "GradientRounding",
        "Outline",
        "OutlineColor",
        "ClipOutline",
        "ClipOutlineColor",
        "LuminosityRange0",
        "LuminosityRange1",
        "GradientRange0",
        "GradientRange1",
        "MiniCube",
        "MiniCubePosition",
        "MiniCubeScale",
        "MiniCubeHighlightColor",
        //"LuminosityTable0",
        //"LuminosityTable1",
        //"GradientTable0",
        //"GradientTable1",
        //"PlaneTable0",
        //"PlaneTable1"
    ];
    let str = this.generateStateString(null, blacklist);
    return str;
};


// STATE MAPPING ==============================================================

// Property map.  Each name is mapped to a setter and getter in the
// volume viewer.  These can also be used to set or get the complete state of
// a viewer, en masse, and even interpolate between two states in animation.
//
// Meaning of values associated with each property:
//     "set":
//         setter: set<NAME>()
//         getter: get<NAME>()
//     "enable":
//         setter: enable<NAME>()
//         getter: is<NAME>Enabled()
//     "enables":
//         setter: enable<NAME>()
//         getter: are<NAME>Enabled()
//     "allow":
//         setter: allow<NAME>()
//         getter: is<NAME>Allowed()
//     "allows":
//         setter: allow<NAME>()
//         getter: are<NAME>Allowed()
//
VolumeViewer.PROPERTY_MAP = Object.freeze({
    // Projection properties
    RotationMatrix:             "set",      // 3x3 matrix
    Fov:                        "set",      // number
    Zoom:                       "set",      // number
    ZoomLinear:                 "set",      // number
    ScreenPan:                  "set",      // 2D vector
    VolumeOffset:               "set",      // 2D vector

    // Volume processing properties
    Gradients:                  "enables",  // boolean
    GradientRounding:           "enable",   // boolean

    // General appearance properties
    BackgroundColor:            "set",      // RGBA color entry
    Outline:                    "enable",   // boolean
    OutlineColor:               "set",      // RGBA color entry

    // 3D clipping properties
    Clipping:                   "enable",   // boolean
    ClipNormal:                 "set",      // 3D vector
    ClipOffset:                 "set",      // number
    ClipOutline:                "enable",   // boolean
    ClipOutlineColor:           "set",      // RGBA color entry
    ClipFlattening:             "enable",   // boolean
    ClipSpecularLevel:          "set",      // number

    // Lighting properties
    Lighting:                   "enable",   // boolean
    LuminosityLighting:         "enable",   // boolean
    GradientLighting:           "enable",   // boolean
    PlaneLighting:              "enable",   // boolean
    LightPosition:              "set",      // 3D vector + abs/rel flag
    AmbientLevel:               "set",      // number
    DiffusePower:               "set",      // number
    SpecularLevel:              "set",      // number

    // Transparency properties
    LuminosityAlpha0:           "set",      // number
    LuminosityAlpha1:           "set",      // number
    GradientAlpha0:             "set",      // number
    GradientAlpha1:             "set",      // number
    PlaneAlpha0:                "set",      // number
    PlaneAlpha1:                "set",      // number

    // Transfer function range properties
    LuminosityRange0:           "set",      // range (min, max)
    LuminosityRange1:           "set",      // range (min, max)
    GradientRange0:             "set",      // range (min, max)
    GradientRange1:             "set",      // range (min, max)

    // 2D/MPR plane properties
    AutoPlane:                  "enable",   // boolean
    PlaneMatrix:                "set",      // 3x3 matrix
    PlanePoint:                 "set",      // 3D vector
    PlaneOffset:                "set",      // 3D vector
    PlaneSnapToVoxel:           "enable",   // boolean
    PlaneInteriorClamping:      "enable",   // boolean
    PlaneOffsetClamping:        "enable",   // boolean
    PlaneBorders:               "enables",  // boolean
    PlaneIntersections:         "enables",  // boolean
    PlaneBorderColors:          "set",      // array of RGBA color entries (3-tuple)
    PlaneBorderLineWidth:       "set",      // number
    PlaneIntersectionColors:    "set",      // array of RGBA color entries (3-tuple)
    PlaneIntersectionLineWidth: "set",      // number
    PlaneAlphaLevels:           "set",      // array of alpha values (3-tuple)
    PlaneCrosshairs:            "enables",  // boolean
    //PlaneCrosshairRadii:        "set",      // range (inner, outer)  // STM_TODO - obsolete
    PlaneCrosshairColor:        "set",      // RGBA color entry
    PlaneSpecularLevel:         "set",      // number
    TrueLines:                  "enables",  // boolean

    // Transient pivot properties
    PivotMatrix:                "set",      // 3x3 matrix
    PivotPoint:                 "set",      // 3D vector

    // Fog properties
    FogStart:                   "set",      // number
    FogLevel:                   "set",      // number

    // Raytraced shadow properties
    LuminosityShadows:          "enables",  // boolean
    GradientShadows:            "enables",  // boolean
    ShadowMultiplier:           "set",      // number
    ShadowSurfaceAlpha:         "set",      // number
    ShadowStepMultiplier:       "set",      // number
    ShadowAmbientLevel:         "set",      // number

    // Stereo/3D properties
    StereoView:                 "enable",   // boolean
    StereoBackgroundColor:      "set",      // RGBA color entry
    StereoBorderColor:          "set",      // RGBA color entry
    StereoBorderSize:           "set",      // number
    StereoAspect:               "set",      // number
    StereoSeparation:           "set",      // number
    StereoWidth:                "set",      // number
    StereoEdge:                 "set",      // number
    ParallelStereo:             "enable",   // boolean
    EyeDistance:                "set",      // number

    // Sampling option properties
    Interpolation:              "enable",   // boolean
    SampleOnAxis:               "enable",   // boolean
    Stipple:                    "enable",   // boolean
    RandomizedSampling:         "enable",   // boolean
    FadeLevel:                  "set",      // number

    // Mini-cube properties
    MiniCube:                   "enable",   // boolean
    MiniCubePosition:           "set",      // 2D vector
    MiniCubeScale:              "set",      // number
    MiniCubeHighlightColor:     "set",      // RGBA color entry

    // Annotation properties
    Annotations:                "enables",  // boolean

    // User control properties
    InputMode:                  "set",      // string
    UserRotation:               "allow",    // boolean
    UserZoom:                   "allow",    // boolean
    UserPan:                    "allow",    // boolean
    UserRoll:                   "allow",    // boolean
    UserMovePlane:              "allow",    // boolean
    UserMoveClip:               "allow",    // boolean
    UserMoveAnnotations:        "allow",    // boolean
    RockerSnapBack:             "enable",   // boolean
    //DefaultAnimationTime:       "set",      // number

    // Color table properties
    LuminosityTable0:           "set",      // color table
    LuminosityTable1:           "set",      // color table
    GradientTable0:             "set",      // color table
    GradientTable1:             "set",      // color table
    PlaneTable0:                "set",      // color table
    PlaneTable1:                "set"       // color table
});

// Sanitizes a list of property names, ensuring that it is an array.
VolumeViewer.sanitizePropertyNameList = function(list) {
    let newList = null;

    if      (list === undefined)        newList = [];
    else if (list === null)             newList = [];
    else if (Array.isArray(list))       newList = list;
    else if (typeof list === 'object')  newList = Object.keys(list);
    else if (typeof list === 'string')  newList = [list];
    else                                newList = [];

    return newList;
};

// Returns true if the specified name is a valid property name, or false
// otherwise.
VolumeViewer.isValidPropertyName = function(propertyName) {
    if (!propertyName)  return false;
    if (!(propertyName in VolumeViewer.PROPERTY_MAP))  return false;
    return true;
};

// Gets the name of the getter function associated with the specified property.
VolumeViewer.getPropertyGetterName = function(propertyName) {
    if (!VolumeViewer.isValidPropertyName(propertyName))  return null;
    let type = VolumeViewer.PROPERTY_MAP[propertyName];
    if (type === "set")      return `get${propertyName}`;
    if (type === "enable")   return `is${propertyName}Enabled`;
    if (type === "enables")  return `are${propertyName}Enabled`;
    if (type === "allow")    return `is${propertyName}Allowed`;
    if (type === "allows")   return `are${propertyName}Allowed`;
    return null;
};

// Gets the name of the setter function associated with the specified property.
VolumeViewer.getPropertySetterName = function(propertyName) {
    if (!VolumeViewer.isValidPropertyName(propertyName))  return null;
    let type = VolumeViewer.PROPERTY_MAP[propertyName];
    if (type === "set")      return `set${propertyName}`;
    if (type === "enable")   return `enable${propertyName}`;
    if (type === "enables")  return `enable${propertyName}`;
    if (type === "allow")    return `allow${propertyName}`;
    if (type === "allows")   return `allow${propertyName}`;
    return null;
};

// Gets the function associated with the specified function name.
VolumeViewer.prototype.getPropertyFunction = function(functionName) {
    if (!functionName)  return undefined;
    if (!(functionName in this))  return undefined;
    let func = this[functionName];
    if (!func)  return undefined;
    if (typeof func !== 'function')  return undefined;
    return func;
};

// Gets the value of the specified property.
VolumeViewer.prototype.getPropertyValue = function(propertyName) {
    let functionName = VolumeViewer.getPropertyGetterName(propertyName);
    let func = this.getPropertyFunction(functionName);
    if (!func)  return undefined;
    return func.call(this);
};

// Sets the value of the specified property.
VolumeViewer.prototype.setPropertyValue = function(propertyName, value) {
    let functionName = VolumeViewer.getPropertySetterName(propertyName);
    let func = this.getPropertyFunction(functionName);
    if (!func)  return undefined;
    return func.call(this, value);
};

// Generates a dictionary of all properties associated with the viewer,
// and their associated values.  The dictionary may be filtered based
// on the optional "whitelist" and "blacklist" arrays.
VolumeViewer.prototype.generatePropertyState = function(whitelist, blacklist) {
    if (whitelist === undefined || whitelist === null)
        whitelist = Object.keys(VolumeViewer.PROPERTY_MAP);

    whitelist = VolumeViewer.sanitizePropertyNameList(whitelist);
    blacklist = VolumeViewer.sanitizePropertyNameList(blacklist);

    let propertyState = {};
    for (let i=0; i<whitelist.length; ++i) {
        let propertyName = whitelist[i];
        if (!blacklist.includes(propertyName)) {
            let value = this.getPropertyValue(propertyName);
            if (value !== undefined) {
                propertyState[propertyName] = value;
            }
        }
    }
    return propertyState;
};

// Applies the specified property state dictionary to the volume viewer.
// Allows the user to set a large number of properties all at once.
// The dictionary may be filtered based on the optional "whitelist" and
// "blacklist" arrays.
VolumeViewer.prototype.applyPropertyState = function(propertyState, whitelist, blacklist) {
    if (!propertyState || typeof propertyState !== 'object')
        return;

    if (whitelist === undefined || whitelist === null)
        whitelist = null;
    else
        whitelist = VolumeViewer.sanitizePropertyNameList(whitelist);

    blacklist = VolumeViewer.sanitizePropertyNameList(blacklist);

    this.beginActionBlock();
    try {
        const keys = Object.keys(VolumeViewer.PROPERTY_MAP);
        for (let i=0; i<keys.length; ++i) {
            let propertyName = keys[i];
            if (whitelist === null || whitelist.includes(propertyName)) {
                if (!blacklist.includes(propertyName)) {
                    if (propertyName in propertyState) {
                        let value = propertyState[propertyName];
                        this.setPropertyValue(propertyName, value);
                    }
                }
            }
        }
    }
    finally { this.endActionBlock(); }
};

// Makes a copy of the specified property state dictionary.
// Invalid properties are removed.
// The dictionary may be filtered based on the optional "whitelist" and
// "blacklist" arrays.
VolumeViewer.prototype.copyPropertyState = function(propertyState, whitelist, blacklist) {
    if (!propertyState || typeof propertyState !== 'object')
        return null;

    if (whitelist === undefined || whitelist === null)
        whitelist = null;
    else
        whitelist = VolumeViewer.sanitizePropertyNameList(whitelist);

    blacklist = VolumeViewer.sanitizePropertyNameList(blacklist);

    let newPropertyState = {};
    let propertyNames = Object.keys(propertyState);
    for (let i=0; i<propertyNames.length; ++i) {
        let propertyName = propertyNames[i];
        if (VolumeViewer.isValidPropertyName(propertyName)) {
            if (whitelist === null || whitelist.includes(propertyName)) {
                if (!blacklist.includes(propertyName)) {
                    let value = propertyState[propertyName];
                    let newValue = VolumeViewer.deepCopy(value);
                    newPropertyState[propertyName] = newValue;
                }
            }
        }
    }

    return newPropertyState;
};

// Using the specified property state dictionary, generates two new property
// state dictionaries representing the old and new property states.
// These may be used for interpolation or state restoration at a later time.
// Optionally, the caller may choose to have both dictionaries only contain
// entries for properties that have changed.
VolumeViewer.prototype.generatePropertyStateDelta = function(propertyState, checkEqual=false) {
    let oldPropertyState = {};
    let newPropertyState = {};

    if (propertyState && typeof propertyState === 'object') {
        let propertyNames = Object.keys(propertyState);
        for (let i=0; i<propertyNames.length; ++i) {
            let propertyName = propertyNames[i];
            if (VolumeViewer.isValidPropertyName(propertyName)) {
                let newValue = propertyState[propertyName];
                let oldValue = this.getPropertyValue(propertyName);
                if (oldValue !== undefined) {
                    if (!checkEqual || !VolumeViewer.deepEquals(oldValue, newValue)) {
                        oldPropertyState[propertyName] = oldValue;
                        newPropertyState[propertyName] = newValue;
                    }
                }
            }
        }
    }

    return [oldPropertyState, newPropertyState];
};

// Returns true if the specified property state is different from the current
// property state, or false otherwise.
VolumeViewer.prototype.isPropertyStateDifferent = function(propertyState) {
    function isEmpty(obj) {
        for (var key in obj) { if (obj.hasOwnProperty(key)) return false; }
        return true;
    }
    let propertyStateDelta = this.generatePropertyStateDelta(propertyState, true);
    return (!isEmpty(propertyStateDelta[0]) || !isEmpty(propertyStateDelta[1]));
}

// Returns true if the two specified property states are different, or false
// if they are the same.
VolumeViewer.prototype.arePropertyStatesDifferent = function(propertyState0, propertyState1) {
    return !VolumeViewer.deepEquals(propertyState0, propertyState1);
};

// Given two property state objects and an offset, creates a new property
// state dictionary that is an interpolation of the other two property states.
// The specified offset may range between 0 and 1.  Useful for animation.
VolumeViewer.prototype.applyInterpolatedPropertyState = function(offset, propertyState0, propertyState1) {
    let propertyState = Animate.interpolate(offset, propertyState0, propertyState1);

    this.applyPropertyState(propertyState);
};

// Stringifies an object into a JSON string format.
// Similar to JSON.stringify, but does a better job of concatenating small
// objects and arrays on the same line.
// STM_TODO - this really belongs somewhere else.
VolumeViewer.jsonSmartStringify = function(obj, replacer, space) {
    if (replacer)  dconsole.warn("jsonSmartStringify: Replacer functionality not yet supported");

    const MAX_SPACES = 10;

    if (space === undefined || space === null) {
        space = false;
    }
    if (typeof space === 'boolean') {
        space = space ? 2 : 0;
    }
    if (typeof space === 'number') {
        if (space > MAX_SPACES)  space = MAX_SPACES;
        let count = space;
        space = "";
        for (let i=0; i<count; ++i)
            space += " ";
    }
    if (typeof space === 'string') {
        space = space.substring(0, MAX_SPACES);
    }
    else {
        space = "";
    }

    let spaceStr  = space;
    let padTokens = spaceStr.length > 0;
    let newLine   = padTokens ? "\n" : "";
    let padSpace  = padTokens ? " " : "";

    let indent       = 0;
    let indentStack  = [];
    let lineSuppress = 0;

    function beginIndent() {
        indentStack.push(indent);
        ++indent;
    }
    function endIndent() {
        if (indentStack.length > 0)
            indent = indentStack.pop();
        else
            indent = 0;
    }
    function beginSuppressLine() {
        ++lineSuppress;
    }
    function endSuppressLine() {
        if (lineSuppress > 0)
            --lineSuppress;
    }
    function isLineSuppressed() {
        return lineSuppress > 0;
    }

    function getIndentString() {
        if (lineSuppress > 0)  return padSpace;
        let str = newLine;
        if (spaceStr.length)
            for (let i=0; i<indent; ++i)
                str += spaceStr;
        return str;
    }

    const MAX_NESTED = 3;
    function isSimple(obj, level=0) {
        if (level > MAX_NESTED)  return false;
        if (obj === undefined || obj === null)  return true;
        if (Array.isArray(obj)) {
            let simpleContents = true;
            for (let i=0; i<obj.length; ++i) {
                let value = obj[i];
                if (value !== undefined && value !== null &&
                    typeof value !== 'number' && typeof value !== 'string' &&
                    typeof value !== 'boolean') {
                    if (!isSimple(value, level+1))  return false;
                    simpleContents = false;
                    break;
                }
            }
            if (simpleContents && obj.length > 24)  return false;
            if (!simpleContents && obj.length > 1)  return false;

            return true;
        }
        if (typeof obj === 'object') {
            let keys = Object.keys(obj);
            if (keys.length > 4)  return false;
            for (let i=0; i<keys.length; ++i) {
                let value = obj[keys[i]];
                if (!isSimple(value, level+1))  return false;
            }
            return true;
        }
        return true;
    }

    function generateJSON(obj, level=0) {
        let str = "";
        if (level > 256) {
            str += JSON.stringify(null);
        }
        else if (obj === undefined || obj === null) {
            str += JSON.stringify(obj);
        }
        else if (typeof obj === 'function') {
            str += JSON.stringify(undefined);
        }
        else if (Array.isArray(obj)) {
            let suppress = (!isLineSuppressed() && isSimple(obj));
            if (suppress)  beginSuppressLine();

            let first = true;
            str += "[";
            beginIndent();
            {
                for (let i=0; i<obj.length; ++i) {
                    let value = obj[i];
                    if (value !== undefined && typeof value !== 'function') {
                        if (first)  str += getIndentString();
                        else        str += "," + getIndentString();

                        first = false;
                        let valueStr = generateJSON(value, level+1);
                        str += `${valueStr}`;
                    }
                }
            }
            endIndent();
            if (!first)  str += getIndentString();
            str += "]";

            if (suppress)  endSuppressLine();
        }
        else if (typeof obj === 'object') {
            let suppress = (!isLineSuppressed() && isSimple(obj));
            if (suppress)  beginSuppressLine();

            let first = true;
            str += "{";
            beginIndent();
            {
                let keys = Object.keys(obj);
                for (let i=0; i<keys.length; ++i) {
                    let key   = keys[i];
                    let value = obj[key];
                    if (value !== undefined && typeof value !== 'function') {
                        if (first)  str += getIndentString();
                        else        str += "," + getIndentString();

                        first = false;
                        let keyStr = JSON.stringify(key);
                        let valueStr = generateJSON(value, level+1);
                        str += `${keyStr}:${padSpace}${valueStr}`;
                    }
                }
            }
            endIndent();
            if (!first)  str += getIndentString();
            str += "}";

            if (suppress)  endSuppressLine();
        }
        else {
            str += JSON.stringify(obj);
        }
        return str;
    }

    let jsonStr = generateJSON(obj);

    return jsonStr;
};


// VIEW PROPERTY STATES =======================================================

VolumeViewer.PROPERTY_STATE_3D_VIEW = Object.freeze(
{
    "RotationMatrix": {
        "m00": -0.8799905776977539,
        "m01": -0.2591352164745331,
        "m02": 0.3980773389339447,
        "m10": -0.46131354570388794,
        "m11": 0.6659303307533264,
        "m12": -0.5862820744514465,
        "m20": -0.11316539347171783,
        "m21": -0.6995611786842346,
        "m22": -0.7055549025535583
    },
    "Fov": 45,
    "ScreenPan": { "x": 0, "y": 0 },
    "VolumeOffset": { "x": 0, "y": 0 },
    "Gradients": true,
    "BackgroundColor": { "red": 0.09375, "green": 0.09375, "blue": 0.09375, "alpha": 1 },
    "Clipping": true,
    "ClipNormal": { "x": 0, "y": 0, "z": -1 },
    "ClipOffset": 1.0,
    "ClipFlattening": true,
    "ClipSpecularLevel": 0,
    "Lighting": true,
    "LuminosityLighting": true,
    "GradientLighting": true,
    "PlaneLighting": true,
    "LightPosition": { "x": 0, "y": 0, "z": 1, "absolute": false },
    "AmbientLevel": 0.1,
    "DiffusePower": 0.75,
    "SpecularLevel": 1,
    "LuminosityAlpha0": 1,
    "LuminosityAlpha1": 0,
    "GradientAlpha0": 1.3,
    "GradientAlpha1": 0.032,
    "PlaneAlpha0": 0,
    "PlaneAlpha1": 0,
    "AutoPlane": false,
    "PlaneMatrix": {
        "m00": 1,
        "m01": 0,
        "m02": 0,
        "m10": 0,
        "m11": 1,
        "m12": 0,
        "m20": 0,
        "m21": 0,
        "m22": 1
    },
    "PlanePoint": { "x": 0, "y": 0, "z": 0 },
    "PlaneOffset": { "px": 0, "py": 0, "pz": 0 },
    "PlaneSnapToVoxel": false,
    "PlaneInteriorClamping": false,
    "PlaneOffsetClamping": false,
    "PlaneBorders": false,
    "PlaneIntersections": false,
    "PlaneBorderColors": [
        { "red": 0.259, "green": 0.8, "blue": 0.259, "alpha": 0.8 },
        { "red": 0.8, "green": 0.259, "blue": 0.259, "alpha": 0.8 },
        { "red": 0.259, "green": 0.259, "blue": 0.8, "alpha": 0.8 }
    ],
    "PlaneBorderLineWidth": 2,
    "PlaneIntersectionColors": [
        { "red": 0.1, "green": 0.1, "blue": 0.1, "alpha": 0.8 },
        { "red": 0.1, "green": 0.1, "blue": 0.1, "alpha": 0.8 },
        { "red": 0.1, "green": 0.1, "blue": 0.1, "alpha": 0.8 }
    ],
    "PlaneIntersectionLineWidth": 1.5,
    "PlaneAlphaLevels": [ 1, 1, 1 ],
    "PlaneCrosshairs": false,
    "PlaneCrosshairRadii": { "inner": 0.078125, "outer": 0.171875 },
    "PlaneCrosshairColor": { "red": 1, "green": 0.1255, "blue": 0.1255, "alpha": 1 },
    "PlaneSpecularLevel": 0,
    "TrueLines": true,
    "PivotMatrix": {
        "m00": 1,
        "m01": 0,
        "m02": 0,
        "m10": 0,
        "m11": 1,
        "m12": 0,
        "m20": 0,
        "m21": 0,
        "m22": 1
    },
    "PivotPoint": { "x": 0, "y": 0, "z": 0 },
    "FogStart": 1,
    "FogLevel": 0,
    "LuminosityShadows": false,
    "GradientShadows": false,
    "ShadowMultiplier": 1.5,
    "ShadowSurfaceAlpha": 0,
    "ShadowStepMultiplier": 3.415899,
    "ShadowAmbientLevel": 0.1,
    "StereoView": false,
    "StereoBackgroundColor": { "red": 0, "green": 0, "blue": 0, "alpha": 1 },
    "StereoBorderColor": { "red": 0.9, "green": 0.9, "blue": 0.9, "alpha": 1 },
    "StereoBorderSize": 2,
    "StereoAspect": 0.75,
    "StereoSeparation": 0.25,
    "StereoWidth": 0.8,
    "StereoEdge": 0.25,
    "ParallelStereo": true,
    "EyeDistance": 1.5,
    "Interpolation": true,
    "SampleOnAxis": true,
    "Stipple": true,
    "RandomizedSampling": false,
    "FadeLevel": 1,
    "InputMode": "_SPIN",
    "UserRotation": true,
    "UserZoom": true,
    "UserPan": true,
    "UserRoll": true,
    "UserMovePlane": true,
    "UserMoveClip": true,
    "UserMoveAnnotations": true,
    "RockerSnapBack": true,
    "LuminosityTable0": [
        { "value": 0, "color": { "red": 1, "green": 0, "blue": 0, "alpha": 0 } },
        { "value": 0.1, "color": { "red": 1, "green": 0, "blue": 0, "alpha": 0.01 } },
        { "value": 0.2, "color": { "red": 1, "green": 0.352941, "blue": 0, "alpha": 0.025 } },
        { "value": 0.3, "color": { "red": 1, "green": 0.776471, "blue": 0.203922, "alpha": 0.015 } },
        { "value": 0.4, "color": { "red": 1, "green": 0.909804, "blue": 0.4, "alpha": 0.025 } },
        { "value": 0.5, "color": { "red": 1, "green": 0.988235, "blue": 0.596078, "alpha": 0.04 } },
        { "value": 0.6, "color": { "red": 1, "green": 0.980392, "blue": 0.827451, "alpha": 0.07 } },
        { "value": 0.8, "color": { "red": 0.807843, "green": 1, "blue": 0.972549, "alpha": 0.08 } },
        { "value": 0.95, "color": { "red": 1, "green": 1, "blue": 1, "alpha": 0.08 } }
    ],
    "LuminosityTable1": [
        { "value": 0, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 0 } },
        { "value": 0.2, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 0 } },
        { "value": 0.6, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 1 } },
        { "value": 1, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 1 } }
    ],
    "GradientTable0": [
        { "value": 0.01, "color": { "red": 0, "green": 0, "blue": 0, "alpha": 0 } },
        { "value": 0.01, "color": { "red": 0.772549, "green": 0.933333, "blue": 1, "alpha": 0.18 } },
        { "value": 0.5, "color": { "red": 0.772549, "green": 0.933333, "blue": 1, "alpha": 0.18 } },
        { "value": 1, "color": { "red": 0.772549, "green": 0.933333, "blue": 1, "alpha": 0.39 } }
    ],
    "GradientTable1": [
        { "value": 0, "color": { "red": 0.14901960784313725, "green": 0.8509803921568627, "blue": 0.14901960784313725, "alpha": 0 } },
        { "value": 0.2, "color": { "red": 0.14901960784313725, "green": 0.8509803921568627, "blue": 0.14901960784313725, "alpha": 0 } },
        { "value": 0.6, "color": { "red": 0.14901960784313725, "green": 0.8509803921568627, "blue": 0.14901960784313725, "alpha": 1 } },
        { "value": 1, "color": { "red": 0.14901960784313725, "green": 0.8509803921568627, "blue": 0.14901960784313725, "alpha": 1 } }
    ],
    "PlaneTable0": [
        { "value": 0, "color": { "red": 0, "green": 0, "blue": 0, "alpha": 1 } },
        { "value": 1, "color": { "red": 1, "green": 1, "blue": 1, "alpha": 1 } }
    ],
    "PlaneTable1": [
        { "value": 0, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 0 } },
        { "value": 0.2, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 0 } },
        { "value": 0.2, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 0.5 } },
        { "value": 1, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 0.8 } }
    ]
}
);

VolumeViewer.PROPERTY_STATE_3D2_VIEW = Object.freeze(
{
    "RotationMatrix": {
        "m00": -0.8799905776977539,
        "m01": -0.2591352164745331,
        "m02": 0.3980773389339447,
        "m10": -0.46131354570388794,
        "m11": 0.6659303307533264,
        "m12": -0.5862820744514465,
        "m20": -0.11316539347171783,
        "m21": -0.6995611786842346,
        "m22": -0.7055549025535583
    },
    "Fov": 45,
    "ScreenPan": { "x": 0, "y": 0 },
    "VolumeOffset": { "x": 0, "y": 0 },
    "Gradients": true,
    "BackgroundColor": { "red": 0.4, "green": 0.4, "blue": 0.4, "alpha": 1 },
    "Clipping": true,
    "ClipNormal": { "x": 0, "y": 0, "z": -1 },
    "ClipOffset": 1.0,
    "ClipFlattening": true,
    "ClipSpecularLevel": 0,
    "Lighting": true,
    "LuminosityLighting": true,
    "GradientLighting": false,
    "PlaneLighting": true,
    "LightPosition": { "x": 0, "y": 0, "z": 1, "absolute": false },
    "AmbientLevel": 0.30078125,
    "DiffusePower": 0.3984375,
    "SpecularLevel": 0.40234375,
    "LuminosityAlpha0": 1,
    "LuminosityAlpha1": 0,
    "GradientAlpha0": 0,
    "GradientAlpha1": 0.032,
    "PlaneAlpha0": 0,
    "PlaneAlpha1": 0,
    "AutoPlane": false,
    "PlaneMatrix": {
        "m00": 1,
        "m01": 0,
        "m02": 0,
        "m10": 0,
        "m11": 1,
        "m12": 0,
        "m20": 0,
        "m21": 0,
        "m22": 1
    },
    "PlanePoint": { "x": 0, "y": 0, "z": 0 },
    "PlaneOffset": { "px": 0, "py": 0, "pz": 0 },
    "PlaneSnapToVoxel": false,
    "PlaneInteriorClamping": true,
    "PlaneOffsetClamping": false,
    "PlaneBorders": false,
    "PlaneIntersections": false,
    "PlaneBorderColors": [
        { "red": 0.259, "green": 0.8, "blue": 0.259, "alpha": 0.8 },
        { "red": 0.8, "green": 0.259, "blue": 0.259, "alpha": 0.8 },
        { "red": 0.259, "green": 0.259, "blue": 0.8, "alpha": 0.8 }
    ],
    "PlaneBorderLineWidth": 2,
    "PlaneIntersectionColors": [
        { "red": 0.1, "green": 0.1, "blue": 0.1, "alpha": 0.8 },
        { "red": 0.1, "green": 0.1, "blue": 0.1, "alpha": 0.8 },
        { "red": 0.1, "green": 0.1, "blue": 0.1, "alpha": 0.8 }
    ],
    "PlaneIntersectionLineWidth": 1.5,
    "PlaneAlphaLevels": [ 1, 1, 1 ],
    "PlaneCrosshairs": false,
    "PlaneCrosshairRadii": { "inner": 0.078125, "outer": 0.171875 },
    "PlaneCrosshairColor": { "red": 1, "green": 0.1255, "blue": 0.1255, "alpha": 1 },
    "PlaneSpecularLevel": 1,
    "TrueLines": true,
    "PivotMatrix": {
        "m00": 1,
        "m01": 0,
        "m02": 0,
        "m10": 0,
        "m11": 1,
        "m12": 0,
        "m20": 0,
        "m21": 0,
        "m22": 1
    },
    "PivotPoint": { "x": 0, "y": 0, "z": 0 },
    "FogStart": 1,
    "FogLevel": 0.05,
    "LuminosityShadows": false,
    "GradientShadows": false,
    "ShadowMultiplier": 1.2,
    "ShadowSurfaceAlpha": 0,
    "ShadowStepMultiplier": 5.40625,
    "ShadowAmbientLevel": 0.53125,
    "StereoView": false,
    "StereoBackgroundColor": { "red": 0, "green": 0, "blue": 0, "alpha": 1 },
    "StereoBorderColor": { "red": 0.9, "green": 0.9, "blue": 0.9, "alpha": 1 },
    "StereoBorderSize": 2,
    "StereoAspect": 0.75,
    "StereoSeparation": 0.25,
    "StereoWidth": 0.8,
    "StereoEdge": 0.25,
    "ParallelStereo": true,
    "EyeDistance": 1.5,
    "Interpolation": true,
    "SampleOnAxis": true,
    "Stipple": true,
    "RandomizedSampling": false,
    "FadeLevel": 1,
    "InputMode": "_SPIN",
    "UserRotation": true,
    "UserZoom": true,
    "UserPan": true,
    "UserRoll": true,
    "UserMovePlane": true,
    "UserMoveClip": true,
    "UserMoveAnnotations": true,
    "RockerSnapBack": true,
    "LuminosityTable0": [
        { "value": 0.025, "color": { "red": 0.09411764705882353, "green": 0.09411764705882353, "blue": 0.09411764705882353, "alpha": 0 } },
        { "value": 0.25, "color": { "red": 0.25098039215686274, "green": 0.25098039215686274, "blue": 0.25098039215686274, "alpha": 0.1568627450980392 } },
        { "value": 0.5, "color": { "red": 0.5019607843137255, "green": 0.5019607843137255, "blue": 0.5019607843137255, "alpha": 0.5019607843137255 } },
        { "value": 0.75, "color": { "red": 0.7529411764705882, "green": 0.7529411764705882, "blue": 0.7529411764705882, "alpha": 0.7529411764705882 } },
        { "value": 1, "color": { "red": 1, "green": 1, "blue": 1, "alpha": 1 } }
    ],
    "LuminosityTable1": [
        { "value": 0.19593147111418205, "color": { "red": 0.09803921568627451, "green": 0.4666666666666667, "blue": 0.09803921568627451, "alpha": 0 } },
        { "value": 1, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 1 } }
    ],
    "GradientTable0": [],
    "GradientTable1": [
        { "value": 0, "color": { "red": 0.14901960784313725, "green": 0.8509803921568627, "blue": 0.14901960784313725, "alpha": 0 } },
        { "value": 0.2, "color": { "red": 0.14901960784313725, "green": 0.8509803921568627, "blue": 0.14901960784313725, "alpha": 0 } },
        { "value": 0.6, "color": { "red": 0.14901960784313725, "green": 0.8509803921568627, "blue": 0.14901960784313725, "alpha": 1 } },
        { "value": 1, "color": { "red": 0.14901960784313725, "green": 0.8509803921568627, "blue": 0.14901960784313725, "alpha": 1 } }
    ],
    "PlaneTable0": [
        { "value": 0, "color": { "red": 0, "green": 0, "blue": 0, "alpha": 1 } },
        { "value": 1, "color": { "red": 1, "green": 1, "blue": 1, "alpha": 1 } }
    ],
    "PlaneTable1": [
        { "value": 0, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 0 } },
        { "value": 0.2, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 0 } },
        { "value": 0.2, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 0.5 } },
        { "value": 1, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 0.8 } }
    ]
}
);

VolumeViewer.PROPERTY_STATE_MPR_VIEW = Object.freeze(
{
    "RotationMatrix": {
        "m00": -0.7599982023239136,
        "m01": 0.5652000308036804,
        "m02": -0.32086071372032166,
        "m10": 0.5471363067626953,
        "m11": 0.28994220495224,
        "m12": -0.7852231860160828,
        "m20": -0.35077717900276184,
        "m21": -0.7723227143287659,
        "m22": -0.5295970439910889
    },
    "Fov": 45,
    "ScreenPan": { "x": 0, "y": 0 },
    "VolumeOffset": { "x": 0, "y": 0 },
    "Gradients": true,
    "BackgroundColor": { "red": 0, "green": 0, "blue": 0, "alpha": 1 },
    "Clipping": false,
    "ClipNormal": { "x": 0, "y": 0, "z": 1 },
    "ClipOffset": 0.2,
    "ClipFlattening": true,
    "ClipSpecularLevel": 0,
    "Lighting": true,
    "LuminosityLighting": true,
    "GradientLighting": true,
    "PlaneLighting": false,
    "LightPosition": { "x": 0, "y": 0, "z": 1, "absolute": false },
    "AmbientLevel": 0,
    "DiffusePower": 0.75,
    "SpecularLevel": 1,
    "LuminosityAlpha0": 0,
    "LuminosityAlpha1": 0,
    "GradientAlpha0": 0,
    "GradientAlpha1": 0,
    "PlaneAlpha0": 1,
    "PlaneAlpha1": 0.4,
    "AutoPlane": false,
    "PlaneMatrix": {
        "m00": 1,
        "m01": 0,
        "m02": 0,
        "m10": 0,
        "m11": 1,
        "m12": 0,
        "m20": 0,
        "m21": 0,
        "m22": 1
    },
    "PlanePoint": { "x": 0, "y": 0, "z": 0 },
    "PlaneOffset": { "px": 0, "py": 0, "pz": 0 },
    "PlaneSnapToVoxel": false,
    "PlaneInteriorClamping": true,
    "PlaneOffsetClamping": false,
    "PlaneBorders": true,
    "PlaneIntersections": true,
    "PlaneBorderColors": [
        { "red": 0.259, "green": 0.8, "blue": 0.259, "alpha": 0.8 },
        { "red": 0.8, "green": 0.259, "blue": 0.259, "alpha": 0.8 },
        { "red": 0.259, "green": 0.259, "blue": 0.8, "alpha": 0.8 }
    ],
    "PlaneBorderLineWidth": 2,
    "PlaneIntersectionColors": [
        { "red": 0.06, "green": 0.06, "blue": 0, "alpha": 0.8 },
        { "red": 0.06, "green": 0.06, "blue": 0, "alpha": 0.8 },
        { "red": 0.06, "green": 0.06, "blue": 0, "alpha": 0.8 }
    ],
    "PlaneIntersectionLineWidth": 1.0,
    "PlaneAlphaLevels": [ 1, 1, 1 ],
    "PlaneCrosshairs": false,
    "PlaneCrosshairRadii": { "inner": 0.078125, "outer": 0.171875 },
    "PlaneCrosshairColor": { "red": 1, "green": 0.1255, "blue": 0.1255, "alpha": 1 },
    "PlaneSpecularLevel": 0,
    "TrueLines": true,
    "PivotMatrix": {
        "m00": 1,
        "m01": 0,
        "m02": 0,
        "m10": 0,
        "m11": 1,
        "m12": 0,
        "m20": 0,
        "m21": 0,
        "m22": 1
    },
    "PivotPoint": { "x": 0, "y": 0, "z": 0 },
    "FogStart": 1,
    "FogLevel": 0,
    "LuminosityShadows": false,
    "GradientShadows": false,
    "ShadowMultiplier": 1.5,
    "ShadowSurfaceAlpha": 0,
    "ShadowStepMultiplier": 3.415899,
    "ShadowAmbientLevel": 0.1,
    "StereoView": false,
    "StereoBackgroundColor": { "red": 0, "green": 0, "blue": 0, "alpha": 1 },
    "StereoBorderColor": { "red": 0.9, "green": 0.9, "blue": 0.9, "alpha": 1 },
    "StereoBorderSize": 2,
    "StereoAspect": 0.75,
    "StereoSeparation": 0.25,
    "StereoWidth": 0.8,
    "StereoEdge": 0.25,
    "ParallelStereo": true,
    "EyeDistance": 1.5,
    "Interpolation": true,
    "SampleOnAxis": true,
    "Stipple": true,
    "RandomizedSampling": false,
    "FadeLevel": 1,
    "InputMode": "_CROSSHAIR_SPIN",
    "UserRotation": true,
    "UserZoom": true,
    "UserPan": true,
    "UserRoll": true,
    "UserMovePlane": true,
    "UserMoveClip": true,
    "UserMoveAnnotations": true,
    "RockerSnapBack": true,
    "LuminosityTable0": [
        { "value": 0, "color": { "red": 1, "green": 0, "blue": 0, "alpha": 0 } },
        { "value": 0.1, "color": { "red": 1, "green": 0, "blue": 0, "alpha": 0.01 } },
        { "value": 0.2, "color": { "red": 1, "green": 0.352941, "blue": 0, "alpha": 0.025 } },
        { "value": 0.3, "color": { "red": 1, "green": 0.776471, "blue": 0.203922, "alpha": 0.015 } },
        { "value": 0.4, "color": { "red": 1, "green": 0.909804, "blue": 0.4, "alpha": 0.025 } },
        { "value": 0.5, "color": { "red": 1, "green": 0.988235, "blue": 0.596078, "alpha": 0.04 } },
        { "value": 0.6, "color": { "red": 1, "green": 0.980392, "blue": 0.827451, "alpha": 0.07 } },
        { "value": 0.8, "color": { "red": 0.807843, "green": 1, "blue": 0.972549, "alpha": 0.08 } },
        { "value": 0.95, "color": { "red": 1, "green": 1, "blue": 1, "alpha": 0.08 } }
    ],
    "LuminosityTable1": [
        { "value": 0, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 0 } },
        { "value": 0.2, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 0 } },
        { "value": 0.6, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 1 } },
        { "value": 1, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 1 } }
    ],
    "GradientTable0": [
        { "value": 0.01, "color": { "red": 0, "green": 0, "blue": 0, "alpha": 0 } },
        { "value": 0.01, "color": { "red": 0.772549, "green": 0.933333, "blue": 1, "alpha": 0.18 } },
        { "value": 0.5, "color": { "red": 0.772549, "green": 0.933333, "blue": 1, "alpha": 0.18 } },
        { "value": 1, "color": { "red": 0.772549, "green": 0.933333, "blue": 1, "alpha": 0.39 } }
    ],
    "GradientTable1": [],
    "PlaneTable0": [
        { "value": 0, "color": { "red": 0, "green": 0, "blue": 0, "alpha": 1 } },
        { "value": 1, "color": { "red": 1, "green": 1, "blue": 1, "alpha": 1 } }
    ],
    "PlaneTable1": [
        { "value": 0, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 0 } },
        { "value": 0.2, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 0 } },
        { "value": 0.2, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 0.5 } },
        { "value": 1, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 0.8 } }
    ]
}
);

VolumeViewer.PROPERTY_STATE_2D_VIEW = Object.freeze(
{
    "RotationMatrix": {
        "m00": -1,
        "m01": 0,
        "m02": 0,
        "m10": 0,
        "m11": -1,
        "m12": 0,
        "m20": 0,
        "m21": 0,
        "m22": 1
    },
    "Fov": 0,
    "ScreenPan": { "x": 0, "y": 0 },
    "VolumeOffset": { "x": 0, "y": 0 },
    "Gradients": true,
    "BackgroundColor": { "red": 0, "green": 0, "blue": 0, "alpha": 1 },
    "Clipping": false,
    "ClipNormal": { "x": 0, "y": 0, "z": 1 },
    "ClipOffset": 0.2,
    "ClipFlattening": true,
    "ClipSpecularLevel": 0,
    "Lighting": true,
    "LuminosityLighting": true,
    "GradientLighting": true,
    "PlaneLighting": false,
    "LightPosition": { "x": 0, "y": 0, "z": 1, "absolute": false },
    "AmbientLevel": 0,
    "DiffusePower": 0.75,
    "SpecularLevel": 1,
    "LuminosityAlpha0": 0,
    "LuminosityAlpha1": 0,
    "GradientAlpha0": 0,
    "GradientAlpha1": 0,
    "PlaneAlpha0": 1,
    "PlaneAlpha1": 0.4,
    "AutoPlane": true,
    "PlaneMatrix": {
        "m00": 1,
        "m01": 0,
        "m02": 0,
        "m10": 0,
        "m11": 1,
        "m12": 0,
        "m20": 0,
        "m21": 0,
        "m22": 1
    },
    "PlanePoint": { "x": 0, "y": 0, "z": 0 },
    "PlaneOffset": { "px": 0, "py": 0, "pz": 0 },
    "PlaneSnapToVoxel": false,
    "PlaneInteriorClamping": false,
    "PlaneOffsetClamping": true,
    "PlaneBorders": false,
    "PlaneIntersections": false,
    "PlaneBorderColors": [
        { "red": 0.259, "green": 0.8, "blue": 0.259, "alpha": 0.8 },
        { "red": 0.8, "green": 0.259, "blue": 0.259, "alpha": 0.8 },
        { "red": 0.259, "green": 0.259, "blue": 0.8, "alpha": 0.8 }
    ],
    "PlaneBorderLineWidth": 2,
    "PlaneIntersectionColors": [
        { "red": 0.1, "green": 0.1, "blue": 0.1, "alpha": 0.8 },
        { "red": 0.1, "green": 0.1, "blue": 0.1, "alpha": 0.8 },
        { "red": 0.1, "green": 0.1, "blue": 0.1, "alpha": 0.8 }
    ],
    "PlaneIntersectionLineWidth": 1.5,
    "PlaneAlphaLevels": [ 1, 1, 1 ],
    "PlaneCrosshairs": false,
    "PlaneCrosshairRadii": { "inner": 0.078125, "outer": 0.171875 },
    "PlaneCrosshairColor": { "red": 1, "green": 0.1255, "blue": 0.1255, "alpha": 1 },
    "PlaneSpecularLevel": 0,
    "TrueLines": true,
    "PivotMatrix": {
        "m00": 1,
        "m01": 0,
        "m02": 0,
        "m10": 0,
        "m11": 1,
        "m12": 0,
        "m20": 0,
        "m21": 0,
        "m22": 1
    },
    "PivotPoint": { "x": 0, "y": 0, "z": 0 },
    "FogStart": 1,
    "FogLevel": 0,
    "LuminosityShadows": false,
    "GradientShadows": false,
    "ShadowMultiplier": 1.5,
    "ShadowSurfaceAlpha": 0,
    "ShadowStepMultiplier": 3.415899,
    "ShadowAmbientLevel": 0.1,
    "StereoView": false,
    "StereoBackgroundColor": { "red": 0, "green": 0, "blue": 0, "alpha": 1 },
    "StereoBorderColor": { "red": 0.9, "green": 0.9, "blue": 0.9, "alpha": 1 },
    "StereoBorderSize": 2,
    "StereoAspect": 0.75,
    "StereoSeparation": 0.25,
    "StereoWidth": 0.8,
    "StereoEdge": 0.25,
    "ParallelStereo": true,
    "EyeDistance": 1.5,
    "Interpolation": true,
    "SampleOnAxis": true,
    "Stipple": true,
    "RandomizedSampling": false,
    "FadeLevel": 1,
    "InputMode": "_SPIN",
    "UserRotation": true,
    "UserZoom": true,
    "UserPan": true,
    "UserRoll": true,
    "UserMovePlane": true,
    "UserMoveClip": true,
    "UserMoveAnnotations": true,
    "RockerSnapBack": true,
    "LuminosityTable0": [
        { "value": 0, "color": { "red": 1, "green": 0, "blue": 0, "alpha": 0 } },
        { "value": 0.1, "color": { "red": 1, "green": 0, "blue": 0, "alpha": 0.01 } },
        { "value": 0.2, "color": { "red": 1, "green": 0.352941, "blue": 0, "alpha": 0.025 } },
        { "value": 0.3, "color": { "red": 1, "green": 0.776471, "blue": 0.203922, "alpha": 0.015 } },
        { "value": 0.4, "color": { "red": 1, "green": 0.909804, "blue": 0.4, "alpha": 0.025 } },
        { "value": 0.5, "color": { "red": 1, "green": 0.988235, "blue": 0.596078, "alpha": 0.04 } },
        { "value": 0.6, "color": { "red": 1, "green": 0.980392, "blue": 0.827451, "alpha": 0.07 } },
        { "value": 0.8, "color": { "red": 0.807843, "green": 1, "blue": 0.972549, "alpha": 0.08 } },
        { "value": 0.95, "color": { "red": 1, "green": 1, "blue": 1, "alpha": 0.08 } }
    ],
    "LuminosityTable1": [
        { "value": 0, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 0 } },
        { "value": 0.2, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 0 } },
        { "value": 0.6, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 1 } },
        { "value": 1, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 1 } }
    ],
    "GradientTable0": [
        { "value": 0.01, "color": { "red": 0, "green": 0, "blue": 0, "alpha": 0 } },
        { "value": 0.01, "color": { "red": 0.772549, "green": 0.933333, "blue": 1, "alpha": 0.18 } },
        { "value": 0.5, "color": { "red": 0.772549, "green": 0.933333, "blue": 1, "alpha": 0.18 } },
        { "value": 1, "color": { "red": 0.772549, "green": 0.933333, "blue": 1, "alpha": 0.39 } }
    ],
    "GradientTable1": [],
    "PlaneTable0": [
        { "value": 0, "color": { "red": 0, "green": 0, "blue": 0, "alpha": 1 } },
        { "value": 1, "color": { "red": 1, "green": 1, "blue": 1, "alpha": 1 } }
    ],
    "PlaneTable1": [
        { "value": 0, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 0 } },
        { "value": 0.2, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 0 } },
        { "value": 0.2, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 0.5 } },
        { "value": 1, "color": { "red": 0.15, "green": 0.85, "blue": 0.15, "alpha": 0.8 } }
    ]
}
);


// WEBGL SHADERS ==============================================================

// Default vertex shader for the mini-cube
VolumeViewer.DEFAULT_MINI_VS = `
    #define POSITION_LOCATION 0

    precision highp float;
    precision highp int;

    #if __VERSION__ >= 300
        layout(location = POSITION_LOCATION) in vec3 aVertexPosition;
    #else
        attribute vec3 aVertexPosition;
    #endif

    uniform mat4 uModelViewMatrix;
    uniform mat4 uProjectionMatrix;

    #if __VERSION__ >= 300
        out vec4 vPosition;
    #else
        varying vec4 vPosition;
    #endif

    void main(void) {
        vec3 vertexPosition = aVertexPosition;

        vPosition = uProjectionMatrix * uModelViewMatrix * vec4(vertexPosition, 1.0);
        gl_Position = vPosition;
    }
`;

// Default fragment shader for the mini-cube
VolumeViewer.DEFAULT_MINI_FS = `
    precision highp float;
    precision highp int;
    precision highp sampler2D;
    #if __VERSION__ >= 300
        precision highp sampler3D;
    #endif

    #if __VERSION__ >= 300
        in vec4 vPosition;
    #else
        varying vec4 vPosition;
    #endif

    #if __VERSION__ >= 300
        out vec4 fragColor;
    #endif

    uniform mat4      uModelViewMatrix;
    uniform mat4      uInverseMatrix;

    uniform sampler2D uSamplerFaces;

    uniform vec3      uLightPos;
    uniform int       uFaceHighlight;
    uniform vec4      uFaceColor;

    void main(void) {
        vec4 facePos   = vPosition;  // front face position (screen coordinates)
        vec4 cameraPos = facePos;    // camera position (screen coordinates)
        cameraPos.z = 0.0;

        // Convert the position of our front face and camera to world coordinates
        facePos   = uInverseMatrix * facePos;
        cameraPos = uInverseMatrix * cameraPos;
        facePos   = vec4(facePos.xyz / facePos.w, 1.0);
        cameraPos = vec4(cameraPos.xyz / cameraPos.w, 1.0);

        // Which face is this pixel on?
        vec4 color;
        vec2 texPos;
        vec3 aface = abs(facePos.xyz);
        vec2 texOffset;
        vec3 normal;
        vec3 faceTex = facePos.xyz * 0.5 + 0.5;
        int  face;
        if (aface.x > aface.y && aface.x > aface.z) {
            // X face
            if (facePos.x < 0.0) {
                // Left face
                texPos = vec2(faceTex.y, faceTex.z);
                texOffset = vec2(1.0, 1.0);
                normal = vec3(-1.0, 0.0, 0.0);
                face = 1;
            }
            else {
                // Right face
                texPos = vec2(1.0-faceTex.y, faceTex.z);
                texOffset = vec2(1.0, 3.0);
                normal = vec3(1.0, 0.0, 0.0);
                face = 2;
            }
        }
        else if (aface.y > aface.x && aface.y > aface.z) {
            // Y face
            if (facePos.y < 0.0) {
                // Anterior face
                texPos = vec2(1.0-faceTex.x, faceTex.z);
                texOffset = vec2(0.0, 0.0);
                normal = vec3(0.0, -1.0, 0.0);
                face = 3;
            }
            else {
                // Posterior face
                texPos = vec2(faceTex.x, faceTex.z);
                texOffset = vec2(0.0, 2.0);
                normal = vec3(0.0, 1.0, 0.0);
                face = 4;
            }
        }
        else {
            // Z face
            if (facePos.z < 0.0) {
                // Superior face
                texPos = vec2(faceTex.x, faceTex.y);
                texOffset = vec2(2.0, 0.0);
                normal = vec3(0.0, 0.0, -1.0);
                face = 5;
            }
            else {
                // Inferior face
                texPos = vec2(1.0-faceTex.x, faceTex.y);
                texOffset = vec2(2.0, 2.0);
                normal = vec3(0.0, 0.0, 1.0);
                face = 6;
            }
        }

        // Get the coordinate inside the texture
        vec2 coord;
        coord.x = (texPos.x + texOffset.x) / 4.0;
        coord.y = (texPos.y + texOffset.y) / 4.0;

        // Look up the texel
        #if __VERSION__ >= 300
            color = texture(uSamplerFaces, coord);
        #else
            color = texture2D(uSamplerFaces, coord);
        #endif

        // Special case: explicitly color the edges of the cube.
        // (Covers up mipmapping artifacts...)
        vec4 edgeColor;
        #if __VERSION__ >= 300
            edgeColor = texture(uSamplerFaces, vec2(0.0));
        #else
            edgeColor = texture2D(uSamplerFaces, vec2(0.0));
        #endif
        if (color.a < 1.0)
            color = edgeColor;

        // Light the cube
        const float ambientMult = 0.25;
        const float diffuseMult = 1.0 - ambientMult;

        float lightDot = dot(normal, normalize(uLightPos));
        lightDot = clamp(lightDot, 0.0, 1.0);
        float diffuse = pow(lightDot, 1.0);

        color.rgb *= diffuseMult*diffuse + ambientMult;

        // Highlight the selected face, if we have one
        if (face == uFaceHighlight)
            color.rgb = mix(color.rgb, uFaceColor.rgb, uFaceColor.a);

        // Finally, set the pixel
        #if __VERSION__ >= 300
            fragColor = color;
        #else
            gl_FragColor = color;
        #endif
    }
`;

// ============================================================================

// Default vertex shader
VolumeViewer.DEFAULT_VS = `
    #define POSITION_LOCATION 0

    precision highp float;
    precision highp int;

    #if __VERSION__ >= 300
        layout(location = POSITION_LOCATION) in vec3 aVertexPosition;
    #else
        attribute vec3 aVertexPosition;
    #endif

    uniform mat4 uModelViewMatrix;
    uniform mat4 uProjectionMatrix;
    uniform vec3 uScale;
    uniform vec3 uSize;

    #if __VERSION__ >= 300
        out vec4 vPosition;
    #else
        varying vec4 vPosition;
    #endif

    void main(void) {
        vec3 scale = uSize * abs(uScale);
        float maxScale = max(abs(scale.x), max(abs(scale.y), abs(scale.z)));
        scale /= maxScale;

        vec3 vertexPosition = aVertexPosition * scale;

        vPosition = uProjectionMatrix * uModelViewMatrix * vec4(vertexPosition, 1.0);
        gl_Position = vPosition;
    }
`;

// Default fragment shader
VolumeViewer.DEFAULT_FS = `
    precision highp float;
    precision highp int;
    precision highp sampler2D;
    #if __VERSION__ >= 300
        precision highp sampler3D;
    #endif

    #if __VERSION__ >= 300
        in vec4 vPosition;
    #else
        varying vec4 vPosition;
    #endif

    uniform mat4      uProjectionMatrix;
    uniform mat4      uModelViewMatrix;
    uniform mat4      uInverseMatrix;
    //uniform mat4      uInverseProjMatrix;
    //uniform mat4      uInverseModelMatrix;

    uniform sampler2D uStripSampler0;
    uniform sampler2D uStripSampler1;

    uniform mat4      uPlaneBorderColors;
    uniform mat4      uPlaneIntersectionColors;

    uniform vec3      uLightPos;
    uniform vec4      uBackgroundColor;
    uniform vec4      uOutlineColor;
    uniform vec4      uClipOutlineColor;
    uniform vec4      uLighting;
    uniform ivec4     uShowFlags;
    uniform ivec4     uShowFlags2;
    uniform vec4      uCanvasSize;
    uniform vec4      uLevels;
    uniform vec4      uAlphas;
    uniform vec4      uTransferRanges0;
    uniform vec4      uTransferRanges1;
    uniform vec3      uClipPlanePoint;
    uniform vec3      uClipPlaneNormal;
    uniform vec3      uPlanePoint;
    uniform vec2      uPlaneAlpha;
    uniform vec4      uPlaneAlphaLevels;
    uniform vec4      uPlaneCrosshairs;
    uniform mat3      uPlaneMatrix;
    uniform vec4      uSelectedPoint;
    uniform float     uSampleCount;
    uniform vec3      uScale;
    uniform vec3      uSize;
    uniform vec4      uShadowParams;
    uniform vec4      uDepthTiles;
    uniform vec4      uMiscValues;

    #if __VERSION__ >= 300
        uniform sampler3D uSamplerLum0;
        uniform sampler3D uSamplerLum1;
        uniform sampler3D uSamplerGrad0;
        uniform sampler3D uSamplerGrad1;
    #else
        uniform sampler2D uSamplerLum0;
        uniform sampler2D uSamplerLum1;
        uniform sampler2D uSamplerGrad0;
        uniform sampler2D uSamplerGrad1;
    #endif

    #if __VERSION__ >= 300
        out vec4 fragColor;
    #endif

    // Useful constants
    const float cLightAngle      = 0.90;  // angular radius of light source (cos(~26 degrees))
    const float cLightDistance   = 1.1;   // Distance at which the light source is considered to be radiating
    const float cLightDistance2  = cLightDistance * cLightDistance;  // Same as above, but squared
    //const float cDiffusePow      = 0.75;  // diffuse power (lower number = more uniform lighting)
    const float cDiffuseMult     = 1.00;  // brightness (> 1.0 = oversaturated)
    const float cLightSourceSize = 0.10;  // Size of the light source in world coordinates (if displayed)
    const float cClipEdge        = 0.02;  // Thickness of fuzzy clip edge, in world space
    const float cInverseClipEdge = 1.0 / cClipEdge;  // Inverse of clip edge
    const float cLightEdge       = 0.10;  // Thickness of "flat" lighting area on clip face, in world space

    // Perform a 3D volume lookup using a 2D texture (WebGL 1.0 compatibility mode)
    #if __VERSION__ < 300
        vec4 texture3DLookup(sampler2D sampler, vec3 coord) {
            #ifdef USE_TRILINEAR_INTERPOLATION
                coord.xyz *= vec3(uDepthTiles.zw, uSize.z);

                float low = float(floor(coord.z));
                float high = low + 1.0;
                float mixval = coord.z - low;

                float rlow = float(floor(low * uDepthTiles.z));
                float clow = low - rlow * uDepthTiles.x;
                float rhigh = float(floor(high * uDepthTiles.z));
                float chigh = high - rhigh * uDepthTiles.x;

                vec2 coord0 = coord.xy + uDepthTiles.zw*vec2(clow, rlow);
                vec2 coord1 = coord.xy + uDepthTiles.zw*vec2(chigh, rhigh);

                vec4 texel0 = texture2D(sampler, coord0);
                vec4 texel1 = texture2D(sampler, coord1);
                vec4 texel = mix(texel0, texel1, mixval);

                return texel;
            #else
                coord.xyz *= vec3(uDepthTiles.zw, uSize.z);

                float mid = float(floor(coord.z + 0.5));

                float rmid = float(floor(mid * uDepthTiles.z));
                float cmid = mid - rmid * uDepthTiles.x;

                vec2 coord0 = coord.xy + uDepthTiles.zw*vec2(cmid, rmid);

                vec4 texel = texture2D(sampler, coord0);

                return texel;
            #endif
        }
    #endif  // __VERSION__ < 300

    // Overlay one (potentially translucent) color on top of another
    vec4 overlayColor(vec4 color0, vec4 color1) {
        return vec4((1.0 - color1.a)*color0.rgb + color1.a*color1.rgb,
                    color0.a * (1.0 - color1.a) + color1.a);
    }

    // Underlay one color beneath another (potentially translucent) color
    vec4 underlayColor(vec4 color0, vec4 color1) {
        return vec4(color0.rgb + (1.0 - color0.a) * color1.a * color1.rgb,
                    color0.a * (1.0 - color1.a) + color1.a);
    }

    // Perform a weighted average of two colors
    vec4 combineColors(vec4 color0, vec4 color1) {
        // Compute a merged color from the two specified colors.
        // The new color is generated using an average of the
        // two colors weighted by their alpha values.
        vec4 combinedColor = vec4(0.0, 0.0, 0.0, 0.0);
        float totalAlpha = color0.a + color1.a - (color0.a * color1.a);
        if (totalAlpha > 0.0) {
            combinedColor.rgb = (color0.rgb * color0.a +
                                 color1.rgb * color1.a) / totalAlpha;
            combinedColor.a = clamp(totalAlpha, 0.0, 1.0);
        }
        return combinedColor;
    }

    // Perform a weighted average of two colors
    vec4 mergeColors(vec4 color0, vec4 color1) {
        vec4 combinedColor = vec4(0.0, 0.0, 0.0, 0.0);
        float sumAlpha = color0.a + color1.a;
        float totalAlpha = sumAlpha - (color0.a * color1.a);
        if (totalAlpha > 0.0) {
            combinedColor.rgb = (color0.rgb * color0.a +
                                 color1.rgb * color1.a) / sumAlpha;
            combinedColor.a = clamp(totalAlpha, 0.0, 1.0);
        }
        return combinedColor;
    }

    // Compute the level of specular highlighting at the specified point
    float computeSpecular(vec3 pos, vec3 dir, vec3 lightPos) {
        #ifdef SHOW_FINITE_SPECULAR
            // Use this code if you want to assume that the light source is
            // a finite distance away (possibly even inside the model).

            vec3 d1 = pos - lightPos;
            vec3 d2 = d1 - dir;
            vec3 num = cross(d1, d2);
            float dist2;
            if (dot(dir, d1) >= 0.0)
                dist2 = dot(d1, d1);  // ray is moving away from light source
            else
                dist2 = dot(num, num) / dot(dir, dir);  // ray is moving toward light source
            if (dist2 < cLightDistance2) {
                float dist = sqrt(dist2);
                return sin((1.0 - dist/cLightDistance) * 3.1415926 * 0.5);
            }
            return 0.0;

        #else
            // Use this code if you want to assume that the light source is
            // infinitely far away.

            float dotval = dot(dir, normalize(lightPos-pos));
            if (dotval > cLightAngle)
                return (dotval-cLightAngle)/(1.0-cLightAngle);
            else
                return 0.0;
        #endif  // SHOW_FINITE_SPECULAR
    }

    // If we are undersampling, this may improve the volume's
    // appearance somewhat.  Or not.  :)
    float computeRandomOffset(vec2 fragPos, vec2 seed) {
        const float block = 256.0;

        vec2 screenPos = mod(fragPos.xy, block);
        // Precision errors occur on the iPad if we don't precompute our offsets this way!
        vec2 combinedOffset = vec2(0.0) - vec2(uCanvasSize.zw);
        screenPos += mod(combinedOffset, block);
        screenPos = mod(screenPos, block);
        screenPos /= 13.94234923;
        screenPos += seed.xy;
        return fract(cos(dot(screenPos,vec2(23.14069263277926,2.665144142690225)))*12345.6789);
    }

    // Compute an offset using a stipple pattern generated from the screen
    // position.  Improves the volume's appearance when undersampling.
    float computeStippleOffset(vec2 fragPos, vec2 offset) {
        const float block     = 4.0;
        const float subblock  = 2.0;
        const float block2    = block * block;
        const float subblock2 = subblock * subblock;

        vec2 screenPos = mod(fragPos.xy, block);
        // Precision errors occur on the iPad if we don't precompute our offsets this way!
        vec2 combinedOffset = offset - vec2(uCanvasSize.zw);
        screenPos += combinedOffset;
        screenPos = mod(screenPos.xy, block);
        float val = (floor(screenPos.y) * block + floor(screenPos.x));

        // Ugly, but necessary due to WebGL 1.0 limitations.
        // Calculate step value using an inline binary search.
        // This uses a variant of a Bayer dithering pattern.
        //
        //   5 C 7 E
        //   B 0 8 3
        //   6 F 4 D
        //   9 2 A 1

        float step;
        if (val < 8.0)
            if (val < 4.0)
                if (val < 2.0)
                    if (val <  1.0)  step =  5.0;  // value == 0
                    else             step = 12.0;  // value == 1
                else
                    if (val <  3.0)  step =  7.0;  // value == 2
                    else             step = 14.0;  // value == 3
            else
                if (val < 6.0)
                    if (val <  5.0)  step = 11.0;  // value == 4
                    else             step =  0.0;  // value == 5
                else
                    if (val <  7.0)  step =  8.0;  // value == 6
                    else             step =  3.0;  // value == 7
        else
            if (val < 12.0)
                if (val < 10.0)
                    if (val <  9.0)  step =  6.0;  // value == 8
                    else             step = 15.0;  // value == 9
                else
                    if (val < 11.0)  step =  4.0;  // value == 10
                    else             step = 13.0;  // value == 11
            else
                if (val < 14.0)
                    if (val < 13.0)  step =  9.0;  // value == 12
                    else             step =  2.0;  // value == 13
                else
                    if (val < 15.0)  step = 10.0;  // value == 14
                    else             step =  1.0;  // value == 15

        step /= block2;

        screenPos = fragPos.xy / block;
        screenPos = mod(screenPos.xy, subblock);
        screenPos -= mod(uCanvasSize.zw / block, subblock);
        screenPos = mod(screenPos, subblock);
        val = (floor(screenPos.y) * subblock + floor(screenPos.x));

        float substep;
        if (val < 2.0)
            if (val <  1.0)  substep =  0.0;  // value == 0
            else             substep =  3.0;  // value == 1
        else
            if (val <  3.0)  substep =  2.0;  // value == 2
            else             substep =  1.0;  // value == 3

        substep /= subblock2;

        step = step + substep / block2;

        return step;
    }

    // Calculate an offset for a raytrace
    float computeSampleOffset(vec2 seed) {
        #ifdef STIPPLE
            #ifdef RANDOMIZE_SAMPLING
                return computeRandomOffset(gl_FragCoord.xy, vec2(0.0));
            #else
                return computeStippleOffset(gl_FragCoord.xy, vec2(0.0));
            #endif // RANDOMIZE_SAMPLING
        #else  // STIPPLE
            #ifdef RANDOMIZE_SAMPLING
                return computeRandomOffset(gl_FragCoord.xy, seed*8.0);
            #else
                return 0.0;
            #endif // RANDOMIZE_SAMPLING
        #endif  // STIPPLE
    }

    // Detect whether a ray has crossed a plane
    bool checkPlaneCrossing(vec3 fromPos, vec3 toPos, vec3 dir) {
        float oldDist = dot(fromPos, dir);
        float newDist = dot(toPos, dir);
        if ((oldDist < 0.0 && newDist >= 0.0) || (oldDist >= 0.0 && newDist < 0.0))
            return true;
        else
            return false;
    }

    // Determine whether our current voxel position is at a border (for drawing outlines)
    bool atBorder(vec3 edge, vec3 scale) {
        const float offset = 0.025;//0.05;

        int count = 0;
        count += abs(edge.x) > scale.x-offset ? 1 : 0;
        count += abs(edge.y) > scale.y-offset ? 1 : 0;
        count += abs(edge.z) > scale.z-offset ? 1 : 0;
        return count >= 2;
    }

    // Determine whether our current voxel position is near the intersection
    // of the clip plane and an outer face of the volume (for drawing outlines)
    bool atClipBorder(vec3 edge, vec3 scale, vec3 planePoint, vec3 planeNorm,
                      float borderLineWidth) {
        int count = 0;
        count += abs(edge.x) > scale.x-borderLineWidth ? 1 : 0;
        count += abs(edge.y) > scale.y-borderLineWidth ? 1 : 0;
        count += abs(edge.z) > scale.z-borderLineWidth ? 1 : 0;
        if (count >= 1) {
            float dotval = dot(planeNorm, planeNorm);
            if (dotval > 0.0) {
                float d = dot(planePoint - edge, planeNorm) / dotval;
                float len = length(d * planeNorm);
                if (len < borderLineWidth)
                    return true;
            }
        }
        return false;
    }

    // Compute the intersection of the specified ray and the specified volume
    // face, and return true if they intersect
    bool computeVolumeFacePoint(vec3 pos, vec3 dir, vec3 norm, vec3 scale, out vec3 newPos) {
        vec3 plane = scale * norm;  // STM_TODO - hacky!
        float dotdir = dot(dir, norm);
        if (dotdir > 0.0) {
            float d = dot(plane - pos, norm) / dotdir;
            newPos = d * dir + pos;
            const float fudge = 1.000001;
            if (newPos.x >= -scale.x*fudge && newPos.x <= scale.x*fudge &&
                newPos.y >= -scale.y*fudge && newPos.y <= scale.y*fudge &&
                newPos.z >= -scale.z*fudge && newPos.z <= scale.z*fudge) {
                return true;
            }
        }
        return false;
    }

    // Compute the point at which the specified ray exits the volume
    // (implicitly, the nearest point on the ray to the camera that is
    // still inside the volume)
    void computeVolumeEndPoint(vec3 pos, vec3 dir, vec3 scale, out vec3 near) {
        vec3 newPos;

        near = pos + dir * 3.5;

        // Test all six faces of the volume
        if (computeVolumeFacePoint(pos, dir, vec3(-1.0, 0.0, 0.0), scale, newPos)) {
            float dist = length(pos - newPos);
            if (dist < length(pos-near))  near = newPos;
        }
        if (computeVolumeFacePoint(pos, dir, vec3(1.0, 0.0, 0.0), scale, newPos)) {
            float dist = length(pos - newPos);
            if (dist < length(pos-near))  near = newPos;
        }
        if (computeVolumeFacePoint(pos, dir, vec3(0.0, -1.0, 0.0), scale, newPos)) {
            float dist = length(pos - newPos);
            if (dist < length(pos-near))  near = newPos;
        }
        if (computeVolumeFacePoint(pos, dir, vec3(0.0, 1.0, 0.0), scale, newPos)) {
            float dist = length(pos - newPos);
            if (dist < length(pos-near))  near = newPos;
        }
        if (computeVolumeFacePoint(pos, dir, vec3(0.0, 0.0, -1.0), scale, newPos)) {
            float dist = length(pos - newPos);
            if (dist < length(pos-near))  near = newPos;
        }
        if (computeVolumeFacePoint(pos, dir, vec3(0.0, 0.0, 1.0), scale, newPos)) {
            float dist = length(pos - newPos);
            if (dist < length(pos-near))  near = newPos;
        }
    }

    // Compute the light level specifically for a plane
    float computePlaneLightLevel(vec3 curPos, vec3 cameraPos, vec4 planeColor, vec3 rayDir,
                                 vec3 gradient, vec3 lightPos, vec3 usScale, float ambient) {
        // Calculate the direction of the light
        vec3 lightDir = normalize(curPos - lightPos);

        // Calculate the surface normal (take scaling into account)
        vec3 normal = normalize(gradient / usScale);
        if (length(normal) >= 1e-6)
            normal = normalize(normal);
        else
            normal = lightDir;
        if (dot(curPos - cameraPos.xyz, normal.xyz) < 0.0)
            normal = -normal;

        // Compute diffuse light level
        float diffuse = dot(normal.xyz, lightDir);
        if (diffuse < 0.0)  diffuse = clamp(-diffuse, 0.0, 1.0)*0.1;
        else                diffuse = clamp(diffuse, 0.0, 1.0);
        diffuse = pow(diffuse, uLevels.w) * cDiffuseMult;  // uLevels.w == cDiffusePow

        // Extremely translucent areas should not be shaded
        const float diffCenter = 0.7;
        float diffScale = clamp(planeColor.a*4.0, 0.0, 1.0);
        diffuse = clamp(diffCenter-(diffCenter-diffuse)*diffScale, 0.0, 1.0);

        float lightLevel = (diffuse * (1.0 - ambient)) + ambient;  // diffuse + ambient

        // Compute specular light level
        if (uLighting.y > 0.0) {  // uLighting.y == cPlaneSpecularLevel
            vec3 ref = reflect(-rayDir, normal.xyz);
            // Translucent areas should also not have specular
            lightLevel += uLighting.y * 0.5 * computeSpecular(curPos, ref, lightPos) * diffScale;
        }

        return lightLevel;
    }

    // Compute the color of the plane at the specified point (lighting is done elsewhere)
    vec4 computePlaneColor(
                           #if __VERSION__ >= 300
                                sampler3D sampler,
                           #else
                                sampler2D sampler,
                           #endif
                           sampler2D planeSampler,
                           vec3 planePos, vec3 texPosMult, float texPosAdd, vec3 normal,
                           float minLuminosity, float deltaLuminosity, float planeAlpha) {

        vec4 color = vec4(0.0);

        vec3 texPos = planePos * texPosMult + texPosAdd;

        // Ensure that the specified point is actually in the volume...
        if (texPos.x >= 0.0 && texPos.x <= 1.0 && texPos.y >= 0.0 && texPos.y <= 1.0 &&
            texPos.z >= 0.0 && texPos.z <= 1.0) {

            // Look up the luminosity from the volume
            #if __VERSION__ >= 300
                color = textureLod(sampler, texPos, 0.0);
            #else
                color = texture3DLookup(sampler, texPos);
            #endif

            // Normalize the luminosity
            color.x = clamp((color.x - minLuminosity) / deltaLuminosity, 0.0, 1.0);
            // Convert the normalized luminosity to a color based on a
            // lookup texture
            #if __VERSION__ >= 300
                color = textureLod(planeSampler, vec2(color.x, 1.0), 0.0);
            #else
                color = texture2D(planeSampler, vec2(color.x, 1.0));
            #endif

            // STM_TODO - not sure we want this
            #ifdef DISPLAY_SELECTED
                // Display the selected point on the plane
                if (uSelectedPoint.w != 0.0) {
                    float ptDist = length(planePos - uSelectedPoint.xyz);
                    float maxLen = 0.03;
                    if (ptDist < maxLen) {
                        vec4 pColor = vec4(0.1, 1.0, 0.1, clamp(4.0*(maxLen-ptDist)/maxLen, 0.0, 1.0));
                        color = mergeColors(color, pColor);
                    }
                }
            #endif

            // Finally, apply the plane alpha
            color.a *= planeAlpha;
        }

        return color;
    }

    #ifdef SHOW_PLANE_INTERSECTIONS
        // Compute the color used to denote the intersection of two or more planes
        vec4 computePlaneIntersectionColor(vec3 planePos, vec3 texPosMult, float texPosAdd,
                                           vec3 normal, float planeAlpha) {

            vec4 color = vec4(0.0);

            vec3 texPos = planePos * texPosMult + texPosAdd;

            float lineWidth = uPlaneIntersectionColors[3].w;

            // Ensure that the specified point is actually in the volume...
            if (texPos.x >= 0.0 && texPos.x <= 1.0 && texPos.y >= 0.0 && texPos.y <= 1.0 &&
                texPos.z >= 0.0 && texPos.z <= 1.0) {

                // Clip out areas that are not part of the crosshair
                float pointDist = length(uPlanePoint - planePos);
                if (pointDist >= uPlaneCrosshairs.x && pointDist < uPlaneCrosshairs.y) {

                    const float EPSILON = 1e-6;

                    vec3 a;
                    float d;

                    if (uPlaneCrosshairs.z > 0.0)  planeAlpha = 1.0;

                    #ifdef TRUE_LINES
                        vec4 l0, l1, l2;
                        vec2 norm;

                        l0 = uProjectionMatrix * uModelViewMatrix * vec4(uPlanePoint, 1.0);
                        l2 = uProjectionMatrix * uModelViewMatrix * vec4(planePos, 1.0);
                        l0.xy *= 0.5 * uCanvasSize.xy / l0.w;
                        l2.xy *= 0.5 * uCanvasSize.xy / l2.w;

                        for (int i=0; i<3; ++i) {
                            d = 0.0;
                            if      (i == 0)  a = cross(normal.xyz, uPlaneMatrix[0].xyz);
                            else if (i == 1)  a = cross(normal.xyz, uPlaneMatrix[1].xyz);
                            else if (i == 2)  a = cross(normal.xyz, uPlaneMatrix[2].xyz);

                            l1 = uProjectionMatrix * uModelViewMatrix * vec4(uPlanePoint+a, 1.0);
                            l1.xy *= 0.5 * uCanvasSize.xy / l1.w;
                            norm = l1.xy - l0.xy;
                            if (dot(norm, norm) >= EPSILON) {
                                norm = normalize(norm).yx * vec2(1.0, -1.0);
                                d = abs(dot(l2.xy - l0.xy, norm));
                                d = clamp(1.0 - (d - lineWidth * 0.5), 0.0, 1.0);
                                if (d > 0.0)  {
                                    color = mergeColors(color, uPlaneIntersectionColors[i] * vec4(1.0, 1.0, 1.0, d*planeAlpha));
                                }
                            }
                        }
                    #else  // TRUE_LINES
                        float nearness = lineWidth * 0.00625;

                        vec3 norm;

                        vec3 delta = planePos - uPlanePoint;

                        for (int i=0; i<3; ++i) {
                            if      (i == 0)  norm = uPlaneMatrix[0].xyz;
                            else if (i == 1)  norm = uPlaneMatrix[1].xyz;
                            else if (i == 2)  norm = uPlaneMatrix[2].xyz;

                            if (norm != normal && dot(norm, norm) > 0.0) {
                                d = abs(dot(delta, norm));
                                d = clamp((4.0*(nearness-d)/nearness), 0.0, 1.0);
                                if (d > EPSILON) {
                                    color = mergeColors(color, uPlaneIntersectionColors[i] * vec4(1.0, 1.0, 1.0, d*planeAlpha));
                                }
                            }
                        }
                    #endif
                }
            }

            return color;
        }
    #endif  // SHOW_PLANE_INTERSECTIONS

    #ifdef SHOW_PLANE_BORDERS
        // Compute the color used to denote the intersection of two or more planes
        vec4 computePlaneBorderColor(vec3 planePos, vec3 texPosMult, float texPosAdd,
                                     vec3 normal, vec3 scale, float planeAlpha) {

            vec4 color = vec4(0.0);

            vec3 texPos = planePos * texPosMult + texPosAdd;

            float lineWidth = uPlaneBorderColors[3].w;

            // Ensure that the specified point is actually in the volume...
            if (texPos.x >= 0.0 && texPos.x <= 1.0 && texPos.y >= 0.0 && texPos.y <= 1.0 &&
                texPos.z >= 0.0 && texPos.z <= 1.0) {

                #ifdef TRUE_LINES
                    float d;
                    float maxd = 0.0;

                    vec3 facePoint, faceNormal;
                    vec3 a;
                    vec4 l0, l1, l2;
                    vec2 norm;
                    vec3 orth;
                    vec3 p;
                    float det;

                    const float EPSILON = 1e-6;

                    l2 = uProjectionMatrix * uModelViewMatrix * vec4(planePos, 1.0);
                    l2.xy *= 0.5 * uCanvasSize.xy / l2.w;

                    for (int i=0; i<6; ++i) {
                        d = 0.0;
                        if      (i == 0) { faceNormal = vec3( 1.0,  0.0,  0.0); facePoint = faceNormal * scale.x; }
                        else if (i == 1) { faceNormal = vec3(-1.0,  0.0,  0.0); facePoint = faceNormal * scale.x; }
                        else if (i == 2) { faceNormal = vec3( 0.0,  1.0,  0.0); facePoint = faceNormal * scale.y; }
                        else if (i == 3) { faceNormal = vec3( 0.0, -1.0,  0.0); facePoint = faceNormal * scale.y; }
                        else if (i == 4) { faceNormal = vec3( 0.0,  0.0,  1.0); facePoint = faceNormal * scale.z; }
                        else if (i == 5) { faceNormal = vec3( 0.0,  0.0, -1.0); facePoint = faceNormal * scale.z; }
                        a = cross(normal, faceNormal);
                        if (dot(a, a) >= EPSILON) {
                            if      (abs(a.x) >= EPSILON)  orth = vec3(1.0, 0.0, 0.0);
                            else if (abs(a.y) >= EPSILON)  orth = vec3(0.0, 1.0, 0.0);
                            else if (abs(a.z) >= EPSILON)  orth = vec3(0.0, 0.0, 1.0);
                            else                           orth = vec3(0.0, 0.0, 0.0);
                            det = dot(a, orth);
                            if (abs(det) > EPSILON) {
                                p = dot(uPlanePoint, normal)*cross(faceNormal, orth) +
                                    dot(facePoint, faceNormal)*cross(orth, normal);
                                p /= det;
                                l0 = uProjectionMatrix * uModelViewMatrix * vec4(p,   1.0);
                                l1 = uProjectionMatrix * uModelViewMatrix * vec4(p+a, 1.0);
                                l0.xy *= 0.5 * uCanvasSize.xy / l0.w;
                                l1.xy *= 0.5 * uCanvasSize.xy / l1.w;
                                norm = l1.xy - l0.xy;
                                if (dot(norm, norm) >= EPSILON) {
                                    norm = normalize(norm).yx * vec2(1.0, -1.0);
                                    d = abs(dot(l2.xy - l0.xy, norm));
                                    d = clamp(1.0 - (d - lineWidth), 0.0, 1.0);
                                }
                            }
                        }
                        maxd = max(d, maxd);
                    }

                    if (maxd > 0.0)  {
                        vec4 edgeColor = vec4(1.0);
                        if (normal == uPlaneMatrix[0])
                            edgeColor = uPlaneBorderColors[0];
                        else if (normal == uPlaneMatrix[1])
                            edgeColor = uPlaneBorderColors[1];
                        else if (normal == uPlaneMatrix[2])
                            edgeColor = uPlaneBorderColors[2];
                        edgeColor.a *= maxd;
                        color = edgeColor;
                        color.a *= planeAlpha;
                    }
                #else  // TRUE_LINES
                    float nearness = lineWidth * 0.00625;

                    if (atClipBorder(planePos, scale, planePos, normal, nearness)) {
                        vec4 edgeColor = vec4(1.0);
                        if (normal == uPlaneMatrix[0])
                            edgeColor = uPlaneBorderColors[0];
                        else if (normal == uPlaneMatrix[1])
                            edgeColor = uPlaneBorderColors[1];
                        else if (normal == uPlaneMatrix[2])
                            edgeColor = uPlaneBorderColors[2];
                        color = edgeColor;
                        color.a *= planeAlpha;

                    }
                #endif  // TRUE_LINES
            }

            return color;
        }
    #endif  // SHOW_PLANE_BORDERS

    // Compute the color of the volume (or subvolume) at the specified point
    vec4 computeVolumeColor(
                            #if __VERSION__ >= 300
                                 sampler3D samplerLum,
                                 sampler3D samplerGrad,
                            #else
                                 sampler2D samplerLum,
                                 sampler2D samplerGrad,
                            #endif
                            sampler2D stripSampler,
                            vec3 texPos,
                            float gradAdd, vec3 gradMult,
                            float minLuminosity, float deltaLuminosity,
                            float minGradient, float deltaGradient,
                            float luminosityAlpha, float gradientAlpha,
                            float alphaStep,
                            vec3 cameraPos,
                            vec3 curPos, vec3 lightPos, vec3 rayDir,
                            vec3 usScale, bool showLight,
                            inout float surfaceAlpha) {

        vec3  cGradOffset              = uMiscValues.x / uSize;
        vec3  cClipPlanePoint          = uClipPlanePoint;
        vec3  cClipPlaneNormal         = uClipPlaneNormal;
        vec3  cClipPlaneUnitNormal     = normalize(cClipPlaneNormal);
        bool  cClippingEnabled         = length(cClipPlaneNormal) > 1e-6 ? true : false;
        bool  cLightLuminosity         = uShowFlags.z != 0 ? true : false;
        bool  cLightGradient           = uShowFlags.w != 0 ? true : false;
        bool  cLightPlane              = uShowFlags2.z != 0 ? true : false;
        bool  cLightEnabled            = cLightLuminosity || cLightGradient;
        float cAmbient                 = uLevels.x;
        float cDiffuse                 = 1.0 - cAmbient;
        float cSpecularLevel           = uLighting.x;
        float cPlaneSpecularLevel      = uLighting.y;
        float cClipSpecularLevel       = uLighting.z;

        // Grab the 1-tuple voxel color at this location.
        // Format: x == luminosity (normalized)
        #if __VERSION__ >= 300
            vec4 voxelColor = textureLod(samplerLum, texPos.xyz, 0.0);
        #else
            vec4 voxelColor = texture3DLookup(samplerLum, texPos.xyz);
        #endif

        // Grab the 4-tuple gradient data at this location.
        // Format: xyz == surface normal (biased), w == gradient magnitude
        #ifdef OFFSET_GRADIENT
            #if __VERSION__ >= 300
                vec4 gradColor = textureLod(samplerGrad, texPos.xyz + cGradOffset, 0.0);
            #else
                vec4 gradColor = texture3DLookup(samplerGrad, texPos.xyz + cGradOffset);
            #endif
        #else
            #if __VERSION__ >= 300
                vec4 gradColor = textureLod(samplerGrad, texPos.xyz, 0.0);
            #else
                vec4 gradColor = texture3DLookup(samplerGrad, texPos.xyz);
            #endif
        #endif  // OFFSET_GRADIENT

        float luminosity = voxelColor.x;
        vec3  gradient   = gradColor.xyz;

        // Rescale gradient based on user-defined scale
        #ifdef USE_GRADIENT_ROUNDING
            float gradientMagnitude = gradColor.w;
            gradient = (gradient + gradAdd) * gradMult;
            gradient = normalize(gradient) * gradientMagnitude;
        #else
            // Compute the gradient magnitude from the gradient vector
            gradient = (gradient + gradAdd) * gradMult;
            float gradientMagnitude = dot(gradient, gradient);
            if (gradientMagnitude > 1e-12)
                gradientMagnitude = sqrt(gradientMagnitude);
        #endif

        // Re-normalize luminosity and gradient magnitude
        float normLuminosity = (luminosity - minLuminosity) / deltaLuminosity;
        float normGradientMagnitude = (gradientMagnitude - minGradient) /
                                      deltaGradient;

        // Convert luminosity and gradient magnitude to colors.
        // This is basically the 2D transfer function.
        #if __VERSION__ >= 300
            vec4 luminosityColor = textureLod(stripSampler, vec2(normLuminosity, 0.0), 0.0);
            vec4 gradientColor   = textureLod(stripSampler, vec2(normGradientMagnitude, 0.5), 0.0);
        #else
            vec4 luminosityColor = texture2D(stripSampler, vec2(normLuminosity, 0.0));
            vec4 gradientColor   = texture2D(stripSampler, vec2(normGradientMagnitude, 0.5));
        #endif

        if (normLuminosity <= 0.0) gradientColor = vec4(0, 0, 0, 0);

        // Bounds checking -- is this point inside the volume?
        if (texPos.x < 0.0 || texPos.x > 1.0 || texPos.y < 0.0 || texPos.y > 1.0 ||
            texPos.z < 0.0 || texPos.z > 1.0) {
            // Nope, we are outside
            luminosityColor.a = 0.0;
            gradientColor.a   = 0.0;
        }
        else if (cClippingEnabled) {
            // Do not display any part of the volume that is above the clipping plane
            float dotprod = dot((curPos - cClipPlanePoint), cClipPlaneUnitNormal);
            if (dotprod >= 0.0) {
                // Narrow the fuzzy edge of the clipping region, depending on
                // the angle at which we are viewing the clip plane
                float clipDot = abs(dot(rayDir, cClipPlaneUnitNormal));
                float clipEdge = 0.0;
                float inverseClipEdge = 0.0;
                if (clipDot >= 1e-6) {
                    clipEdge = cClipEdge * clipDot;
                    inverseClipEdge = 1.0 / clipEdge;
                }
                if (dotprod >= clipEdge) {
                    // We are in the clipping region
                    luminosityColor.a = 0.0;
                    gradientColor.a   = 0.0;
                }
                else {
                    // We are at the fuzzy edge of the clipping region
                    float temp = 1.0 - dotprod * inverseClipEdge;
                    luminosityColor.a = clamp(luminosityColor.a * temp, 0.0, 1.0);
                    gradientColor.a = clamp(gradientColor.a * temp, 0.0, 1.0);
                }
            }
        }

        luminosityColor.a *= alphaStep * luminosityAlpha;
        gradientColor.a *= alphaStep * gradientAlpha;

        // Ensure that our alpha values are sane
        luminosityColor.a = clamp(luminosityColor.a, 0.0, 1.0);
        gradientColor.a = clamp(gradientColor.a, 0.0, 1.0);

        // The colors defined here will be lit
        vec4 combinedColor = vec4(0.0, 0.0, 0.0, 0.0);
        if (cLightLuminosity && cLightGradient) {
            combinedColor = combineColors(luminosityColor, gradientColor);
        }
        else if (cLightLuminosity) {
            combinedColor = luminosityColor;
        }
        else if (cLightGradient) {
            combinedColor = gradientColor;
        }

        // Light the pixel
        #ifdef SHOW_LIGHTING
            // We inline this to improve speed
            if (showLight && combinedColor.a > 0.0) {
                if (cLightEnabled) {
                    // Calculate the direction of the light
                    vec3 lightDir = normalize(curPos - lightPos);

                    // Calculate the surface normal (take scaling into account)
                    vec3 normal = gradient / usScale;
                    if (length(normal) >= 1e-6)
                        normal = normalize(normal);
                    else
                        normal = lightDir;
                    if (dot(curPos - cameraPos.xyz, normal.xyz) < 0.0)
                        normal = -normal;

                    float specularMult = cSpecularLevel;
                    #ifdef SHOW_CLIP_FLATTENING
                        if (cClippingEnabled) {
                            // This code will be called if the portion of the
                            // volume we are rendering lies at the edge of the
                            // clipping plane.
                            // We want to re-orient the normals in the volume
                            // so that they are facing the same direction as
                            // the clipping plane's normals.
                            // This will "flatten" the lighting of the
                            // clipping plane's surface.
                            float dotprod = -dot((curPos - cClipPlanePoint), cClipPlaneUnitNormal);
                            if (dotprod < cLightEdge && dotprod >= -cClipEdge) {
                                float clipDot = clamp(dot(rayDir, cClipPlaneUnitNormal), 0.0, 1.0);
                                float lightEdge = 0.0;
                                float inverseLightEdge = 0.0;
                                if (clipDot >= 1e-6) {
                                    lightEdge = cLightEdge * clipDot;
                                    inverseLightEdge = 1.0 / (cLightEdge * clipDot);
                                }
                                if (dotprod < lightEdge) {
                                    // We are at the fuzzy edge of the clipping region
                                    float lightMult = 1.0 - clamp((lightEdge-dotprod) * inverseLightEdge, 0.0, 1.0);
                                    lightMult = smoothstep(0.0, 1.0, lightMult);

                                    // Interpolate between the volume's original
                                    // normal and the plane's normal.
                                    vec3 planeNormal = cClipPlaneUnitNormal;
                                    if (dot(curPos - cameraPos.xyz, planeNormal.xyz) < 0.0)
                                        planeNormal = -planeNormal;
                                    normal = normalize(mix(planeNormal, normal, lightMult));

                                    // Remove specular highlighting if requested
                                    specularMult = mix(cClipSpecularLevel, cSpecularLevel, lightMult);
                                }
                            }
                        }
                    #endif  // SHOW_CLIP_FLATTENING

                    // Compute diffuse light level
                    float diffuse = dot(normal.xyz, lightDir);
                    if (diffuse < 0.0)  diffuse = clamp(-diffuse, 0.0, 1.0)*0.1;
                    else                diffuse = clamp(diffuse, 0.0, 1.0);
                    diffuse = pow(diffuse, uLevels.w) * cDiffuseMult;  // uLevels.w == cDiffusePow

                    // Extremely translucent areas should not be shaded
                    const float diffCenter = 0.7;
                    float diffScale = clamp(combinedColor.a*4.0/alphaStep, 0.0, 1.0);
                    diffuse = clamp(diffCenter-(diffCenter-diffuse)*diffScale, 0.0, 1.0);

                    float lightLevel = (diffuse * cDiffuse) + cAmbient;  // diffuse + ambient

                    // Compute specular light level
                    if (specularMult > 0.0) {
                        vec3 ref = reflect(-rayDir, normal.xyz);
                        // Translucent areas should also not have specular
                        lightLevel += specularMult * 0.5 * computeSpecular(curPos, ref, lightPos) * diffScale;
                    }

                    combinedColor.rgb *= lightLevel;
                }
            }
        #endif  // SHOW_LIGHTING

        // The colors defined here will be unlit
        if (!cLightLuminosity && !cLightGradient) {
            combinedColor = combineColors(luminosityColor, gradientColor);
        }
        else if (!cLightLuminosity) {
            combinedColor = combineColors(combinedColor, luminosityColor);
        }
        else if (!cLightGradient) {
            combinedColor = combineColors(combinedColor, gradientColor);
        }

        #ifdef SHOW_SHADOWS_LUMINOSITY
        #ifdef SHOW_SHADOWS_GRADIENT
            if (surfaceAlpha < combinedColor.a)
                surfaceAlpha = combinedColor.a;
        #else
            if (surfaceAlpha < luminosityColor.a)
                surfaceAlpha = luminosityColor.a;
        #endif
        #else
        #ifdef SHOW_SHADOWS_GRADIENT
            if (surfaceAlpha < gradientColor.a)
                surfaceAlpha = gradientColor.a;
        #endif
        #endif

        return combinedColor;
    }

    // Compute the shadow alpha of the volume (or subvolume) at the specified point
    #ifdef SHOW_SHADOWS
        float computeVolumeAlpha(
                                 #if __VERSION__ >= 300
                                      sampler3D samplerLum,
                                      sampler3D samplerGrad,
                                 #else
                                      sampler2D samplerLum,
                                      sampler2D samplerGrad,
                                 #endif
                                 sampler2D stripSampler,
                                 vec3 texPos,
                                 float gradAdd, vec3 gradMult,
                                 float minLuminosity, float invDeltaLuminosity,
                                 float minGradient, float invDeltaGradient,
                                 float luminosityAlpha, float gradientAlpha,
                                 float alphaStep,
                                 vec3 curPos) {

            bool  cClippingEnabled     = dot(uClipPlaneNormal, uClipPlaneNormal) > 1e-12 ? true : false;
            vec3  cClipPlaneUnitNormal = normalize(uClipPlaneNormal);

            // Grab the 4-tuple voxel color at this location.
            // Format: xyz == gradient (biased), w == luminosity
            #if __VERSION__ >= 300
                vec4 voxelColor = textureLod(samplerLum, texPos.xyz, 0.0);
            #else
                vec4 voxelColor = texture3DLookup(samplerLum, texPos.xyz);
            #endif

            #if __VERSION__ >= 300
                vec4 gradColor = textureLod(samplerGrad, texPos.xyz, 0.0);
            #else
                vec4 gradColor = texture3DLookup(samplerGrad, texPos.xyz);
            #endif

            float luminosity        = voxelColor.x;
            //vec3  gradient          = gradColor.xyz;
            float gradientMagnitude = gradColor.w;

            // Rescale gradient based on user-defined scale
            //gradient = (gradient + gradAdd) * gradMult;
            //gradient = normalize(gradient) * gradientMagnitude;

            // Re-normalize luminosity and gradient magnitude
            float normLuminosity = (luminosity - minLuminosity) * invDeltaLuminosity;
            float normGradientMagnitude = (gradientMagnitude - minGradient) *
                                          invDeltaGradient;

            // Convert luminosity and gradient magnitude to colors.
            // This is basically the 2D transfer function.
            #ifdef SHOW_SHADOWS_LUMINOSITY
                #if __VERSION__ >= 300
                    vec4 luminosityColor = textureLod(stripSampler, vec2(normLuminosity, 0.0), 0.0);
                #else
                    vec4 luminosityColor = texture2D(stripSampler, vec2(normLuminosity, 0.0));
                #endif
            #endif
            #ifdef SHOW_SHADOWS_GRADIENT
                #if __VERSION__ >= 300
                    vec4 gradientColor   = textureLod(stripSampler, vec2(normGradientMagnitude, 0.5), 0.0);
                #else
                    vec4 gradientColor   = texture2D(stripSampler, vec2(normGradientMagnitude, 0.5));
                #endif
            #endif

            #ifdef SHOW_SHADOWS_GRADIENT
                if (normLuminosity <= 0.0)  gradientColor.a = 0.0;
            #endif

            // Bounds checking -- is this point inside the volume?
            if (texPos.x < 0.0 || texPos.x > 1.0 || texPos.y < 0.0 || texPos.y > 1.0 ||
                texPos.z < 0.0 || texPos.z > 1.0) {
                // Nope, we are outside
                return 0.0;
            }
            else if (cClippingEnabled) {
                // Do not display any part of the volume that is above the clipping plane
                float dotprod = dot((curPos - uClipPlanePoint), cClipPlaneUnitNormal);
                if (dotprod >= 0.0) {
                    if (dotprod > cClipEdge) {
                        // We are in the clipping region
                        return 0.0;
                    }
                    else {
                        // We are at the fuzzy edge of the clipping region
                        float temp = 1.0 - dotprod * cInverseClipEdge;
                        #ifdef SHOW_SHADOWS_LUMINOSITY
                            luminosityColor.a = clamp(luminosityColor.a * temp, 0.0, 1.0);
                        #endif
                        #ifdef SHOW_SHADOWS_GRADIENT
                            gradientColor.a = clamp(gradientColor.a * temp, 0.0, 1.0);
                        #endif
                    }
                }
            }

            #ifdef SHOW_SHADOWS_LUMINOSITY
                luminosityColor.a *= alphaStep * luminosityAlpha;
                luminosityColor.a = clamp(luminosityColor.a, 0.0, 1.0);
            #endif

            #ifdef SHOW_SHADOWS_GRADIENT
                gradientColor.a *= alphaStep * gradientAlpha;
                gradientColor.a = clamp(gradientColor.a, 0.0, 1.0);
            #endif

            #ifdef SHOW_SHADOWS_LUMINOSITY
            #ifdef SHOW_SHADOWS_GRADIENT
                float finalAlpha = clamp(luminosityColor.a + gradientColor.a -
                                         (luminosityColor.a * gradientColor.a), 0.0, 1.0);
            #else
                float finalAlpha = clamp(luminosityColor.a, 0.0, 1.0);
            #endif
            #else
            #ifdef SHOW_SHADOWS_GRADIENT
                float finalAlpha = clamp(gradientColor.a, 0.0, 1.0);
            #endif
            #endif

            return finalAlpha;
        }
    #endif  // SHOW_SHADOWS

    void main(void) {
        bool  cShowOutline             = uShowFlags.x != 0 ? true : false;
        bool  cLightLuminosity         = uShowFlags.z != 0 ? true : false;
        bool  cLightGradient           = uShowFlags.w != 0 ? true : false;
        bool  cLightEnabled            = cLightLuminosity || cLightGradient;
        bool  cLightPlane              = uShowFlags2.z != 0 ? true : false;
        float cMinLuminosity0          = uTransferRanges0.x;
        float cMaxLuminosity0          = uTransferRanges0.y;
        float cDeltaLuminosity0        = cMaxLuminosity0 - cMinLuminosity0;
        float cMinGradientMagnitude0   = uTransferRanges0.z;
        float cMaxGradientMagnitude0   = uTransferRanges0.w;
        float cDeltaGradientMagnitude0 = cMaxGradientMagnitude0 - cMinGradientMagnitude0;
        float cMinLuminosity1          = uTransferRanges1.x;
        float cMaxLuminosity1          = uTransferRanges1.y;
        float cDeltaLuminosity1        = cMaxLuminosity1 - cMinLuminosity1;
        float cMinGradientMagnitude1   = uTransferRanges1.z;
        float cMaxGradientMagnitude1   = uTransferRanges1.w;
        float cDeltaGradientMagnitude1 = cMaxGradientMagnitude1 - cMinGradientMagnitude1;
        float cLuminosityAlpha0        = uAlphas.x;
        float cGradientAlpha0          = uAlphas.y;
        float cLuminosityAlpha1        = uAlphas.z;
        float cGradientAlpha1          = uAlphas.w;
        float cPlaneAlpha0             = uPlaneAlpha.x;
        float cPlaneAlpha1             = uPlaneAlpha.y;
        float cAmbient                 = uLevels.x;
        float cDiffuse                 = 1.0 - cAmbient;
        vec3  cClipPlanePoint          = uClipPlanePoint;
        vec3  cClipPlaneNormal         = uClipPlaneNormal;
        bool  cClippingEnabled         = length(cClipPlaneNormal) > 1e-6 ? true : false;
        vec4  cFogColor                = vec4(uBackgroundColor.xyz, uLevels.y * 0.0214375);  // empirically determined
        float cFogClipDistance         = uLevels.z * 1.75;  // maximum distance from the center of the cube to the corner
        bool  cShowClipOutline         = cClippingEnabled && (uShowFlags2.x != 0 ? true : false);
        float cMinAlphaSurface         = uShadowParams.y;
        float cShadowAmbient           = uShadowParams.w;
        float cShadowDiffuse           = 1.0 - cShadowAmbient;
        float cShadowMult              = uShadowParams.x;
        float cShadowStepMult          = uShadowParams.z;
        vec3  cGradOffset              = uMiscValues.x / uSize;
        float cFadeLevel               = uMiscValues.y;

        vec4 facePos   = vPosition;  // back face position (screen coordinates)
        vec4 cameraPos = facePos;  // camera position (screen coordinates)
        cameraPos.z = 0.0;

        // Convert the position of our back face and camera to world coordinates
        facePos   = uInverseMatrix * facePos;
        cameraPos = uInverseMatrix * cameraPos;
        facePos   = vec4(facePos.xyz / facePos.w, 1.0);
        cameraPos = vec4(cameraPos.xyz / cameraPos.w, 1.0);

        // Compute a direction vector from the back face to the camera
        vec3 rayDir = cameraPos.xyz - facePos.xyz;
        rayDir = normalize(rayDir);

        // Compute scaling factors
        vec3 scale = uSize * uScale;
        float maxScale = max(abs(scale.x), max(abs(scale.y), abs(scale.z)));
        vec3 invScale = maxScale / scale;
        scale /= maxScale;

        // Conveniences for world-to-texture conversion
        const float texPosAdd = 0.5;
        vec3 texPosMult = 0.5 * invScale;
        const float gradAdd = -128.0 / 255.0;
        vec3 gradMult = 2.0 * scale;

        // From here on out, we assume scale is positive
        scale = abs(scale);
        vec3 usScale = abs(uScale);

        // Compute the end point of the ray as it exits the volume
        vec3 endPos;
        computeVolumeEndPoint(facePos.xyz, rayDir, scale, endPos);

        #ifdef SAMPLE_ON_AXIS
            // We will attempt to sample the volume at evenly-spaced planes
            // along the X, Y or Z axis -- whichever axis is pointing most
            // directly towards the camera.
            // Step 1 of this process is to figure out which axis that is.
            // (We could just check the X, Y and Z values directly, rather
            // than using dot products, but this leaves the door open for
            // using non-axis-aligned planes someday...)

            vec3 cameraDir = normalize(cameraPos.xyz);
            vec3 curAxis = vec3(1.0, 0.0, 0.0);
            float curDot = dot(curAxis, cameraDir);
            float bestDot = curDot;
            vec3 bestAxis = curAxis;
            curAxis = vec3(0.0, 1.0, 0.0);
            curDot = dot(curAxis, cameraDir);
            if (abs(bestDot) < abs(curDot)) {
                bestDot = curDot;
                bestAxis = curAxis;
            }
            curAxis = vec3(0.0, 0.0, 1.0);
            curDot = dot(curAxis, cameraDir);
            if (abs(bestDot) < abs(curDot)) {
                bestDot = curDot;
                bestAxis = curAxis;
            }
            if (bestDot < 0.0)
                bestAxis = -bestAxis;

            vec3 sampleAxis = bestAxis;
        #else
            // Use the camera vector directly for sample plane alignment
            vec3 sampleAxis = rayDir;
        #endif

        // Determine whether we are displaying planes
        bool computePlane = cPlaneAlpha0 > 0.0 || cPlaneAlpha1 > 0.0;

        // A hack, but a useful one: reduce our step count if we are only displaying
        // planes, which need less accuracy (speeds up the framerate)
        float sampleCount = float(uSampleCount);
        bool undersample = false;
        #ifndef SHOW_VOLUMES
            if (cLuminosityAlpha0 <= 0.0 && cLuminosityAlpha1 <= 0.0 &&  // not displaying luminosity
                cGradientAlpha0 <= 0.0 && cGradientAlpha1 <= 0.0 &&      // not displaying gradients
                !(uLevels.y > 0.0 && uLevels.z > -1.0) &&                // not displaying fog
                computePlane) {                                          // displaying planes
                undersample = true;
            }
        #endif  // SHOW_VOLUMES

        vec3 rayStart = facePos.xyz;
        vec3 rayEnd   = endPos;
        float stepIncrement, stepsLeft;

        if (!undersample) {
            // Determine our sampling interval based on the axis
            stepIncrement = 1.0 / (dot(rayDir, sampleAxis) * sampleCount);

            // Snap the endpoints of the sampling ray to the nearest planes
            // along the X, Y or Z axis
            float d;
            vec3 pos;

            d = dot(rayStart, sampleAxis) / dot(rayDir, sampleAxis);
            pos = rayStart - d*rayDir;
            d = ceil(d / stepIncrement) * stepIncrement;  // quantize
            rayStart = pos + d*rayDir;

            d = dot(rayEnd, sampleAxis) / dot(rayDir, sampleAxis);
            pos = rayEnd - d*rayDir;
            d = floor(d / stepIncrement) * stepIncrement;  // quantize
            rayEnd = pos + d*rayDir;

            // To avoid undersampling artifacts, compute a sample offset here
            float offset = computeSampleOffset(rayStart.xy + rayEnd.yz);
            vec3 lurch0 = offset * stepIncrement * rayDir;
            vec3 lurch1 = (1.0 - offset) * stepIncrement * rayDir;
            rayStart -= lurch0;
            rayEnd += lurch1;

            float totalDist = length(rayEnd - rayStart);
            stepsLeft = floor(totalDist / abs(stepIncrement) + 0.5) + 2.5;
        }
        else {
            // This is our undersampling case.
            // Because we are only computing plane crossings, we can jump
            // across the entire volume (front to back) in a single step!
            stepsLeft = 2.5;
            stepIncrement = length(rayEnd - rayStart);
        }

        // Compute the alpha value per step
        float cAlphaStep = stepIncrement * 70.0;  // empirically determined
        /* STM_TODO - experimental code
        {
            float tempCount = cAlphaStep;
            float transparency = pow(0.5, tempCount);  // imitate successive steps
            transparency = pow(transparency, 0.4545);
            //transparency = pow(transparency, 0.5);
            cAlphaStep = (1.0 - clamp(transparency, 0.0, 1.0)) * 4.0;
        }
        //*/

        #ifdef SHOW_SHADOWS
            vec3 voxelSize = scale / uSize;
            float shadowOffset = 4.0 * min(voxelSize.x, min(voxelSize.y, voxelSize.z));  // STM_TODO - make this configurable
        #endif

        // Useful constants
        float cInvIncrement = 1.0 / stepIncrement;

        // Ensure that hidden planes won't be displayed by setting their
        // normals to zero-length vectors.
        vec3 xDir = uPlaneAlphaLevels[0] > 0.0 ? uPlaneMatrix[0] : vec3(0.0);
        vec3 yDir = uPlaneAlphaLevels[1] > 0.0 ? uPlaneMatrix[1] : vec3(0.0);
        vec3 zDir = uPlaneAlphaLevels[2] > 0.0 ? uPlaneMatrix[2] : vec3(0.0);

        // Fetch the position of the light (by default, the same as the
        // camera position)
        vec3 cLightPos = uLightPos;

        // And so it begins...
        vec4 color = uBackgroundColor;

        #ifdef SHOW_FOG
            // Fog constants
            vec3 fogClipNormal = normalize(cameraPos.xyz);
            vec3 fogClipPoint = fogClipNormal * cFogClipDistance;

            // STM_TODO - we can also use the clip plane as a fogging plane

            float fogDot = dot(rayDir, fogClipNormal);
            float minDot = 1e-6;
            if (abs(fogDot) < minDot)  fogDot = (fogDot < 0.0) ? -minDot : minDot;
            float invFogDot = fogDot != 0.0 ? 1.0 / fogDot : 0.0;

            // Scale fog based on our sampling rate
            cFogColor.a *= cAlphaStep;

            // Clamp (because this can get large!)
            cFogColor.a = clamp(cFogColor.a, 0.0, 1.0);

            if (cFogColor.a > 0.0)  color = vec4(cFogColor.xyz, 1.0);
        #endif  // SHOW_FOG

        // Draw the far portion of the clip outline
        const float cClipLineWidth = 0.025;
        if (cShowClipOutline) {
            if (atClipBorder(facePos.xyz, scale, cClipPlanePoint, cClipPlaneNormal,
                             cClipLineWidth))
                color = overlayColor(color, uClipOutlineColor);
        }

        // Draw the far portion of the outline
        if (cShowOutline) {
            if (atBorder(facePos.xyz, scale))
                color = overlayColor(color, uOutlineColor);
        }

        // Set up our raytracing parameters
        #ifdef FRONT_TO_BACK
            vec3 sampleStart = rayEnd;
            vec3 sampleDir = -rayDir;
        #else
            vec3 sampleStart = rayStart;
            vec3 sampleDir = rayDir;
        #endif
        vec3 curPos;
        float curStep = 0.0;
        vec3 prevPos = sampleStart + sampleDir*(curStep - stepIncrement);

        // Our starting color will depend on whether we are compositing
        // front-to-back or back-to-front...
        #ifdef FRONT_TO_BACK
            vec4 curColor = vec4(0.0, 0.0, 0.0, 0.0);
        #else
            vec4 curColor = color;
        #endif

        // Starting from the front (or back) face of the volume cube, raytrace
        // away from (or towards) the camera in steps, computing a new color
        // and alpha at each step.
        // Foreground colors cover background colors.  We take this
        // into account regardless of whether we are compositing front-to-back
        // or back-to-front.
        //
        // All of these steps are used to compute a final pixel color.

        // STM_TODO - this is a total hack for WebGL 1.0
        // (which requires constants in for() loops)
        const int MAX_COUNT = int(3.5 * 512.0 * 2.0) + 1;
        for (int i=0; i<MAX_COUNT; ++i) {
            // Compute position in world space (bounding volume: vec3(-1) - vec3(1))
            curPos = sampleStart + sampleDir * curStep;

            // The following code block will draw planes on three axes, if requested
            #ifdef SHOW_PLANES
                vec4 planeColor0 = vec4(0.0);
                vec4 planeColor1 = vec4(0.0);
                vec4 planeColor2 = vec4(0.0);

                if (computePlane)
                {
                    // Has our ray crossed a plane?
                    float d;
                    int count = 0;
                    mat4 planeData = mat4(0.0);

                    // Compute the endpoints for the line segment that we will use
                    // to check for plane crossings
                    vec3 newPos = curPos - uPlanePoint;
                    vec3 oldPos = prevPos - uPlanePoint;

                    const float PLANE_THRESHOLD = 1e-3;

                    // The plane that moves along the X axis (displays coronal image)
                    if (checkPlaneCrossing(oldPos, newPos, xDir)) {
                        d = dot(rayDir, xDir);
                        if (abs(d) >= PLANE_THRESHOLD) {
                            d = -dot(oldPos, xDir) / d;
                            planeData[0] = vec4(xDir, d);
                            count += 1;
                        }
                    }

                    // The plane that moves along the Y axis (displays sagittal image)
                    if (checkPlaneCrossing(oldPos, newPos, yDir)) {
                        d = dot(rayDir, yDir);
                        if (abs(d) >= PLANE_THRESHOLD) {
                            d = -dot(oldPos, yDir) / d;
                            if (count == 0)  planeData[0] = vec4(yDir, d);  // Hack for WebGL 1.0
                            else             planeData[1] = vec4(yDir, d);
                            count += 1;
                        }
                    }

                    // The plane that moves along the Z axis (displays axial image)
                    if (checkPlaneCrossing(oldPos, newPos, zDir)) {
                        d = dot(rayDir, zDir);
                        if (abs(d) >= PLANE_THRESHOLD) {
                            d = -dot(oldPos, zDir) / d;
                            if      (count == 0)  planeData[0] = vec4(zDir, d);  // Hack for WebGL 1.0
                            else if (count == 1)  planeData[1] = vec4(zDir, d);
                            else                  planeData[2] = vec4(zDir, d);
                            count += 1;
                        }
                    }

                    // We have at least one plane crossing.  Now perform our expensive calculations.
                    if (count > 0) {
                        // If we have crossed more than one plane on this step, things
                        // get tricky.
                        // Perform an inlined bubble sort on the plane crossings,
                        // sorted by distance (closer points later in the list).
                        // We have to do it this way because WebGL 1.0 doesn't
                        // allow variable indices for matrices, only constants.  Ugh.
                        vec4 tempData;
                        if (count > 2) {
                            // Three plane crossings
                            if (planeData[0].w > planeData[1].w) {
                                tempData     = planeData[1];
                                planeData[1] = planeData[0];
                                planeData[0] = tempData;
                            }
                            if (planeData[1].w > planeData[2].w) {
                                tempData     = planeData[2];
                                planeData[2] = planeData[1];
                                planeData[1] = tempData;
                            }
                        }
                        if (count > 1) {
                            // Two or three plane crossings
                            if (planeData[0].w > planeData[1].w) {
                                tempData     = planeData[1];
                                planeData[1] = planeData[0];
                                planeData[0] = tempData;
                            }
                        }

                        vec4 voxelColor, voxelColor1;
                        float lightLevel;
                        vec3 planePos;
                        vec3 planeNormal;
                        float planeAlphaLevel;

                        // Compute colors, starting from the most distant plane
                        for (int n=0; n<3; ++n) {
                            planePos = planeData[n].w * rayDir + prevPos;
                            planeNormal = planeData[n].xyz;

                            // A little hacky...
                            if      (planeNormal == uPlaneMatrix[0].xyz)  planeAlphaLevel = uPlaneAlphaLevels[0];
                            else if (planeNormal == uPlaneMatrix[1].xyz)  planeAlphaLevel = uPlaneAlphaLevels[1];
                            else                                          planeAlphaLevel = uPlaneAlphaLevels[2];

                            #ifdef SHOW_PLANE0
                                voxelColor = computePlaneColor(uSamplerLum0, uStripSampler0, planePos, texPosMult, texPosAdd, planeNormal, cMinLuminosity0, cDeltaLuminosity0, cPlaneAlpha0*planeAlphaLevel);
                            #else
                                voxelColor = vec4(0.0);
                            #endif
                            #ifdef SHOW_PLANE1
                                voxelColor1 = computePlaneColor(uSamplerLum1, uStripSampler1, planePos, texPosMult, texPosAdd, planeNormal, cMinLuminosity1, cDeltaLuminosity1, cPlaneAlpha1*planeAlphaLevel);
                                voxelColor = mergeColors(voxelColor, voxelColor1);
                            #endif
                            #ifdef SHOW_PLANE_LIGHTING
                                lightLevel = cLightPlane ? computePlaneLightLevel(planePos, cameraPos.xyz, voxelColor, rayDir, planeNormal, cLightPos, usScale, cAmbient) : 1.0;
                                voxelColor.rgb *= lightLevel;
                            #endif
                            #ifdef SHOW_PLANE_INTERSECTIONS
                                voxelColor1 = computePlaneIntersectionColor(planePos, texPosMult, texPosAdd, planeNormal, voxelColor.a);
                                voxelColor = overlayColor(voxelColor, voxelColor1);
                            #endif
                            #ifdef SHOW_PLANE_BORDERS
                                voxelColor1 = computePlaneBorderColor(planePos, texPosMult, texPosAdd, planeNormal, scale, max(cPlaneAlpha0, cPlaneAlpha1)*planeAlphaLevel);
                                voxelColor = overlayColor(voxelColor, voxelColor1);
                            #endif

                            if      (n == 0)  planeColor0 = voxelColor;
                            else if (n == 1)  planeColor1 = voxelColor;
                            else              planeColor2 = voxelColor;

                            if (n >= count-1)
                                break;
                        }
                    }
                }

            #endif  // SHOW_PLANES

            // Abort whenever we've gone beyond the bounding volume
            stepsLeft -= 1.0;
            if (stepsLeft < 0.0)  break;

            // The following code block will draw 3D volumes, if requested
            #ifdef SHOW_VOLUMES
                vec4 volumeColor = vec4(0.0);

                // Convert world position to texture coordinates
                vec3 texPos = curPos * texPosMult + texPosAdd;

                float surfaceAlpha = 0.0;

                // Load colors from up to two volumes, then merge them
                #ifdef SHOW_VOLUME0
                    volumeColor = computeVolumeColor(uSamplerLum0, uSamplerGrad0, uStripSampler0,
                                                     texPos, gradAdd, gradMult,
                                                     cMinLuminosity0, cDeltaLuminosity0,
                                                     cMinGradientMagnitude0, cDeltaGradientMagnitude0,
                                                     cLuminosityAlpha0, cGradientAlpha0,
                                                     cAlphaStep,
                                                     cameraPos.xyz,
                                                     curPos, cLightPos, rayDir, usScale, true,
                                                     surfaceAlpha);
                #endif  // SHOW_VOLUME0

                #ifdef SHOW_VOLUME1
                    vec4 volumeColor1 = computeVolumeColor(uSamplerLum1, uSamplerGrad1, uStripSampler1,
                                                           texPos, gradAdd, gradMult,
                                                           cMinLuminosity1, cDeltaLuminosity1,
                                                           cMinGradientMagnitude1, cDeltaGradientMagnitude1,
                                                           cLuminosityAlpha1, cGradientAlpha1,
                                                           cAlphaStep,
                                                           cameraPos.xyz,
                                                           curPos, cLightPos, rayDir, usScale, true,
                                                           surfaceAlpha);

                    #ifdef SHOW_VOLUME0
                        volumeColor = combineColors(volumeColor, volumeColor1);
                    #else
                        volumeColor = volumeColor1;
                    #endif
                #endif  // SHOW_VOLUME1

                #ifdef SHOW_SHADOWS
                    // Darken our volume colors if we are casting shadows
                    if (surfaceAlpha * cInvIncrement > cMinAlphaSurface) {
                        // This code block is inlined for maximum speed
                        float shadowAlpha = 1.0;
                        vec3 surfacePos = curPos;

                        // If stippling, offset the point from which we
                        // raytrace to the light source, based on our screen
                        // position.  This reduces artifacts.
                        #ifdef STIPPLE
                            #ifdef RANDOMIZE_SAMPLING
                                surfacePos += rayDir * stepIncrement * (computeRandomOffset(gl_FragCoord.xy, vec2(2.0, 3.0)) - 0.5);
                            #else
                                surfacePos += rayDir * stepIncrement * (computeStippleOffset(gl_FragCoord.xy, vec2(2.0, 3.0)) - 0.5);
                            #endif  // RANDOMIZE_SAMPLING
                        #endif  // STIPPLE

                        vec3 lightRayDir = normalize(cLightPos - surfacePos);

                        // Compute where our "light ray" should end
                        vec3 endPos;
                        computeVolumeEndPoint(surfacePos, lightRayDir, scale, endPos);

                        float totalDist = length(endPos - surfacePos);
                        float distToLight = length(cLightPos - surfacePos);
                        if (totalDist > distToLight)
                            totalDist = distToLight;
                        totalDist *= 0.99999;  // fudge factor
                        float stepsLeft = floor(totalDist * float(uSampleCount)) + 1.0;
                        float increment = totalDist / stepsLeft;

                        float alphaStep = increment * cShadowStepMult * 20.0;  // empirically determined

                        increment *= cShadowStepMult;
                        stepsLeft /= cShadowStepMult;

                        float volumeAlpha;

                        // Offset the raytrace to the light source based on
                        // random sampling or dithering.  This reduces repeating
                        // patterns in the shadows.
                        float step = computeSampleOffset(vec2(surfacePos.xy + endPos.yz)) * increment + shadowOffset;

                        const int MAX_STEPS = 180;
                        for (int i=0; i<MAX_STEPS; ++i) {
                            stepsLeft -= 1.0;
                            if (stepsLeft < 0.0)  break;

                            vec3 curPos = surfacePos + lightRayDir*step;

                            // Convert world position to texture coordinates
                            vec3 texPos = curPos * texPosMult + texPosAdd;

                            #ifdef SHOW_VOLUME0
                                volumeAlpha = computeVolumeAlpha(uSamplerLum0, uSamplerGrad0, uStripSampler0,
                                                                 texPos, gradAdd, gradMult,
                                                                 cMinLuminosity0, 1.0/cDeltaLuminosity0,
                                                                 cMinGradientMagnitude0, 1.0/cDeltaGradientMagnitude0,
                                                                 cLuminosityAlpha0, cGradientAlpha0,
                                                                 alphaStep,
                                                                 curPos);
                                //volumeAlpha *= clamp(step/0.1, 0.0, 1.0);
                                shadowAlpha *= 1.0 - clamp(volumeAlpha * cShadowMult, 0.0, 1.0);
                            #endif  // SHOW_VOLUME0

                            #ifdef SHOW_VOLUME1
                                volumeAlpha = computeVolumeAlpha(uSamplerLum1, uSamplerGrad1, uStripSampler1,
                                                                 texPos, gradAdd, gradMult,
                                                                 cMinLuminosity1, 1.0/cDeltaLuminosity1,
                                                                 cMinGradientMagnitude1, 1.0/cDeltaGradientMagnitude1,
                                                                 cLuminosityAlpha1, cGradientAlpha1,
                                                                 alphaStep,
                                                                 curPos);
                                //volumeAlpha *= clamp(step/0.1, 0.0, 1.0);
                                shadowAlpha *= 1.0 - clamp(volumeAlpha * cShadowMult, 0.0, 1.0);
                            #endif  // SHOW_VOLUME1

                            // Early abort if we pass through something opaque
                            if (shadowAlpha < 0.0039) {
                                shadowAlpha = 0.0;
                                break;
                            }

                            step += increment;
                        }

                        shadowAlpha = clamp(shadowAlpha, 0.0, 1.0);
                        shadowAlpha = (shadowAlpha * cShadowDiffuse) + cShadowAmbient;
                        volumeColor.rgb *= shadowAlpha;
                    }
                #endif  // SHOW_SHADOWS

            #endif  // SHOW_VOLUMES

            // The following code block will draw the light source, if requested
            #ifdef SHOW_LIGHT_SOURCE
                vec4 lightColor = vec4(0.0);

                float lightBall = length(curPos - cLightPos);
                lightBall = clamp(cLightSourceSize - lightBall, 0.0, 1.0);
                if (lightBall > 0.0)
                    lightColor = vec4(1.0, 1.0, 0.0, lightBall);
            #endif  // SHOW_LIGHT_SOURCE

            // The following code block will display fog, if requested
            #ifdef SHOW_FOG
                vec4 fogColor = vec4(0.0);

                // Apply fog to the color
                // (NOTE: this occurs at every step in the raytrace, making the
                // fog cumulative as the ray moves forward)
                if (cFogColor.a > 0.0) {
                    float d = dot(fogClipPoint - curPos, fogClipNormal) * invFogDot;
                    if (d >= 0.0)
                        fogColor = cFogColor;
                }
            #endif  // SHOW_FOG

            // Finally, composite the individual color components for this voxel
            // (how the colors are composited will depend on whether
            // we are accumulating colors front-to-back or back-to-front)
            #ifdef FRONT_TO_BACK
                #ifdef SHOW_FOG
                    curColor = underlayColor(curColor, fogColor);
                #endif
                #ifdef SHOW_LIGHT_SOURCE
                    curColor = underlayColor(curColor, lightColor);
                #endif
                #ifdef SHOW_PLANES
                    curColor = underlayColor(curColor, planeColor2);
                    curColor = underlayColor(curColor, planeColor1);
                    curColor = underlayColor(curColor, planeColor0);
                #endif
                #ifdef SHOW_VOLUMES
                    curColor = underlayColor(curColor, volumeColor);
                #endif
            #else
                #ifdef SHOW_PLANES
                    curColor = overlayColor(curColor, planeColor0);
                    curColor = overlayColor(curColor, planeColor1);
                    curColor = overlayColor(curColor, planeColor2);
                #endif
                #ifdef SHOW_VOLUMES
                    curColor = overlayColor(curColor, volumeColor);
                #endif
                #ifdef SHOW_LIGHT_SOURCE
                    curColor = overlayColor(curColor, lightColor);
                #endif
                #ifdef SHOW_FOG
                    curColor = overlayColor(curColor, fogColor);
                #endif
            #endif

            // Done with this step...  save our old position
            prevPos = curPos;

            // Next step in the raytrace...
            curStep += stepIncrement;

            #ifdef FRONT_TO_BACK
                // When doing front-to-back compositing, we have the option
                // of an early abort if our voxel color becomes sufficiently
                // opaque...
                if (curColor.a > 0.995) {
                    curColor.a = 1.0;
                    break;
                }
            #endif
        }

        // All voxels have been processed, and a volume color has been computed.
        // Now merge the volume color with the background.
        #ifdef FRONT_TO_BACK
            color = underlayColor(curColor, color);  // underlay the background
        #else
            //color = overlayColor(color, curColor);  // overlay the volume
            color = curColor;
        #endif

        // We are now done with the raytracing.  Make some final
        // adjustments to the pixel color.

        curPos = endPos;

        // Draw the near portion of the outline
        if (cShowOutline) {
            if (atBorder(curPos, scale))
                color = overlayColor(color, uOutlineColor);
        }

        // Draw the near portion of the clip outline
        if (cShowClipOutline) {
            if (atClipBorder(curPos, scale, cClipPlanePoint, cClipPlaneNormal,
                             cClipLineWidth))
                color = overlayColor(color, uClipOutlineColor);
        }

        #ifdef SHOW_FOG
            // Determine the distance to the fog plane, and add fog to the color
            // based on that distance.  (We do this via a single mathematical
            // operation that is equivalent to adding successive stepped quantities
            // of fog.)
            if (cFogColor.a > 0.0) {
                float d = dot(fogClipPoint - curPos, fogClipNormal) * invFogDot;
                if (d >= 0.0) {
                    // Still in the fogged area
                    float stepCount = length(d * rayDir) / float(stepIncrement);
                    float transparency = pow(1.0 - cFogColor.a, stepCount);  // imitate successive steps
                    transparency = clamp(transparency, 0.0, 1.0);
                    color.xyz = (color.xyz - cFogColor.xyz)*transparency + cFogColor.xyz;
                    color.a = 0.0;  // opaque
                }
            }
        #endif  // SHOW_FOG

        // Finally, set the fade level
        color.rgb = mix(uBackgroundColor.rgb, color.rgb, cFadeLevel);

        //color.a = clamp(color.a, 0.002, 1.0);  // some drivers have problems when alpha == 0
        color.a = 1.0;

        // All that work for one little pixel...
        #if __VERSION__ >= 300
            fragColor = color;
        #else
            gl_FragColor = color;
        #endif
    }
`;
