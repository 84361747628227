// volume.js
import { parse as nrrdParse } from 'nrrd-js/nrrd';
import { parse as niftiParse } from 'nifti-js/nifti';
import "daikon/release/current/daikon";
import Histogram from './histogram';
import dconsole from "./dconsole";
const { daikon } = window;

// STATIC VARIABLES ===========================================================

// "RPI" is the orientation used by default in the Volume class, for historical
// reasons (the VolumeViewer uses this orientation).
Volume.DEFAULT_ORIENTATION = "RPI";

// The "26" in ADF_KERNEL_SIZE comes from the anisotropic diffusion filter's
// 3x3x3 kernel size, minus the center value (which is always the same
// and therefore doesn't need to be calculated or stored).
Volume.ADF_KERNEL_SIZE = 26;

// Special value for the nullValue parameter used to construct volumes.
// If nullValue is set to this, the volume's null value will be calculated
// based on the majority value at the volume's eight corners.
Volume.CALCULATE_NULL = "calc";

// PRIVATE STATIC METHODS =====================================================

// Intelligently reallocates large memory buffers.
Volume.smartReallocate = function(buffer, typeName, newSize) {
    if (!buffer || buffer.length < newSize) {
        let bigSize = Math.ceil(newSize * 1.5);
        if (typeof window[typeName] === 'function') {
            let newBuffer = null;
            try { newBuffer = new window[typeName](bigSize); } catch(e) { newBuffer = null; }
            if (!newBuffer || newBuffer.length < bigSize) {
                try { newBuffer = window[typeName](newSize); } catch(e) { newBuffer = null; }
                if (!newBuffer || newBuffer.length < newSize) {
                    dconsole.error("BUFFER REALLOCATION FAILED!  Requested size: " + newSize);
                    newBuffer = null;
                    buffer = null;
                }
                else {
                    dconsole.warn("Buffer reallocation failed, but recovered.  Requested size: " + newSize);
                    buffer = newBuffer;
                }
            }
            else
                buffer = newBuffer;
        }
        else {
            dconsole.error("Invalid reallocation type requested: " + typeName);
            buffer = null;
        }
    }

    return buffer;
};

// Concatenates DICOM image data (synchronously).
// For internal use only.
Volume.concatenateDicomImageDataSynchronous = function(series, onFinishedImageRead) {
    // STM_TODO - This is a really big hack.
    // I have copied code from the concatenateImageData() and
    // concatenateNextImageData() methods in Daikon and modified it
    // to work synchronously.
    let frameSize = series.validatePixelDataLength(series.images[0]);
    let buffer = new Uint8Array(new ArrayBuffer(frameSize * series.images.length));

    for (let index=0; index<series.images.length; ++index) {
        let data, length;

        if (series.isMosaic) {
            data = series.getMosaicData(series.images[index], series.images[index].getPixelDataBytes());
        } else {
            data = series.images[index].getPixelDataBytes();
        }

        length = series.validatePixelDataLength(series.images[index]);
        series.images[index].clearPixelData();
        buffer.set(new Uint8Array(data, 0, length), (frameSize * index));
    }

    onFinishedImageRead(buffer.buffer);
};

// Saves/downloads a blob with the specified filename.
Volume.saveBlob = function(blob, filename) {
    const timeout = 40000;

    if (!blob)      return false;
    if (!filename)  return false;

    let url = URL.createObjectURL(blob);
    if (!url)  return false;

    let a = document.createElement("a");
    a.style = "display: none";
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();

    if (timeout) {
        setTimeout(function() {
            window.URL.revokeObjectURL(url);
            a.remove();
        }, timeout);
    }

    return true;
};

// PUBLIC STATIC METHODS ======================================================

// Converts the specified array, width, height and depth into a standard
// volume object format.  The array is used as-is and is not copied.
Volume.generateVolume = function(data, width, height, depth, count=1,
                                 scaleX=1.0, scaleY=1.0, scaleZ=1.0,
                                 orientation=null, nullValues=null) {
    if (width < 1 || height < 1 || depth < 1 || count < 1 || !data)  return null;

    return new Volume(data, width, height, depth, count,
                      scaleX, scaleY, scaleZ, orientation, nullValues);
};

// Generates an empty volume of the specified size.
Volume.generateEmptyVolume = function(width, height, depth, count=1,
                                      scaleX=1.0, scaleY=1.0, scaleZ=1.0,
                                      orientation=null) {
    if (width < 1 || height < 1 || depth < 1 || count < 1)  return null;

    let data = new Float32Array(width*height*depth*count);
    return new Volume(data, width, height, depth, count,
                      scaleX, scaleY, scaleZ, orientation);
};

// Generates a subvolume based on a multi-volume image and an index,
// using a standard volume object format.
Volume.generateSubVolume = function(volume, index=0) {
    if (!volume)  return null;
    if (index < 0 || index >= volume.count)  return null;

    // Generate a view into the data at the specified volume index
    // (this will NOT copy the data!)
    const width       = volume.width;
    const height      = volume.height;
    const depth       = volume.depth;
    const scaleX      = volume.scaleX;
    const scaleY      = volume.scaleY;
    const scaleZ      = volume.scaleZ;
    const orientation = volume.orientation;
    const nullValue   = volume.getNullValue(index);

    const buffer      = volume.data.buffer;
    const volumeSize  = width * height * depth;
    const offset      = index * volumeSize * Float32Array.BYTES_PER_ELEMENT;
    const newData     = new Float32Array(buffer, offset, volumeSize);  // this is a view, not a copy

    return Volume.generateVolume(newData, width, height, depth, 1,
                                 scaleX, scaleY, scaleZ, orientation, [nullValue]);
};

// Combines multiple sub-volumes into a single contiguous volume.
// All sub-volumes must have the same width, height and depth.
// The volume count will be the sum of the counts of all combined sub-volumes.
Volume.generateCombinedVolume = function(volumes) {
    if (!volumes)  return null;
    if (volumes.length <= 0)  return null;

    const width       = volumes[0].width;
    const height      = volumes[0].height;
    const depth       = volumes[0].depth;
    const scaleX      = volumes[0].scaleX;
    const scaleY      = volumes[0].scaleY;
    const scaleZ      = volumes[0].scaleZ;
    const orientation = volumes[0].orientation;

    let count      = volumes[0].count;
    let nullValues = volumes[0].getNullValues();

    for (let i=1; i<volumes.length; ++i) {
        if (volumes[i].count > 0) {
            if (volumes[i].width  !== width)   return null;
            if (volumes[i].height !== height)  return null;
            if (volumes[i].depth  !== depth)   return null;

            count += volumes[i].count;
            nullValues = nullValues.concat(volumes[i].getNullValues());
        }
    }

    const volumeSize = width * height * depth;

    let data = new Float32Array(volumeSize * count);

    count = 0;
    for (let i=0; i<volumes.length; ++i) {
        if (volumes[i].count > 0) {
            data.set(volumes[i].data, count*volumeSize);
            count += volumes[i].count;
        }
    }

    let newVolume = Volume.generateVolume(data, width, height, depth, count,
                                          scaleX, scaleY, scaleZ, orientation,
                                          nullValues);

    return newVolume;
};

// Applies an axis layer to a volume (layer 2).
// Returns the modified volume.
Volume.applyAxisVolume = function(volume) {
    if (!volume)  return null;

    volume = Volume.generateSubVolume(volume, 0);
    let volume2 = Volume.generateAxisVolume(volume.width, volume.height, volume.depth);
    volume = Volume.generateCombinedVolume([volume, volume2]);

    return volume;
};

// Applies an orientation layer to a volume (layer 2).
// Returns the modified volume.
Volume.applyOrientationVolume = function(volume) {
    if (!volume)  return null;

    volume = Volume.generateSubVolume(volume, 0);
    let volume2 = Volume.generateOrientationVolume();
    volume2 = Volume.generateSubVolume(volume2, 0);
    volume2 = volume2.resampleVolume(volume.width, volume.height, volume.depth);
    volume = Volume.generateCombinedVolume([volume, volume2]);

    return volume;
};

// Saves the specified volume as a NRRD file, using the browser's
// download capability.
// (Note that browser security may restrict the functionality of this method.)
Volume.saveVolumeNrrd = function(volume, filename, reorient=true) {
    if (!volume)  return;

    let newVolume = Volume.copyVolumeDeep(volume);
    if (reorient)
        newVolume.applyOrientation("LPS");

    let nrrdHeader = {};

    let orientation = newVolume.orientation;
    if (!orientation)  orientation = "LPS";
    orientation = Volume.sanitizeOrientation(orientation);

    // Convert the space string to something that meets NRRD approval
    let space = "";
    for (let i=0; i<3; ++i) {
        if (i < orientation.length) {
            let str = null;
            let ch = orientation[i];
            if (ch === 'L')
                str = "left";
            else if (ch === 'R')
                str = "right";
            else if (ch === 'A')
                str = "anterior";
            else if (ch === 'P')
                str = "posterior";
            else if (ch === 'S')
                str = "superior";
            else if (ch === 'I')
                str = "inferior";
            if (str) {
                if (space.length > 0)  space += "-";
                space += str;
            }
        }
    }
    if (space.length > 0) {
        if (space === "right-anterior-superior" ||
            space === "left-anterior-superior" ||
            space === "left-posterior-superior") {
            // The easy way
            nrrdHeader.space = space;
        }
        else {
            // The hard way
            // STM_TODO - THIS IS EXTREMELY BROKEN AND NEEDS TO BE FIXED!!!!!
            newVolume.applyOrientation("LPS");
            let af = Volume.generateAffine(newVolume.orientation, orientation);
            // Affine matrix includes spacing as a magnitude
            af[0][0] *= newVolume.scaleX;
            af[0][1] *= newVolume.scaleX;
            af[0][2] *= newVolume.scaleX;
            af[1][0] *= newVolume.scaleY;
            af[1][1] *= newVolume.scaleY;
            af[1][2] *= newVolume.scaleY;
            af[2][0] *= newVolume.scaleZ;
            af[2][1] *= newVolume.scaleZ;
            af[2][2] *= newVolume.scaleZ;
            let spaceDirections = [
                [ af[0][0], af[0][1], af[0][2] ],
                [ af[1][0], af[1][1], af[1][2] ],
                [ af[2][0], af[2][1], af[2][2] ]
            ];
            if (newVolume.count > 1) {
                spaceDirections[0].push(0);
                spaceDirections[1].push(0);
                spaceDirections[2].push(0);
                spaceDirections.push(null);
            }
            nrrdHeader.space = "left-posterior-superior";
            nrrdHeader.spaceDimension = (newVolume.count > 1) ? 4 : 3;
            nrrdHeader.spaceDirections = spaceDirections;
        }
    }

    if (newVolume.count > 1) {
        nrrdHeader.sizes    = [newVolume.width, newVolume.height, newVolume.depth, newVolume.count];
        nrrdHeader.spacings = [newVolume.scaleX, newVolume.scaleY, newVolume.scaleZ, 1.0];
    }
    else {
        nrrdHeader.sizes    = [newVolume.width, newVolume.height, newVolume.depth];
        nrrdHeader.spacings = [newVolume.scaleX, newVolume.scaleY, newVolume.scaleZ];
    }

    nrrdHeader.data = newVolume.data;

    let assertReset = false;
    if (window.assert === undefined) {
        assertReset = true;
        window.assert = function(val, why) {
            if (!val) {
                let str = "Assertion failed!";
                if (why)  str += " " + why;
                throw new Error(str);
            }
        };
    }

    try {
        let buffer = nrrdSerialize(nrrdHeader);
        let blob = new Blob([buffer]);
        Volume.saveBlob(blob, filename);
    }
    finally {
        if (assertReset)
            window.assert = undefined;
    }
};

// Loads the specified buffer (formatted as a NRRD) into a volume.
// NOTE: The "bivolume" variable is temporary.  Do not count on it being
// available in the future.  STM_TODO STM_DEMO
Volume.loadVolumeNrrd = function(arrayBuffer, bivolume=false) {
    if (arrayBuffer === undefined || arrayBuffer === null) {
        return null;
    }

    try {
        let volume = null;

        const nrrd = nrrdParse(arrayBuffer);
        if (nrrd !== undefined && nrrd !== null) {
            const dimensions = nrrd.dimension;

            const width  = dimensions > 0 ? nrrd.sizes[0] : 1;
            const height = dimensions > 1 ? nrrd.sizes[1] : 1;
            const depth  = dimensions > 2 ? nrrd.sizes[2] : 1;
            const layers = dimensions > 3 ? nrrd.sizes[3] : 1;

            const data   = nrrd.data;

            let xscale = 1.0;
            let yscale = 1.0;
            let zscale = 1.0;

            let affine = null;

            // There are several possible ways of identifying scaling factors
            // in a NRRD file...
            if ('spaceDirections' in nrrd) {
                // Later, we will use the space directions to
                // reorganize the volume in memory.
                let sd = nrrd.spaceDirections;
                affine = [
                    [sd[0][0], sd[1][0], sd[2][0]],
                    [sd[0][1], sd[1][1], sd[2][1]],
                    [sd[0][2], sd[1][2], sd[2][2]]
                ];
            }
            else if ('spacings' in nrrd) {
                xscale = dimensions > 0 ? nrrd.spacings[0] : 1.0;
                yscale = dimensions > 1 ? nrrd.spacings[1] : 1.0;
                zscale = dimensions > 2 ? nrrd.spacings[2] : 1.0;
            }

            // "ALS" is the default for unoriented NRRD files, for historical reasons...
            let orientation = "ALS";
            if ('space' in nrrd) {
                if (nrrd.space) {
                    orientation = Volume.sanitizeOrientation(nrrd.space);
                }
            }

            const nullValues = null;

            if ((dimensions > 3) || !bivolume) {
                const newWidth  = width;
                const newHeight = height;
                const newDepth  = depth;
                const newLayers = layers <= 2 ? layers : 2;
                const newXScale = xscale;
                const newYScale = yscale;
                const newZScale = zscale;
                const newSize   = newWidth*newHeight*newDepth*newLayers;
                const newData   = new Float32Array(newSize);
                for (let srcLayer=0; srcLayer<layers; ++srcLayer) {
                    const dstLayer = srcLayer <= 1 ? srcLayer : 1;
                    const volumeSize = newWidth * newHeight * newDepth;
                    for (let i=0; i<volumeSize; ++i) {
                        let srcPos = srcLayer*volumeSize+i;
                        let dstPos = dstLayer*volumeSize+i;
                        if (srcPos === dstPos)
                            newData[dstPos] = data[srcPos];
                        else if (newData[dstPos] < data[srcPos])
                            newData[dstPos] = data[srcPos];
                    }
                }

                volume = Volume.generateVolume(newData, newWidth, newHeight, newDepth, newLayers,
                                               newXScale, newYScale, newZScale, orientation,
                                               nullValues);
            }
            else {
                // Very special case: split this NRRD into two volumes to
                // show segmentation.  (THIS IS TEMPORARY!!!!!!!)  STM_TODO STM_DEMO
                const newWidth  = height;
                const newHeight = width;
                const newDepth  = depth;
                const newXScale = yscale;
                const newYScale = xscale;
                const newZScale = zscale;
                const newData   = new Float32Array(newWidth*newHeight*newDepth*2);
                for (let q=0; q<2; ++q) {
                    for (let k=0; k<depth; ++k) {
                        for (let j=0; j<height; ++j) {
                            for (let i=0; i<width; ++i) {
                                let oldPos = (((k * height) + j) * width) + i;
                                let newPos = ((((depth-k-1) * width) + (width-i-1)) * height) + (height-j-1);
                                let value = data[oldPos];
                                let mask = Math.floor(value + 1e-5);
                                let nvalue = value - mask;
                                let fvalue;
                                if (q === 0) {
                                    fvalue = nvalue;
                                }
                                else {
                                    if (mask >= 1.0)
                                        fvalue = nvalue;
                                    else
                                        fvalue = 0.0;

                                    newPos += (newWidth*newHeight*newDepth);
                                }
                                newData[newPos] = fvalue;
                            }
                        }
                    }
                }

                volume = Volume.generateVolume(newData, newWidth, newHeight, newDepth, 2,
                                               newXScale, newYScale, newZScale);
            }

            // Apply the affine transformation and the orientation
            if (volume !== null) {
                const finalOrientation = Volume.DEFAULT_ORIENTATION;
                let affine0 = Volume.snapToAffine(affine);
                let affine1 = Volume.generateAffine(volume.orientation, finalOrientation);
                if (affine0 || affine1) {
                    let affineMult = Volume.multiplyAffines(affine0, affine1);
                    volume.applyAffine(affineMult);
                }
                volume.orientation = finalOrientation;
            }
        }

        if (volume !== null) {
            dconsole.log("Image loaded ("+volume.width+", "+volume.height+", "+volume.depth+", "+volume.count+")");
            dconsole.log("Scale: ["+volume.scaleX.toFixed(2)+":"+volume.scaleY.toFixed(2)+":"+volume.scaleZ.toFixed(2)+"]");
            dconsole.log("Orientation: " + volume.orientation);
        }

        return volume;
    }
    catch(err) {
        dconsole.error("Failed to load NRRD volume");
        return null;
    }
};

// Loads the specified buffer (formatted as a NIFTI) into a volume.
Volume.loadVolumeNifti = function(arrayBuffer) {
    if (arrayBuffer === undefined || arrayBuffer === null) {
        return null;
    }

    try {
        let volume = null;

        const nifti = niftiParse(arrayBuffer);
        if (nifti !== undefined && nifti !== null) {
            const dimensions = nifti.dimension;

            const width  = dimensions > 0 ? nifti.sizes[0] : 1;
            const height = dimensions > 1 ? nifti.sizes[1] : 1;
            const depth  = dimensions > 2 ? nifti.sizes[2] : 1;
            const layers = dimensions > 3 ? nifti.sizes[3] : 1;

            const data   = nifti.data;

            let xscale = 1.0;
            let yscale = 1.0;
            let zscale = 1.0;

            let affine = null;

            // There are several possible ways of identifying scaling factors
            // in a Nifti file...
            if ('spaceDirections' in nifti) {
                // Later, we will use the space directions to
                // reorganize the volume in memory.
                affine = [
                    [nifti.spaceDirections[0][0], nifti.spaceDirections[1][0], nifti.spaceDirections[2][0]],
                    [nifti.spaceDirections[0][1], nifti.spaceDirections[1][1], nifti.spaceDirections[2][1]],
                    [nifti.spaceDirections[0][2], nifti.spaceDirections[1][2], nifti.spaceDirections[2][2]]
                ];
            }
            else if ('spacings' in nifti) {
                xscale = dimensions > 0 ? nifti.spacings[0] : 1.0;
                yscale = dimensions > 1 ? nifti.spacings[1] : 1.0;
                zscale = dimensions > 2 ? nifti.spacings[2] : 1.0;
            }

            let orientation = "LPS";
            if ('space' in nifti) {
                if (nifti.space) {
                    orientation = Volume.sanitizeOrientation(nifti.space);
                }
            }

            const nullValues = null;

            const newWidth  = width;
            const newHeight = height;
            const newDepth  = depth;
            const newLayers = layers <= 2 ? layers : 2;
            const newXScale = xscale;
            const newYScale = yscale;
            const newZScale = zscale;
            const newSize   = newWidth*newHeight*newDepth*newLayers;
            const newData   = new Float32Array(newSize);
            for (let i=0; i<newSize; ++i)
                newData[i] = data[i];  // ensures that data values are float32s

            volume = Volume.generateVolume(newData, newWidth, newHeight, newDepth, newLayers,
                                           newXScale, newYScale, newZScale, orientation, nullValues);

            // Apply the affine transformation and the orientation
            if (volume !== null) {
                const finalOrientation = Volume.DEFAULT_ORIENTATION;
                let affine0 = Volume.snapToAffine(affine);
                let affine1 = Volume.generateAffine(volume.orientation, finalOrientation);
                if (affine0 || affine1) {
                    let affineMult = Volume.multiplyAffines(affine0, affine1);
                    volume.applyAffine(affineMult);
                }
                volume.orientation = finalOrientation;
            }
        }

        if (volume !== null) {
            dconsole.log("Image loaded ("+volume.width+", "+volume.height+", "+volume.depth+", "+volume.count+")");
            dconsole.log("Scale: ["+volume.scaleX.toFixed(2)+":"+volume.scaleY.toFixed(2)+":"+volume.scaleZ.toFixed(2)+"]");
            dconsole.log("Orientation: " + volume.orientation);
        }

        return volume;
    }
    catch(err) {
        dconsole.error("Failed to load Nifti volume");
        return null;
    }
};

// Loads the specified array buffers (formatted as DICOMs) into a volume.
Volume.loadVolumeDicom = function(arrayBuffers) {
    if (!arrayBuffers)  {
        return null;
    }

    try {
        let volume = null;

        let fileCount = arrayBuffers.length;
        let fileImages = [];
        fileImages.length = fileCount;

        if (fileImages.length < 1) {
            dconsole.error("No DICOM files provided");
            return null;
        }

        // Generate DICOM images from the buffers
        for (let i=0; i<arrayBuffers.length; ++i) {
            const arrayBuffer = arrayBuffers[i];
            if (arrayBuffer) {
                let image = daikon.Series.parseImage(new DataView(arrayBuffer));
                if (image && image.hasPixelData()) {
                    fileImages[i] = image;
                }
            }
        }

        // Sanity checks
        let frameCount = 0;
        for (let i=0; i<fileImages.length; ++i) {
            if (!fileImages[i]) {
                dconsole.error("Error parsing DICOM images");
                return null;
            }
            frameCount += fileImages[i].getNumberOfFrames();
        }

        if (frameCount < 1) {
            dconsole.error("DICOM image contains no frames");
            return null;
        }

        const seriesId            = fileImages[0].getSeriesId();
        const bitsAllocated       = fileImages[0].getBitsAllocated();
        const pixelRepresentation = fileImages[0].getPixelRepresentation();
        const samplesPerPixel     = fileImages[0].getNumberOfSamplesPerPixel();
        let   directions          = fileImages[0].getImageDirections();
        let   origin              = fileImages[0].getImagePosition();

        for (let i=1; i<fileImages.length; ++i) {
            if (fileImages[i].getSeriesId() !== seriesId) {
                dconsole.error("DICOM images are not all from the same series");
                return null;
            }
            if (fileImages[i].getBitsAllocated() !== bitsAllocated) {
                dconsole.error("DICOM images are using different sample sizes");
                return null;
            }
            if (fileImages[i].getPixelRepresentation() !== pixelRepresentation) {
                dconsole.error("DICOM images are not consistently signed");
                return null;
            }
            if (fileImages[i].getNumberOfSamplesPerPixel() !== samplesPerPixel) {
                dconsole.error("DICOM images are using inconsistent color channels");
                return null;
            }
        }

        if (samplesPerPixel !== 1) {
            dconsole.error("DICOM images contain more than one color channel");
            return null;
        }
        if (pixelRepresentation !== 0 && pixelRepresentation !== 1) {
            dconsole.error("DICOM images use an unknown pixel representation");
            return null;
        }
        if (bitsAllocated !== 8 && bitsAllocated !== 16) {
            dconsole.error("DICOM images use an unsupported sample size");
            return null;
        }

        dconsole.log("DICOM bits per sample: " + bitsAllocated);

        let viewer = this;

        const width       = fileImages[0].getCols();
        const height      = fileImages[0].getRows();
        const depth       = frameCount;
        const scale       = fileImages[0].getPixelSpacing();
        const scaleX      = scale[0];
        const scaleY      = scale[1];
        const scaleZ      = fileImages[0].getSliceThickness();
        const orientation = "LPS";  // DICOM is always LPS

        let series = new daikon.Series();
        for (let i=0; i<fileImages.length; ++i)
            series.addImage(fileImages[i]);

        series.buildSeries();
        //series.concatenateImageData(null, function (imageData) {
        Volume.concatenateDicomImageDataSynchronous(series, function (imageData) {
            let data = null;
            if (bitsAllocated == 16) {
                if (pixelRepresentation === 0)
                    data = new Uint16Array(imageData, 0, width*height*depth);
                else
                    data = new Int16Array(imageData, 0, width*height*depth);
            }
            else if (bitsAllocated == 8) {
                if (pixelRepresentation === 0)
                    data = new Uint8Array(imageData, 0, width*height*depth);
                else
                    data = new Int8Array(imageData, 0, width*height*depth);
            }
            else {
                return null;
            }

            const newWidth  = width;
            const newHeight = height;
            const newDepth  = depth;
            const newXScale = scaleX;
            const newYScale = scaleY;
            const newZScale = scaleZ;
            const newSize   = newWidth * newHeight * newDepth;
            const newData   = new Float32Array(newSize);
            for (let i=0; i<newSize; ++i)
                newData[i] = data[i];

            function cross(a, b) {
                const vec = [
                    a[1] * b[2] - a[2] * b[1],
                    a[2] * b[0] - a[0] * b[2],
                    a[0] * b[1] - a[1] * b[0]
                ];
                return vec;
            }
            if (!directions)  directions = [1, 0, 0, 0, 1, 0];
            if (!origin)      origin = [0, 0, 0];

            // DICOM only gives us two orientation vectors.
            // The two vectors will always be orthogonal and unary (per
            // the DICOM standard).  We can infer that the third vector
            // will be the cross-product of the first two,
            // but we don't know which direction it should go!
            let vec0 = [ directions[0], directions[1], directions[2] ];
            let vec1 = [ directions[3], directions[4], directions[5] ];
            let vec2 = cross(vec0, vec1);

            // Hack: Use the sign of the Z coordinate of the position
            // to determine whether we are going "up" or "down"...
            if (origin[2] > 0) {
                vec2[0] = -vec2[0];
                vec2[1] = -vec2[1];
                vec2[2] = -vec2[2];
            }

            // Generate an affine matrix from the orientation, so we can
            // rearrange the data in memory
            let affine = [
                [ vec0[0], vec1[0], vec2[0] ],
                [ vec0[1], vec1[1], vec2[1] ],
                [ vec0[2], vec1[2], vec2[2] ],
            ];

            const nullValues = null;

            volume = Volume.generateVolume(newData, newWidth, newHeight, newDepth, 1,
                                           newXScale, newYScale, newZScale, orientation,
                                           nullValues);

            // Apply the affine matrix, and re-orient to the default...
            const finalOrientation = Volume.DEFAULT_ORIENTATION;
            let affine0 = Volume.snapToAffine(affine);
            let affine1 = Volume.generateAffine(volume.orientation, finalOrientation);
            if (affine0 || affine1) {
                let affineMult = Volume.multiplyAffines(affine0, affine1);
                volume.applyAffine(affineMult);
            }
            volume.orientation = finalOrientation;
        });

        if (volume !== null) {
            dconsole.log("Image loaded ("+volume.width+", "+volume.height+", "+volume.depth+", "+volume.count+")");
            dconsole.log("Scale: ["+volume.scaleX.toFixed(2)+":"+volume.scaleY.toFixed(2)+":"+volume.scaleZ.toFixed(2)+"]");
            dconsole.log("Orientation: " + volume.orientation);
        }

        return volume;
    }
    catch(err) {
        dconsole.error("Failed to load DICOM volume");
        return null;
    }
};

// Creates a duplicate of the specified volume.  The volume data is copied.
Volume.copyVolumeDeep = function(volume) {
    if (volume === undefined || volume === null)  return null;

    const width       = volume.width;
    const height      = volume.height;
    const depth       = volume.depth;
    const count       = volume.count;
    const scaleX      = volume.scaleX;
    const scaleY      = volume.scaleY;
    const scaleZ      = volume.scaleZ;
    const orientation = volume.orientation;
    const nullValues  = volume.getNullValues();
    const dataExists  = volume.data !== undefined && volume.data !== null;

    let data = dataExists ? new Float32Array(volume.data) : null;
    return Volume.generateVolume(data, width, height, depth, count,
                                 scaleX, scaleY, scaleZ, orientation, nullValues);
};

// Creates a duplicate of the specified volume.  The original volume data is used.
Volume.copyVolumeShallow = function(volume) {
    if (volume === undefined || volume === null)  return null;

    const width       = volume.width;
    const height      = volume.height;
    const depth       = volume.depth;
    const count       = volume.count;
    const scaleX      = volume.scaleX;
    const scaleY      = volume.scaleY;
    const scaleZ      = volume.scaleZ;
    const orientation = volume.orientation;
    const nullValues  = volume.getNullValues();
    const data        = volume.data;

    return Volume.generateVolume(data, width, height, depth, count,
                                 scaleX, scaleY, scaleZ, orientation, nullValues);
};

// Creates a duplicate of the specified volume dimensions, without copying
// the actual data.  The "data" parameter in the volume is set to null.
Volume.copyVolumeShape = function(volume) {
    if (volume === undefined || volume === null)  return null;

    const width       = volume.width;
    const height      = volume.height;
    const depth       = volume.depth;
    const count       = volume.count;
    const scaleX      = volume.scaleX;
    const scaleY      = volume.scaleY;
    const scaleZ      = volume.scaleZ;
    const orientation = volume.orientation;
    const nullValues  = volume.getNullValues();
    const data        = null;

    return Volume.generateVolume(data, width, height, depth, count,
                                 scaleX, scaleY, scaleZ, orientation, nullValues);
};

// Generates a test volume that shows the X, Y and Z axes of the volume,
// with the fastest axis (smallest stride) as a continuous line and
// the slowest axis (largest stride) as a series of widely spaced dots.
Volume.generateAxisVolume = function(width=null, height=null, depth=null) {
    if (!width)  width   = 48;
    if (!height)  height = width;
    if (!depth)   depth  = height;

    const count    = 1;
    const xCenter  = Math.round(width / 2.0);
    const yCenter  = Math.round(height / 2.0);
    const zCenter  = Math.round(depth / 2.0);

    const xStep = 1;
    const yStep = 2;
    const zStep = 4;

    let data = new Float32Array(width * height * depth * count);

    let volume = Volume.generateVolume(data, width, height, depth, count,
                                       1.5, 1.5, 1.5);

    for (let i=xCenter; i<width; i+=xStep) {
        let j = yCenter;
        let k = zCenter;
        let pos = ((k * height) + j) * width + i;
        data[pos] = 1.0;
    }
    for (let j=yCenter; j<height; j+=yStep) {
        let i = xCenter;
        let k = zCenter;
        let pos = ((k * height) + j) * width + i;
        data[pos] = 1.0;
    }
    for (let k=zCenter; k<depth; k+=zStep) {
        let i = xCenter;
        let j = yCenter;
        let pos = ((k * height) + j) * width + i;
        data[pos] = 1.0;
    }

    return volume;
};

// Generates a test volume that shows the right, left, anterior, posterior,
// superior and inferior sides of the volume as letters.
// Each side has a small dot in the corner that should be aligned to the
// lower left when viewing.
// There is also a second layer containing the three axes of the volume,
// with the fastest axis (smallest stride) as a continuous line and
// the slowest axis (largest stride) as a series of widely spaced dots.
Volume.generateOrientationVolume = function() {
    const RIGHT_SIDE = [
        "            ",
        "            ",
        "            ",
        "   #####    ",
        "   ##  ##   ",
        "   ##  ##   ",
        "   #####    ",
        "   ## ##    ",
        "   ##  ##   ",
        "            ",
        " #          ",
        "            "
    ];
    const LEFT_SIDE = [
        "            ",
        "            ",
        "            ",
        "   ##       ",
        "   ##       ",
        "   ##       ",
        "   ##       ",
        "   ##       ",
        "   ######   ",
        "            ",
        " #          ",
        "            "
    ];
    const ANTERIOR_SIDE = [
        "            ",
        "            ",
        "            ",
        "     ##     ",
        "    ####    ",
        "   ##  ##   ",
        "   ##  ##   ",
        "   ######   ",
        "   ##  ##   ",
        "            ",
        " #          ",
        "            "
    ];
    const POSTERIOR_SIDE = [
        "            ",
        "            ",
        "            ",
        "   #####    ",
        "   ##  ##   ",
        "   ##  ##   ",
        "   #####    ",
        "   ##       ",
        "   ##       ",
        "            ",
        " #          ",
        "            "
    ];
    const SUPERIOR_SIDE = [
        "            ",
        "            ",
        "            ",
        "    ####    ",
        "   ##       ",
        "    ####    ",
        "       ##   ",
        "       ##   ",
        "    ####    ",
        "            ",
        " #          ",
        "            "
    ];
    const INFERIOR_SIDE = [
        "            ",
        "            ",
        "            ",
        "   ######   ",
        "     ##     ",
        "     ##     ",
        "     ##     ",
        "     ##     ",
        "   ######   ",
        "            ",
        " #          ",
        "            "
    ];
    const mult   = 4;
    const size   = LEFT_SIDE.length*mult;

    const width  = size;
    const height = size;
    const depth  = size;
    const count  = 2;

    const thickness = 2;

    const center = size/2;
    const edge = size - (thickness*2);

    const xStep = 1;
    const yStep = 2;
    const zStep = 4;

    let data = new Float32Array(width * height * depth * count);

    let volume = Volume.generateVolume(data, width, height, depth, count,
                                       1.5, 1.5, 1.5, "RPI");

    function setValue(arr, pos, x, y) {
        x = Math.floor(x/mult);
        y = Math.floor(y/mult);
        let ch = arr[y][x];
        let val = 0.0;
        if (ch === ' ')  val = 0.25;
        else             val = 1.0;
        if (data[pos] < val)  data[pos] = val;
    }

    for (let k=0; k<depth; ++k) {
        for (let j=0; j<height; ++j) {
            for (let i=0; i<width; ++i) {
                let pos = ((k * height) + j) * width + i;
                data[pos] = 0.0;
                if (i < thickness) {
                    setValue(LEFT_SIDE, pos, j, k);
                }
                if ((width-1) - i < thickness) {
                    setValue(RIGHT_SIDE, pos, (width-1) - j, k);
                }
                if (j < thickness) {
                    setValue(ANTERIOR_SIDE, pos, (height-1) - i, k);
                }
                if ((height-1) - j < thickness) {
                    setValue(POSTERIOR_SIDE, pos, i, k);
                }
                if (k < thickness) {
                    setValue(SUPERIOR_SIDE, pos, i, j);
                }
                if ((depth-1) - k < thickness) {
                    setValue(INFERIOR_SIDE, pos, (width-1) - i, j);
                }
            }
        }
    }

    function setAxis(x, y, z, w=1) {
        let pos = (((w * depth + z) * height) + y) * width + x;
        data[pos] = 1.0;
    }

    for (let i=center; i<edge; i+=xStep) {
        setAxis(i, center, center);
    }
    for (let j=center; j<edge; j+=yStep) {
        setAxis(center, j, center);
    }
    for (let k=center; k<edge; k+=zStep) {
        setAxis(center, center, k);
    }

    return volume;
};

// Generates a test volume.
// This test volume is algorithmically generated, not loaded, and therefore
// doesn't require a server fetch.
Volume.generateTestVolume = function(size=48, noise=0.0, vcount=1) {
    // Create spherical test volume
    const border = 3;
    const radius = (size * 0.5) / (size + border * 2);
    size += border * 2;

    let count = Math.floor(vcount);
    if (count < 1 || count > 2) {
        return null;
    }

    const width    = size;
    const height   = size;
    const depth    = size;
    const center   = (size / 2.0) - 0.5;
    const mult     = 1.0 / size;
    const edge     = radius * 0.1875;
    const cut      = radius * 0.25 * 1.2;
    const cube     = cut * 0.5;
    const interior = 0.0;

    let data = new Float32Array(width * height * depth * count);

    let volume = Volume.generateVolume(data, width, height, depth, count,
                                       1.5, 1.5, 1.5);

    for (let k = 0; k < depth; ++k) {
        for (let j = 0; j < height; ++j) {
            for (let i = 0; i < width; ++i) {
                let pos = i + j * width + k * width * height;
                let xDelta = Math.abs((i - center) * mult);
                let yDelta = Math.abs((j - center) * mult);
                let zDelta = Math.abs((k - center) * mult);
                let delta = xDelta*xDelta + yDelta*yDelta + zDelta*zDelta;
                delta = Math.sqrt(delta);

                let intensity;
                if (delta > radius)
                    intensity = 0.0;
                else if (delta > radius - edge)
                    intensity = (radius - delta) / edge;
                else
                    intensity = 1.0;

                //const div = 5;
                //let xb = Math.floor(i / div);
                //let yb = Math.floor(j / div);
                //let zb = Math.floor(k / div);
                //let oddeven = (xb + yb + zb) % 2;
                //if (oddeven !== 0)
                //    data[pos] = 0.0;else

                if (delta > radius)
                    data[pos] = intensity;
                else if (xDelta < cube && yDelta < cube && zDelta < cube)
                    data[pos] = 1.0;
                else if (xDelta < cut)
                    data[pos] = interior;
                else if (yDelta < cut)
                    data[pos] = interior;
                else if (zDelta < cut)
                    data[pos] = interior;
                else
                    data[pos] = intensity;
            }
        }
    }

    let subVolume  = Volume.generateSubVolume(volume, 0);
    let tempVolume = subVolume.resampleVolumeBell(width, height, depth);
    tempVolume.addNoise(noise);
    volume.data.set(tempVolume.data);

    if (count >= 2) {
        const offset    = width * height * depth;
        const cubeDist  = size * radius * 0.56;
        const cubeEdge0 = (center - cubeDist) - 1.5;
        const cubeEdge1 = (center - cubeDist) + 1.5;
        const cubeEdge2 = (center + cubeDist) - 1.5;
        const cubeEdge3 = (center + cubeDist) + 1.5;
        const inside    = 1.0;
        const outside   = 0.0;

        for (let k = 0; k < depth; ++k) {
            const kInside = (k >= cubeEdge0 && k <= cubeEdge1) ||
                            (k >= cubeEdge2 && k <= cubeEdge3);
            for (let j = 0; j < height; ++j) {
                const jInside = (j >= cubeEdge0 && j <= cubeEdge1) ||
                                (j >= cubeEdge2 && j <= cubeEdge3);
                for (let i = 0; i < width; ++i) {
                    const iInside = (i >= cubeEdge0 && i <= cubeEdge1) ||
                                    (i >= cubeEdge2 && i <= cubeEdge3);

                    let pos = i + j * width + k * width * height + offset;

                    let edgeCount = 0;
                    if (kInside)  ++edgeCount;
                    if (jInside)  ++edgeCount;
                    if (iInside)  ++edgeCount;
                    if (k < cubeEdge0 || k > cubeEdge3 || j < cubeEdge0 || j > cubeEdge3 || i < cubeEdge0 || i > cubeEdge3)
                        data[pos] = outside;
                    else if (edgeCount >= 2)
                        data[pos] = inside;
                    else
                        data[pos] = outside;
                }
            }
        }

        let subVolume  = Volume.generateSubVolume(volume, 1);
        let tempVolume = subVolume.resampleVolumeCubicBSpline(width, height, depth);
        volume.data.set(tempVolume.data, offset);
        tempVolume = null;
    }

    return volume;
};

// Generates a test volume consisting of pseudorandom blobs.
// This test volume is algorithmically generated, not loaded, and therefore
// doesn't require a server fetch.
Volume.generateBlobVolume = function(size=120, seed=1394) {
    function random() {
        let x = Math.sin(seed++) * 10000;
        return x - Math.floor(x);
    }
    let data = new Float32Array(size*size*size);
    let points = [];
    for (let i=0; i<150; ++i) {
        let x = random() * 1.0 - 0.5;
        let y = random() * 1.0 - 0.5;
        let z = random() * 1.0 - 0.5;
        let fade = random() * 1.0 + 1.0;
        points.push({x:x, y:y, z:z, fade:fade});
    }

    let pos = 0;
    let max = 0.0;
    for (let i=0; i<size; ++i) {
        let x = (2.0*i/size) - 1.0;
        for (let j=0; j<size; ++j) {
            let y = (2.0*j/size) - 1.0;
            for (let k=0; k<size; ++k) {
                let z = (2.0*k/size) - 1.0;
                let bright = 0.0;
                for (let q=0; q<points.length; ++q) {
                    let obj = points[q];
                    let xd = x - obj.x;
                    let yd = y - obj.y;
                    let zd = z - obj.z;
                    let len = Math.sqrt(xd*xd + yd*yd + zd*zd);
                    len *= 6.0;
                    len = 1.0 - len;
                    let val = obj.fade;
                    if (len > 1.0)  len = 1.0;
                    if (len <= 0.0)  len = 0.0;
                    val *= len;
                    if (val < 0.0) val = 0.0;
                    bright += val;
                }
                let len = Math.sqrt(x*x + y*y + z*z);
                if (len > 0.5)  bright -= (len - 0.5) * 4.0;
                //if (z > 0.3) bright -= (z - 0.3) / 0.05;
                if (bright < 0.0)  bright = 0.0;
                if (len <= 1.0)  bright += 0.2;
                data[pos++] = bright;
                if (bright > max)  bright = max;
            }
        }
    }
    if (max > 0.0) {
        for (let i=0; i<data.length; ++i) {
            data[i] = data[i] / max;
        }
    }

    return Volume.generateVolume(data, size, size, size);
};

// Sanitizes the provided orientation string so that it is valid.
// If he orientation is not valid and cannot be converted to something valid,
// this method returns null.
Volume.sanitizeOrientation = function(orientation) {
    if (!orientation)
        return Volume.DEFAULT_ORIENTATION;

    if (typeof orientation !== 'string')
        return null;  // not a string

    orientation = orientation.trim().toUpperCase();

    const nameMap = {
        "R":         "R",
        "L":         "L",
        "S":         "S",
        "I":         "I",
        "A":         "A",
        "P":         "P",
        "RIGHT":     "R",
        "LEFT":      "L",
        "DEXTER":    "R",
        "SINISTER":  "L",
        "SUPERIOR":  "S",
        "INFERIOR":  "I",
        "ANTERIOR":  "A",
        "POSTERIOR": "P",
        "TIME":      "T"
    };

    let splitVals = orientation.split("-");
    if (splitVals.length === 3 || splitVals.length === 4) {
        let newOrientation = "";
        for (let i=0; i<splitVals.length; ++i) {
            let name = splitVals[i];
            if (name in nameMap)
                newOrientation += nameMap[name];
            else
                return null;  // invalid name
        }
        orientation = newOrientation;
    }

    if (orientation.length !== 3 && orientation.length !== 4)
        return null;  // too many or too few axes

    let axial    = 0;
    let sagittal = 0;
    let coronal  = 0;
    for (let i=0; i<3; ++i) {
        let ch = orientation[i];
        if      (ch === 'R' || ch === 'L')  ++sagittal;
        else if (ch === 'S' || ch === 'I')  ++axial;
        else if (ch === 'A' || ch === 'P')  ++coronal;
    }

    if (axial !== 1 || sagittal !== 1 || coronal !== 1)
        return null;  // repeated or missing axes

    if (orientation.length === 4 && orientation[3] !== 'T')
        return null;  // last layer must be time

    // The orientation is valid
    return orientation;
};

// Converts the provided orientation string into its opposite.
// If he orientation is not valid and cannot be converted to something valid,
// this method returns null.
Volume.reverseOrientation = function(orientation) {
    orientation = Volume.sanitizeOrientation(orientation);
    if (!orientation)
        return null;

    if (orientation.length < 3)
        return null;

    let newOrientation = "";
    for (let i=0; i<orientation.length; ++i) {
        let ch = orientation[i];
        if      (ch === 'L')  ch = 'R';
        else if (ch === 'R')  ch = 'L';
        else if (ch === 'S')  ch = 'I';
        else if (ch === 'I')  ch = 'S';
        else if (ch === 'A')  ch = 'P';
        else if (ch === 'P')  ch = 'A';
        newOrientation += ch;
    }

    return newOrientation;
};

// Multiplies two 3x3 affine matrices.
// I should have used gl-matrix here, but I was trying to reduce dependencies...
Volume.multiplyAffines = function(affine0, affine1) {
    if (!affine0 && !affine1)
        return null;
    if (!affine0)
        return affine1;
    if (!affine1)
        return affine0;

    let a00 = affine0[0][0];
    let a01 = affine0[0][1];
    let a02 = affine0[0][2];
    let a10 = affine0[1][0];
    let a11 = affine0[1][1];
    let a12 = affine0[1][2];
    let a20 = affine0[2][0];
    let a21 = affine0[2][1];
    let a22 = affine0[2][2];

    let b00 = affine1[0][0];
    let b01 = affine1[0][1];
    let b02 = affine1[0][2];
    let b10 = affine1[1][0];
    let b11 = affine1[1][1];
    let b12 = affine1[1][2];
    let b20 = affine1[2][0];
    let b21 = affine1[2][1];
    let b22 = affine1[2][2];

    let newAffine = [[0.0, 0.0, 0.0], [0.0, 0.0, 0.0], [0.0, 0.0, 0.0]];

    newAffine[0][0] = b00 * a00 + b01 * a10 + b02 * a20;
    newAffine[0][1] = b00 * a01 + b01 * a11 + b02 * a21;
    newAffine[0][2] = b00 * a02 + b01 * a12 + b02 * a22;

    newAffine[1][0] = b10 * a00 + b11 * a10 + b12 * a20;
    newAffine[1][1] = b10 * a01 + b11 * a11 + b12 * a21;
    newAffine[1][2] = b10 * a02 + b11 * a12 + b12 * a22;

    newAffine[2][0] = b20 * a00 + b21 * a10 + b22 * a20;
    newAffine[2][1] = b20 * a01 + b21 * a11 + b22 * a21;
    newAffine[2][2] = b20 * a02 + b21 * a12 + b22 * a22;

    return newAffine;
};

// Inverts the specified 3x3 affine matrix.
Volume.invertAffine = function(affine) {
    if (!affine)
        return null;

    let a00 = affine[0][0];
    let a01 = affine[0][1];
    let a02 = affine[0][2];
    let a10 = affine[1][0];
    let a11 = affine[1][1];
    let a12 = affine[1][2];
    let a20 = affine[2][0];
    let a21 = affine[2][1];
    let a22 = affine[2][2];

    let b01 =  a22 * a11 - a12 * a21;
    let b11 = -a22 * a10 + a12 * a20;
    let b21 =  a21 * a10 - a11 * a20;

    // Calculate the determinant
    var det = a00 * b01 + a01 * b11 + a02 * b21;

    if (!det)  return null;  // cannot be inverted

    det = 1.0 / det;

    let newAffine = [[0.0, 0.0, 0.0], [0.0, 0.0, 0.0], [0.0, 0.0, 0.0]];

    newAffine[0][0] = b01 * det;
    newAffine[0][1] = (-a22 * a01 + a02 * a21) * det;
    newAffine[0][2] = (a12 * a01 - a02 * a11) * det;
    newAffine[1][0] = b11 * det;
    newAffine[1][1] = (a22 * a00 - a02 * a20) * det;
    newAffine[1][2] = (-a12 * a00 + a02 * a10) * det;
    newAffine[2][0] = b21 * det;
    newAffine[2][1] = (-a21 * a00 + a01 * a20) * det;
    newAffine[2][2] = (a11 * a00 - a01 * a10) * det;

    return newAffine;
};

// Generates an affine transformation matrix, based on a starting and
// ending orientation.
// Returns null if an error occurs.
Volume.generateAffine = function(oldOrientation, newOrientation) {
    oldOrientation = Volume.sanitizeOrientation(oldOrientation);
    newOrientation = Volume.sanitizeOrientation(newOrientation);

    if (!oldOrientation || !newOrientation)
        return null;

    function orientationToIndices(orientation) {
        let axisMap = [0, 0, 0];
        for (let i=0; i<3; ++i) {
            let ch = orientation[i];
            let index = 0;
            if      (ch === 'R' || ch === 'L')  index = 1;
            else if (ch === 'A' || ch === 'P')  index = 2;
            else if (ch === 'S' || ch === 'I')  index = 3;
            axisMap[i] = index;
        }
        return axisMap;
    }

    let axisMap0 = orientationToIndices(oldOrientation);
    let axisMap1 = orientationToIndices(newOrientation);

    let affine = [[0, 0, 0], [0, 0, 0], [0, 0, 0]];
    for (let i=0; i<3; ++i) {
        let index = axisMap0[i];
        for (let j=0; j<3; ++j) {
            if (axisMap1[j] === index) {
                let value = (oldOrientation[i] !== newOrientation[j]) ? -1 : 1;
                affine[j][i] = value;
            }
        }
    }

    return affine;
};

// Given an affine transformation matrix, this method snaps the basis vectors
// to the nearest natural axes, while also trying to preserve the length of the
// vectors.
Volume.snapToAffine = function(affine) {
    if (!affine)  return null;

    let newAffine = [
        [ 0.0, 0.0, 0.0 ],
        [ 0.0, 0.0, 0.0 ],
        [ 0.0, 0.0, 0.0 ]
    ];

    let slot = [ null, null, null ];

    for (let i=0; i<3; ++i) {
        let bestIndex = null;
        let bestValue = 0.0;
        let vectorLen = 0.0;
        for (let j=0; j<3; ++j) {
            let value = affine[j][i];
            if (slot[j] === null) {
                if (bestValue === null || (Math.abs(bestValue) < Math.abs(value))) {
                    bestIndex = j;
                    bestValue = value;
                }
            }
            vectorLen += value * value;
        }
        vectorLen = Math.sqrt(vectorLen);
        vectorLen = bestValue >= 0.0 ? vectorLen : -vectorLen;
        slot[bestIndex] = vectorLen;
        newAffine[bestIndex][i] = vectorLen;
    }

    return newAffine;
};

// Generates a histogram of gradient magnitudes, based on a provided
// gradient array.
Volume.calculateGradientHistogram = function(gradients) {
    if (gradients === undefined || gradients === null)  return null;

    if (gradients.histogram === undefined || gradients.histogram === null) {
        let histogram = new Histogram(0.0, gradients.gradientMax);

        const width           = gradients.width;
        const height          = gradients.height;
        const depth           = gradients.depth;
        const GRADIENT_STRIDE = 3;

        let gradientSize = width * height * depth * GRADIENT_STRIDE;
        histogram.clear();
        let pos = 0;
        while (pos < gradientSize) {
            const x = gradients.gradients[pos++];
            const y = gradients.gradients[pos++];
            const z = gradients.gradients[pos++];
            let gradMagnitude = Math.sqrt(x*x + y*y + z*z);
            histogram.addValue(gradMagnitude);
        }

        gradients.histogram = histogram;
    }

    return gradients.histogram;
};

// Computes a weight for a nearest neighbor convolution filter,
// based on a specified distance from an origin pixel.
Volume.filterNearestNeighbor = function NearestNeighbor(x) {
    if (x < 0.5 && x >= -0.5)  return 1.0;
    else                       return 0.0;
};

// Computes a weight for a linear interpolation convolution filter,
// based on a specified distance from an origin pixel.
Volume.filterLinearInterpolation = function LinearInterpolation(x) {
    x = Math.abs(x);

    if (x < 1.0)  return 1.0 - x;
    else          return 0.0;
};

// Computes a weight for a trapezoid convolution filter,
// based on a specified distance from an origin pixel and a scale.
// Used for downsampling.
Volume.filterTrapezoid = function Trapezoid(x, scale) {
    x = Math.abs(x);

    if (scale < 1.0) {  // upsampling
        if      (x < 0.5 - scale*0.5)  return 1.0;
        else if (x < 0.5 + scale*0.5)  return (0.5 + scale*0.5 - x) / scale;
        else                           return 0.0;
    }
    else {  // downsampling
       if      (x < scale*0.5 - 0.5)  return 1.0;
       else if (x < scale*0.5 + 0.5)  return 0.5 + scale*0.5 - x;
       else                           return 0.0;
    }
};

// Computes a weight for a bell convolution filter,
// based on a specified distance from an origin pixel.
Volume.filterBell = function Bell(x) {
    x = Math.abs(x);

    if      (x <  0.5)  return 0.75 - x*x;
    else if (x <= 1.5)  return (x-1.5)*(x-1.5)*0.5;
    else                return 0.0;
};

// Computes a weight for a cubic B-spline convolution filter,
// based on a specified distance from an origin pixel.
Volume.filterCubicBSpline = function CubicBSpline(x) {
    x = Math.abs(x);

    if      (x < 1.0)  return ((3.0*x - 6.0)*x*x + 4.0);       // (3x^3 - 6x^2 + 4) / 6
    else if (x < 2.0)  return (((6.0 - x)*x - 12.0)*x + 8.0);  // (-x^3 + 6x^2 - 12x + 8) / 6
    else               return 0.0;
};

// Computes a weight for a Mitchell-Netravali convolution filter,
// based on a specified distance from an origin pixel.
Volume.filterMitchell = function Mitchell(x) {
    x = Math.abs(x);

    if      (x < 1.0)  return (21.0*x - 36.0)*x*x + 16.0;           // (7x^3 - 12x^2 + 16/3) / 6
    else if (x < 2.0)  return ((-7.0*x + 36.0)*x - 60.0)*x + 32.0;  // (-7/3x^3 + 12x^2 - 20x + 32/3) / 6
    else               return 0.0;
};

// Computes a weight for a Catmull-Rom convolution filter,
// based on a specified distance from an origin pixel.
Volume.filterCatmullRom = function CatmullRom(x) {
    x = Math.abs(x);

    if      (x < 1.0)  return ((9.0*x - 15.0)*x*x + 6.0);             // (9x^3 - 15x^2 + 6) / 6;
    else if (x < 2.0)  return (((-3.0*x + 15.0)*x - 24.0)*x + 12.0);  // (-3x^3 + 15x^2 - 24x + 12.0) / 6;
    else               return 0.0;
};

// Generates a Gaussian filter based on the specified sigma.
// The sigma parameter represents the standard deviation sigma for
// Gaussian smoothing.
Volume.getGaussianFilter = function(sigma) {
    if (sigma < 0.1)  sigma = 0.1;
    if (sigma > 2.0)  sigma = 2.0;

    let expBase     = -0.5 / (sigma * sigma);
    let sigmaCutoff = Math.max(sigma * 3.0, 0.625);
    let bias        = Math.exp(sigmaCutoff*sigmaCutoff*expBase);

    let filter = function Gaussian(x) {
        x = Math.abs(x);

        if   (x <= sigmaCutoff)  return Math.max(Math.exp(x*x*expBase) - bias, 0.0);
        else                     return 0.0;
    };

    return filter;
};

// Computes and returns the window size (radius) of the specified filter.
Volume.getFilterWindowSize = function(filter, scale) {
    let x;
    for (x=0.5; x<16.0; x+=0.125) {
        if (filter(x, scale) === 0.0) {
            if (filter(x+0.01, scale) === 0.0)  // account for potential 0 crossing of filter
                return x;
        }
    }
    return x;
};

// PUBLIC API =================================================================

// Constructor for Volume.
export default function Volume(data, width, height, depth, count=1,
                scaleX=1.0, scaleY=1.0, scaleZ=1.0,
                orientation=null, nullValues=null) {

    // Sanitize
    if (width < 1 || height < 1 || depth < 1 || count < 1) {
        data   = new Float32Array(1);
        width  = 1;
        height = 1;
        depth  = 1;
        count  = 1;
    }

    orientation = Volume.sanitizeOrientation(orientation);
    if (!orientation)  orientation = Volume.DEFAULT_ORIENTATION;

    this.data        = data;
    this.width       = width;
    this.height      = height;
    this.depth       = depth;
    this.count       = count;

    this.scaleX      = scaleX;
    this.scaleY      = scaleY;
    this.scaleZ      = scaleZ;

    this.orientation = orientation;

    this.limits      = null;

    this.nullValues  = null;

    this.setNullValues(nullValues);
}

// Sets the pixel scale for the volume, using individual x, y and z fields.
Volume.prototype.setScaleXYZ = function(scaleX=1.0, scaleY=1.0, scaleZ=1.0) {
    this.scaleX = scaleX;
    this.scaleY = scaleY;
    this.scaleZ = scaleZ;
};

// Sets the pixel scale for the volume, using an object with x, y and z
// fields.
Volume.prototype.setScale = function(scale) {
    if (scale === undefined || scale === null)
        this.setScaleXYZ(1.0, 1.0, 1.0);
    else
        this.setScaleXYZ(scale.x, scale.y, scale.z);
};

// Gets the current pixel scale for the volume.
Volume.prototype.getScale = function() {
    return {
        x: this.scaleX,
        y: this.scaleY,
        z: this.scaleZ
    };
};

// Sets the value of a single voxel.
// Note: This method is slow compared to setting the volume data yourself.
// Use only if speed is not an issue.
Volume.prototype.setVoxel = function(value, x, y, z, w) {
    if (this.data === undefined || this.data === null)  return;

    if (x === undefined || x === null)  x = 0;
    if (y === undefined || y === null)  y = 0;
    if (z === undefined || z === null)  z = 0;
    if (w === undefined || w === null)  w = 0;

    if (value === undefined || value === null)  value = 0.0;

    x = Math.round(x);
    y = Math.round(y);
    z = Math.round(z);
    w = Math.round(w);

    if (x >= 0 && x < this.width) {
        if (y >= 0 && y < this.height) {
            if (z >= 0 && z < this.depth) {
                if (w >= 0 && w < this.count) {
                    let pos = (((((w * this.depth) + z) * this.height) + y) * this.width) + x;
                    this.data[pos] = value;
                }
            }
        }
    }
};

// Gets the value of a single voxel.
// Note: This method is slow compared to getting the volume data yourself.
// Use only if speed is not an issue.
Volume.prototype.getVoxel = function(x, y, z, w) {
    if (this.data === undefined || this.data === null)  return undefined;

    if (x === undefined || x === null)  x = 0;
    if (y === undefined || y === null)  y = 0;
    if (z === undefined || z === null)  z = 0;
    if (w === undefined || w === null)  w = 0;

    x = Math.round(x);
    y = Math.round(y);
    z = Math.round(z);
    w = Math.round(w);

    if (x >= 0 && x < this.width) {
        if (y >= 0 && y < this.height) {
            if (z >= 0 && z < this.depth) {
                if (w >= 0 && w < this.count) {
                    let pos = (((((w * this.depth) + z) * this.height) + y) * this.width) + x;
                    return this.data[pos];
                }
            }
        }
    }

    return undefined;
};

// Attempts to infer the null value associated with the volume by
// looking at the corners of the volume.  If 5 of 8 corners have the
// same value, this is likely to be the null value.
// Returns the possible null value, or undefined if the null value
// could not be inferred.
Volume.prototype.calculateNullValue = function(index=0) {
    if (index < 0 || index >= this.count)  return undefined;

    let nullValue = undefined;

    const data   = this.data;
    const width  = this.width;
    const height = this.height;
    const depth  = this.depth;
    const count  = this.count;

    const offset = width * height * depth * index;

    function getLumValue(i, j, k) {
        return data[(k*height + j)*width + i + offset];
    };

    // Fetch the values at the corners of the sub-volume
    let corners = [
        getLumValue(0,       0,        0      ),
        getLumValue(width-1, 0,        0      ),
        getLumValue(0,       height-1, 0      ),
        getLumValue(width-1, height-1, 0      ),
        getLumValue(0,       0,        depth-1),
        getLumValue(width-1, 0,        depth-1),
        getLumValue(0,       height-1, depth-1),
        getLumValue(width-1, height-1, depth-1)
    ];

    // Ugly, but allows us to use exact types
    let counts = [];
    for (let i=0; i<corners.length; ++i) {
        let value = corners[i];
        let pos;
        for (pos=0; pos<counts.length; ++pos) {
            let obj = counts[pos];
            if (obj.value === value) {
                ++obj.count;
                break;
            }
        }
        if (pos >= counts.length)
            counts.push({value:value, count:1});
    }
    let bestCount = undefined;
    let bestValue = null;
    for (let i=0; i<counts.length; ++i) {
        let obj = counts[i];
        let value = obj.value;
        let count = obj.count;
        if (bestCount === undefined || bestCount < count) {
            bestCount = count;
            bestValue = value;
        }
    }
    if (bestCount >= 5) {
        nullValue = bestValue;
    }

    return nullValue;
};

// Sets the null value associated with the sub-volume at the specified index.
// This will force recalculation of volume limits, among other things.
// If Volume.CALCULATE_NULL is used for the null value, the method
// will attempt to infer the null value from the volume data.
Volume.prototype.setNullValue = function(nullValue, index=0) {
    if (index < 0 || index >= this.count)  return;

    if (nullValue === Volume.CALCULATE_NULL) {
        nullValue = this.calculateNullValue(index);
        if (nullValue !== undefined)
            dconsole.log("Calculated null value for volume: " + nullValue);
    }

    if (nullValue === undefined)
        if (this.nullValues === null || index >= this.nullValues.length)
            return;  // no change

    if (this.nullValues === null)
        this.nullValues = [];

    if (this.nullValues.length < index+1)
        this.nullValues.length = index+1;

    if (this.nullValues[index] !== nullValue) {
        this.nullValues[index] = nullValue;
        this.clearLimits(index);
    }

    if (nullValue === undefined) {
        let endPos = this.nullValues.length-1;
        while (endPos >= 0) {
            if (this.nullValues[endPos] !== undefined)
                break;
            --endPos;
        }
        ++endPos;
        if (endPos <= 0)
            this.nullValues = null;
        else if (this.nullValues.length > endPos)
            this.nullValues.length = endPos;
    }
};

// Gets the null value associated with the sub-volume at the specified index.
Volume.prototype.getNullValue = function(index=0) {
    if (index < 0 || index >= this.count)  return undefined;
    if (this.nullValues === null)  return undefined;
    if (index >= this.nullValues.length)  return undefined;

    return this.nullValues[index];
};

// Sets the null values associated with all of the sub-volumes.
// Takes an array that is correlated with the sub-volumes of the array.
Volume.prototype.setNullValues = function(nullValues=null) {
    if (nullValues === undefined || nullValues === null)
        nullValues = [];

    if (!Array.isArray(nullValues))
        nullValues = [nullValues];

    if (this.nullValues === null)
        this.nullValues = [];

    let maxLength = Math.max(nullValues.length, this.nullValues.length);
    if (maxLength >= this.count)  maxLength = this.count;

    for (let i=maxLength-1; i>=0; --i) {
        const nullValue = (i < nullValues.length) ? nullValues[i] : undefined;
        this.setNullValue(nullValue, i);
    }
};

// Gets the null values associated with all of the sub-volumes.
// Returns an array.  The length of the array will be the same as the count
// of sub-volumes.
Volume.prototype.getNullValues = function() {
    let nullValues = [];
    nullValues.length = this.count;

    for (let i=0; i<this.count; ++i)
        nullValues[i] = this.getNullValue(i);

    return nullValues;
};

// Clears the cached minima and maxima for the volume.
Volume.prototype.clearLimits = function(index=undefined) {
    if (!this.limits)  return;

    if (index === undefined || index === null) {
        this.limits = null;
        return;
    }

    if (index < 0 || index >= this.count)  return;

    this.limits[index] = null;
};

// Calculates minima and maxima for the volume at the specified index.
Volume.prototype.calculateLimits = function(index=0) {
    if (index < 0 || index >= this.count)  return null;
    if (this.data === undefined || this.data === null)  return null;

    if (this.limits === undefined || this.limits === null ||
        this.limits.length !== this.count)  {
        this.limits = [];
        this.limits.length = this.count;
        this.limits.fill(null);
    }

    let limits = this.limits[index];
    if (limits !== undefined && limits !== null)  return limits;

    const size = this.width * this.height * this.depth;
    if (size < 1)  return null;

    const data = this.data;
    if (data === undefined || data === null)  return null;

    const offset = index * size;

    let luminosityMin = 0.0;
    let luminosityMax = 0.0;

    const nullValue = this.getNullValue(index);

    let pos;
    for (pos=0; pos<data.length; ++pos) {
        const value = data[offset+pos];
        if (value !== nullValue) {
            luminosityMin = luminosityMax = value;
            break;
        }
    }

    for (++pos; pos<data.length; ++pos) {
        const value = data[offset+pos];
        if (value !== nullValue) {
            if (luminosityMin > value)  luminosityMin = value;
            if (luminosityMax < value)  luminosityMax = value;
        }
    }

    const minDelta = 1e-7;
    let luminosityDelta = luminosityMax - luminosityMin;
    if (luminosityDelta < minDelta) {
        luminosityDelta = minDelta;
        luminosityMax = luminosityMin + luminosityDelta;
    }

    limits = {
        luminosityMin:   luminosityMin,
        luminosityMax:   luminosityMax,
        luminosityDelta: luminosityDelta,
        histogram:       null
    };
    this.limits[index] = limits;

    return limits;
};

// Calculates and returns a histogram for the volume.
// If a histogram has been previously calculated and cached, the cached
// histogram will be returned.
Volume.prototype.calculateHistogram = function(index=0) {
    if (index < 0 || index >= this.count)  return null;
    if (this.data === undefined || this.data === null)  return null;

    let limits = this.calculateLimits(index);
    if (limits === null)  return null;

    if (limits.histogram === undefined || limits.histogram === null) {
        const nullValue = this.getNullValue(index);

        let histogram = new Histogram(limits.luminosityMin, limits.luminosityMax,
                                      nullValue);

        const data   = this.data;
        const width  = this.width;
        const height = this.height;
        const depth  = this.depth;
        const count  = this.count;
        const size   = width * height * depth;
        const offset = index * size;

        histogram.clear();
        for (let i=0; i<size; ++i) {
            const value = data[offset+i];
            histogram.addValue(value);
        }

        limits.histogram = histogram;
    }

    return limits.histogram;
};

// Generates gradients for the current volume.
Volume.prototype.generateGradients = function(halfGradient, includeHistogram=false, index=0) {
    if (index < 0 || index >= this.count)  return null;
    if (this.data === undefined || this.data === null)  return null;

    let limits = this.calculateLimits(index);
    if (limits === null)  return null;

    const luminosityMin = limits.luminosityMin;
    const luminosityMax = limits.luminosityMax;

    let lo, hi, mi;

    let startTime, endTime, deltaTime;

    // Compute gradient vectors, which double as surface normals for lighting
    let gradientMaxSq = 1e-7;
    if (halfGradient) {
        lo = -1;
        hi = 0;
        mi = 0;
    }
    else {
        lo = -1;
        hi = 1;
        mi = 0;
    }

    const GRADIENT_STRIDE = 3;
    const data            = this.data;
    const width           = this.width;
    const height          = this.height;
    const depth           = this.depth;
    const volumeSize      = width * height * depth;
    const normalSize      = volumeSize * GRADIENT_STRIDE;
    const offset          = index * volumeSize;
    const nullValue       = this.getNullValue(index);

    function getLumValue(i, j, k) {
        if (i < 0 || i >= width || j < 0 || j >= height || k < 0 || k >= depth)
            return luminosityMin;
        return data[(k*height + j)*width + i + offset];
    };
    function getGradValue(i, j, k, i0, j0, k0, i1, j1, k1) {
        return getLumValue(i+i0, j+j0, k+k0) - getLumValue(i+i1, j+j1, k+k1);
    };

    Volume.normalBuffer = Volume.smartReallocate(Volume.normalBuffer, 'Float32Array', normalSize);
    if (!Volume.normalBuffer)  return null;

    // Compute gradient vectors for the volume.
    dconsole.log("Starting gradient vector calculation...");
    startTime = Date.now();

    let gradients = Volume.normalBuffer;

    let pos = 0;
    for (let k=0; k<depth; ++k) {
        for (let j=0; j<height; ++j) {
            for (let i=0; i<width; ++i) {
                let xDelta = getGradValue(i, j, k, hi, mi, mi, lo, mi, mi);
                let yDelta = getGradValue(i, j, k, mi, hi, mi, mi, lo, mi);
                let zDelta = getGradValue(i, j, k, mi, mi, hi, mi, mi, lo);

                const lenSq = xDelta*xDelta + yDelta*yDelta + zDelta*zDelta;
                if (gradientMaxSq < lenSq)  gradientMaxSq = lenSq;

                gradients[pos++] = xDelta;
                gradients[pos++] = yDelta;
                gradients[pos++] = zDelta;
            }
        }
    }
    const gradientMax = Math.sqrt(gradientMaxSq);

    let gradientObject = {
        gradients:   gradients,
        width:       width,
        height:      height,
        depth:       depth,
        gradientMax: gradientMax,
        histogram:   null
    };

    if (includeHistogram)
        Volume.calculateGradientHistogram(gradientObject);

    endTime = Date.now();
    deltaTime = (endTime - startTime) / 1000.0;
    dconsole.log("Done with gradient vector calculation (time="+deltaTime+"s)");

    return gradientObject;
};

// Adds noise to the specified volume.
// Largely, but not exclusively, used for testing purposes.
Volume.prototype.addNoise = function(noise=0.1, index=0) {
    // Sanity checks
    if (noise <= 0.0)  return;
    if (index < 0 || index >= this.count)  return;
    if (this.data === undefined || this.data === null)  return;

    const data       = this.data;
    const width      = this.width;
    const height     = this.height;
    const depth      = this.depth;
    const volumeSize = width * height * depth;
    const offset     = index * volumeSize;

    if (data === undefined || data === null)  return;
    if (data.length < offset + volumeSize)  return;

    // Find the range of values in the array
    let limits = this.calculateLimits(index);
    if (limits === null)  return;

    // Add some crap to the data
    const range = limits.luminosityDelta;
    const randRange = noise * range;
    const bias = 0.0; // Math.random() * range;
    if (randRange > 0.0 || bias !== 0.0) {
        for (let i=0; i<volumeSize; ++i) {
            data[i+offset] = data[i+offset] + Math.random() * randRange - randRange*0.5 + bias;
        }
    }

    this.clearLimits(index);
};

// Adds noise to the specified volume, but only replaces values
// that are equal to the null value.
Volume.prototype.applyNoiseToNullValues = function(index=0) {
    const width     = this.width;
    const height    = this.height;
    const depth     = this.depth;
    const count     = this.count;
    const data      = this.data;
    if (index < 0 || index >= count)  return;

    const nullValue = this.getNullValue(index);

    if (nullValue === undefined)  return;

    let histogram = this.calculateHistogram(index);
    let limits = this.calculateLimits(index);

    let mean = histogram.normValueToValue(histogram.computeMode());
    let std = mean - histogram.minValue;

    dconsole.log("APPLYING NOISE TO VOLUME (null="+nullValue+", mean="+mean+", std="+std+")");
    dconsole.log("---LIMITS = "+limits.luminosityMin + " - " + limits.luminosityMax);

    std *= 1.0;

    // STM_TODO - COPIED FROM ELSEWHERE - PUT THIS IN COMMON LOCATION!
    let seed = 3049.0;

    const mmul = 214013.0;      // prime
    const madd = 2531011.0;     // prime
    const mmod = 4294967296.0;  // power of 2

    // Deterministic pseudo-random function with uniform distribution
    const uniformRandom = function() {
        seed = ((seed * mmul) + madd) % mmod;
        return seed * 1.0 / mmod;
    };

    // Deterministic pseudo-random function with Gaussian distribution
    // (peak is at 0.5, extent is 0.5 in either direction)
    const gaussianRandom = function() {
        return (uniformRandom() + uniformRandom() + uniformRandom() + uniformRandom()) * 0.25;
    };

    // STM_TODO - END COMMON CODE

    const size = width * height * depth;
    const offset = index * size;

    for (let i=0; i<size; ++i) {
        const pos = i + offset;
        let value = data[pos];
        if (value === nullValue) {
            let rand = (gaussianRandom() - 0.5) * 2.0;
            value = (rand * std) + mean;
            data[pos] = value;
        }
    }

    this.clearLimits(index);
    this.setNullValue(undefined, index);
};

// Tests for existence of NaNs in the volume data.
Volume.prototype.checkNaNs = function(index=0) {
    const width     = this.width;
    const height    = this.height;
    const depth     = this.depth;
    const count     = this.count;
    const data      = this.data;
    if (index < 0 || index >= count)  return;

    const size = width * height * depth;
    const offset = index * size;

    let nanCount = 0;

    for (let i=0; i<size; ++i) {
        const pos = i + offset;
        let value = data[pos];
        if (Number.isNaN(value))
            ++nanCount;
    }

    if (nanCount > 0)
        console.warn(`${nanCount} NaNs found in volume!`);

    return nanCount;
};

// Snaps all values near the provided value to that value.
// "Nearness" is defined by the provided epsilon.
// STM_TODO - this is a hack used to get around FP precision errors in volumes.
// We should get rid of it as soon as possible.
Volume.prototype.snapNearValue = function(index=0, nearValue=0.0, epsilon=1e-6) {
    const width     = this.width;
    const height    = this.height;
    const depth     = this.depth;
    const count     = this.count;
    const data      = this.data;
    if (index < 0 || index >= count)  return;

    const size = width * height * depth;
    const offset = index * size;

    for (let i=0; i<size; ++i) {
        const pos = i + offset;
        let value = data[pos];
        let delta = value >= nearValue ? value-nearValue : nearValue-value;
        if (delta <= epsilon)
            data[pos] = nearValue;
        else
            data[pos] = value;
    }

    this.clearLimits(index);
    this.setNullValue(nearValue, index);
};

// Converts the specified subvolume to a mask.
// Values of 1.0 represent null values in the original volume, and
// values of 0.0 represent non-null values.
Volume.prototype.convertToNullMask = function(index=0) {
    const width     = this.width;
    const height    = this.height;
    const depth     = this.depth;
    const count     = this.count;
    const data      = this.data;
    if (index < 0 || index >= count)  return;

    const nullValue = this.getNullValue(index);

    if (nullValue === undefined)  return;

    const size = width * height * depth;
    const offset = index * size;

    for (let i=0; i<size; ++i) {
        const pos = i + offset;
        let value = data[pos];
        if (value === nullValue)
            data[pos] = 1.0;
        else
            data[pos] = 0.0;
    }

    this.clearLimits(index);
    this.setNullValue(undefined, index);
};

// Calculates the minimum value in the specified source volume.
Volume.calculateMinimumValue = function(volume, index) {

    if (volume === undefined || volume === null)  return { mean: 0.0, std: 0.0 };

    if (index === undefined || index === null)  index = 0;

    const width  = volume.width;
    const height = volume.height;
    const depth  = volume.depth;
    const count  = volume.count;

    if (index < 0 || index >= count)  return { mean: 0.0, std: 0.0 };

    const size = width * height * depth;

    if (size <= 0)  return 0.0;

    const indexOffset = index * size;

    let minValue = volume.data[0];
    for (let i=1; i<size; ++i) {
        let value = volume.data[indexOffset+i];
        if (minValue > value)  minValue = value;
    }

    return {
        mean: minValue,
        std:  0.0
    };
};

// Calculates the mean and standard deviation for the corners of the
// specified source volume.
Volume.calculateEdgeStandardDeviation = function(volume, index) {

    if (volume === undefined || volume === null)  return { mean: 0.0, std: 0.0 };

    if (index === undefined || index === null)  index = 0;

    const width  = volume.width;
    const height = volume.height;
    const depth  = volume.depth;
    const count  = volume.count;

    if (index < 0 || index >= count)  return { mean: 0.0, std: 0.0 };

    const indexOffset = index * width * height * depth;

    const inset     = 2;
    const blockSize = 4;
    const minSize   = (inset + blockSize) * 2;

    const xSkip = (width  > minSize) ? (width  - minSize) : 0;
    const ySkip = (height > minSize) ? (height - minSize) : 0;
    const zSkip = (depth  > minSize) ? (depth  - minSize) : 0;

    const fromX = inset;
    const fromY = inset;
    const fromZ = inset;

    const toX = width  - inset;
    const toY = height - inset;
    const toZ = depth  - inset;

    const jumpPoint = inset + blockSize;

    // Looping function for corners of the volume
    function loopFunc(cornerFunc) {
        for (let z=fromZ; z<toZ; ++z) {
            if (z === jumpPoint)  z += zSkip;
            for (let y=fromY; y<toY; ++y) {
                if (y === jumpPoint)  y += ySkip;
                for (let x=fromX; x<toX; ++x) {
                    if (x === jumpPoint)  x += xSkip;

                    let offset = ((z * height) + y) * width + x + indexOffset;
                    cornerFunc(volume.data[offset]);
                }
            }
        }
    }

    // Calculate mean
    let mean  = 0.0;
    let items = 0;
    loopFunc(function(value) {
        mean += value;
        ++items;
    });
    if (items > 0)  mean /= items;

    // Calculate standard deviation
    let stdval = 0.0;
    loopFunc(function(value) {
        let delta = value - mean;
        delta *= delta;
        stdval += delta;
    });
    if (items > 1)  stdval /= (items - 1);
    stdval = Math.sqrt(stdval);

    return {
        mean: mean,
        std:  stdval
    };
};

// Calculates integer or half-integer factors for upscaling an image that will
// result in voxels that are close to isotropic, based on a provided voxel size.
Volume.calculateUpsampleFactors = function(scaleX, scaleY, scaleZ) {
    let bestScale = Math.min(Math.min(scaleX, scaleY), scaleZ);

    const getScaleMultiplier = function(val) {
        if      (val >= 3.90)  val = 4.0;
        else if (val >= 2.90)  val = 3.0;
        else if (val >= 2.40)  val = 2.5;
        else if (val >= 1.90)  val = 2.0;
        else if (val >= 1.40)  val = 1.5;
        else                   val = 1.0;
        return val;
    };

    return {
        x: getScaleMultiplier(scaleX / bestScale),
        y: getScaleMultiplier(scaleY / bestScale),
        z: getScaleMultiplier(scaleZ / bestScale)
    };
};

// Returns a resized volume with a new width, height and depth.
// The volume is not resampled.
// If additional areas within the volume are created, the new areas are
// filled with the lowest value in each layer, or with a user-defined function.
Volume.prototype.resizeVolume = function(newWidth=undefined, newHeight=undefined, newDepth=undefined,
                                         xOffset=undefined, yOffset=undefined, zOffset=undefined,
                                         fillCalcFunc=undefined) {
    const width  = this.width;
    const height = this.height;
    const depth  = this.depth;
    const count  = this.count;
    const data   = this.data;

    if (newWidth  === undefined || newWidth  === null)  newWidth  = width;
    if (newHeight === undefined || newHeight === null)  newHeight = height;
    if (newDepth  === undefined || newDepth  === null)  newDepth  = depth;

    if (newWidth < 1 || newHeight < 1 || newDepth < 1)  return;

    if (xOffset === undefined || xOffset === null)  {
        // Banker's rounding
        xOffset = (newWidth - width) / 2;
        xOffset = ((width % 2) === 0) ? Math.floor(xOffset) : Math.ceil(xOffset);
    }

    if (yOffset === undefined || yOffset === null) {
        // Banker's rounding
        yOffset = (newHeight - height) / 2;
        yOffset = ((height % 2) === 0) ? Math.floor(yOffset) : Math.ceil(yOffset);
    }

    if (zOffset === undefined || zOffset === null) {
        // Banker's rounding
        zOffset = (newDepth - depth) / 2;
        zOffset = ((depth % 2) === 0) ? Math.floor(zOffset) : Math.ceil(zOffset);
    }

    if (fillCalcFunc === undefined || fillCalcFunc === null)
        fillCalcFunc = Volume.calculateMinimumValue;
    if (!(fillCalcFunc instanceof Array))
        fillCalcFunc = [fillCalcFunc];

    let volume = null;

    let seed = 3049.0;

    const mmul = 214013.0;      // prime
    const madd = 2531011.0;     // prime
    const mmod = 4294967296.0;  // power of 2

    // Deterministic pseudo-random function with uniform distribution
    const uniformRandom = function() {
        seed = ((seed * mmul) + madd) % mmod;
        return seed * 1.0 / mmod;
    };

    // Deterministic pseudo-random function with Gaussian distribution
    // (peak is at 0.5, extent is 0.5 in either direction)
    const gaussianRandom = function() {
        return (uniformRandom() + uniformRandom() + uniformRandom() + uniformRandom()) * 0.25;
    };

    dconsole.log("Starting volume resizing...");
    let startTime = Date.now();

    if (width !== newWidth || height !== newHeight || depth !== newDepth ||
        xOffset !== 0.0 || yOffset !== 0.0 || zOffset !== 0.0)  {

        const volumeSize = width * height * depth;

        const newVolumeSize = newWidth * newHeight * newDepth;
        let newData = data;
        if (data !== undefined && data !== null) {
            newData = new Float32Array(newVolumeSize * count);

            for (let index=0; index<count; ++index) {
                const oldOffset = index * volumeSize;
                const newOffset = index * newVolumeSize;

                let fillFunc = (index < fillCalcFunc.length) ? fillCalcFunc[index] : null;
                if (fillFunc === undefined || fillFunc === null)
                    fillFunc = Volume.calculateMinimumValue;

                const std = fillFunc(this, index);

                const mean  = std ? std.mean : 0.0;
                const stdev = std ? std.std : 0.0;

                for (let k=0; k<newDepth; ++k) {
                    const z = k - zOffset;
                    const zInside = (z >= 0 && z < depth);
                    for (let j=0; j<newHeight; ++j) {
                        const y = j - yOffset;
                        const yInside = (y >= 0 && y < height);
                        for (let i=0; i<newWidth; ++i) {
                            const x = i - xOffset;
                            const xInside = (x >= 0 && x < width);

                            const toPos = (k*newHeight + j)*newWidth + i + newOffset;

                            if (xInside && yInside && zInside) {
                                const fromPos = (z*height + y)*width + x + oldOffset;
                                newData[toPos] = data[fromPos];
                            }
                            else {
                                newData[toPos] = ((gaussianRandom() - 0.5) * 2.0) * stdev * 3.5 + mean;
                            }
                        }
                    }
                }
            }
        }

        const scaleX      = this.scaleX;
        const scaleY      = this.scaleY;
        const scaleZ      = this.scaleZ;
        const orientation = this.orientation;
        const nullValues  = this.getNullValues();

        volume = Volume.generateVolume(newData, newWidth, newHeight, newDepth, count,
                                       scaleX, scaleY, scaleZ, orientation, nullValues);
    }
    else {
        volume = Volume.copyVolumeDeep(this);
    }

    let endTime = Date.now();
    let deltaTime = (endTime - startTime) / 1000.0;

    dconsole.log("Done with volume resizing (time="+deltaTime+"s)");


    return volume;
};

// Smooths and resizes a data volume using Gaussian convolution filtering,
// based on the specified size, sigma and offset.
Volume.prototype.resampleVolumeGaussian = function(newWidth, newHeight, newDepth,
                                                   xOffset=0.0, yOffset=0.0, zOffset=0.0,
                                                   sigma=0.5) {

    let filter = Volume.getGaussianFilter(sigma);

    return this.resampleVolume(newWidth, newHeight, newDepth,
                               xOffset, yOffset, zOffset,
                               filter, filter, filter);
};

// Smooths and resizes a data volume based on the specified size and pixel offsets,
// using a nearest neighbor (box) convolution filter.
Volume.prototype.resampleVolumeNearestNeighbor = function(newWidth, newHeight, newDepth,
                                                          xOffset=0.0, yOffset=0.0, zOffset=0.0) {
    return this.resampleVolume(newWidth, newHeight, newDepth,
                               xOffset, yOffset, zOffset,
                               Volume.filterNearestNeighbor, Volume.filterNearestNeighbor,
                               Volume.filterNearestNeighbor);
};

// Smooths and resizes a data volume based on the specified size and pixel offsets,
// using a linear interpolation (triangle) convolution filter.
Volume.prototype.resampleVolumeLinearInterpolation = function(newWidth, newHeight, newDepth,
                                                              xOffset=0.0, yOffset=0.0, zOffset=0.0) {
    return this.resampleVolume(newWidth, newHeight, newDepth,
                               xOffset, yOffset, zOffset,
                               Volume.filterLinearInterpolation, Volume.filterLinearInterpolation,
                               Volume.filterLinearInterpolation);
};

// Smooths and resizes a data volume based on the specified size and pixel offsets,
// using a bell convolution filter.
Volume.prototype.resampleVolumeBell = function(newWidth, newHeight, newDepth,
                                               xOffset=0.0, yOffset=0.0, zOffset=0.0) {
    return this.resampleVolume(newWidth, newHeight, newDepth,
                               xOffset, yOffset, zOffset,
                               Volume.filterBell, Volume.filterBell,
                               Volume.filterBell);
};

// Smooths and resizes a data volume based on the specified size and pixel offsets,
// using a cubic b-spline convolution filter.
Volume.prototype.resampleVolumeCubicBSpline = function(newWidth, newHeight, newDepth,
                                                       xOffset=0.0, yOffset=0.0, zOffset=0.0) {
    return this.resampleVolume(newWidth, newHeight, newDepth,
                               xOffset, yOffset, zOffset,
                               Volume.filterCubicBSpline, Volume.filterCubicBSpline,
                               Volume.filterCubicBSpline);
};

// Smooths and resizes a data volume based on the specified size and pixel offsets,
// using a Mitchell-Netravali convolution filter.
Volume.prototype.resampleVolumeMitchell = function(newWidth, newHeight, newDepth,
                                                   xOffset=0.0, yOffset=0.0, zOffset=0.0) {
    return this.resampleVolume(newWidth, newHeight, newDepth,
                               xOffset, yOffset, zOffset,
                               Volume.filterMitchell, Volume.filterMitchell,
                               Volume.filterMitchell);
};

// Smooths and resizes a data volume based on the specified size and pixel offsets,
// using a Catmull-Rom convolution filter.
Volume.prototype.resampleVolumeCatmullRom = function(newWidth, newHeight, newDepth,
                                                     xOffset=0.0, yOffset=0.0, zOffset=0.0) {
    return this.resampleVolume(newWidth, newHeight, newDepth,
                               xOffset, yOffset, zOffset,
                               Volume.filterCatmullRom, Volume.filterCatmullRom,
                               Volume.filterCatmullRom);
};

// Smooths and resizes a data volume based on the specified size and pixel offsets,
// using specified filters for the x, y and z axes.
Volume.prototype.resampleVolume = function(newWidth, newHeight, newDepth,
                                           xOffset=undefined, yOffset=undefined,
                                           zOffset=undefined,
                                           xFilter=undefined, yFilter=undefined,
                                           zFilter=undefined) {
    let oldWidth    = this.width;
    let oldHeight   = this.height;
    let oldDepth    = this.depth;
    let count       = this.count;
    let orientation = this.orientation;
    let nullValues  = this.getNullValues();

    // STM_TODO - handle resampling with null values!!!

    let oldPixels = this.data;

    if (newWidth  === undefined || newWidth  === null)  newWidth  = this.width;
    if (newHeight === undefined || newHeight === null)  newHeight = this.height;
    if (newDepth  === undefined || newDepth  === null)  newDepth  = this.depth;

    const MIN_SIZE = 1;

    if (newWidth  < MIN_SIZE)  newWidth  = MIN_SIZE;
    if (newHeight < MIN_SIZE)  newHeight = MIN_SIZE;
    if (newDepth  < MIN_SIZE)  newDepth  = MIN_SIZE;

    if (xOffset === undefined || xOffset === null)  xOffset = 0.0;
    if (yOffset === undefined || yOffset === null)  yOffset = 0.0;
    if (zOffset === undefined || zOffset === null)  zOffset = 0.0;

    const DOWN_FILTER = Volume.filterTrapezoid;
    const UP_FILTER   = Volume.filterCatmullRom;
    const SAME_FILTER = Volume.filterLinearInterpolation;

    if (xFilter === undefined || xFilter === null)
        xFilter = (newWidth === oldWidth)   ? SAME_FILTER : (newWidth <= oldWidth)   ? DOWN_FILTER : UP_FILTER;
    if (yFilter === undefined || yFilter === null)
        yFilter = (newHeight === oldHeight) ? SAME_FILTER : (newHeight <= oldHeight) ? DOWN_FILTER : UP_FILTER;
    if (zFilter === undefined || zFilter === null)
        zFilter = (newDepth === oldDepth)   ? SAME_FILTER : (newDepth <= oldDepth)   ? DOWN_FILTER : UP_FILTER;

    // Uncomment this code to add a border
    /*
    let border = Math.ceil(windowRadius);
    newWidth  += border*2;
    newHeight += border*2;
    newDepth  += border*2;
    xOffset -= border;
    yOffset -= border;
    zOffset -= border;
    //*/

    let scaleX = this.scaleX * oldWidth  / newWidth;
    let scaleY = this.scaleY * oldHeight / newHeight;
    let scaleZ = this.scaleZ * oldDepth  / newDepth;

    let oldXMult = oldWidth  / newWidth;
    let oldYMult = oldHeight / newHeight;
    let oldZMult = oldDepth  / newDepth;

    let filterXScale = oldXMult;
    let filterYScale = oldYMult;
    let filterZScale = oldZMult;

    const newXMult = newWidth  / oldWidth;
    const newYMult = newHeight / oldHeight;
    const newZMult = newDepth  / oldDepth;

    // Special case for even integer size multiples
    const xEven = Math.round(newXMult / 2.0) === newXMult / 2.0;
    const yEven = Math.round(newYMult / 2.0) === newYMult / 2.0;
    const zEven = Math.round(newZMult / 2.0) === newZMult / 2.0;

    const dstXOffset = (xEven ? 0.0 : 0.5) + xOffset;
    const dstYOffset = (yEven ? 0.0 : 0.5) + yOffset;
    const dstZOffset = (zEven ? 0.0 : 0.5) + zOffset;

    const srcXOffset = 0.5;
    const srcYOffset = 0.5;
    const srcZOffset = 0.5;

    dconsole.log("Starting volume resampling...");
    let startTime = Date.now();

    // The maximum number of surrounding pixels in each dimension to filter from
    // (actual volume of the filter is (windowRadius*2+1)^3)
    let xWindowRadius = Volume.getFilterWindowSize(xFilter, filterXScale);
    let yWindowRadius = Volume.getFilterWindowSize(yFilter, filterYScale);
    let zWindowRadius = Volume.getFilterWindowSize(zFilter, filterZScale);

    dconsole.log("Filtering: x: " + xFilter.name + ", y: " +
                 yFilter.name + ", z: " + zFilter.name);
    dconsole.log("Convolution window radius: (" + xWindowRadius +
                 ", " + yWindowRadius + ", " + zWindowRadius+")");

    if (oldWidth !== newWidth || oldHeight !== newHeight || oldDepth !== newDepth)
        dconsole.log("New size: (" + newWidth + ", " + newHeight + ", " +
                     newDepth + ")");

    dconsole.log("Number of pixels: " + newWidth * newHeight * newDepth);

    // STM_TODO - allow buffer reuse!
    let newPixels = oldPixels;
    if (oldPixels !== undefined && oldPixels !== null) {
        newPixels = new Float32Array(newWidth * newHeight * newDepth * count);

        for (let index=0; index<count; ++index) {
            const oldOffset = index * oldWidth * oldHeight * oldDepth;
            const newOffset = index * newWidth * newHeight * newDepth;

            let limits = this.calculateLimits(index);
            let minval = limits !== null ? limits.luminosityMin : 0.0;

            let minX, maxX, ox;
            let minY, maxY, oy;
            let minZ, maxZ, oz;
            let x, y, z;
            let num, denom;
            let dx, dy, dz;
            let sum;
            let oldValue, value;
            let xWeight, yWeight, zWeight;
            let weight;

            // For every destination pixel in the volume...
            for (z = 0; z < newDepth; ++z) {
                oz = (z + dstZOffset) * oldZMult - srcZOffset;
                minZ = Math.floor(oz-zWindowRadius);
                maxZ = Math.ceil(oz+zWindowRadius);

                for (y = 0; y < newHeight; ++y) {
                    oy = (y + dstYOffset) * oldYMult - srcYOffset;
                    minY = Math.floor(oy-yWindowRadius);
                    maxY = Math.ceil(oy+yWindowRadius);

                    for (x = 0; x < newWidth; ++x) {
                        ox = (x + dstXOffset) * oldXMult - srcXOffset;
                        minX = Math.floor(ox-xWindowRadius);
                        maxX = Math.ceil(ox+xWindowRadius);

                        // Convolve using the filter window
                        num = 0.0;
                        denom = 0.0;

                        for (dz = minZ; dz <= maxZ; ++dz) {
                            zWeight = zFilter(oz - dz, filterZScale);
                            if (zWeight !== 0.0) {
                                for (dy = minY; dy <= maxY; ++dy) {
                                    yWeight = yFilter(oy - dy, filterYScale);
                                    if (yWeight !== 0.0) {
                                        for (dx = minX; dx <= maxX; ++dx) {
                                            xWeight = xFilter(ox - dx, filterXScale);
                                            if (xWeight !== 0.0) {

                                                oldValue = minval;
                                                if (dx >= 0 && dx < oldWidth && dy >= 0 && dy < oldHeight && dz >= 0 && dz < oldDepth)
                                                    oldValue = oldPixels[(dz*oldHeight + dy)*oldWidth + dx + oldOffset];

                                                weight = xWeight * yWeight * zWeight;
                                                num += oldValue * weight;
                                                denom += weight;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        if (denom === 0.0) { denom = 1.0; }  // sanity check
                        value = num / denom;

                        // Copy the new pixel value to the array
                        newPixels[(z*newHeight + y)*newWidth + x + newOffset] = value;
                    }
                }
            }
        }
    }

    let endTime = Date.now();
    let deltaTime = (endTime - startTime) / 1000.0;

    dconsole.log("Done with volume resampling (time="+deltaTime+"s)");

    return Volume.generateVolume(newPixels, newWidth, newHeight, newDepth, count,
                                 scaleX, scaleY, scaleZ, orientation, nullValues);
};

// Generates an edge map for the anisotropic diffusion filter.
// This map may be re-used during multiple iterations by the filter.
Volume.prototype.generateAnisotropicEdgeMap = function(threshold, weight, index=0) {
    // Sanity checks
    if (threshold < 0.0001)  threshold = 0.0001;
    if (threshold > 1.0000)  threshold = 1.0000;
    if (weight < 0.0)  weight = 0.0;
    if (weight > 1.00)  weight = 1.00;
    if (index < 0 || index >= this.count)  return null;
    if (this.data === undefined || this.data === null)  return null;

    const width      = this.width;
    const height     = this.height;
    const depth      = this.depth;
    const data       = this.data;
    const volumeSize = width * height * depth;
    const offset     = index * volumeSize;

    dconsole.log("Generating anisotropic diffusion filter matrix...");
    let startTime = Date.now();

    if (width <= 0 || height <= 0 || depth <= 0)  return null;
    if (data === undefined || data === null)  return null;

    const matrixSize = width * height * depth * Volume.ADF_KERNEL_SIZE;

    let limits = this.calculateLimits(index);
    if (limits === null)  return null;

    const invLuminosityDelta = 1.0 / limits.luminosityDelta;

    // STM_TODO - use smart realloc
    let edgeMap = new Uint8Array(matrixSize);

    let pos = 0;
    for (let k=0; k<depth; ++k) {
        for (let j=0; j<height; ++j) {
            for (let i=0; i<width; ++i) {
                let center = data[((k*height) + j)*width + i + offset];
                for (let k0=-1; k0<=1; ++k0) {
                    let k1 = k + k0;
                    let kmult = 1.0 - Math.abs(k0*weight);
                    for (let j0=-1; j0<=1; ++j0) {
                        let j1 = j + j0;
                        let jmult = 1.0 - Math.abs(j0*weight);
                        for (let i0=-1; i0<=1; ++i0) {
                            let i1 = i + i0;
                            let imult = 1.0 - Math.abs(i0*weight);
                            if (i0 === 0 && j0 === 0 && k0 === 0) {
                                // no need to store this; it's always 255
                            }
                            else if (i1 >= 0 && i1 < width && j1 >= 0 && j1 < height && k1 >= 0 && k1 < depth) {
                                let cvalue = kmult * jmult * imult;
                                let edge = data[((k1*height) + j1)*width + i1 + offset];
                                // STM_TODO - should use gradients for threshold, not luminosity
                                let delta = Math.abs(center - edge) * invLuminosityDelta / threshold;
                                delta = 1.0 / (1.0 + delta*delta);
                                let kernelWeight = Math.ceil(delta * cvalue * 255.0);
                                //if (kernelWeight < 0)    kernelWeight = 0;
                                if (kernelWeight < 2)    kernelWeight = 2;  // STM_TODO - not sure if this is the right thing to do...
                                if (kernelWeight > 255)  kernelWeight = 255;

                                edgeMap[pos++] = kernelWeight;
                            }
                            else  {
                                edgeMap[pos++] = 0;
                            }
                        }
                    }
                }
            }
        }
    }

    let endTime = Date.now();
    let deltaTime = (endTime - startTime) / 1000.0;
    dconsole.log("Done generating anisotropic diffusion filter matrix (time="+deltaTime+"s)");

    return edgeMap;
};

// Runs an anisotropic diffusion filter on the specified volume, using a
// precomputed edge map.
// The original volume is modified in-place.
Volume.prototype.runAnisotropicFilterWithEdgeMap = function(edgeMap, iterations=2, index=0) {
    // Sanity checks
    if (!edgeMap)  return;
    if (iterations <= 0)  return;
    if (index < 0 || index >= this.count)  return;
    if (this.data === undefined || this.data === null)  return;

    const width      = this.width;
    const height     = this.height;
    const depth      = this.depth;
    const volumeSize = width * height * depth;
    const offset     = index * volumeSize;

    if (edgeMap.length < volumeSize*Volume.ADF_KERNEL_SIZE)  return;

    dconsole.log("Starting anisotropic diffusion filtering...");
    let startTime = Date.now();

    let array0 = new Float32Array(volumeSize);
    let array1 = new Float32Array(volumeSize);

    for (let i=0; i<volumeSize; ++i)  array0[i] = this.data[i+offset];

    let src = array0;
    let dst = array1;

    const anisotropicConvolve = function(src, dst) {
        let pos = 0;
        for (let k=0; k<depth; ++k) {
            for (let j=0; j<height; ++j) {
                for (let i=0; i<width; ++i) {
                    let num = 0.0;
                    let den = 0.0;
                    for (let k0=-1; k0<=1; ++k0) {
                        let k1 = k + k0;
                        for (let j0=-1; j0<=1; ++j0) {
                            let j1 = j + j0;
                            for (let i0=-1; i0<=1; ++i0) {
                                let i1 = i + i0;
                                let scale = 0.0;
                                if (i0 === 0 && j0 === 0 && k0 === 0) {
                                    scale = 255;
                                }
                                else {
                                    scale = edgeMap[pos++];
                                }
                                if (scale > 0) {
                                    let value = src[((k1*height) + j1)*width + i1];
                                    num += scale * value;
                                    den += scale;
                                }
                            }
                        }
                    }
                    let value = num / den;
                    dst[((k*height) + j)*width + i] = value;
                }
            }
        }
    };

    let temp;
    for (let i=0; i<iterations; ++i) {
        anisotropicConvolve(src, dst);
        temp = src; src = dst; dst = temp;
    }

    // Copy everything back to the original array
    for (let i=0; i<volumeSize; ++i)  this.data[i+offset] = src[i];

    // Null out our huge arrays, and let garbage collection do its job
    edgeMap = null;
    src     = null;
    dst     = null;
    temp    = null;
    array0  = null;
    array1  = null;

    this.clearLimits(index);

    let endTime = Date.now();
    let deltaTime = (endTime - startTime) / 1000.0;
    dconsole.log("Done with anisotropic diffusion filtering (time="+deltaTime+"s)");
};

// Runs an anisotropic diffusion filter on the specified volume, with the
// specified number of iterations.
// The original volume is modified in-place.
Volume.prototype.runAnisotropicFilter = function(threshold=0.01, weight=0.25, iterations=2, index=0) {
    if (iterations <= 0)  return;

    let edgeMap = this.generateAnisotropicEdgeMap(threshold, weight, index);

    this.runAnisotropicFilterWithEdgeMap(edgeMap, iterations, index);
};

// Applies an affine transformation to a volume, rearranging it in memory
// to match the specified matrix.
Volume.prototype.applyAffine = function(affine) {
    // This function reorganizes image data in memory based on an
    // affine transform provided by the calling method.

    // Sanity checks first
    if (!affine)               return;
    if (affine.length < 3)     return;
    if (affine[0].length < 3)  return;
    if (affine[1].length < 3)  return;
    if (affine[2].length < 3)  return;

    // Assert no rotation
    let rotation = false;
    for (let i=0; i<3; ++i) {
        let hcount = 0;
        let vcount = 0;
        for (let j=0; j<3; ++j) {
            if (affine[i][j] !== 0.0)  ++hcount;
            if (affine[j][i] !== 0.0)  ++vcount;
        }
        if (hcount !== 1 || vcount !== 1)  rotation = true;
    }
    if (rotation) {
        dconsole.error("Affine matrix performs a rotation, which is not supported by this interface. Aborting.");
        return;
    }

    // We could just do matrix multiplications with the affine matrix
    // to convert coordinates from our source to our destination coordinate
    // system, but that is inefficient for our purposes, especially since we're
    // not doing rotation at all and this conversion doesn't involve scaling.

    // The goal here is to map all pixels 1:1 from a source array to a
    // destination array, without any modification, scaling or interpolation
    // of values.

    let nlayers = Math.max(this.count, 1);
    let toPos = [0, 1, 2, 3];
    let oldscale = [this.scaleX, this.scaleY, this.scaleZ, 1.0];
    let newscale = [this.scaleX, this.scaleY, this.scaleZ, 1.0];
    let olddims = [this.width, this.height, this.depth, nlayers];
    let newdims = [this.width, this.height, this.depth, nlayers];

    // Map the basis vectors from the old coordinate system to the new one
    for (let i=0; i<3; ++i) {
        for (let j=0; j<3; ++j) {
            let value = affine[i][j];
            if (value !== 0.0) {
                toPos[i] = j;
                newscale[j] = value * oldscale[j];
                newdims[i] = olddims[j];
            }
        }
    }

    if (this.data !== undefined && this.data !== null) {
        // Compute axis strides for our source and destination.
        // Since our multidimensional "volume" is actually a flat 1D array,
        // we need to multiply our X, Y, Z and W coordinate values
        // by "strides" to locate their real position in memory.
        let srcStride = [1, 1, 1, 1];
        srcStride[0]     = 1;
        srcStride[1]     = srcStride[0] * olddims[0];
        srcStride[2]     = srcStride[1] * olddims[1];
        srcStride[3]     = srcStride[2] * olddims[2];
        let oldPixelSize = srcStride[3] * olddims[3];

        let dstStride = [1, 1, 1, 1];
        dstStride[toPos[0]] = 1;
        dstStride[toPos[1]] = dstStride[toPos[0]] * newdims[0];
        dstStride[toPos[2]] = dstStride[toPos[1]] * newdims[1];
        dstStride[toPos[3]] = dstStride[toPos[2]] * newdims[2];
        let newPixelSize    = dstStride[toPos[3]] * newdims[3];

        if (oldPixelSize !== newPixelSize) {
            dconsole.error("Internal error when creating new affine-transformed array");
            return;
        }

        let dstStart = [0, 0, 0, 0];
        for (let i=0; i<4; ++i) {
            if (newscale[i] < 0.0) {
                dstStart[i] = (olddims[i] - 1) * dstStride[i];
                dstStride[i] = -dstStride[i];
            }
        }

        // Short-circuit the process if the orientation isn't actually changing
        let startChanged = (dstStart[0] !== 0 || dstStart[1] !== 0 ||
                            dstStart[2] !== 0 || dstStart[3] !== 0);
        let strideChanged = (dstStride[0] !== srcStride[0] ||
                             dstStride[1] !== srcStride[1] ||
                             dstStride[2] !== srcStride[2] ||
                             dstStride[3] !== srcStride[3]);
        let dimsChanged = (newdims[0] !== olddims[0] || newdims[1] !== olddims[1] ||
                           newdims[2] !== olddims[2] || newdims[3] !== olddims[3]);

        if (startChanged || strideChanged || dimsChanged) {
            let newPixels = this.data;
            let oldPixels = new Float32Array(this.data);

            // Now copy the old data buffer to the new data buffer, transposing and
            // flipping axes as necessary
            for (let srcW=0; srcW<olddims[3]; ++srcW) {
                let srcWPos = srcW * srcStride[3];
                let dstWPos = dstStart[3] + srcW * dstStride[3];

                for (let srcZ=0; srcZ<olddims[2]; ++srcZ) {
                    let srcZPos = srcZ * srcStride[2];
                    let dstZPos = dstStart[2] + srcZ * dstStride[2];

                    for (let srcY=0; srcY<olddims[1]; ++srcY) {
                        let srcYPos = srcY * srcStride[1];
                        let dstYPos = dstStart[1] + srcY * dstStride[1];

                        for (let srcX=0; srcX<olddims[0]; ++srcX) {
                            let srcXPos = srcX * srcStride[0];
                            let dstXPos = dstStart[0] + srcX * dstStride[0];

                            let srcPos = srcXPos + srcYPos + srcZPos + srcWPos;
                            let dstPos = dstXPos + dstYPos + dstZPos + dstWPos;

                            newPixels[dstPos] = oldPixels[srcPos];
                        }
                    }
                }
            }

            newscale[0] = Math.abs(newscale[0]);
            newscale[1] = Math.abs(newscale[1]);
            newscale[2] = Math.abs(newscale[2]);
            newscale[3] = Math.abs(newscale[3]);

            this.data   = newPixels;
        }
    }

    let newWidth  = newdims[0];
    let newHeight = newdims[1];
    let newDepth  = newdims[2];
    let newScaleX = newscale[toPos[0]];
    let newScaleY = newscale[toPos[1]];
    let newScaleZ = newscale[toPos[2]];

    // Set new dimension and scale values
    this.width  = newWidth;
    this.height = newHeight;
    this.depth  = newDepth;
    this.scaleX = newScaleX;
    this.scaleY = newScaleY;
    this.scaleZ = newScaleZ;
};

// Applies an orientation to a volume, rearranging it in memory to match
// the provided orientation.
Volume.prototype.applyOrientation = function(orientation) {
    orientation = Volume.sanitizeOrientation(orientation);
    if (!orientation)  return;

    let affine = Volume.generateAffine(this.orientation, orientation);
    if (!affine)  return;

    this.applyAffine(affine);

    // STM_TODO - this should probably be in applyAffine(), not here...
    this.orientation = orientation;

};

// STM_TODO - TEMP - TEST CODE
Volume.prototype.setAreaSphere = function(radius=115.0, index=0) {
    if (radius < 1.0)  return;
    if (index < 0 || index >= this.count)  return;
    if (data === undefined || data === null)  return;

    const data       = this.data;
    const width      = this.width;
    const height     = this.height;
    const depth      = this.depth;
    const volumeSize = width * height * depth;
    const offset     = index * volumeSize;

    const scaleX     = this.scaleX;
    const scaleY     = this.scaleY;
    const scaleZ     = this.scaleZ;

    const centerX = width / 2.0;
    const centerY = height / 2.0;
    const centerZ = depth / 2.0;

    const border = 3.0;

    for (let k = 0; k < depth; ++k) {
        let z = (k - centerZ) * scaleZ;
        if (z > 0.0)  z = 0.0;
        for (let j = 0; j < height; ++j) {
            let y = (j - centerY) * scaleY;
            for (let i = 0; i < width; ++i) {
                let x = (i - centerX) * scaleX;

                let value = 0;
                let dist = Math.sqrt(x*x + y*y + z*z);
                if      (dist > radius)         value = 0.0;
                else if (dist < radius-border)  value = 1.0;
                else                            value = (radius - dist) / border;

                data[(k*height + j)*width + i + offset] = value;
            }
        }
    }

    this.clearLimits(index);
};
