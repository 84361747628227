// Import our external dependencies.
import $ from 'jquery';
import * as quat from "gl-matrix/src/gl-matrix/quat";
import * as mat3 from "gl-matrix/src/gl-matrix/mat3";

// PRIVATE METHODS ============================================================

// Gets the current time, in seconds.
Animate.getCurrentTime = function() {
    return Date.now() / 1000.0;
};

// Adds a callback with relevant parameters to a callback list.
Animate.addCallback = function(entry, callbackList) {
    if (entry.callback !== null) {
        let params = {
            index:         entry.index,
            startTime:     entry.startTime,
            duration:      entry.duration,
            timeOffset:    entry.timeOffset,
            timeDelta:     entry.timeDelta,
            offset:        entry.offset,
            mappedOffset:  entry.mappedOffset,
            callback:      entry.callback
        }
        callbackList.push(params);
    }
};

// Computes a mapped offset.
Animate.prototype.computeMappedOffset = function(entry) {
    if      (entry.mapper)        return entry.mapper(entry.offset);
    else if (this.defaultMapper)  return this.defaultMapper(entry.offset);
    else                          return entry.offset;
};

// Requests an animation frame for updating, if one hasn't already been
// requested.
Animate.prototype.startUpdate = function() {
    if (this.animationHandle === null) {
        const animate = this;
        this.animationHandle = requestAnimationFrame(function() {
            // Only animate if we're running in realtime
            if (animate.realtime)
                animate.animate();
        });
    }
};

// Cancels an animation frame, if one was requested.
Animate.prototype.stopUpdate = function() {
    if (this.animationHandle !== null) {
        cancelAnimationFrame(this.animationHandle);
        this.animationHandle = null;
    }
};

// Resets the schedule to make it ready for another run.
Animate.prototype.resetSchedule = function() {
    let schedule = this.schedule;
    if (schedule !== null) {
        for (let i=0; i<schedule.length; ++i) {
            let entry = schedule[i];
            entry.timeOffset   = null;
            entry.timeDelta    = null;
            entry.offset       = null;
            entry.mappedOffset = null;
        }
    }
};

// Processes a single entry in the schedule.
Animate.prototype.processEntry = function(entry, callbackList) {
    const currentTimeOffset = this.currentTimeOffset;

    if (currentTimeOffset < entry.startTime)           return;  // not started yet
    if (entry.offset !== null && entry.offset >= 1.0)  return;  // already finished

    if (entry.timeOffset === null) {
        // First time (or last time, if duration === 0.0)
        entry.animation    = this;
        entry.timeOffset   = 0.0;
        entry.timeDelta    = 0.0;
        entry.offset       = entry.duration > 0.0 ? 0.0 : 1.0;
        entry.mappedOffset = this.computeMappedOffset(entry);

        Animate.addCallback(entry, callbackList);
    }

    let oldTimeOffset = entry.timeOffset;
    let oldOffset     = entry.offset;

    if (currentTimeOffset >= entry.endTime) {  // duration is implicitly non-zero
        // Last time
        entry.timeOffset = entry.duration;
        entry.offset     = 1.0;
    }
    else {
        // Somewhere in between...
        entry.timeOffset = currentTimeOffset - entry.startTime;
        entry.offset     = entry.timeOffset / entry.duration;
    }

    // Clamp
    if (entry.offset < 0.0)  entry.offset = 0.0;
    if (entry.offset > 1.0)  entry.offset = 1.0;

    entry.timeDelta    = entry.timeOffset - oldTimeOffset;
    entry.mappedOffset = this.computeMappedOffset(entry);

    if (entry.timeOffset !== oldTimeOffset || entry.offset !== oldOffset) {
        Animate.addCallback(entry, callbackList);
    }
};

// Processes all entries in the schedule.
Animate.prototype.processEntries = function() {
    let callbackList = [];

    const schedule = this.schedule;
    for (let i=0; i<schedule.length; ++i) {
        let entry = schedule[i];
        this.processEntry(entry, callbackList);
    }

    // Sort by timestamp, so that processing order is guaranteed
    // regardless of framerate
    if (callbackList.length > 1) {
        callbackList.sort(function(params0, params1) {
            const time0 = params0.startTime + params0.timeOffset;
            const time1 = params1.startTime + params1.timeOffset;

            if      (time0 < time1)                  return -1;
            else if (time0 > time1)                  return 1;
            else if (params0.index < params1.index)  return -1;
            else if (params0.index > params1.index)  return 1;
            else                                     return 0;
        });
    }

    for (let i=0; i<callbackList.length; ++i) {
        const params = callbackList[i];
        params.callback(params);
    }
};

// Animation callback that is invoked periodically.
Animate.prototype.animate = function(timestamp) {
    this.animationHandle = null;

    if (!this.isRunning()) {
        // Nothing to see here, move along
        return;
    }

    let currentTime = Animate.getCurrentTime();

    if (this.realtime) {
        // Animation is running in realtime
        this.currentTime       = currentTime;
        this.currentTimeOffset = this.currentTime - this.startTime;
    }
    else {
        // Animation is running at a fixed framerate
        this.currentTime       = currentTime;
        this.currentTimeOffset = this.currentTimeOffset + 1.0 / this.framerate;
        this.startTime         = this.currentTime - this.currentTimeOffset;
    }

    if (this.currentTimeOffset < this.windowStartTime) {
        let delta = this.windowStartTime - this.currentTimeOffset;
        this.currentTimeOffset += delta;
        this.startTime -= delta;
    }

    this.processEntries();

    if (this.currentTimeOffset < this.windowEndTime) {
        // We're not done with this cycle; continue animation loop
        this.startUpdate();
    }
    else if (this.repeat && this.windowDuration > 0.0) {
        // We've finished this cycle, but are looping; start over
        this.currentTimeOffset -= this.windowStartTime;
        this.currentTimeOffset = this.currentTimeOffset % this.windowDuration;
        this.currentTimeOffset += this.windowStartTime;
        this.startTime = this.currentTime - this.currentTimeOffset;
        this.resetSchedule();
        this.processEntries();

        this.startUpdate();
    }
    else {
        // Done with animation
        this.stop();
    }
};

// PUBLIC STATIC METHODS ======================================================

// Maps offset to either 0 or 1 with no interpolation.
Animate.mapBox = function(offset) {
    if (offset < 0.5)  return 0.0;
    else               return 1.0;
};

// Maps offset to itself (implicit linear interpolation).
Animate.mapLinear = function(offset) {
    if      (offset <= 0.0)  return 0.0;
    else if (offset >= 1.0)  return 1.0;
    else                     return offset;
};

// Maps offset using cubic Hermite interpolation (smooth start and end).
Animate.mapSmooth = function(offset) {
    if      (offset <= 0.0)  return 0.0;
    else if (offset >= 1.0)  return 1.0;
    else                     return offset * offset * (3.0 - 2.0 * offset);
};

Animate.mapSmoother = function(offset) {
    if      (offset <= 0.0)  return 0.0;
    else if (offset >= 1.0)  return 1.0;
    else                     return offset*offset*offset*(offset*(offset*6.0-15.0)+10.0);
};

// Maps offset using a sine wave function (smooth start and end).
Animate.mapSine = function(offset) {
    if      (offset <= 0.0)  return 0.0;
    else if (offset >= 1.0)  return 1.0;
    else                     return -0.5 * Math.cos(offset*Math.PI) + 0.5;
};

// Maps offset using cubic Hermite interpolation (eases in).
Animate.mapEaseIn = function(offset) {
    if      (offset <= 0.0)  return 0.0;
    else if (offset >= 1.0)  return 1.0;
    else {
        offset *= 0.5;
        let mapOffset = offset * offset * (3.0 - 2.0 * offset);
        mapOffset *= 2.0;
        return mapOffset;
    }
};

// Maps offset using cubic Hermite interpolation (eases out).
Animate.mapEaseOut = function(offset) {
    if      (offset <= 0.0)  return 0.0;
    else if (offset >= 1.0)  return 1.0;
    else {
        offset = offset * 0.5 + 0.5;
        let mapOffset = offset * offset * (3.0 - 2.0 * offset);
        mapOffset = (mapOffset - 0.5) * 2.0;
        return mapOffset;
    }
};

// Maps offset using a full sine wave, looping back on itself (start and
// end offsets are zero).
Animate.mapCircle = function(offset) {
    if      (offset <= 0.0)  return 0.0;
    else if (offset >= 1.0)  return 0.0;
    else                     return -0.5 * Math.cos(offset*Math.PI*2.0) + 0.5;
};

// Creates an animation object with the specified schedule.
Animate.createAnimation = function(schedule=null, repeat=false, defaultMapper=undefined) {
    let animate = new Animate();
    animate.setAnimationSchedule(schedule, repeat);
    animate.setDefaultMapper(defaultMapper);
    return animate;
};

// Interpolates between two unknown types.  (Actually uses nearest neighbor.)
Animate.interpolateUnknown = function(offset, value0, value1) {
    if (offset < 0.5)  return value0;
    else               return value1;
};

// Interpolates between two numeric values.
Animate.interpolateNumber = function(offset, value0, value1) {
    if (offset === 0.0)  return value0;
    if (offset === 1.0)  return value1;

    return (value1 - value0) * offset + value0;
};

// Intepolates between two colors.
Animate.interpolateColor = function(offset, value0, value1) {
    // STM_TODO - use HSV for interpolation
    if (!value0 || !value1)  return Animate.interpolateUnknown(offset, value0, value1);

    let red   = Animate.interpolateNumber(offset, value0.red,   value1.red);
    let green = Animate.interpolateNumber(offset, value0.green, value1.green);
    let blue  = Animate.interpolateNumber(offset, value0.blue,  value1.blue);
    let alpha = Animate.interpolateNumber(offset, value0.alpha, value1.alpha);

    return {
        red:   red,
        green: green,
        blue:  blue,
        alpha: alpha
    };
};

// Interpolates between two color entries (value + color).
Animate.interpolateColorEntry = function(offset, value0, value1) {
    if (!value0 || !value1)  return Animate.interpolateUnknown(offset, value0, value1);

    let value = Animate.interpolateNumber(offset, value0.value, value1.value);
    let color = Animate.interpolateColor(offset, value0.color, value1.color);

    return {
        value: value,
        color: color
    };
};

// Interpolates between two color tables.
Animate.interpolateColorTable = function(offset, value0, value1) {
    // STM_TODO - dependency on VolumeViewer here.  Should create a new ColorMap class.
    if (!value0 || !value1)  return Animate.interpolateUnknown(offset, value0, value1);

    if (offset <= 0.0)  return value0;
    if (offset >= 1.0)  return value1;

    let table0 = VolumeViewer.convertColorTable(value0);
    let table1 = VolumeViewer.convertColorTable(value1);
    let table2 = [];

    let key;
    let color0, color1;

    function toColorEntry(arrayColor) {
        return {
            value: arrayColor[0],
            color: {
                red:   arrayColor[1][0],
                green: arrayColor[1][1],
                blue:  arrayColor[1][2],
                alpha: arrayColor[1][3]
            }
        }
    }

    let pos0 = 0;
    let pos1 = 0;
    while (true) {
        let key0 = pos0 < table0.length ? table0[pos0][0] : null;
        let key1 = pos1 < table1.length ? table1[pos1][0] : null;

        if (key0 === null && key1 === null)  break;

        if (key1 === null) {
            key = key0;
            color0 = toColorEntry(table0[pos0]);
            color1 = VolumeViewer.calculateTransferColor(table1, key);
            ++pos0;
        }
        else if (key0 === null) {
            key = key1;
            color0 = VolumeViewer.calculateTransferColor(table0, key);
            color1 = toColorEntry(table1[pos1]);
            ++pos1;
        }
        else if (key0 < key1) {
            key = key0;
            color0 = toColorEntry(table0[pos0]);
            color1 = VolumeViewer.calculateTransferColor(table1, key);
            ++pos0;
        }
        else if (key0 > key1) {
            key = key1;
            color0 = VolumeViewer.calculateTransferColor(table0, key);
            color1 = toColorEntry(table1[pos1]);
            ++pos1;
        }
        else {
            key = key0;  // = key1
            color0 = toColorEntry(table0[pos0]);
            color1 = toColorEntry(table1[pos1]);
            ++pos0;
            ++pos1;
        }

        let entry = Animate.interpolateColorEntry(offset, color0, color1);
        table2.push(entry);
    }

    return table2;
};

// Interpolates between two 3x3 rotation matrices.
Animate.interpolateMatrix = function(offset, value0, value1) {
    if (offset <= 0.0) {
        return {
            m00: value0.m00, m01: value0.m01, m02: value0.m02,
            m10: value0.m10, m11: value0.m11, m12: value0.m12,
            m20: value0.m20, m21: value0.m21, m22: value0.m22
        };
    }
    if (offset >= 1.0 ||
        (value0.m00 === value1.m00 &&
         value0.m01 === value1.m01 &&
         value0.m02 === value1.m02 &&
         value0.m10 === value1.m10 &&
         value0.m11 === value1.m11 &&
         value0.m12 === value1.m12 &&
         value0.m20 === value1.m20 &&
         value0.m21 === value1.m21 &&
         value0.m22 === value1.m22)) {
        return {
            m00: value1.m00, m01: value1.m01, m02: value1.m02,
            m10: value1.m10, m11: value1.m11, m12: value1.m12,
            m20: value1.m20, m21: value1.m21, m22: value1.m22
        };
    }

    // STM_TODO - make this work with mat4 or other matrix types.
    let m0 = mat3.create();
    let m1 = mat3.create();
    let m2 = mat3.create();
    let q0 = quat.create();
    let q1 = quat.create();
    let q2 = quat.create();

    function copyMatrix(m, obj) {
        m[0] = obj.m00 ? obj.m00 : 0.0;
        m[1] = obj.m01 ? obj.m01 : 0.0;
        m[2] = obj.m02 ? obj.m02 : 0.0;
        m[3] = obj.m10 ? obj.m10 : 0.0;
        m[4] = obj.m11 ? obj.m11 : 0.0;
        m[5] = obj.m12 ? obj.m12 : 0.0;
        m[6] = obj.m20 ? obj.m20 : 0.0;
        m[7] = obj.m21 ? obj.m21 : 0.0;
        m[8] = obj.m22 ? obj.m22 : 0.0;
    }

    copyMatrix(m0, value0);
    copyMatrix(m1, value1);
    quat.fromMat3(q0, m0);
    quat.fromMat3(q1, m1);
    quat.slerp(q2, q0, q1, offset);
    mat3.fromQuat(m2, q2);
    return {
        m00: m2[0], m01: m2[1], m02: m2[2],
        m10: m2[3], m11: m2[4], m12: m2[5],
        m20: m2[6], m21: m2[7], m22: m2[8]
    };
};

// Interpolates between two arrays, by interpolating between all of the
// individual values in the arrays.
Animate.interpolateArray = function(offset, value0, value1) {
    if (!value0 || !value1)  return Animate.interpolateUnknown(offset, value0, value1);

    let len0 = value0.length;
    let len1 = value1.length;

    let value2 = [];
    if (offset < 0.5)  value2.length = len0;
    else               value2.length = len1;

    for (let i=0; i<value2.length; ++i) {
        let subValue0 = i < len0 ? value0[i] : undefined;
        let subValue1 = i < len1 ? value1[i] : undefined;

        value2[i] = Animate.interpolate(offset, subValue0, subValue1);
    }

    return value2;
};

// Interpolates between two dictionaries, by interpolating between all of the
// individual entries in the dictionaries.
Animate.interpolateDictionary = function(offset, value0, value1) {
    if (!value0 || !value1)  return Animate.interpolateUnknown(offset, value0, value1);

    if (offset <= 0.0)  return value0;
    if (offset >= 1.0)  return value1;

    let value2 = {};
    let keys;
    keys = Object.keys(value0);
    for (let i=0; i<keys.length; ++i)
        value2[keys[i]] = null;

    keys = Object.keys(value1);
    for (let i=0; i<keys.length; ++i)
        value2[keys[i]] = null;

    keys = Object.keys(value2);
    for (let i=0; i<keys.length; ++i) {
        let key = keys[i];
        let subValue0 = (key in value0) ? value0[key] : undefined;
        let subValue1 = (key in value1) ? value1[key] : undefined;

        value2[key] = Animate.interpolate(offset, subValue0, subValue1);
    }

    return value2;
};

// Generic interpolation method.  Interpolates between two unspecified data values.
Animate.interpolate = function(offset, value0, value1) {
    function isDictionary(obj) {
        return typeof obj === 'object' && obj !== null && !(obj instanceof Array) && !(obj instanceof Date);
    };
    function isArray(obj) {
        if (obj)
            if (Array.isArray(obj))
                return true;
        return false;
    }
    function isColor(obj) {
        if (isDictionary(obj))
            if ('red' in obj && 'green' in obj && 'blue' in obj)
                return true;
        return false;
    }
    function isColorEntry(obj) {
        if (isDictionary(obj))
            if ('value' in obj && 'color' in obj)
                if (isColor(obj.color))
                    return true;
        return false;
    }
    function isColorTable(obj) {
        if (isArray(obj)) {
            if (obj.length > 0) {
                let isTable = true;
                for (let i=0; i<obj.length; ++i) {
                    if (!isColorEntry(obj[i])) {
                        isTable = false;
                        break;
                    }
                }
                if (isTable)
                    return true;
            }
        }
        return false;
    }
    function isMatrix(obj) {
        if (isDictionary(obj)) {
            if ('m00' in obj && 'm01' in obj && 'm02' in obj &&
                'm10' in obj && 'm11' in obj && 'm12' in obj &&
                'm20' in obj && 'm21' in obj && 'm22' in obj) {
                return true;
            }
        }
        return false;
    }

    if (typeof value0 === 'string') {
        let num = Number(value0);
        if (!Number.isNaN(num))
            value0 = num;
    }
    if (typeof value1 === 'string') {
        let num = Number(value1);
        if (!Number.isNaN(num))
            value1 = num;
    }

    if (value0 === value1)  return value1;

    if (value0 === null || value1 === null) {
        return Animate.interpolateUnknown(offset, value0, value1);
    }
    if (typeof value0 !== typeof value1) {
        return Animate.interpolateUnknown(offset, value0, value1);
    }
    if (typeof value0 === 'number') {
        return Animate.interpolateNumber(offset, value0, value1);
    }
    if (typeof value0 === 'boolean' || typeof value0 === 'function' ||
        typeof value0 === 'string') {
        return Animate.interpolateUnknown(offset, value0, value1);
    }
    if (typeof value0 === 'object') {
        if (isMatrix(value0) && isMatrix(value1)) {
            return Animate.interpolateMatrix(offset, value0, value1);
        }
        if (isColorTable(value0) && isColorTable(value1)) {
            return Animate.interpolateColorTable(offset, value0, value1);
        }
        if (isColorEntry(value0) && isColorEntry(value1)) {
            return Animate.interpolateColorEntry(offset, value0, value1);
        }
        if (isColor(value0) && isColor(value1)) {
            return Animate.interpolateColor(offset, value0, value1);
        }
        if (isArray(value0) && isArray(value1)) {
            return Animate.interpolateArray(offset, value0, value1);
        }
        if (isDictionary(value0) && isDictionary(value1)) {
            return Animate.interpolateDictionary(offset, value0, value1);
        }
        return Animate.interpolateUnknown(offset, value0, value1);
    }

    return Animate.interpolateUnknown(offset, value0, value1);
};


// PUBLIC METHODS =============================================================

// Constructor for an Animate object.
export default function Animate() {
    this.schedule          = null;
    this.totalTime         = 0.0;
    this.repeat            = false;
    this.realtime          = true;
    this.framerate         = 30.0;

    this.startTime         = null;
    this.pauseTime         = null;
    this.currentTime       = null;
    this.currentTimeOffset = null;

    this.windowStartTime   = 0.0;
    this.windowEndTime     = 0.0;
    this.windowDuration    = 0.0;

    this.animationHandle   = null;

    this.defaultMapper     = null;

    this.stopFunction      = null;
}

// Sets the default mapper used by the animation object, if no mapper
// is specified for a schedule entry.
Animate.prototype.setDefaultMapper = function(defaultMapper) {
    this.defaultMapper = defaultMapper;
};

// Sets the fixed framerate at which the animation will occur, in frames
// per second.  If 0 is specified, the animation will run in real time.
Animate.prototype.setAnimationFramerate = function(fps) {
    if (fps === undefined || fps === null || fps <= 0.0) {
        this.realtime = true;
        return;
    }

    this.realtime  = false;
    this.framerate = fps;
};

// Forces the animation to run in real time.
Animate.prototype.clearAnimationFramerate = function() {
    this.setFramerate(null);
};

// Sets the animation schedule for the Animate object.
Animate.prototype.setAnimationSchedule = function(schedule, repeat=false,
                                                  windowStartTime=undefined, windowEndTime=undefined) {
    let isRunning = this.isRunning();

    this.stop();

    if (!schedule) {
        this.schedule  = null;
        this.totalTime = 0.0;
        this.repeat    = false;

        this.setAnimationWindow();

        return;
    }

    function isArray(obj) {
        let isObj = typeof obj==='object' && obj!==null;
        let isArray = isObj && obj instanceof Array;
        return isArray;
    }

    if (isArray(schedule)) {
        schedule = { series: schedule };
    }

    let newSchedule = [];

    function copyEntry(startTime, entry, maxLevels) {
        if (maxLevels <= 0)  return startTime;

        if (entry.series !== undefined) {
            if (isArray(entry.series))  {
                // Sub-entries are in series
                let endTime = startTime;
                let nextTime = startTime;
                for (let i=0; i<entry.series.length; ++i) {
                    let subEntry = entry.series[i];
                    nextTime = copyEntry(nextTime, subEntry, maxLevels-1);
                    if (endTime < nextTime)  endTime = nextTime;
                }
                return endTime;
            }
        }
        else if (entry.parallel !== undefined) {
            if (isArray(entry.parallel)) {
                // Sub-entries are in parallel
                let endTime = startTime;
                let nextTime = startTime;
                for (let i=0; i<entry.parallel.length; ++i) {
                    let subEntry = entry.parallel[i];
                    nextTime = copyEntry(startTime, subEntry, maxLevels-1);
                    if (endTime < nextTime)  endTime = nextTime;
                }
                return endTime;
            }
        }
        else {
            // Entry is leaf node with no children
            let duration = 0.0;
            let callback = null;

            if (entry.start !== undefined)                             startTime = startTime + entry.start;
            if (entry.duration !== undefined && entry.duration > 0.0)  duration = entry.duration;
            if (entry.callback !== undefined)                          callback = entry.callback;

            let endTime = startTime + duration;

            if (callback !== null) {
                let mapper = null;

                if (entry.mapper !== undefined)  mapper = entry.mapper;

                let index = newSchedule.length;

                let newEntry = {
                    index:        index,
                    startTime:    startTime,
                    endTime:      endTime,
                    duration:     duration,
                    mapper:       mapper,
                    callback:     callback,

                    timeOffset:   null,
                    timeDelta:    null,
                    offset:       null,
                    mappedOffset: null

                };
                newSchedule.push(newEntry);
            }

            return endTime;
        }

        return startTime;
    }

    let endTime = copyEntry(0.0, schedule, 32);

    let totalTime = endTime;
    if (totalTime < 0.0)  totalTime = 0.0;

    this.schedule  = newSchedule;
    this.totalTime = totalTime;
    this.repeat    = repeat;

    this.setAnimationWindow(windowStartTime, windowEndTime);

    if (isRunning)
        this.start();
};

// Sets the time window for the current animation.
Animate.prototype.setAnimationWindow = function(windowStartTime=undefined, windowEndTime=undefined, relative=false) {
    if (windowStartTime === undefined || windowStartTime === null)  windowStartTime = 0;
    if (windowEndTime === undefined || windowEndTime === null)      windowEndTime   = relative ? 1.0 : this.totalTime;

    if (relative) {
        const totalTime = this.totalTime;
        windowStartTime *= totalTime;
        windowEndTime   *= totalTime;
    }

    if (windowStartTime < 0)              windowStartTime = 0;
    if (windowEndTime > this.totalTime)   windowEndTime   = this.totalTime;
    if (windowEndTime < windowStartTime)  windowEndTime   = windowStartTime;

    let windowDuration = windowEndTime - windowStartTime;

    this.windowStartTime = windowStartTime;
    this.windowEndTime   = windowEndTime;
    this.windowDuration  = windowDuration;
};

// Gets the time window for the current animation.
Animate.prototype.getAnimationWindow = function() {

    let relativeMult = this.totalTime > 0.0 ? 1.0 / this.totalTime : 0.0;

    let relativeStartTime = this.windowStartTime;
    let relativeEndTime   = this.windowEndTime;
    if (this.totalTime > 0.0) {
        relativeStartTime /= this.totalTime;
        relativeEndTime   /= this.totalTime;
    }
    else {
        relativeStartTime = 0.0;
        relativeEndTime   = 1.0;
    }

    return {
        windowStartTime:   this.windowStartTime,
        windowEndTime:     this.windowEndTime,
        relativeStartTime: relativeStartTime,
        relativeEndTime:   relativeEndTime
    };
};

// Returns the total time for the current animation.
Animate.prototype.getTotalTime = function() {
    return this.totalTime;
};

// Starts the animation.
Animate.prototype.start = function(stopFunction=undefined) {
    if (this.pauseTime !== null) {
        this.resume();
        return;
    }

    if (this.startTime !== null)  return;  // already running
    if (this.schedule === null)   return;  // no schedule

    this.startTime = Animate.getCurrentTime() - this.windowStartTime;

    this.startUpdate();

    // Replace the stop function, if one was specified
    if (stopFunction !== undefined) {
        const oldStopFunction = this.stopFunction;
        this.stopFunction = stopFunction;

        // Now that we've replaced the old stop function, guarantee its invocation
        if (oldStopFunction)
            oldStopFunction();
    }
};

// Stops and resets the animation.
Animate.prototype.stop = function() {
    this.stopUpdate();
    this.resetSchedule();

    this.startTime         = null;
    this.pauseTime         = null;
    this.currentTime       = null;
    this.currentTimeOffset = null;

    const oldStopFunction = this.stopFunction;
    this.stopFunction     = null;

    // Invoke the stop function, if one was specified
    if (oldStopFunction)
        oldStopFunction();
};

// Fast-forwards the animation all the way to the end, then stops.
Animate.prototype.finish = function() {
    if (this.startTime !== null)  {
        const currentTime = Animate.getCurrentTime();

        // Resume if necessary
        if (this.pauseTime !== null) {
            let deltaTime = currentTime - this.pauseTime;
            if (deltaTime > 0.0)
                this.startTime += deltaTime;
            this.pauseTime = null;
        }

        // Fast forward to the end
        this.currentTime       = currentTime;
        this.currentTimeOffset = this.totalTime;
        this.startTime         = this.currentTime - this.currentTimeOffset;
        this.processEntries();

        this.stop();
    }
};

// Pauses the animation at the current time.  The animation may later
// be resumed.
Animate.prototype.pause = function() {
    if (this.startTime !== null) {
        if (this.pauseTime === null) {
            this.pauseTime = Animate.getCurrentTime();
            this.stopUpdate();
        }
    }
};

// Resumes the animation after a pause.
Animate.prototype.resume = function() {
    if (this.startTime !== null)  {
        if (this.pauseTime !== null) {
            let currentTime = Animate.getCurrentTime();
            let deltaTime = currentTime - this.pauseTime;
            if (deltaTime > 0.0)
                this.startTime += deltaTime;
            this.pauseTime = null;
            this.startUpdate();
        }
    }
};

// Resets the animation to tbe beginning.  Does not change the running state.
Animate.prototype.reset = function() {
    const isRunning = this.isRunning();

    const oldStopFunction = this.stopFunction;
    this.stopFunction     = null;

    this.stop();
    if (isRunning)
        this.start(oldStopFunction);
};

// Returns true if the animation is running, or false otherwise.
Animate.prototype.isRunning = function() {
    if (this.startTime === null || this.pauseTime !== null ||
        this.schedule === null)
        return false;
    else
        return true;

};
